import { asyncConnect } from 'redux-connect';
import { fromJS } from 'immutable';
import {
  addCredit,
  download,
  hasLiked,
  isCommentsLoaded,
  isCreditsLoaded,
  isProjectLoaded,
  isCollectionLoaded,
  isRelatedProjectsLoaded,
  like,
  likeComment,
  loadCommentReplies,
  loadComments,
  loadCredits,
  loadProject,
  loadCollection,
  loadRelatedProjects,
  postComment,
  reportComment,
  reportProject,
  unLike,
  unLikeComment,
} from 'redux/modules/project';
import {
  isLoaded as isSuggestedRolesLoaded,
  load as loadSuggestedRoles,
} from 'redux/modules/suggestedRoles2';
import {
  isLoaded as isSuggestedSkillsLoaded,
  load as loadSuggestedSkills,
} from 'redux/modules/suggestedSkills2';
import { setSuccessMessage } from 'redux/modules/successMessage';
import loadable from '@loadable/component';

const ProjectBase = loadable(() => import('./ProjectBase'));

const Project = asyncConnect(
  [
    {
      promise: ({
        match: {
          params: { hash },
        },
        store: { dispatch, getState },
      }) => {
        const promises = [];

        const projectHash = 'c' + hash;

        if (!isProjectLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadProject(projectHash)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { hash },
        },
      }) => {
        const promises = [];

        const projectHash = 'c' + hash;

        if (!isCollectionLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadCollection(projectHash)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { hash },
        },
      }) => {
        const promises = [];

        const projectHash = 'c' + hash;

        if (!isCreditsLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadCredits(projectHash)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { hash },
        },
      }) => {
        const promises = [];

        const projectHash = 'c' + hash;

        if (!isCommentsLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadComments(projectHash)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { hash },
        },
      }) => {
        const promises = [];

        const projectHash = 'c' + hash;

        if (!isRelatedProjectsLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadRelatedProjects(projectHash, 5)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { hash },
        },
      }) => {
        const promises = [];

        const projectHash = 'c' + hash;

        if (!isSuggestedRolesLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadSuggestedRoles()));
        }

        if (!isSuggestedSkillsLoaded(getState(), projectHash)) {
          promises.push(dispatch(loadSuggestedSkills()));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    user: state.auth.user,
    creditsEntities: fromJS(state.entities.credits),
    loadingSuggestedRoles: state.suggestedRoles2.loading,
    loadingSuggestedSkills: state.suggestedSkills2.loading,
    project: fromJS(state.project),
    projectCommentsEntities: fromJS(state.entities.projectComments),
    projectEntities: fromJS(state.entities.projects),
    suggestedRoles: state.suggestedRoles2.suggestedRoles,
    suggestedSkills: state.suggestedSkills2.suggestedSkills,
    userEntities: fromJS(state.entities.users),
    roleEntities: fromJS(state.entities.roles),
    skillEntities: fromJS(state.entities.skills),
  }),
  {
    addCredit,
    download,
    hasLiked,
    isCommentsLoaded,
    isCreditsLoaded,
    isProjectLoaded,
    isCollectionLoaded,
    isRelatedProjectsLoaded,
    like,
    likeComment,
    loadCommentReplies,
    loadComments,
    loadCredits,
    loadProject,
    loadCollection,
    loadRelatedProjects,
    loadSuggestedRoles,
    loadSuggestedSkills,
    postComment,
    reportComment,
    reportProject,
    setSuccessMessage,
    unLike,
    unLikeComment,
  }
)(ProjectBase);

export default Project;
