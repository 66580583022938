const LOADSUBSCRIPTIONPORTALURL =
  'twineApp/subscriptions/LOADSUBSCRIPTIONPORTALURL';
const LOADSUBSCRIPTIONPORTALURL_SUCCESS =
  'twineApp/subscriptions/LOADSUBSCRIPTIONPORTALURL_SUCCESS';
const LOADSUBSCRIPTIONPORTALURL_FAIL =
  'twineApp/subscriptions/LOADSUBSCRIPTIONPORTALURL_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  subscriptionUrl: '',
  errors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADSUBSCRIPTIONPORTALURL:
      return { ...state, loading: true };
    case LOADSUBSCRIPTIONPORTALURL_SUCCESS:
      return {
        ...state,
        subscriptionUrl: action.result.url,
        loading: false,
        loaded: true,
      };
    case LOADSUBSCRIPTIONPORTALURL_FAIL:
      return { ...state, errors: action.errors, loading: false, loaded: true };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [
      LOADSUBSCRIPTIONPORTALURL,
      LOADSUBSCRIPTIONPORTALURL_SUCCESS,
      LOADSUBSCRIPTIONPORTALURL_FAIL,
    ],
    promise: (client) => client.get(`/subscriptions/customer-portal`),
  };
}
