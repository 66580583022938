const PENDINGRATING = 'twineApp/rating/PENDINGRATING';
const PENDINGRATING_SUCCESS = 'twineApp/rating/PENDINGRATING_SUCCESS';
const PENDINGRATING_FAIL = 'twineApp/rating/PENDINGRATING_FAIL';
const RATE = 'twineApp/rating/RATE';
const RATE_SUCCESS = 'twineApp/rating/RATE_SUCCESS';
const RATE_FAIL = 'twineApp/rating/RATE_FAIL';

const initialState = {
  loadingPendingRatings: false,
  loadedPendingRatings: false,
  pendingRatings: [],
  rating: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PENDINGRATING:
      return {
        ...state,
        loadingPendingRatings: true,
      };

    case PENDINGRATING_SUCCESS:
      return {
        ...state,
        loadingPendingRatings: false,
        loadedPendingRatings: true,
        pendingRatings:
          typeof action.result === 'undefined' ||
          action.result.constructor === Array
            ? state.pendingRatings
            : state.pendingRatings.concat(action.result.threads),
      };

    case PENDINGRATING_FAIL:
      return {
        ...state,
        loadingPendingRatings: false,
      };

    case RATE:
      return {
        ...state,
        rating: true,
      };

    case RATE_SUCCESS:
      return {
        ...state,
        rating: false,
      };

    case RATE_FAIL:
      return {
        ...state,
        rating: false,
      };

    default:
      return state;
  }
}

export function loadPendingRatings(user) {
  return {
    types: [PENDINGRATING, PENDINGRATING_SUCCESS, PENDINGRATING_FAIL],
    promise: (client) =>
      client.get(
        '/users/' + user.id + '/rating/pending?include=message.sender'
      ),
  };
}

export function rate(userId, briefId, rating, comment = '') {
  return {
    types: [RATE, RATE_SUCCESS, RATE_FAIL],
    promise: (client) =>
      client.post('/users/' + userId + '/rating', {
        data: { for: 'collaboration', notice_id: briefId, rating, comment },
      }),
  };
}
