import { creativeMoreExamples } from 'helpers/HelperFunctions';

const hasRole = (user) =>
  user &&
  user.featured_roles &&
  user.featured_roles.length > 0 &&
  user.featured_roles[0];

const MEDIUM_STEPS = [
  {
    label: 'Profile picture',
    isComplete: (user) => !user.avatars.placeholder || user.avatars.temp,
    redir: ['about', 'portfolio-avatar'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Add a bio',
    isComplete: (user) => user.bio.length > 0,
    redir: ['about', 'portfolio-bio'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Location',
    isComplete: (user) => user.location.method === 'html5',
    redir: ['about', 'portfolio-location'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Freelance roles',
    isComplete: (user) => user.featured_roles.length > 0,
    redir: ['about', 'portfolio-roles'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Specific skills',
    isComplete: (user) => user.skills.length > 0,
    redir: ['about', 'portfolio-skills'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Industry sectors',
    isComplete: (user) => user.sectors.length > 0,
    redir: ['about', 'portfolio-sectors'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
];

const HIGH_STEPS = [
  {
    label: 'Profile cover image',
    isComplete: (user) => !user.covers.placeholder || user.covers.temp,
    redir: ['about', 'portfolio-cover'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Testimonials',
    isComplete: (user) => user.stats.num_of_testimonials > 0,
    redir: ['about', 'portfolio-testimonials'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: 'Skill experience level',
    isComplete: (user) => user.skills.some((skill) => skill.experience > 0),
    redir: ['about', 'portfolio-experience'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-edit-my-portfolio-sdtjwv/',
  },
  {
    label: (user) => {
      const role = hasRole(user);

      let label = 'Add your projects';

      const numOfProjects = user.stats.num_of_projects || 0;

      const [, remaining] =
        (role && creativeMoreExamples(role, numOfProjects)) || Array(2);

      if (remaining && numOfProjects > 0)
        label = `Examples – ${remaining} more advised`;

      return label;
    },
    isComplete: (user) => {
      const role = hasRole(user);

      const numOfProjects = user.stats.num_of_projects || 0;

      // Defaults to `> 0` comparison, when no `featured_role` exists
      return role
        ? !creativeMoreExamples(role, numOfProjects)
        : numOfProjects > 0;
    },
    redir: ['about', 'portfolio-credits'],
    helpCentreLink:
      'https://help.twine.net/en/article/how-to-add-examples-to-your-portfolio-yppzco/',
  },
];

export { MEDIUM_STEPS, HIGH_STEPS };
