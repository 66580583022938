import { asyncConnect } from 'redux-connect';
import { fromJS } from 'immutable';
import { denormalize } from 'normalizr';
import { getUserByUsername, loadUser } from 'redux/modules/users';
import {
  acceptPitch,
  connectPayPal,
  declinePitch,
  getPaymentToken,
} from 'redux/modules/briefDetails';
import {
  loadInbox,
  loadMessages,
  loadPendingPitches,
  loadAcceptedPitches,
  loadAcceptedBriefs,
  updateNewMessage,
  sendMessage,
  sendFile,
  isIntro,
  decrementMessageCount,
  deleteMessage,
  clearMessages,
  clearInbox,
  archiveThread,
  markAsRead,
  // WebSockets
  loadNewMessage,
} from 'redux/modules/messages';

import { setUserTyping, setNewMessage } from 'redux/modules/websockets'; // Redux
import { get } from 'helpers/HelperFunctions';
import { sendUserReport } from 'redux/modules/reports';
import messageSchema from 'redux/schemas/messageSchema';
import threadSchema from 'redux/schemas/threadSchema';

import loadable from '@loadable/component';

const MessagesBase = loadable(() => import('./MessagesBase'));
const Messages = asyncConnect(
  [
    {
      promise: ({
        match: { params },
        location: {
          query: { archived },
        },
        store: { dispatch, getState },
      }) => {
        const promises = [];
        const store = getState();

        if (!store.messages.inboxLoaded) {
          promises.push(dispatch(loadInbox(1, archived)));
        }

        if (params.username) {
          if (!getUserByUsername(store, params.username)) {
            promises.push(dispatch(loadUser(params.username)));
          }

          promises.push(dispatch(loadMessages(params.username)));
          promises.push(dispatch(loadPendingPitches(params.username)));
          promises.push(dispatch(loadAcceptedPitches(params.username)));
        }

        return Promise.all(promises);
      },
    },
  ],
  (
    state,
    {
      match: {
        params: { username },
      },
    }
  ) => ({
    authUser: state.auth.user,
    selectedUser: getUserByUsername(state, username),
    paymentToken: state.briefDetails.paymentToken,
    inbox: state.messages.inbox,
    inboxLoaded: state.messages.inboxLoaded,
    inboxLoading: state.messages.inboxLoading,
    inboxPagesLoaded: state.messages.inboxPagesLoaded,
    inboxMore: state.messages.inboxMore,
    messages: state.messages.messages,
    normMessages: denormalize(
      get(state, ['messages', 'messages', username, 'messages'], []),
      [messageSchema],
      state.entities
    ),
    introsLeft: state.messages.introsLeft,
    introsLoading: state.messages.introsLoading,
    loadingUsers: state.users.loading,
    messageEntities: state.entities.messages,
    userEntities: state.entities.users,
    threadEntities: state.entities.threads,
    // threads: getInboxThreads(state),
    threads: denormalize(state.messages.inbox, [threadSchema], state.entities),
    briefEntities: state.entities.briefs,
    voucherEntities: state.entities.vouchers,
    pitches: fromJS(state.messages.pitches),
    acceptedPitches: fromJS(state.messages.acceptedPitches),
    acceptedBriefs: fromJS(state.messages.acceptedBriefs),
    sendingFiles: state.briefDetails.sendingFiles,
    banners: state.banner.banners,
    // WebSockets
    // `usertyping` action
    userIsTyping: state.websockets.userIsTyping,
    // `newmessage` action
    newMessageAvailable: state.websockets.newMessageAvailable,
    newMessageSenderId: state.websockets.newMessageSenderId,
  }),
  {
    loadInbox,
    loadMessages,
    loadPendingPitches,
    loadAcceptedPitches,
    loadAcceptedBriefs,
    loadUser,
    updateNewMessage,
    sendMessage,
    sendFile,
    decrementMessageCount,
    isIntro,
    acceptPitch,
    declinePitch,
    deleteMessage,
    getPaymentToken,
    connectPayPal,
    clearMessages,
    clearInbox,
    archiveThread,
    markAsRead,
    // WebSockets
    setUserTyping, // `usertyping` action
    setNewMessage, // `newmessage` action
    loadNewMessage, // Websocket API request onNudge
    sendUserReport,
  }
)(MessagesBase);

export default Messages;
