import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { disconnectUndock } from 'redux/modules/auth';
import usePrevious from 'hooks/usePrevious';
import * as Sentry from '@sentry/react';

const AlertHandler = ({ error, successMessage, disconnectUndock, user }) => {
  const previousError = usePrevious(error);
  const previousSuccessMessage = usePrevious(successMessage);

  useEffect(() => {
    if (error && error !== '') {
      // Log the error to Sentry
      Sentry.captureException(new Error(error), {
        logger: 'user-errors',
        extra: {
          username: user ? user.username : null,
          runtime: typeof window !== 'undefined' ? 'client' : 'server',
        },
      });

      // Show the error to the user
      if (
        !previousError &&
        typeof error === 'string' &&
        typeof window !== 'undefined'
      ) {
        showError();
      }
    }

    errorHandling();
  }, [error]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof successMessage === 'string' &&
      previousSuccessMessage !== successMessage &&
      successMessage &&
      successMessage !== ''
    ) {
      showSuccess();
    }
  }, [successMessage]);

  const showError = () => {
    Alert.error(error, {
      position: 'top',
      effect: 'jelly',
      timeout: 3000,
    });
  };

  const errorHandling = () => {
    if (error === 'Your session has expired, please log into Undock.') {
      disconnectUndock();
    }
  };

  const showSuccess = () => {
    Alert.success(successMessage, {
      position: 'top',
      effect: 'jelly',
      timeout: 5 * 1000, // 5 seconds
    });
  };

  return <noscript />;
};

export default connect(() => ({}), {
  disconnectUndock,
})(AlertHandler);
