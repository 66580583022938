import { jsonToFormData } from 'helpers/HelperFunctions';

const GENERATEPITCH = 'twineApp/pitchWriter/GENERATEPITCH';
const GENERATEPITCH_SUCCESS = 'twineApp/pitchWriter/GENERATEPITCH_SUCCESS';
const GENERATEPITCH_FAIL = 'twineApp/pitchWriter/GENERATEPITCH_FAIL';

const initialState = {
  loadingGeneratedPitch: false,
  loadedGeneratedPitch: false,
  generatedPitch: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GENERATEPITCH:
      return {
        ...state,
        loadingGeneratedPitch: true,
      };
    case GENERATEPITCH_SUCCESS:
      return {
        ...state,
        generatedPitch: action.result.pitch,
        loadingGeneratedPitch: false,
        loadedGeneratedPitch: true,
      };
    case GENERATEPITCH_FAIL:
      return {
        ...state,
        error: action.error,
        loadingGeneratedPitch: false,
        loadedGeneratedPitch: true,
      };
    default:
      return state;
  }
}

export function generatePitch(data) {
  return {
    types: [GENERATEPITCH, GENERATEPITCH_SUCCESS, GENERATEPITCH_FAIL],
    promise: (client) =>
      client.post(`/pitch_writer`, { data: jsonToFormData(data) }),
  };
}
