import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Third Party
import { Navbar } from 'react-bootstrap';
// Twine Components
import SvgIcon from 'components/SvgIcon/SvgIcon';

// Styles
import styles from './StickyNavBar.module.scss';
import NavBarCTA from '../Main/NavBarCTA/NavBarCTA';

export default function StickyNavBar({ user, onProjectPage, onJobsPage }) {
  const [showNavBar, setShowNavBar] = useState(false);
  const scrollPosition = 0;

  const [displayUpsellButton, setDisplayUpsellButton] = useState(false);
  const [displayApplyButton, setDisplayApplyButton] = useState(false);
  const [displaySignUpButton, setDisplaySignUpButton] = useState(false);

  useEffect(() => {
    ctaToDisplay();
  }, [onProjectPage, onJobsPage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 78) {
      setShowNavBar(true);
    }
    if (position < 79) {
      setShowNavBar(false);
    }
  };

  const ctaToDisplay = () => {
    setDisplayUpsellButton(false);
    setDisplayApplyButton(false);
    setDisplayApplyButton(false);

    if (user) {
      if (onJobsPage && !user.pro) {
        setDisplayUpsellButton(true);
        return;
      }

      if (onProjectPage) {
        setDisplayApplyButton(true);
        return;
      }
    }

    if (!user) {
      setDisplaySignUpButton(true);
      return;
    }
  };

  return (
    <>
      {showNavBar && (
        <div className={styles.StickyNavBar}>
          <Navbar fixedTop staticTop inverse>
            <div className={styles.StickyNavContent}>
              <Navbar.Brand>
                <a href="/">
                  <div className={styles.brand}>
                    <SvgIcon icon="logo" alt="Twine" />
                  </div>
                </a>
              </Navbar.Brand>
              <div
                className="visible-xs-block right"
                style={{ marginTop: '20px', marginRight: '80px' }}
              ></div>

              <div className={styles.ctaContainer}>
                <NavBarCTA
                  displayUpsellButton={displayUpsellButton}
                  displayApplyButton={displayApplyButton}
                  displaySignUpButton={displaySignUpButton}
                />
              </div>
            </div>
          </Navbar>
        </div>
      )}
    </>
  );
}

StickyNavBar.defaultProps = {
  onProjectPage: false,
  onJobsPage: false,
};

StickyNavBar.propTypes = {
  user: PropTypes.object,
  onProjectPage: PropTypes.bool,
  onJobsPage: PropTypes.bool,
};
