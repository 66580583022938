import { schema } from 'normalizr';
import userSchema from './userSchema';

const inviteSchema = new schema.Entity('invites');

inviteSchema.define({
  user: userSchema,
});

export default inviteSchema;
