import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import { update as updateUser } from 'redux/modules/auth';

@connect(
  ({ auth: { user } }) => ({
    user,
  }),
  {
    updateUser,
  },
  null,
  { forwardRef: true }
)
export default class EmailConfirmation extends Component {
  static propTypes = {
    user: PropTypes.object,
    banner: PropTypes.object,
    removeBanner: PropTypes.func,
    updateUser: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      resent: false,
    };
  }

  onResend = () => {
    this.props.updateUser(this.props.user, {
      email: this.props.banner.message,
    });

    this.setState(
      {
        resent: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            resent: false,
          });
        }, 5000);
      }
    );
  };

  onCancel = () => {
    this.props.updateUser(this.props.user, {
      email_new: null,
    });

    this.props.removeBanner(this.props.banner.tag);
  };

  render() {
    const { banner } = this.props;

    const { resent } = this.state;

    return (
      <div>
        <span
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
          className="mr++"
        >
          Please confirm your email. A confirmation email was sent to{' '}
          <strong>{banner.message}</strong>. Your email will not be changed
          until you complete this step!
        </span>
        <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          <Button bsStyle="success" disabled={resent} onClick={this.onResend}>
            {resent ? 'Email Resent' : 'Resend'}
          </Button>
          <Button bsStyle="info" className="ml--" onClick={this.onCancel}>
            Cancel
          </Button>
        </span>
      </div>
    );
  }
}
