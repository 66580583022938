import { schema } from 'normalizr';
import messageSchema from './messageSchema';
import userSchema from './userSchema';

const threadSchema = new schema.Entity('threads');

threadSchema.define({
  message: messageSchema,
  user: userSchema,
});

export default threadSchema;
