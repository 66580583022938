import roleSchema from '../schemas/roleSchema';

const LOAD = 'twineApp/suggestedRoles2/LOAD';
const LOAD_SUCCESS = 'twineApp/suggestedRoles2/LOAD_SUCCESS';
const LOAD_FAIL = 'twineApp/suggestedRoles2/LOAD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  suggestedRoles: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        suggestedRoles: action.result.suggested_roles,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.suggestedRoles2 && globalState.suggestedRoles2.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.get(
        '/suggestedRoles?include=defaultQuestions,questions&limit=1000'
      ),
    schema: { suggested_roles: [roleSchema] },
  };
}
