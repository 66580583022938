import { schema } from 'normalizr';
import userSchema from './userSchema';

const projectSchema = new schema.Entity('projects');

projectSchema.define({
  user: userSchema,
});

export default projectSchema;
