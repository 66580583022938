import React from 'react';
import Panel from 'components/Panel/Panel';
import styles from './MarkdownSection.module.scss';

const MarkdownSection = () => {
  return (
    <Panel className={styles.container}>
      <p className={styles.title}>Markdown and Embeds</p>
      <p>
        You can use Markdown syntax in the description of your project. Please
        see <a href="https://www.markdownguide.org/basic-syntax/">this guide</a>{' '}
        for how to use Markdown
      </p>
      <p>You can also add embeds for the following services:</p>
      <ul>
        <li>YouTube</li>
        <li>TikTok</li>
        <li>Instagram</li>
        <li>Vimeo</li>
        <li>Soundcloud</li>
        <li>Behance</li>
      </ul>
      <p>
        Please see{' '}
        <a href="https://help.twine.net/en/article/how-to-add-embeds-to-a-project-138v75z/">
          this guide
        </a>{' '}
        for how to add Embeds to your project
      </p>
    </Panel>
  );
};

export default MarkdownSection;
