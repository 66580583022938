import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ManageProjectsCard.module.scss';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import { useDispatch } from 'react-redux';
import { deleteProject } from 'redux/modules/textProjects';
import AlertBox from 'components/AlertBox/AlertBox';

const ManageProjectsCard = ({ project }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirm = () => {
    dispatch(deleteProject(project.id));
    setShowDeleteModal(false);
    window.location.reload();
  };

  return (
    <>
      <AlertBox
        show={showDeleteModal}
        title="Delete Project"
        text={'Are you sure you want to delete this project?'}
        confirmButtonText={"Yes I'm sure"}
        onConfirm={() => handleDeleteConfirm()}
        onCancel={() => setShowDeleteModal(false)}
        theme="danger"
      />
      <div className={styles.projectCard}>
        <a className={styles.projectInfo} href={project.links.main_relative}>
          <div className={styles.projectTitle}>{project.title}</div>
          Added {moment(project.created_at).fromNow()}
        </a>
        <div className={`${styles.projectButtons} ${styles.desktop}`}>
          <Button className={styles.button} href={project.links.edit}>
            <SvgIcon icon="pencil" size="16" />
            Edit
          </Button>
          <Button
            bsStyle="danger"
            className={styles.button}
            onClick={() => setShowDeleteModal(true)}
          >
            <SvgIcon icon="trash" size="16" />
            Delete
          </Button>
        </div>
        <div className={`${styles.projectButtons} ${styles.mobile}`}>
          <Button className={styles.button} href={project.links.edit}>
            <SvgIcon icon="pencil" size="16" />
          </Button>
          <Button
            bsStyle="danger"
            className={styles.button}
            onClick={() => setShowDeleteModal(true)}
          >
            <SvgIcon icon="trash" size="16" />
          </Button>
        </div>
      </div>
    </>
  );
};

ManageProjectsCard.propTypes = {
  project: PropTypes.object,
  user: PropTypes.object,
};

export default ManageProjectsCard;
