import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactStars from 'react-stars';
import Icon from 'components/Icon/Icon';
import Modal from 'components/Modal/Modal';
import Button from 'react-bootstrap/lib/Button';

export default class RatingModal extends Component {
  static propTypes = {
    pitch: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onRated: PropTypes.func.isRequired,
    brief: PropTypes.object,
    onExit: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      rated: false,
      showRatingDialog: true,
      showRatingFeedback: false,
      ratingValue: 5,
      ratingComment: '',
    };
  }

  handleRating() {
    this.props.onRated(
      this.props.user.id,
      this.props.pitch.notice_id,
      this.state.ratingValue,
      this.state.ratingComment
    );

    this.setState({
      rated: true,
      showRatingDialog: this.state.ratingValue > 4 ? true : false,
      showRatingFeedback: this.state.ratingValue > 4 ? true : false,
    });
  }

  handleExit() {
    if (this.props.onExit) {
      this.props.onExit();
    }

    this.setState({
      showRatingDialog: false,
      showRatingFeedback: false,
      rated: false,
    });
  }

  render() {
    const {
      rated,
      showRatingDialog,
      showRatingFeedback,
      ratingValue,
      ratingComment,
    } = this.state;

    const { user, brief, title, subtitle } = this.props;

    return (
      <Modal show={showRatingDialog} onExit={this.handleExit.bind(this)}>
        <div
          onClick={this.handleExit.bind(this)}
          style={{ float: 'right', color: '#aaa' }}
          className="pointer"
        >
          <Icon icon="&#xe60d;" size="1.3em" />
        </div>
        {!rated && <h4 className="mb+">{title ? title : 'Rate'}</h4>}
        <div>
          {rated ? (
            <div className="pt">
              <p>Thanks for rating {user.displayname}</p>
              {showRatingFeedback && (
                <div>
                  <p>
                    We would love to hear your story, and with your permission,
                    potentially write a case study about it. We will then
                    promote these through our social channels and on Twine.
                  </p>
                  <p>
                    <a
                      href={
                        'https://jointwine.typeform.com/to/N4gTf6?username=' +
                        user.username +
                        (brief ? '&url=' + brief.links.main : '')
                      }
                    >
                      Click here to tell us your story
                    </a>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <p>
                {subtitle
                  ? subtitle
                  : 'You recently completed a project with ' +
                    user.displayname +
                    '. How would you rate them?'}
              </p>
              <div id="center-stars">
                <ReactStars
                  count={5}
                  value={ratingValue}
                  color1="#aaa"
                  color2="#eeca37"
                  size={48}
                  half={false}
                  onChange={(value) => this.setState({ ratingValue: value })}
                />
              </div>
              <div className="mt">
                <textarea
                  placeholder="Please leave a comment"
                  value={ratingComment}
                  onChange={(event) =>
                    this.setState({ ratingComment: event.target.value })
                  }
                  maxLength="140"
                  className="form-control"
                  rows="3"
                />
              </div>
              <div className="mt">
                <Button
                  bsStyle="success"
                  disabled={ratingValue === 0 && ratingComment === ''}
                  onClick={this.handleRating.bind(this)}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
