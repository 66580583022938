import { fromJS } from 'immutable';
import { jsonToFormData } from '../../helpers/HelperFunctions';
import billSchema from '../schemas/billSchema';

const SENDBILL = 'twineApp/messages/SENDBILL';
const SENDBILL_SUCCESS = 'twineApp/messages/SENDBILL_SUCCESS';
const SENDBILL_FAIL = 'twineApp/messages/SENDBILL_FAIL';
const GETBILLTOTALS = 'twineApp/messages/GETBILLTOTALS';
const GETBILLTOTALS_SUCCESS = 'twineApp/messages/GETBILLTOTALS_SUCCESS';
const GETBILLTOTALS_FAILURE = 'twineApp/messages/GETBILLTOTALS_FAILURE';
const GETTIMEULARENTRIES = 'twineApp/messages/GETTIMEULARENTRIES';
const GETTIMEULARENTRIES_SUCCESS =
  'twineApp/messages/GETTIMEULARENTRIES_SUCCESS';
const GETTIMEULARENTRIES_FAILURE = 'twineApp/messages/GETBILLTOTALS_FAILURE';

const initialState = {
  savingBill: false,
  loadingBills: false,
  loadedBills: false,
  billTotals: null,
  bills: [],
  timeularEntries: [],
  loadingTimeularEntries: false,
  loadedTimeularEntries: false,
  timeularError: false,
};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case SENDBILL:
      return imState
        .mergeDeep({
          savingBill: true,
        })
        .toJS();

    case SENDBILL_SUCCESS:
      return imState
        .mergeDeep({
          savingBill: false,
        })
        .toJS();

    case SENDBILL_FAIL:
      return imState
        .mergeDeep({
          savingBill: false,
        })
        .toJS();

    case GETBILLTOTALS:
      return imState
        .mergeDeep({
          loadingBills: true,
          loadedBills: false,
          billTotals: null,
          bills: [],
        })
        .toJS();

    case GETBILLTOTALS_SUCCESS:
      return imState
        .mergeDeep({
          loadingBills: false,
          loadedBills: true,
          billTotals: action.result.totals,
          bills: action.entities.bill,
        })
        .toJS();

    case GETBILLTOTALS_FAILURE:
      return imState
        .mergeDeep({
          loadingBills: false,
          loadedBills: false,
          billTotals: null,
          bills: [],
        })
        .toJS();

    case GETTIMEULARENTRIES:
      return imState
        .mergeDeep({
          loadingTimeularEntries: true,
          loadedTimeularEntries: false,
        })
        .toJS();

    case GETTIMEULARENTRIES_SUCCESS:
      return imState
        .mergeDeep({
          loadingTimeularEntries: false,
          loadedTimeularEntries: true,
          timeularEntries: action.result.time_entries || [],
          timeularError: action.result.error ? action.result.error : false,
        })
        .toJS();

    case GETTIMEULARENTRIES_FAILURE:
      return imState
        .mergeDeep({
          loadingTimeularEntries: false,
          loadedTimeularEntries: false,
          timeularError: 'Timeular request failed.',
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export function getTimeularEntries(userId, messageId) {
  return {
    types: [
      GETTIMEULARENTRIES,
      GETTIMEULARENTRIES_SUCCESS,
      GETTIMEULARENTRIES_FAILURE,
    ],
    promise: (client) =>
      client.post(`/users/${userId}/timeular`, {
        data: jsonToFormData({
          messageId: messageId,
        }),
      }),
  };
}

export function sendBill(
  pitchId,
  statementOfWork,
  amount,
  timeularEntryIds,
  invoice
) {
  const data = {
    pitch_id: pitchId,
    statement_of_work: statementOfWork,
    amount,
  };

  if (timeularEntryIds.length > 0) {
    data.timeEntries = timeularEntryIds;
  }

  if (Object.keys(invoice).length > 0) {
    for (const [key, value] of Object.entries(invoice)) {
      if (!value || value === 'null' || value === 'select' || value === '+') {
        delete invoice[key];
      }
    }

    data.invoice = invoice;
  }

  return {
    types: [SENDBILL, SENDBILL_SUCCESS, SENDBILL_FAIL],
    promise: (client) =>
      client.post('/bills', {
        data: jsonToFormData(data),
      }),
    schema: billSchema,
  };
}

export function loadBills(pitchId, billIds) {
  return {
    promise: (client) => {
      const result = client.get(
        `/bills/${pitchId}?include=fees&ids=${billIds}`
      );
      return Promise.resolve(result);
    },
    types: [GETBILLTOTALS, GETBILLTOTALS_SUCCESS, GETBILLTOTALS_FAILURE],
  };
}

export function calculatePaymentForBills(pitchId, billIds) {
  return {
    promise: (client) => {
      const results = client.get(
        `/bills/${pitchId}?ids=${billIds}&include=fees`
      );
      return Promise.resolve(results);
    },
    schema: { bills: [billSchema] },
    types: [GETBILLTOTALS, GETBILLTOTALS_SUCCESS, GETBILLTOTALS_FAILURE],
  };
}

export function calculatePaymentForAmount(pitchId, amount) {
  return {
    promise: (client) => {
      const results = client.get(
        `/bills/${pitchId}/calculate-fee?amount=${amount}`
      );
      return Promise.resolve(results);
    },
    schema: { bills: [billSchema] },
    types: [GETBILLTOTALS, GETBILLTOTALS_SUCCESS, GETBILLTOTALS_FAILURE],
  };
}
