import { schema } from 'normalizr';
import userSchema from './userSchema';

const projectCommentsSchema = new schema.Entity('projectComments');

projectCommentsSchema.define({
  replies: [projectCommentsSchema],
  user: userSchema,
});

export default projectCommentsSchema;
