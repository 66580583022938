const LOADPAYMENTDETAILS = 'twineApp/paymentDetails/LOADPAYMENTDETAILS';
const LOADPAYMENTDETAILS_SUCCESS =
  'twineApp/paymentDetails/LOADPAYMENTDETAILS_SUCCESS';
const LOADPAYMENTDETAILS_FAIL =
  'twineApp/paymentDetails/LOAPAYMENTDETAILSD_FAIL';
const UPDATEPAYMENTDETAILS = 'twineApp/paymentDetails/UPDATEPAYMENTDETAILS';
const UPDATEPAYMENTDETAILS_SUCCESS =
  'twineApp/paymentDetails/UPDATEPAYMENTDETAILS_SUCCESS';
const UPDATEPAYMENTDETAILS_FAIL =
  'twineApp/paymentDetails/UPDATEPAYMENTDETAILS_FAIL';
const UPDATEPAYMENTMETHOD = 'twineApp/paymentDetails/UPDATEPAYMENTMETHOD';
const UPDATEPAYMENTMETHOD_SUCCESS =
  'twineApp/paymentDetails/UPDATEPAYMENTMETHOD_SUCCESS';
const UPDATEPAYMENTMETHOD_FAIL =
  'twineApp/paymentDetails/UPDATEPAYMENTMETHOD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  updated: false,
  updating: false,
  paymentDetails: {},
  paymentDetailsErrors: {},
  paymentMethodErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADPAYMENTDETAILS:
      return {
        ...state,
        loading: true,
      };

    case LOADPAYMENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        paymentDetails: action.result ? action.result.user_payment_detail : {},
      };

    case LOADPAYMENTDETAILS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case UPDATEPAYMENTDETAILS:
      return {
        ...state,
        updating: true,
        paymentDetailsErrors: {},
      };

    case UPDATEPAYMENTDETAILS_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        paymentDetails: action.result,
        paymentDetailsErrors: {},
      };

    case UPDATEPAYMENTDETAILS_FAIL:
      return {
        ...state,
        updating: false,
        updated: false,
        paymentDetailsErrors: action.error.errors,
        error: 'Please fill in all required fields',
      };

    case UPDATEPAYMENTMETHOD:
      return {
        ...state,
        updating: true,
        paymentMethodErrors: {},
      };

    case UPDATEPAYMENTMETHOD_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        paymentDetails: action.result,
        paymentMethodErrors: {},
      };

    case UPDATEPAYMENTMETHOD_FAIL:
      return {
        ...state,
        updating: false,
        updated: false,
        paymentMethodErrors: action.error.errors,
        error: 'Please fill in all required fields',
      };

    default:
      return state;
  }
}

export function load(userId) {
  return {
    types: [
      LOADPAYMENTDETAILS,
      LOADPAYMENTDETAILS_SUCCESS,
      LOADPAYMENTDETAILS_FAIL,
    ],
    promise: (client) => client.get(`/users/${userId}/paymentDetails`),
  };
}

export function updatePaymentDetails(userId, data) {
  let nullData = {};

  Object.keys(data).forEach((key) => {
    nullData[key] =
      data[key] === null || data[key] === undefined ? '' : data[key];
  });

  return {
    types: [
      UPDATEPAYMENTDETAILS,
      UPDATEPAYMENTDETAILS_SUCCESS,
      UPDATEPAYMENTDETAILS_FAIL,
    ],
    promise: (client) =>
      client.post(`/users/${userId}/paymentDetails`, {
        data: nullData,
      }),
  };
}

export function updatePaymentMethod(userId, data) {
  let nullData = {};

  Object.keys(data).forEach((key) => {
    nullData[key] =
      data[key] === null || data[key] === undefined ? '' : data[key];
  });

  return {
    types: [
      UPDATEPAYMENTMETHOD,
      UPDATEPAYMENTMETHOD_SUCCESS,
      UPDATEPAYMENTMETHOD_FAIL,
    ],
    promise: (client) =>
      client.post(`/users/${userId}/paymentDetailsMethod`, {
        data: nullData,
      }),
  };
}
