import { asyncConnect } from 'redux-connect';
import {
  isLoaded as isSuggestedRolesLoaded,
  load as loadSuggestedRoles,
} from 'redux/modules/suggestedRoles2';
import {
  isLoaded as isSuggestedSkillsLoaded,
  load as loadSuggestedSkills,
} from 'redux/modules/suggestedSkills2';
import { loadStats } from 'redux/modules/stats';

import loadable from '@loadable/component';

const CategoriesBase = loadable(() => import('./CategoriesBase'));

const Categories = asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        const promises = [];

        if (!isSuggestedRolesLoaded(getState())) {
          promises.push(dispatch(loadSuggestedRoles()));
        }

        if (!isSuggestedSkillsLoaded(getState())) {
          promises.push(dispatch(loadSuggestedSkills()));
        }

        if (!getState().stats.loadedStats) {
          promises.push(dispatch(loadStats()));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    authUser: state.auth.user,
    loadedSuggestedRoles: state.suggestedRoles2.loaded,
    loadedSuggestedSkills: state.suggestedSkills2.loaded,
    loadingSuggestedRoles: state.suggestedRoles2.loading,
    loadingSuggestedSkills: state.suggestedSkills2.loading,
    suggestedRoles: state.suggestedRoles2.suggestedRoles,
    suggestedSkills: state.suggestedSkills2.suggestedSkills,
    roleEntities: state.entities.roles,
    skillEntities: state.entities.skills,
    stats: state.stats,
    selectedRoles: [],
    selectedSkills: [],
  }),
  {
    loadStats,
    loadSuggestedRoles,
    loadSuggestedSkills,
  }
)(CategoriesBase);

export default Categories;
