const GETCOMPANYDATA = 'twineApp/companies/GETCOMPANYDATA';
const GETCOMPANYDATA_SUCCESS = 'twineApp/companies/GETCOMPANYDATA_SUCCESS';
const GETCOMPANYDATA_FAIL = 'twineApp/companies/GETCOMPANYDATA_FAIL';

const CREATECOMPANYDATA = 'twineApp/companies/CREATECOMPANYDATA';
const CREATECOMPANYDATA_SUCCESS =
  'twineApp/companies/CREATECOMPANYDATA_SUCCESS';
const CREATECOMPANYDATA_FAIL = 'twineApp/companies/CREATECOMPANYDATA_FAIL';

const UPDATECOMPANYDATA = 'twineApp/companies/UPDATECOMPANYDATA';
const UPDATECOMPANYDATA_SUCCESS =
  'twineApp/companies/UPDATECOMPANYDATA_SUCCESS';
const UPDATECOMPANYDATA_FAIL = 'twineApp/companies/UPDATECOMPANYDATA_FAIL';

const DELETECOMPANYDATA = 'twineApp/companies/DELETECOMPANYDATA';
const DELETECOMPANYDATA_SUCCESS =
  'twineApp/companies/DELETECOMPANYDATA_SUCCESS';
const DELETECOMPANYDATA_FAIL = 'twineApp/companies/DELETECOMPANYDATA_FAIL';

const initialState = {
  company: null,

  loadedCompany: false,
  loadingCompany: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Get Company data
    case GETCOMPANYDATA:
      return {
        ...state,

        loadingCompany: true,
      };

    case GETCOMPANYDATA_SUCCESS:
      return {
        ...state,
        company: action.result.companies ? action.result.companies[0] : null,

        loadedCompany: true,
        loadingCompany: false,
      };

    case GETCOMPANYDATA_FAIL:
      return {
        ...state,
        company: null,

        loadedCompany: true,
        loadingCompany: false,
      };

    // Create Company data
    case CREATECOMPANYDATA:
      return {
        ...state,

        loadingCompany: true,
      };

    case CREATECOMPANYDATA_SUCCESS:
      return {
        ...state,
        company: action.result,

        loadedCompany: true,
        loadingCompany: false,
      };

    case CREATECOMPANYDATA_FAIL:
      return {
        ...state,
        company: null,

        loadedCompany: true,
        loadingCompany: false,
      };

    // Update Company data
    case UPDATECOMPANYDATA:
      return {
        ...state,

        loadingCompany: true,
      };

    case UPDATECOMPANYDATA_SUCCESS:
      return {
        ...state,
        company: action.result,

        loadedCompany: true,
        loadingCompany: false,
      };

    case UPDATECOMPANYDATA_FAIL:
      return {
        ...state,
        company: null,

        loadedCompany: true,
        loadingCompany: false,
      };

    // Delete Company data
    case DELETECOMPANYDATA:
      return {
        ...state,
      };

    case DELETECOMPANYDATA_SUCCESS:
      return {
        ...state,
        company: null,
      };

    case DELETECOMPANYDATA_FAIL:
      return {
        ...state,
        company: null,
      };

    default:
      return state;
  }
}

export function getCompanyForClient(clientID = -1) {
  return {
    types: [GETCOMPANYDATA, GETCOMPANYDATA_SUCCESS, GETCOMPANYDATA_FAIL],
    promise: (client) => client.get(`/company?client_id=${clientID}`),
  };
}

/**
 * Removes all keys which have `null` values
 *
 * @param {Object} data
 * @returns Object
 */
const sanitiseData = (data = {}) => {
  // '+' is the default value for the react-telephone-input package
  // 'select' is the default value for the country input

  for (const [key, value] of Object.entries(data)) {
    if (!value || value === 'null' || value === 'select' || value === '+') {
      delete data[key];
    }
  }

  return data;
};

export function createNewCompany(userID = -1, data = {}) {
  return {
    types: [
      CREATECOMPANYDATA,
      CREATECOMPANYDATA_SUCCESS,
      CREATECOMPANYDATA_FAIL,
    ],
    promise: (client) =>
      client.post(`/company`, {
        data: sanitiseData({
          user_id: userID,

          legal_company_name: data.legalCompanyName,
          vat_number: data.vatNumber,

          person_of_contact_name: data.contactName,
          person_of_contact_job_title: data.contactJobTitle,
          person_of_contact_email: data.contactEmail,

          address1: data.address1,
          address2: data.address2,
          city: data.city,
          zip_code: data.zipCode,
          country_code: data.countryCode,

          phone: data.phone,
        }),
      }),
  };
}

export function updateCompany(companyID = -1, data = {}) {
  return {
    types: [
      CREATECOMPANYDATA,
      CREATECOMPANYDATA_SUCCESS,
      CREATECOMPANYDATA_FAIL,
    ],
    promise: (client) =>
      client.patch(`/company/${companyID}`, {
        data: sanitiseData({
          legal_company_name: data.legalCompanyName,
          vat_number: data.vatNumber,

          person_of_contact_name: data.contactName,
          person_of_contact_job_title: data.contactJobTitle,
          person_of_contact_email: data.contactEmail,

          address1: data.address1,
          address2: data.address2,
          city: data.city,
          zip_code: data.zipCode,
          country_code: data.countryCode,

          phone: data.phone,
        }),
      }),
  };
}

export function deleteCompanyByID(companyID = -1) {
  return {
    types: [
      DELETECOMPANYDATA,
      DELETECOMPANYDATA_SUCCESS,
      DELETECOMPANYDATA_FAIL,
    ],
    promise: (client) => client.delete(`/company/${companyID}`),
  };
}
