import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { ranks } from 'helpers/UserRank';
import { MEDIUM_STEPS, HIGH_STEPS } from 'constants/Steps';

import SvgIcon from 'components/SvgIcon/SvgIcon';
import styles from '../Onboarding.module.scss';

const STEP_COMPLETED_COLOUR = '#2cae76';

const UserRankSteps = ({ user, currentRank }) => {
  //
  let nextSteps = null;

  switch (currentRank) {
    case ranks.LOW_RANK:
      nextSteps = MEDIUM_STEPS;
      break;
    case ranks.MEDIUM_RANK:
      nextSteps = HIGH_STEPS;
      break;
  }

  const renderLink = (edit, tab, highlight) => {
    const userLink = user.links.main_relative;
    const tabLink = tab === 'about' ? '/about' : '';

    return `${userLink}${tabLink}?${
      edit ? `edit=${edit}&` : ''
    }highlight=${highlight}`;
  };

  return nextSteps ? (
    <div className={styles.rankStepsContainer}>
      <div className={styles.rankStepsTitle}>Steps to improve your profile</div>

      {nextSteps.map((step, index) => (
        <div key={index} className={styles.rankStepContainer}>
          <div className={styles.rankStepCheckbox}>
            {step.isComplete(user) && (
              <SvgIcon icon="tick" color={STEP_COMPLETED_COLOUR} size="16" />
            )}
          </div>

          <div className={styles.rankStepLabel}>
            <a
              className={styles.rankStepLink}
              href={renderLink(
                /portfolio-(avatar|roles|cover)/.test(step.redir[1]) ? 1 : null,
                step.redir[0],
                step.redir[1]
              )}
            >
              <span
                className={
                  step.isComplete(user) ? styles.completedLabel : undefined
                }
              >
                {typeof step.label === 'function'
                  ? step.label(user)
                  : step.label}
              </span>
            </a>

            <a
              className={styles.rankStepLink}
              href={step.helpCentreLink}
              target="_blank"
              rel="noreferrer"
            >
              <SvgIcon
                size="14"
                icon="help"
                className={classNames(
                  styles.rankStepTooltip,
                  step.isComplete(user) ? styles.completedTooltip : undefined
                )}
              />
            </a>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <span />
  );
};

UserRankSteps.propTypes = {
  user: PropTypes.object,
  currentRank: PropTypes.number,
};

export default UserRankSteps;
