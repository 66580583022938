const SETUSERS = 'twineApp/landingPage/SETUSERS';
const LOADFEATUREDBRIEFS = 'twineApp/landingPage/LOADFEATUREDBRIEFS';
const LOADFEATUREDBRIEFS_SUCCESS =
  'twineApp/landingPage/LOADFEATUREDBRIEFS_SUCCESS';
const LOADFEATUREDBRIEFS_FAIL = 'twineApp/landingPage/LOADFEATUREDBRIEFS_FAIL';

const initialState = {
  users: [],
  featuredBriefs: [],
  loadingFeaturedBriefs: false,
  loadedFeaturedBriefs: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SETUSERS:
      return {
        ...state,
        users: action.users,
      };

    case LOADFEATUREDBRIEFS:
      return {
        ...state,
        loadingFeaturedBriefs: true,
      };

    case LOADFEATUREDBRIEFS_SUCCESS:
      return {
        ...state,
        featuredBriefs:
          typeof action.result.collaborations === 'undefined'
            ? []
            : action.result.collaborations,
        loadingFeaturedBriefs: false,
        loadedFeaturedBriefs: true,
      };

    case LOADFEATUREDBRIEFS_FAIL:
      return {
        ...state,
        loadingFeaturedBriefs: false,
        loadedFeaturedBriefs: false,
      };

    default:
      return state;
  }
}

export function setUsers(users = []) {
  return {
    type: SETUSERS,
    users: users,
  };
}

export function loadFeaturedBriefs(limit = 4) {
  return {
    types: [
      LOADFEATUREDBRIEFS,
      LOADFEATUREDBRIEFS_SUCCESS,
      LOADFEATUREDBRIEFS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/notices/collaboration/featured?include=user,user.avatars,user.covers&randomize=1&limit=' +
          limit
      ),
  };
}
