import testimonialSchema from '../schemas/testimonialSchema';
import { jsonToFormData } from '../../helpers/HelperFunctions';

const LOADTESTIMONIAL = 'twineApp/testimonials/LOADTESTIMONIAL';
const LOADTESTIMONIAL_SUCCESS = 'twineApp/testimonials/LOADTESTIMONIAL_SUCCESS';
const LOADTESTIMONIAL_FAIL = 'twineApp/testimonials/LOADTESTIMONIAL_FAIL';
const ADD_TESTIMONIAL = 'twineApp/testimonials/ADD_TESTIMONIAL';
const ADD_TESTIMONIAL_SUCCESS = 'twineApp/testimonials/ADD_TESTIMONIAL_SUCCESS';
const ADD_TESTIMONIAL_FAIL = 'twineApp/testimonials/ADD_TESTIMONIAL_FAIL';
const UPDATE_TESTIMONIAL = 'twineApp/testimonials/UPDATE_TESTIMONIAL';
const UPDATE_TESTIMONIAL_SUCCESS =
  'twineApp/testimonials/UPDATE_TESTIMONIAL_SUCCESS';
const UPDATE_TESTIMONIAL_FAIL = 'twineApp/testimonials/UPDATE_TESTIMONIAL_FAIL';

const initialState = {
  testimonial: null,
  loadingTestimonial: false,
  loadedTestimonial: false,
  addingTestimonial: false,
  addSuccessful: false,
  updatingTestimonial: false,
  updateSuccessful: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADTESTIMONIAL:
      return {
        ...state,
        loadingTestimonial: true,
      };

    case LOADTESTIMONIAL_SUCCESS:
      return {
        ...state,
        loadingTestimonial: false,
        loadedTestimonial: true,
        testimonial: action.result,
      };

    case LOADTESTIMONIAL_FAIL:
      return {
        ...state,
        loadingTestimonial: false,
      };

    case ADD_TESTIMONIAL:
      return {
        ...state,
        addingTestimonial: true,
      };

    case ADD_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        addingTestimonial: false,
        addSuccessful: true,
      };

    case ADD_TESTIMONIAL_FAIL:
      return {
        ...state,
        addingTestimonial: false,
        addSuccessful: false,
      };

    case UPDATE_TESTIMONIAL:
      return {
        ...state,
        updatingTestimonial: true,
      };

    case UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        updatingTestimonial: false,
        updateSuccessful: true,
      };

    case UPDATE_TESTIMONIAL_FAIL:
      return {
        ...state,
        updatingTestimonial: false,
        updateSuccessful: false,
      };

    default:
      return state;
  }
}

export function loadTestimonial(id, authCode = null) {
  return {
    types: [LOADTESTIMONIAL, LOADTESTIMONIAL_SUCCESS, LOADTESTIMONIAL_FAIL],
    promise: (client) =>
      client.get(
        '/testimonials/' +
          id +
          '?include=user' +
          (authCode.length > 0 ? '&auth_code=' + authCode : '')
      ),
    schema: testimonialSchema,
  };
}

export function addTestimonial(name, companyName, projectName, email, phone) {
  return {
    types: [ADD_TESTIMONIAL, ADD_TESTIMONIAL_SUCCESS, ADD_TESTIMONIAL_FAIL],
    promise: (client) =>
      client.post('/testimonials', {
        data: {
          name: name,
          company_name: companyName,
          project_name: projectName,
          email: email,
          phone: phone,
        },
      }),
  };
}

export function updateTestimonial(id, fields, authCode) {
  return {
    types: [
      UPDATE_TESTIMONIAL,
      UPDATE_TESTIMONIAL_SUCCESS,
      UPDATE_TESTIMONIAL_FAIL,
    ],
    promise: (client) =>
      client.post('/testimonials/' + id + '?auth_code=' + authCode, {
        data: jsonToFormData({ ...fields, _method: 'PATCH' }),
      }),
  };
}
