import PropTypes from 'prop-types';
import React from 'react';
import styles from './Panel.module.scss';

const Header = ({ children, className }) => (
  <div className={`${styles.headerContainer} ${className ? className : ''}`}>
    <h3 className={styles.header} style={{ marginTop: '5px' }}>
      {children}
    </h3>
  </div>
);

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const Item = ({ children, className = '', noPadding = false }) => (
  <div
    className={`${styles.item} ${
      noPadding ? styles.noPadding : false
    } ${className}`}
  >
    {children}
  </div>
);

Item.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
};

export default function Panel({
  children,
  id,
  className,
  noPadding,
  showSeparators,
  backgroundColor,
  style,
}) {
  const classes = [
    styles.container,
    noPadding && styles.noPadding,
    showSeparators && styles.separators,
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div
      id={id}
      className={classes}
      style={{
        backgroundColor,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  noPadding: PropTypes.bool,
  showSeparators: PropTypes.bool,
  backgroundColor: PropTypes.string,
  style: PropTypes.object,
};

Panel.defaultProps = {
  children: null,
  id: '',
  className: '',
  noPadding: false,
  showSeparators: true,
  backgroundColor: 'white',
  style: null,
};

Panel.Item = Item;
Panel.Header = Header;
