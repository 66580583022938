import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row,
} from 'react-bootstrap';
import Panel from 'components/Panel/Panel';
import styles from './CreateProject.module.scss';
import RolesSelector from 'components/Shared/Selectors/RolesSelector';
import { addCredits, createProject } from 'redux/modules/textProjects';
import useAuth from 'hooks/useAuth';
import MarkdownSection from 'components/TextProjects/MarkdownSection';
import { setSuccessMessage } from 'redux/modules/successMessage';
import Modal from 'components/Modal/Modal';

const CreateProject = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);

  const projectSelect = (state) => state.textProjects;
  const projectState = useSelector(projectSelect);

  const { creatingProject } = projectState;

  useEffect(() => {
    if (!user.email_verified) {
      setShowEmailVerifyModal(true);
    }
  }, []);

  const handleRolesChange = (roles) => {
    setSelectedRoles(roles);
  };

  const handleCreateProject = () => {
    dispatch(createProject({ title: title, description: description }))
      .then((response) => {
        if (selectedRoles.length > 0) {
          dispatch(
            addCredits(
              response.id,
              user.id,
              selectedRoles.map((role) => role.value)
            )
          )
            .then(() => {
              dispatch(
                setSuccessMessage('Your project has successfully been created')
              );
              window.location.href = response.links.main_relative;
            })
            .catch((error) => {
              console.error('Error creating credits:', error);
            });
        } else {
          window.location.href = response.links.main_relative;
          dispatch(
            setSuccessMessage('Your project has successfully been created')
          );
        }
      })
      .catch((error) => {
        console.error('Error creating project:', error);
        setErrors(error.errors);
      });
  };

  return (
    <div className="mt++ lato-regular">
      <Grid>
        <Row>
          <Col lg={8}>
            <Panel className={styles.container}>
              <FormGroup controlId="project-title">
                <ControlLabel className="fs-15">Title</ControlLabel>
                <FormControl
                  type="text"
                  value={title}
                  placeholder="Enter the title of your project here"
                  onChange={(event) => setTitle(event.target.value)}
                  maxLength="140"
                  style={{ fontSize: '14px' }}
                  disabled={creatingProject}
                />
                {errors?.title && (
                  <HelpBlock className={styles.error}>
                    {errors?.title[0]}
                  </HelpBlock>
                )}
              </FormGroup>
              <FormGroup controlId="project-description">
                <ControlLabel className="fs-15">Description</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  value={description}
                  placeholder="Enter the description of your project here. Use the cheat sheet on this page to see how you can make use of the Markdown support and various services embeds"
                  onChange={(event) => setDescription(event.target.value)}
                  style={{ fontSize: '14px' }}
                  rows="10"
                  disabled={creatingProject}
                />
                {errors?.description && (
                  <HelpBlock className={styles.error}>
                    {errors?.description[0]}
                  </HelpBlock>
                )}
              </FormGroup>
              <FormGroup controlId="project-credits">
                <ControlLabel className="fs-15">
                  Credit your role(s)
                </ControlLabel>
                <RolesSelector
                  maxValues={5}
                  style={{ width: '100%' }}
                  onSelect={handleRolesChange}
                />
              </FormGroup>
              <div className={styles.buttonContainer}>
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    !user.email_verified
                      ? setShowEmailVerifyModal(true)
                      : handleCreateProject();
                  }}
                  disabled={creatingProject}
                >
                  Create Project
                </Button>
              </div>
            </Panel>
          </Col>
          <Col lg={4}>
            <MarkdownSection />
          </Col>
        </Row>
      </Grid>
      <Modal
        show={showEmailVerifyModal}
        onExit={() => setShowEmailVerifyModal(false)}
        closeButton
      >
        <div className="p++">
          <h3 className="lato-bold text-center mb">Verify your Email</h3>
          <p>
            Before you can add a new project, you need to activate your account.
          </p>
          <p>
            Please check your email for an email from us with an activation
            button and click it.
          </p>
          <p>
            Please check your spam/junk folder in case it has ended up in there.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default CreateProject;
