import { getRedirs } from './RedirectionRules';

const redirRules = {
  get: {
    code: 301,
    fromPrefix: `/get`,
    toPrefix: `/find`,
    routes: getRedirs,
  },
};

const getRedirRoute = (type = '', location = {}) => {
  const { fromPrefix, toPrefix, routes, code } = redirRules[type];

  const { pathname = '' } = location;
  const redirRoute = routes.find(
    (route) =>
      pathname === `${fromPrefix}${route.from}` ||
      pathname === `${fromPrefix}${route.from}s`
  );

  if (redirRoute) {
    return {
      pathname: `${toPrefix}${redirRoute.to}`,
      statusCode: code || 301,
    };
  }

  return null;
};

export { getRedirRoute };
