import { schema } from 'normalizr';
import userSchema from './userSchema';

const testimonialSchema = new schema.Entity('testimonials');

testimonialSchema.define({
  user: userSchema,
});

export default testimonialSchema;
