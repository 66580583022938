import { normalize } from 'normalizr';
import { SET_ENTITIES } from 'redux/modules/entities';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      // If we have a client, use ApiClient, else generic request (for non-api calls)
      const actionPromise = promise(client);

      actionPromise
        .then(
          (res) => {
            if (action.schema) {
              const { result, entities } = normalize(res, action.schema);

              next({ entities, type: SET_ENTITIES });
              return next({ ...rest, entities, result, type: SUCCESS });
            }
            return next({ ...rest, result: res, type: SUCCESS });
          },
          (error) => next({ ...rest, error, type: FAILURE })
        )
        .catch((error) => {
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
  };
}
