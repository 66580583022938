import { asyncConnect } from 'redux-connect';

import { loadTestimonial, updateTestimonial } from 'redux/modules/testimonials';
import { loadStats } from 'redux/modules/stats';

import './TestimonialsUpdate.scss';
import loadable from '@loadable/component';

const TestimonialsUpdateBase = loadable(() =>
  import('./TestimonialsUpdateBase')
);

const TestimonialsUpdate = asyncConnect(
  [
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { id, authcode },
        },
      }) => {
        const promises = [];

        // As we get by encoded ID, just always get the brief
        promises.push(dispatch(loadTestimonial(id, authcode)));

        if (!getState().stats.loadedStats) {
          promises.push(dispatch(loadStats()));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    testimonial: state.testimonials.testimonial,
    updatingTestimonial: state.testimonials.updatingTestimonial,
    updateSuccessful: state.testimonials.updateSuccessful,
    testimonialEntities: state.entities.testimonials,
    userEntities: state.entities.users,
    stats: state.stats,
  }),
  {
    loadStats,
    updateTestimonial,
  }
)(TestimonialsUpdateBase);

export default TestimonialsUpdate;
