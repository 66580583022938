import React, { useMemo } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PropTypes from 'prop-types';

const animatedComponents = makeAnimated();

const SelectSelectize = ({
  isMulti,
  hideResetButton,
  isSearchable = true,
  onValuesChange,
  onSearchChange,
  options,
  renderNoResultsFound,
  values,
  styles,
  className,
  components = null,
  id,
}) => (
  <Select
    components={components || animatedComponents}
    className={className}
    styles={styles}
    isMulti={isMulti}
    isClearable={!hideResetButton}
    isSearchable={isSearchable}
    onChange={onValuesChange}
    options={options}
    noOptionsMessage={renderNoResultsFound}
    onInputChange={onSearchChange}
    value={values}
    id={`selectors-selectSelectize-${id}`}
    instanceId={`selectors-selectSelectize-${id}`}
  />
);

SelectSelectize.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SelectSelectize;
