const ACTIVEHIRES = 'twineApp/briefCard/ACTIVEHIRES';
const ACTIVEHIRES_SUCCESS = 'twineApp/briefCard/ACTIVEHIRES_SUCCESS';
const ACTIVEHIRES_FAIL = 'twineApp/briefCard/ACTIVEHIRES_FAIL';
const ENDHIRE = 'twineApp/briefCard/ENDHIRE';
const ENDHIRE_SUCCESS = 'twineApp/briefCard/ENDHIRE_SUCCESS';
const ENDHIRE_FAIL = 'twineApp/briefCard/ENDHIRE_FAIL';

const initialState = {
  loadingActiveHires: false,
  loadedActiveHires: false,
  activeHires: [],
  loadingEndHire: false,
  loadedEndHire: false,
  endHireResult: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIVEHIRES:
      return {
        ...state,
        loadingActiveHires: true,
      };

    case ACTIVEHIRES_SUCCESS:
      return {
        ...state,
        loadingActiveHires: false,
        loadedActiveHires: true,
        activeHires: action.result,
      };

    case ACTIVEHIRES_FAIL:
      return {
        ...state,
        loadingActiveHires: false,
        loadedActiveHires: true,
      };

    case ENDHIRE:
      return {
        ...state,
        loadingEndHire: true,
      };

    case ENDHIRE_SUCCESS:
      return {
        ...state,
        loadingEndHire: false,
        loadedEndHire: true,
        endHireResult: action.result,
      };

    case ENDHIRE_FAIL:
      return {
        ...state,
        loadingEndHire: false,
        loadedEndHire: true,
      };

    default:
      return state;
  }
}

export function getActiveHires(briefId) {
  return {
    types: [ACTIVEHIRES, ACTIVEHIRES_SUCCESS, ACTIVEHIRES_FAIL],
    promise: (client) => client.get('/activehires/' + briefId),
  };
}

export function endHire(briefId, pitchId) {
  return {
    types: [ENDHIRE, ENDHIRE_SUCCESS, ENDHIRE_FAIL],
    promise: (client) =>
      client.put(`/notices/collaboration/${briefId}/pitches/${pitchId}/end`, {
        data: {},
      }),
  };
}

export function getActiveAndCompletedHires(briefId) {
  return {
    types: [ACTIVEHIRES, ACTIVEHIRES_SUCCESS, ACTIVEHIRES_FAIL],
    promise: (client) =>
      client.get('/activehires/' + briefId + '?includeCompleted=true'),
  };
}
