const LOAD = 'twineApp/notifications/LOAD';
const LOAD_SUCCESS = 'twineApp/notifications/LOAD_SUCCESS';
const LOAD_FAIL = 'twineApp/notifications/LOAD_FAIL';
const DELETE = 'twineApp/notifications/DELETE';
const DELETE_SUCCESS = 'twineApp/notifications/DELETE_SUCCESS';
const DELETE_FAIL = 'twineApp/notifications/DELETE_FAIL';
const MARKASREAD = 'twineApp/notifications/MARKASREAD';
const MARKASREAD_SUCCESS = 'twineApp/notifications/MARKASREAD_SUCCESS';
const MARKASREAD_FAIL = 'twineApp/notifications/MARKASREAD_FAIL';
const STARTEDLOADINGOLD = 'twineApp/notifications/STARTEDLOADINGOLD';
const FINISHEDLOADINGOLD = 'twineApp/notifications/FINISHEDLOADINGOLD';
const ACCEPTCREDIT = 'twineApp/notifications/ACCEPTCREDIT';
const ACCEPTCREDIT_SUCCESS = 'twineApp/notifications/ACCEPTCREDIT_SUCCESS';
const ACCEPTCREDIT_FAIL = 'twineApp/notifications/ACCEPTCREDIT_FAIL';
const DECLINECREDIT = 'twineApp/notifications/DECLINECREDIT';
const DECLINECREDIT_SUCCESS = 'twineApp/notifications/DECLINECREDIT_SUCCESS';
const DECLINECREDIT_FAIL = 'twineApp/notifications/DECLINECREDIT_FAIL';
const LOADCOUNT = 'twineApp/notifications/LOADCOUNT';
const LOADCOUNT_SUCCESS = 'twineApp/notifications/LOADCOUNT_SUCCESS';
const LOADCOUNT_FAIL = 'twineApp/notifications/LOADCOUNT_FAIL';
const STARTEDLOADINGOLDCOUNT = 'twineApp/notifications/STARTEDLOADINGOLDCOUNT';
const FINISHEDLOADINGOLDCOUNT =
  'twineApp/notifications/FINISHEDLOADINGOLDCOUNT';

const initialState = {
  loaded: false,
  notifications: [],
  moreNotifications: false,
  notificationsPagesLoaded: 0,
  loading: false,
  loadedOld: false,
  loadingOld: false,
  old: [],
  count: 0,
  loadingCount: false,
  loadedCount: false,
  oldCount: 0,
  loadingOldCount: false,
  loadedOldCount: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        notifications:
          action.result.constructor === Array && action.result.length === 0
            ? state.notifications
            : state.notifications.concat(action.result.notifications),
        moreNotifications:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        notificationsPagesLoaded: state.notificationsPagesLoaded + 1,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };

    case DELETE:
      // Eager delete
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.id
        ),
      };

    case DELETE_SUCCESS:
      return state;

    case DELETE_FAIL:
      return state;

    case MARKASREAD:
      return state;

    case MARKASREAD_SUCCESS:
      return state;

    case MARKASREAD_FAIL:
      return state;

    case STARTEDLOADINGOLD:
      return {
        ...state,
        loadingOld: true,
      };

    case FINISHEDLOADINGOLD:
      return {
        ...state,
        loadedOld: true,
        loadingOld: false,
        old: action.notifications ? action.notifications : [],
      };

    case ACCEPTCREDIT:
      const tmpArray = Array.from(state.old);
      for (let idx = 0; idx < tmpArray.length; idx++) {
        if (tmpArray[idx].id === action.creditId) {
          tmpArray[idx].roles = tmpArray[idx].roles.filter(
            (role) => role.id !== action.roleId
          ); // eslint-disable-line no-loop-func
          break;
        }
      }

      return {
        ...state,
        old: tmpArray,
      };

    case DECLINECREDIT:
      const tmpArray2 = Array.from(state.old);
      for (let idx = 0; idx < tmpArray2.length; idx++) {
        if (tmpArray2[idx].id === action.creditId) {
          tmpArray2[idx].roles = tmpArray2[idx].roles.filter(
            (role) => role.id !== action.roleId
          ); // eslint-disable-line no-loop-func
          break;
        }
      }

      return {
        ...state,
        old: tmpArray2,
      };

    case LOADCOUNT:
      return {
        ...state,
        loadingCount: true,
      };

    case LOADCOUNT_SUCCESS:
      return {
        ...state,
        loadingCount: false,
        loadedCount: true,
        count: action.result.count,
      };

    case LOADCOUNT_FAIL:
      return {
        ...state,
        loadingCount: false,
      };

    case STARTEDLOADINGOLDCOUNT:
      return {
        ...state,
        loadingOldCount: true,
      };

    case FINISHEDLOADINGOLDCOUNT:
      return {
        ...state,
        loadingOldCount: false,
        loadedOldCount: true,
        oldCount: action.count,
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.notifications && globalState.notifications.loaded;
}

export function load(page) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.get(
        '/notifications?page=' +
          page +
          '&include=notifiable,notifiable.notice,notifiable.sender,notifiable.user'
      ),
  };
}

export function deleteNotification(id) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
    promise: (client) => client.delete('/notifications/' + id),
    id,
  };
}

export function markAsRead(id) {
  return {
    types: [MARKASREAD, MARKASREAD_SUCCESS, MARKASREAD_FAIL],
    promise: (client) => client.put('/notifications/' + id + '/read'),
    id,
  };
}

export function startedLoadingOldNotifications() {
  return {
    type: STARTEDLOADINGOLD,
  };
}

export function finishedLoadingOldNotifications(notifications) {
  return {
    type: FINISHEDLOADINGOLD,
    notifications,
  };
}

export function acceptCredit(creditId, roleId) {
  return {
    types: [ACCEPTCREDIT, ACCEPTCREDIT_SUCCESS, ACCEPTCREDIT_FAIL],
    promise: (client) =>
      client.put('/credits/' + creditId + '/roles/' + roleId + '/accept'),
    creditId,
    roleId,
  };
}

export function declineCredit(creditId, roleId) {
  return {
    types: [DECLINECREDIT, DECLINECREDIT_SUCCESS, DECLINECREDIT_FAIL],
    promise: (client) =>
      client.put('/credits/' + creditId + '/roles/' + roleId + '/decline'),
    creditId,
    roleId,
  };
}

export function loadCount() {
  return {
    types: [LOADCOUNT, LOADCOUNT_SUCCESS, LOADCOUNT_FAIL],
    promise: (client) => client.get('/notifications/count'),
  };
}

export function startedLoadingOldCount() {
  return {
    type: STARTEDLOADINGOLDCOUNT,
  };
}

export function finishedLoadingOldCount(count) {
  return {
    type: FINISHEDLOADINGOLDCOUNT,
    count,
  };
}
