import PropTypes from 'prop-types';
import React from 'react';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './Modal.module.scss';

import SvgIcon from 'components/SvgIcon/SvgIcon';

class Modal extends React.Component {
  static propTypes = {
    animation: PropTypes.oneOf([
      'scale',
      'slideDown',
      'slideLeft',
      'slideLeftRight',
      'slideRight',
      'slideUp',
    ]),
    backgroundExit: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    closeButton: PropTypes.bool,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    modalClassName: PropTypes.string,
    modalStyle: PropTypes.object,
    show: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  static defaultProps = {
    animation: 'slideDown',
    backgroundExit: true,
    className: '',
    closeButton: false,
    modalClassName: '',
    modalStyle: {},
    onEnter: undefined,
    onExit: undefined,
    show: false,
    style: {},
    title: '',
    subtitle: '',
  };

  constructor() {
    super();

    this.state = {
      oversized: false,
    };

    this.checkIfOversized = this.checkIfOversized.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkIfOversized);
    window.addEventListener('keydown', this.handleKeyDown);

    if (this.props.show) {
      setTimeout(this.disableScrolling, 200);

      if (this.props.onEnter) {
        this.props.onEnter();
      }

      // Only check after animation...
      setTimeout(this.checkIfOversized, 200);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // Showing modal
    if (!this.props.show && nextProps.show) {
      setTimeout(this.disableScrolling, 200);

      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }

    // Hiding modal
    if (this.props.show && !nextProps.show) {
      this.enableScrolling();

      if (this.props.onExit) {
        this.props.onExit();
      }

      window.removeEventListener('resize', this.checkIfOversized);
    }
  }

  componentDidUpdate(prevProps) {
    // Showing modal
    if (
      (!prevProps.show && this.props.show) ||
      prevProps.children !== this.props.children
    ) {
      // Only check after animation...
      setTimeout(this.checkIfOversized, 200);
    }
  }

  componentWillUnmount() {
    this.enableScrolling();

    if (this.props.show && this.props.onExit) {
      this.props.onExit();
    }

    window.removeEventListener('resize', this.checkIfOversized);

    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    // if escape pressed
    if (event.keyCode === 27 && this.props.onExit) {
      this.props.onExit();
    }
  }

  static _originalBodyOverflow = null;

  disableScrolling() {
    this._originalBodyOverflow =
      document.getElementsByTagName('body')[0].style.overflow;
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  enableScrolling() {
    document.getElementsByTagName('body')[0].style.overflow =
      typeof this._originalBodyOverflow !== 'undefined'
        ? this._originalBodyOverflow
        : '';
  }

  checkIfOversized() {
    if (!this.refs.modal) {
      return;
    }

    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const modalHeight =
      this.refs.modal.getBoundingClientRect().bottom -
      this.refs.modal.getBoundingClientRect().top;

    this.setState({
      oversized: modalHeight > screenHeight - 40,
    });
  }

  render() {
    const {
      animation,
      backgroundExit,
      children,
      className,
      closeButton,
      modalClassName,
      modalStyle,
      show,
      style,
      title,
      subtitle,
    } = this.props;
    const { oversized } = this.state;

    return (
      <section>
        {show && (
          <TransitionGroup>
            <CSSTransition
              classNames={styles.modalBackgroundAnimation}
              timeout={{ enter: 200, exit: 200 }}
            >
              <div className={styles.overlay} key="1" />
            </CSSTransition>
          </TransitionGroup>
        )}
        {show && (
          <TransitionGroup>
            <CSSTransition
              classNames={`animate-${animation}`}
              timeout={{ enter: 200, exit: 200 }}
            >
              <div
                className={styles.modalContainer}
                onClick={() =>
                  this.props.onExit && backgroundExit
                    ? this.props.onExit()
                    : null
                }
                key="1"
                ref="modalContainer"
              >
                <div
                  className={`${styles.modal} ${
                    oversized ? styles.oversizedModal : ''
                  } ${modalClassName}`}
                  onClick={(event) => event.stopPropagation()}
                  ref="modal"
                  style={modalStyle}
                >
                  {closeButton && (
                    <div
                      className={styles.closeButton}
                      onClick={() =>
                        this.props.onExit ? this.props.onExit() : null
                      }
                    >
                      <SvgIcon icon="close" color="#aaa" size="20" />
                    </div>
                  )}
                  {title !== '' ? (
                    <div className={styles.title}>{title}</div>
                  ) : null}
                  {subtitle !== '' ? (
                    <div className={styles.subtitle}>{subtitle}</div>
                  ) : null}
                  <div className={`${styles.body} ${className}`} style={style}>
                    {children}
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
      </section>
    );
  }
}

export default Modal;
