import publicQuestionSchema from '../schemas/publicQuestionSchema';
import { fromJS } from 'immutable';
import { jsonToFormData } from '../../helpers/HelperFunctions';

const LOADQUESTION = 'twineApp/publicQuestions/LOADQUESTION';
const LOADQUESTION_SUCCESS = 'twineApp/publicQuestions/LOADQUESTION_SUCCESS';
const LOADQUESTION_FAIL = 'twineApp/publicQuestions/LOADQUESTION_FAIL';
const ANSWERQUESTION = 'twineApp/publicQuestions/ANSWERQUESTION';
const ANSWERQUESTION_SUCCESS =
  'twineApp/publicQuestions/ANSWERQUESTION_SUCCESS';
const ANSWERQUESTION_FAIL = 'twineApp/publicQuestions/ANSWERQUESTION_FAIL';

const initialState = {
  loading: [],
  answering: false,
  answeredSuccessfully: null,
};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADQUESTION:
      return imState
        .set('loading', imState.get('loading').push(action.questionId))
        .toJS();

    case LOADQUESTION_SUCCESS:
      return imState
        .set(
          'loading',
          imState.get('loading').filter((id) => id !== action.questionId)
        )
        .toJS();

    case LOADQUESTION_FAIL:
      return imState
        .set(
          'loading',
          imState.get('loading').filter((id) => id !== action.questionId)
        )
        .toJS();

    case ANSWERQUESTION:
      return {
        ...state,
        answering: true,
        answeredSuccessfully: null,
      };

    case ANSWERQUESTION_SUCCESS:
      imState
        .set(
          'loading',
          imState.get('loading').filter((id) => id !== action.questionId)
        )
        .toJS();
      return {
        ...state,
        answering: false,
        answeredSuccessfully: true,
      };

    case ANSWERQUESTION_FAIL:
      imState
        .set(
          'loading',
          imState.get('loading').filter((id) => id !== action.questionId)
        )
        .toJS();
      return {
        ...state,
        answering: false,
        answeredSuccessfully: false,
      };

    default:
      return imState.toJS();
  }
}

export function loadQuestion(briefId, questionId) {
  return {
    types: [LOADQUESTION, LOADQUESTION_SUCCESS, LOADQUESTION_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' + briefId + '/questions/public/' + questionId
      ),
    questionId,
    schema: publicQuestionSchema,
  };
}

export function answerQuestion(briefId, questionId, answer) {
  return {
    types: [ANSWERQUESTION, ANSWERQUESTION_SUCCESS, ANSWERQUESTION_FAIL],
    promise: (client) =>
      client.post(
        '/notices/collaboration/' + briefId + '/questions/public/' + questionId,
        {
          data: jsonToFormData({
            answer,
            _method: 'PATCH',
          }),
        }
      ),
    schema: publicQuestionSchema,
  };
}
