import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';

import { computeUserRank, ranks } from 'helpers/UserRank';

import ProgressBar from './components/ProgressBar';
import UserRankSteps from './components/UserRankSteps';

import styles from './Onboarding.module.scss';

const Onboarding = ({ user, innerRef }) => {
  const currentRank = computeUserRank(user);

  return currentRank !== ranks.INVALID_RANK &&
    currentRank !== ranks.MAX_RANK ? (
    <Col id="static-banner" className={styles.onboardingBanner}>
      <div className={styles.onboardingBannerContainer}>
        <Row className={styles.rankBannerContainer}>
          <Col xs={12} md={8} className={styles.rankBannerContainer}>
            <ProgressBar user={user} />

            <UserRankSteps user={user} currentRank={currentRank} />
          </Col>
        </Row>
      </div>
    </Col>
  ) : (
    <span />
  );
};

Onboarding.propTypes = {
  user: PropTypes.object,
};

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  {
    //
  },
  null,
  { forwardRef: true }
)(Onboarding);
