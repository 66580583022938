import { fromJS } from 'immutable';
import userSchema from '../schemas/userSchema';
import projectSchema from '../schemas/projectSchema';
import superagent from 'superagent';
import xml2js from 'xml2js';
import config from '../../config';

const LOADUSERS = 'twineApp/landingPages/LOADUSERS';
const LOADUSERS_SUCCESS = 'twineApp/landingPages/LOADUSERS_SUCCESS';
const LOADUSERS_FAIL = 'twineApp/landingPages/LOADUSERS_FAIL';
const LOADPROJECTS = 'twineApp/landingPages/LOADPROJECTS';
const LOADPROJECTS_SUCCESS = 'twineApp/landingPages/LOADPROJECTS_SUCCESS';
const LOADPROJECTS_FAIL = 'twineApp/landingPages/LOADPROJECTS_FAIL';
const LOADBLOGRSS = 'twineApp/landingPages/LOADBLOGRSS';
const LOADBLOGRSS_SUCCESS = 'twineApp/landingPages/LOADBLOGRSS_SUCCESS';
const LOADBLOGRSS_FAIL = 'twineApp/landingPages/LOADBLOGRSS_FAIL';
const LOADROLES = 'twineApp/landingPages/LOADROLES';
const LOADROLES_SUCCESS = 'twineApp/landingPages/LOADROLES_SUCCESS';
const LOADROLES_FAIL = 'twineApp/landingPages/LOADROLES_FAIL';
const LOADCOUNTRIES = 'twineApp/landingPages/LOADCOUNTRIES';
const LOADCOUNTRIES_SUCCESS = 'twineApp/landingPages/LOADCOUNTRIES_SUCCESS';
const LOADCOUNTRIES_FAIL = 'twineApp/landingPages/LOADCOUNTRIES_FAIL';
const LOADSKILLS = 'twineApp/landingPages/LOADSKILLS';
const LOADSKILLS_SUCCESS = 'twineApp/landingPages/LOADSKILLS_SUCCESS';
const LOADSKILLS_FAIL = 'twineApp/landingPages/LOADSKILLS_FAIL';
const SETPORTFOLIOCAROUSEL = 'twineApp/landingPages/SETPORTFOLIOCAROUSEL';

const getHash = (role, country = null, city = null, skill = null) => {
  return (
    encodeURIComponent(role) +
    (country ? `|${country}` : '') +
    (city ? `|${city}` : '') +
    (skill ? `|${encodeURIComponent(skill)}` : '')
  );
};

export default function reducer(state = {}, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADPROJECTS:
      return imState
        .mergeDeep({
          projects: {
            loading: true,
          },
        })
        .toJS();

    case LOADPROJECTS_SUCCESS:
      return imState
        .mergeDeep({
          projects: {
            loading: false,
            loaded: true,
            projects:
              typeof action.result.projects === 'undefined'
                ? []
                : action.result.projects,
          },
        })
        .toJS();

    case LOADPROJECTS_FAIL:
      return imState
        .mergeDeep({
          projects: {
            loading: false,
            loaded: true,
          },
        })
        .toJS();

    case LOADUSERS:
      return imState
        .mergeDeep({
          [getHash(action.role, action.country, action.city, action.skill)]: {
            loading: true,
          },
        })
        .toJS();

    case LOADUSERS_SUCCESS:
      return imState
        .mergeDeep({
          [getHash(action.role, action.country, action.city, action.skill)]: {
            loading: false,
            loaded: true,
            users:
              typeof action.result.users === 'undefined'
                ? []
                : action.result.users,
            total:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.total
                : 0,
            role:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.role
                : '',
            role_plural:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.role_plural
                : '',
            role_slug:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.role_slug
                : '',
            role_slug_plural:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.role_slug_plural
                : '',
            skill:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.skill
                : '',
            skill_plural:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.skill_plural
                : '',
            skill_slug:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.skill_slug
                : '',
            skill_slug_plural:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.roleSkill.skill_slug_plural
                : '',
          },
        })
        .toJS();

    case LOADUSERS_FAIL:
      return imState
        .mergeDeep({
          [getHash(action.role, action.country, action.city, action.skill)]: {
            loading: false,
            loaded: true,
          },
        })
        .toJS();

    case LOADBLOGRSS:
      return imState
        .mergeDeep({
          blogPosts: {
            [action.role]: {
              loading: true,
            },
          },
        })
        .toJS();

    case LOADBLOGRSS_SUCCESS:
      let blogPosts = [];

      xml2js.parseString(action.result.text, (err, result) => {
        if (err) {
          return;
        }

        if (
          typeof result !== 'undefined' &&
          typeof result.rss !== 'undefined' &&
          typeof result.rss.channel !== 'undefined' &&
          typeof result.rss.channel[0] !== 'undefined' &&
          typeof result.rss.channel[0].item !== 'undefined'
        ) {
          blogPosts = result.rss.channel[0].item;
        }
      });

      return imState
        .mergeDeep({
          blogPosts: {
            [action.role]: {
              loading: false,
              loaded: true,
              posts: blogPosts,
            },
          },
        })
        .toJS();

    case LOADBLOGRSS_FAIL:
      return imState
        .mergeDeep({
          blogPosts: {
            [action.role]: {
              loading: false,
              loaded: true,
              posts: [],
            },
          },
        })
        .toJS();

    case LOADSKILLS:
      return imState
        .mergeDeep({
          skills: {
            loading: true,
          },
        })
        .toJS();

    case LOADSKILLS_SUCCESS:
      return imState
        .mergeDeep({
          skills: {
            loading: false,
            loaded: true,
            skills:
              typeof action.result.skills === 'undefined'
                ? []
                : action.result.skills,
          },
        })
        .toJS();

    case LOADSKILLS_FAIL:
      return imState
        .mergeDeep({
          skills: {
            loading: false,
            loaded: true,
          },
        })
        .toJS();

    case LOADROLES:
      return imState
        .mergeDeep({
          roles: {
            [action.country]: {
              [action.city]: {
                loading: true,
              },
            },
          },
        })
        .toJS();

    case LOADROLES_SUCCESS:
      return imState
        .mergeDeep({
          roles: {
            [action.country]: {
              [action.city]: {
                loading: false,
                loaded: true,
                roles:
                  typeof action.result.roles === 'undefined'
                    ? []
                    : action.result.roles,
              },
            },
          },
        })
        .toJS();

    case LOADROLES_FAIL:
      return imState
        .mergeDeep({
          roles: {
            [action.country]: {
              [action.city]: {
                loading: false,
                loaded: true,
              },
            },
          },
        })
        .toJS();

    case LOADCOUNTRIES:
      return imState
        .mergeDeep({
          countries: {
            [action.role]: {
              loading: true,
            },
          },
        })
        .toJS();

    case LOADCOUNTRIES_SUCCESS:
      return imState
        .mergeDeep({
          countries: {
            [action.role]: {
              loading: false,
              loaded: true,
              countries:
                typeof action.result.countries === 'undefined'
                  ? []
                  : action.result.countries,
            },
          },
        })
        .toJS();

    case LOADCOUNTRIES_FAIL:
      return imState
        .mergeDeep({
          countries: {
            [action.role]: {
              loading: false,
              loaded: true,
            },
          },
        })
        .toJS();

    case SETPORTFOLIOCAROUSEL:
      return imState
        .merge({
          portfolioCarousel: action.users,
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export { getHash };

export function isBlogRssLoaded(globalState, role = 'freelancer') {
  return (
    globalState.landingPages &&
    typeof globalState.landingPages.blogPosts !== 'undefined' &&
    typeof globalState.landingPages.blogPosts[role] !== 'undefined' &&
    typeof globalState.landingPages.blogPosts[role].loaded !== 'undefined' &&
    globalState.landingPages.blogPosts[role].loaded
  );
}

export function isRolesLoaded(globalState, role, country) {
  return (
    globalState.landingPages &&
    typeof globalState.landingPages.roles !== 'undefined' &&
    typeof globalState.landingPages.roles[country] !== 'undefined' &&
    typeof globalState.landingPages.roles[country].loaded !== 'undefined' &&
    globalState.landingPages.roles[country].loaded
  );
}

export function isCountriesLoaded(globalState, role) {
  return (
    globalState.landingPages &&
    typeof globalState.landingPages.countries !== 'undefined' &&
    typeof globalState.landingPages.countries[role] !== 'undefined' &&
    typeof globalState.landingPages.countries[role].loaded !== 'undefined' &&
    globalState.landingPages.countries[role].loaded
  );
}

export function isUsersLoaded(globalState, role, country, city, skill) {
  return (
    globalState.landingPages &&
    typeof globalState.landingPages[getHash(role, country, city, skill)] !==
      'undefined' &&
    typeof globalState.landingPages[getHash(role, country, city, skill)]
      .loaded !== 'undefined' &&
    globalState.landingPages[getHash(role, country, city, skill)].loaded
  );
}

export function isProjectsLoaded(globalState) {
  return (
    globalState.landingPages &&
    typeof globalState.landingPages.projects !== 'undefined' &&
    typeof globalState.landingPages.projects.loaded !== 'undefined' &&
    globalState.landingPages.loaded
  );
}

export function loadUsers(
  role,
  country = null,
  city = null,
  skill = null,
  limit = config.defaultNumberOfFreelancersOnLandingPages
) {
  const skillFilter = !skill ? '' : `&skill=${skill}`;
  const query = `?include=avatars,covers,stats&limit=${limit}${skillFilter}`;
  const url = `/users/location/${role}${country ? '/' + country : ''}${
    city ? '/' + city : ''
  }${query}`;
  const result = {
    types: [LOADUSERS, LOADUSERS_SUCCESS, LOADUSERS_FAIL],
    promise: (client) => {
      const result = client.get(url);
      return result;
    },
    role,
    country,
    city,
    skill,
    schema: { users: [userSchema] },
  };
  return result;
}

export function loadProjects(role, country = null, city = null) {
  const url = `/users/location/${role}${country ? '/' + country : ''}${
    city ? '/' + city : ''
  }`;
  return {
    types: [LOADPROJECTS, LOADPROJECTS_SUCCESS, LOADPROJECTS_FAIL],
    promise: (client) =>
      client.get(`${url}/?projects=1&include=user,user.avatars,thumbnails`),
    role,
    schema: { projects: [projectSchema] },
  };
}

export function loadBlogRss(role = 'buyer') {
  return {
    types: [LOADBLOGRSS, LOADBLOGRSS_SUCCESS, LOADBLOGRSS_FAIL],
    promise: () => {
      if (__CLIENT__) {
        return Promise.resolve(
          superagent.get(`https://www.twine.net/blog/tag/${role}/feed/`)
        );
      }

      return Promise.resolve(
        superagent
          .get(`https://www.twine.net/blog/tag/${role}/feed/`)
          .buffer(true)
      );
    },
    role,
    ignoreError: true,
  };
}

export function loadRoles(role, country = null, city = null, limit = 20) {
  const url = `/users/location/${role}${country ? '/' + country : ''}${
    city ? '/' + city : ''
  }/roles?limit=${limit}`;
  return {
    types: [LOADROLES, LOADROLES_SUCCESS, LOADROLES_FAIL],
    promise: (client) => client.get(url),
    country: country,
    city: city,
  };
}

export function loadSkills(role, country = null, city = null, limit = 20) {
  const url = `/users/location/${role}${country ? '/' + country : ''}${
    city ? '/' + city : ''
  }/skills?limit=${limit}`;
  return {
    types: [LOADSKILLS, LOADSKILLS_SUCCESS, LOADSKILLS_FAIL],
    promise: (client) => client.get(url),
    country: country,
    city: city,
  };
}

export function loadCountries(role) {
  return {
    types: [LOADCOUNTRIES, LOADCOUNTRIES_SUCCESS, LOADCOUNTRIES_FAIL],
    promise: (client) =>
      client.get(`/users/location/${role}/countries?limit=20`),
    role,
  };
}

export function setPortfolioCarousel(users) {
  return {
    type: SETPORTFOLIOCAROUSEL,
    users: users,
  };
}
