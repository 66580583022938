import { jsonToFormData } from '../../helpers/HelperFunctions';
import roleSchema from '../schemas/roleSchema';
import skillSchema from '../schemas/skillSchema';

const LOADROLES = 'twineApp/projects/LOADROLES';
const LOADROLES_SUCCESS = 'twineApp/projects/LOADROLES_SUCCESS';
const LOADROLES_FAIL = 'twineApp/projects/LOADROLES_FAIL';
const LOADSKILLS = 'twineApp/projects/LOADSKILLS';
const LOADSKILLS_SUCCESS = 'twineApp/projects/LOADSKILLS_SUCCESS';
const LOADSKILLS_FAIL = 'twineApp/projects/LOADSKILLS_FAIL';
const LOADBRIEF = 'twineApp/projects/LOADBRIEF';
const LOADBRIEF_SUCCESS = 'twineApp/projects/LOADBRIEF_SUCCESS';
const LOADBRIEF_FAIL = 'twineApp/projects/LOADBRIEF_FAIL';
const LOADBRIEFS = 'twineApp/projects/LOADBRIEFS';
const LOADBRIEFS_SUCCESS = 'twineApp/projects/LOADBRIEFS_SUCCESS';
const LOADBRIEFS_FAIL = 'twineApp/projects/LOADBRIEFS_FAIL';
const LOADPITCHES = 'twineApp/projects/LOADPITCHES';
const LOADPITCHES_SUCCESS = 'twineApp/projects/LOADPITCHES_SUCCESS';
const LOADPITCHES_FAIL = 'twineApp/projects/LOADPITCHES_FAIL';
const LOADFEATURED = 'twineApp/projects/LOADFEATURED';
const LOADFEATURED_SUCCESS = 'twineApp/projects/LOADFEATURED_SUCCESS';
const LOADFEATURED_FAIL = 'twineApp/projects/LOADFEATURED_FAIL';
const CHANGE_FILTER = 'twineApp/projects/CHANGE_FILTER';
const POSTBRIEF = 'twineApp/projects/POSTBRIEF';
const POSTBRIEF_SUCCESS = 'twineApp/projects/POSTBRIEF_SUCCESS';
const POSTBRIEF_FAIL = 'twineApp/projects/POSTBRIEF_FAIL';
const DELETEBRIEF = 'twineApp/projects/DELETEBRIEF';
const DELETEBRIEF_SUCCESS = 'twineApp/projects/DELETEBRIEF_SUCCESS';
const DELETEBRIEF_FAIL = 'twineApp/projects/DELETEBRIEF_FAIL';
const CLOSEBRIEF = 'twineApp/projects/CLOSEBRIEF';
const CLOSEBRIEF_SUCCESS = 'twineApp/projects/CLOSEBRIEF_SUCCESS';
const CLOSEBRIEF_FAIL = 'twineApp/projects/CLOSEBRIEF_FAIL';
const LOADOWNBRIEFS = 'twineApp/projects/LOADOWNBRIEFS';
const LOADOWNBRIEFS_SUCCESS = 'twineApp/projects/LOADOWNBRIEFS_SUCCESS';
const LOADOWNBRIEFS_FAIL = 'twineApp/projects/LOADOWNBRIEFS_FAIL';
const LOADOWNPITCHES = 'twineApp/projects/LOADOWNPITCHES';
const LOADOWNPITCHES_SUCCESS = 'twineApp/projects/LOADOWNPITCHES_SUCCESS';
const LOADOWNPITCHES_FAIL = 'twineApp/projects/LOADOWNPITCHES_FAIL';
const CLEAR_POSTED_BRIEF = 'twineApp/projects/CLEAR_POSTED_BRIEF';
const CLEAR_DELETED_BRIEF = 'twineApp/projects/CLEAR_DELETED_BRIEF';
const CLEAR_CLOSED_BRIEF = 'twineApp/projects/CLEAR_CLOSED_BRIEF';
const LOADFILES = 'twineApp/projects/LOADFILES';
const LOADFILES_SUCCESS = 'twineApp/projects/LOADFILES_SUCCESS';
const LOADFILES_FAIL = 'twineApp/projects/LOADFILES_FAIL';
const SENDFILES = 'twineApp/projects/SENDFILES';
const SENDFILES_SUCCESS = 'twineApp/projects/SENDFILES_SUCCESS';
const SENDFILES_FAIL = 'twineApp/projects/SENDFILES_FAIL';
const FINALFILES = 'twineApp/projects/FINALFILES';
const FINALFILES_SUCCESS = 'twineApp/projects/FINALFILES_SUCCESS';
const FINALFILES_FAIL = 'twineApp/projects/FINALFILES_FAIL';

const initialState = {
  filterLoadingRoles: false,
  filterLoadedRoles: false,
  filterRoles: [],
  filterLoadingSkills: false,
  filterLoadedSkills: false,
  filterSkills: [],
  filterRole: undefined,
  filterPaid: true,
  filterFree: true,
  loadingBrief: false,
  loadedBrief: false,
  loadedBriefId: '',
  brief: {},
  loadingBriefs: false,
  loadedBriefs: false,
  briefs: [],
  loadingPitches: false,
  loadedPitches: false,
  briefsPagesLoaded: 0,
  moreBriefs: false,
  loadingFeatured: false,
  loadedFeatured: false,
  featured: [],
  posting: false,
  deleting: false,
  closing: false,
  loadingOwnBriefs: false,
  ownBriefs: [],
  loadedOwnBriefs: false,
  loadingOwnPitches: false,
  ownPitches: [],
  loadedOwnPitches: false,
  postingError: '',
  posted: null,
  deletingError: '',
  deleted: null,
  closingError: '',
  closed: null,
  files: [],
  filesLoading: false,
  filesMore: false,
  filesLoaded: false,
  filesPagesLoaded: 0,
  sendingFiles: false,
  finalFilesCount: 0,
  finalFilesCountLoading: false,
  finalFilesCountLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADROLES:
      return {
        ...state,
        filterLoadingRoles: true,
      };

    case LOADROLES_SUCCESS:
      return {
        ...state,
        filterLoadingRoles: false,
        filterLoadedRoles: true,
        filterRoles:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.suggested_roles,
      };

    case LOADROLES_FAIL:
      return {
        ...state,
        filterLoadingRoles: false,
        filterLoadedRoles: false,
      };

    case LOADSKILLS:
      return {
        ...state,
        filterLoadingSkills: true,
      };

    case LOADSKILLS_SUCCESS:
      return {
        ...state,
        filterLoadingSkills: false,
        filterLoadedSkills: true,
        filterSkills:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.suggested_skills,
      };

    case LOADSKILLS_FAIL:
      return {
        ...state,
        filterLoadingSkills: false,
        filterLoadedSkills: false,
      };

    case LOADBRIEF:
      return {
        ...state,
        loadingBrief: true,
      };

    case LOADBRIEF_SUCCESS:
      return {
        ...state,
        loadingBrief: false,
        loadedBrief: true,
        brief: action.result,
        loadedBriefId: action.result.id,
      };

    case LOADBRIEF_FAIL:
      return {
        ...state,
        loadingBrief: false,
        loadedBrief: false,
      };

    case LOADBRIEFS:
      return {
        ...state,
        loadingBriefs: true,
      };

    case LOADBRIEFS_SUCCESS:
      return {
        ...state,
        loadingBriefs: false,
        loadedBriefs: true,
        briefs:
          action.result.constructor === Array && action.result.length === 0
            ? state.briefs
            : state.briefs.concat(action.result.collaborations),
        briefsPagesLoaded: state.briefsPagesLoaded + 1,
        moreBriefs:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
      };

    case LOADBRIEFS_FAIL:
      return {
        ...state,
        loadingBriefs: false,
        loadedBriefs: false,
      };

    case LOADPITCHES:
      return {
        ...state,
        loadingPitches: true,
      };

    case LOADPITCHES_SUCCESS:
      return {
        ...state,
        loadingPitches: false,
        loadedPitches: true,
      };

    case LOADPITCHES_FAIL:
      return {
        ...state,
        loadingPitches: false,
        loadedPitches: false,
      };

    case LOADFEATURED:
      return {
        ...state,
        loadingFeatured: true,
      };

    case LOADFEATURED_SUCCESS:
      return {
        ...state,
        loadingFeatured: false,
        loadedFeatured: true,
        featured:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.collaborations,
      };

    case LOADFEATURED_FAIL:
      return {
        ...state,
        loadingFeatured: false,
        loadedFeatured: false,
      };

    case CHANGE_FILTER:
      const newState = {
        ...state,
        briefs: initialState.briefs,
        briefsPagesLoaded: initialState.briefsPagesLoaded,
        moreBriefs: initialState.moreBriefs,
        filterFree: action.key === 'free' ? action.value : state.filterFree,
        filterPaid: action.key === 'paid' ? action.value : state.filterPaid,
        filterRole: action.key === 'role' ? action.value : state.filterRole,
      };

      if (!newState.filterFree && !newState.filterPaid) {
        newState.filterFree = true;
        newState.filterPaid = true;
      }

      return newState;

    case POSTBRIEF:
      return {
        ...state,
        posting: true,
        postingError: '',
      };

    case POSTBRIEF_SUCCESS:
      const copy = state.briefs;
      copy.unshift(action.result);
      const copy2 = state.ownBriefs;
      copy2.push(action.result);

      return {
        ...state,
        posting: false,
        briefs: copy,
        ownBriefs: copy2,
        posted: action.result,
      };

    case POSTBRIEF_FAIL:
      return {
        ...state,
        posting: false,
        postingError: 'Unknown error',
      };

    case DELETEBRIEF:
      return {
        ...state,
        deleting: true,
        deletingError: '',
      };

    case DELETEBRIEF_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
      };

    case DELETEBRIEF_FAIL:
      return {
        ...state,
        deleting: false,
        deletingError: 'Unknown error',
      };

    case CLOSEBRIEF:
      return {
        ...state,
        closing: true,
        closingError: '',
      };

    case CLOSEBRIEF_SUCCESS:
      return {
        ...state,
        closing: false,
        closed: true,
      };

    case CLOSEBRIEF_FAIL:
      return {
        ...state,
        closing: false,
        closingError: 'Unknown error',
      };

    case LOADOWNBRIEFS:
      return {
        ...state,
        loadingOwnBriefs: true,
      };

    case LOADOWNBRIEFS_SUCCESS:
      return {
        ...state,
        loadingOwnBriefs: false,
        loadedOwnBriefs: true,
        ownBriefs:
          typeof action.result.collaborations !== 'undefined'
            ? action.result.collaborations
            : [],
      };

    case LOADOWNBRIEFS_FAIL:
      return {
        ...state,
        loadingOwnBriefs: false,
        loadedOwnBriefs: false,
      };

    case LOADOWNPITCHES:
      return {
        ...state,
        loadingOwnPitches: true,
      };

    case LOADOWNPITCHES_SUCCESS:
      return {
        ...state,
        loadingOwnPitches: false,
        loadedOwnPitches: true,
        ownPitches:
          typeof action.result.threads !== 'undefined'
            ? action.result.threads
            : [],
      };

    case LOADOWNPITCHES_FAIL:
      return {
        ...state,
        loadingOwnPitches: false,
        loadedOwnPitches: false,
      };

    case CLEAR_POSTED_BRIEF:
      return {
        ...state,
        posted: null,
      };

    case CLEAR_DELETED_BRIEF:
      return {
        ...state,
        deleted: null,
      };

    case CLEAR_CLOSED_BRIEF:
      return {
        ...state,
        closed: null,
      };

    case LOADFILES:
      return {
        ...state,
        filesLoading: true,
      };

    case LOADFILES_SUCCESS:
      return {
        ...state,
        filesLoading: false,
        filesLoaded: true,
        files:
          action.result.constructor === Array && action.result.length === 0
            ? state.files
            : state.files.concat(action.result.files),
        filesMore:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        filesPagesLoaded: state.filesPagesLoaded + 1,
      };

    case LOADFILES_FAIL:
      return {
        ...state,
        filesLoading: false,
      };

    case SENDFILES:
      return {
        ...state,
      };

    case SENDFILES_SUCCESS:
      return {
        ...state,
        files: state.files.concat(action.result),
        finalFilesCount: action.result.draft
          ? state.finalFilesCount
          : state.finalFilesCount + 1,
      };

    case SENDFILES_FAIL:
      return {
        ...state,
      };

    case FINALFILES:
      return {
        ...state,
        finalFilesCountLoading: true,
      };

    case FINALFILES_SUCCESS:
      return {
        ...state,
        finalFilesCount:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.meta.pagination.total,
        finalFilesCountLoading: false,
        finalFilesCountLoaded: true,
      };

    case FINALFILES_FAIL:
      return {
        ...state,
        finalFilesCountLoading: false,
      };

    default:
      return state;
  }
}

export function isFilterRolesLoaded(globalState) {
  return globalState.projects && globalState.projects.filterLoadedRoles;
}

export function isFilterSkillsLoaded(globalState) {
  return globalState.projects && globalState.projects.filterLoadedSkills;
}

export function isBriefsLoaded(globalState) {
  return globalState.projects && globalState.projects.loadedBriefs;
}

export function isBriefLoaded(globalState, briefId) {
  return (
    globalState.projects &&
    globalState.projects.loadedBrief &&
    globalState.projects.loadedBriefId === briefId
  );
}

export function isPitchesLoaded(globalState) {
  return globalState.projects && globalState.projects.loadedPitches;
}

export function isFeaturedLoaded(globalState) {
  return globalState.projects && globalState.projects.loadedFeatured;
}

export function isOwnBriefsLoaded(globalState) {
  return globalState.projects && globalState.projects.loadedOwnBriefs;
}

export function isOwnPitchesLoaded(globalState) {
  return globalState.projects && globalState.projects.loadedOwnPitches;
}

export function loadFilterRoles() {
  return {
    types: [LOADROLES, LOADROLES_SUCCESS, LOADROLES_FAIL],
    promise: (client) => client.get('/notices/collaboration/roles?limit=1000'),
    schema: { suggested_roles: [roleSchema] },
  };
}

export function loadFilterSkills() {
  return {
    types: [LOADSKILLS, LOADSKILLS_SUCCESS, LOADSKILLS_FAIL],
    promise: (client) => client.get('/notices/collaboration/skills?limit=1000'),
    schema: { suggested_skills: [skillSchema] },
  };
}

export function loadBrief(id) {
  const params = '?include=user,user.avatars,user.covers';

  return {
    types: [LOADBRIEF, LOADBRIEF_SUCCESS, LOADBRIEF_FAIL],
    promise: (client) => client.get('/notices/collaboration/' + id + params),
  };
}

export function loadBriefs(page, roleOrSkill, paid, free) {
  let params =
    '?include=user,user.avatars,user.covers&page=' +
    page +
    '&limit=10&operator=and&state=active,in_progress,completed,expired,inactive,paid,final_files_uploaded,paid_final_files_uploaded,final_files_approved,paid_final_files_approved&include_state=1';

  if (typeof roleOrSkill !== 'undefined') {
    params += `&${roleOrSkill.type}=${roleOrSkill.name}`;
  }

  if (paid !== free) {
    params += '&paid=' + +paid;
  }

  return {
    types: [LOADBRIEFS, LOADBRIEFS_SUCCESS, LOADBRIEFS_FAIL],
    promise: (client) => client.get('/notices/collaboration' + params),
  };
}

export function loadPitches(id, page, accepted, completed, declined, operator) {
  let params = '?include=sender,sender.avatars,sender.covers';

  if (page) {
    params += '&page=' + page;
  }

  if (accepted === 1 || accepted === 0) {
    params += '&accepted=' + accepted;
  }

  if (completed === 1 || completed === 0) {
    params += '&completed=' + completed;
  }

  if (declined === 1 || declined === 0) {
    params += '&declined=' + declined;
  }

  if (operator) {
    params += '&operator=' + operator;
  }

  return {
    types: [LOADPITCHES, LOADPITCHES_SUCCESS, LOADPITCHES_FAIL],
    promise: (client) =>
      client.get('/notices/collaboration/' + id + '/pitches' + params),
  };
}

export function loadFeatured() {
  return {
    types: [LOADFEATURED, LOADFEATURED_SUCCESS, LOADFEATURED_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/featured?randomize=1&include=user,user.avatars,user.covers&limit=4'
      ),
  };
}

export function loadOwnBriefs(user) {
  return {
    types: [LOADOWNBRIEFS, LOADOWNBRIEFS_SUCCESS, LOADOWNBRIEFS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          user.id +
          '/notices/collaboration?include=pitches,rating&include_state=1&state=hidden'
      ),
  };
}

export function loadOwnPitches(user) {
  return {
    types: [LOADOWNPITCHES, LOADOWNPITCHES_SUCCESS, LOADOWNPITCHES_FAIL],
    promise: (client) =>
      client.get(
        '/users/' + user.id + '/pitches?include=message.notice&state=accepted'
      ),
  };
}

export function changeFilter(key, value) {
  return {
    type: CHANGE_FILTER,
    key,
    value,
  };
}

export function postBrief(overview, description, role, answers) {
  const data = {
    text: overview,
    spec: description,
    [role.type]: role.name,
  };

  if (answers && answers.constructor === Array && answers.length > 0) {
    data.questions = answers;
  }

  return {
    types: [POSTBRIEF, POSTBRIEF_SUCCESS, POSTBRIEF_FAIL],
    promise: (client) =>
      client.post(
        '/notices/collaboration?include=user,user.avatars,user.covers,pitches',
        { data: jsonToFormData(data) }
      ),
  };
}

export function deleteBrief(id) {
  return {
    types: [DELETEBRIEF, DELETEBRIEF_SUCCESS, DELETEBRIEF_FAIL],
    promise: (client) => client.delete('/notices/collaboration/' + id),
  };
}

export function closeBrief(id) {
  const data = {
    _method: 'PATCH',
    active: 0,
  };

  return {
    types: [CLOSEBRIEF, CLOSEBRIEF_SUCCESS, CLOSEBRIEF_FAIL],
    promise: (client) => client.post('/notices/collaboration/' + id, { data }),
  };
}

export function clearPostedBrief() {
  return {
    type: CLEAR_POSTED_BRIEF,
  };
}

export function loadFiles(briefId, pitchId, page = 1) {
  return {
    types: [LOADFILES, LOADFILES_SUCCESS, LOADFILES_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' +
          briefId +
          '/pitches/' +
          pitchId +
          '/files?page=' +
          page
      ),
  };
}

export function sendFile(briefId, pitchId, name, link, draft) {
  return {
    types: [SENDFILES, SENDFILES_SUCCESS, SENDFILES_FAIL],
    promise: (client) =>
      client.post(
        '/notices/collaboration/' + briefId + '/pitches/' + pitchId + '/files',
        {
          data: jsonToFormData({
            name: name,
            file: link,
            draft: +draft,
          }),
        }
      ),
  };
}

export function getFinalFilesCount(briefId, pitchId) {
  return {
    types: [FINALFILES, FINALFILES_SUCCESS, FINALFILES_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' +
          briefId +
          '/pitches/' +
          pitchId +
          '/files?draft=0'
      ),
  };
}
