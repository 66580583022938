const ERROR = 'twineApp/errorMessage/ERROR';
const RESET_ERROR = 'twineApp/errorMessage/RESET_ERROR';
const UNKNOWN_ERROR_STRING = 'Unknown Error';

export default function reducer(state = null, action = {}) {
  const { type, error } = action;

  let errorMessage = '';

  if (type === RESET_ERROR) {
    errorMessage = null;
  } else if (type === ERROR && !action.ignoreError) {
    errorMessage = action.message;
  } else if (
    error &&
    error.status_code !== 404 &&
    error.status_code !== 401 &&
    !action.ignoreError
  ) {
    // Temp hack as some 404 errors are OK.
    if (error.errors && error.errors.constructor === Object) {
      if (error.errors[Object.keys(error.errors)[0]].constructor === Array) {
        errorMessage = error.errors[Object.keys(error.errors)[0]][0];
      } else if (
        error.errors[Object.keys(error.errors)[0]].constructor === String
      ) {
        errorMessage = error.errors[Object.keys(error.errors)[0]];
      } else {
        errorMessage = UNKNOWN_ERROR_STRING;
      }
    } else if (error.message && error.message.constructor === String) {
      if (
        error.message.length > 300 ||
        error.message.toLowerCase().startsWith('<!doctype') ||
        error.message.toLowerCase().startsWith('<html')
      ) {
        errorMessage =
          'Error code 3001: Please reload this page. If you continue to get this error, please report it at www.twine.net/contact with your device, browser, your username and the time that this happened.';
      } else {
        errorMessage = error.message;
      }
    } else if (error.status_code && error.status_code === '401') {
      errorMessage = 'Not logged in';
    } else {
      errorMessage = UNKNOWN_ERROR_STRING;
    }
  }

  return errorMessage;
}

export function setErrorMessage(message) {
  return {
    type: ERROR,
    message,
  };
}

export function resetErrorMessage() {
  return {
    type: RESET_ERROR,
  };
}
