import React from 'react';

const Loader = ({ loading = false, style, ...rest }) => {
  const loadingImage = require('./loader.gif').default;

  return (
    <div style={{ ...style, textAlign: 'center' }} {...rest}>
      {loading && <img alt="Twine" src={loadingImage} />}
    </div>
  );
};

export default Loader;
