import PropTypes from 'prop-types';
import React from 'react';

export default class ToggleSwitch extends React.Component {
  static propTypes = {
    on: PropTypes.bool,
    onClick: PropTypes.func,
    enabled: PropTypes.bool,
    className: PropTypes.string,
    onText: PropTypes.string,
    offText: PropTypes.string,
    autoToggle: PropTypes.bool,
  };

  static defaultProps = {
    on: false,
    onClick: () => {},
    enabled: true,
    className: '',
    onText: 'on',
    offText: 'off',
    autoToggle: true,
  };

  constructor(props) {
    super(props);
    this.state = { on: this.props.on };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ on: nextProps.on });
  }

  handleClick = (event) => {
    event.preventDefault();

    if (this.props.enabled) {
      this.props.onClick(!this.state.on);

      if (this.props.autoToggle) {
        this.setState({ on: !this.state.on });
      }
    }
  };

  render() {
    const className = [
      'switch-toggle',
      this.props.className,
      this.state.on ? 'on ' : '',
      this.props.enabled ? '' : 'disabled ',
    ]
      .join(' ')
      .trim();

    return (
      <div className={className} onClick={this.handleClick}>
        <div
          style={{
            display: 'inline-block',
            width: '50%',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {this.props.onText}
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '50%',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {this.props.offText}
        </div>
      </div>
    );
  }
}
