const backendStaging = () => process.argv.includes('--cloud-api');

const environment = {
  development: {
    isProduction: false,
  },
  test: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

const config = Object.assign(
  {
    host: process.env.ENDPOINT_FRONTEND
      ? process.env.ENDPOINT_FRONTEND
      : 'fe.local.twine.net',
    port: process.env.FRONTEND_PORT
      ? process.env.FRONTEND_PORT
      : process.env.PORT
      ? process.env.PORT
      : 8080,
    apiHost: process.env.APIHOST
      ? process.env.APIHOST
      : backendStaging()
      ? 'https://staging.twine.net'
      : process.env.ENDPOINT_FRONTEND
      ? `https://${process.env.ENDPOINT_FRONTEND}`
      : 'http://local.twine.net',
    apiPort: process.env.APIPORT
      ? process.env.APIPORT
      : process.env.ENDPOINT_FRONTEND
      ? 443
      : 80,
    baseUrl: process.env.ENDPOINT_FRONTEND
      ? `https://${process.env.ENDPOINT_FRONTEND}`
      : 'http://local.twine.net', // At the moment it's the same as apiHost, but makes sense to keep separate as this could change
    app: {
      title: 'Frontend app',
      head: {
        titleTemplate: '%s',
        title: 'Twine: Hire quality freelancers for your job',
        defaultTitle: 'Twine: Hire quality freelancers for your job',
        description:
          "Twine matches companies to the best digital and creative freelancers from a network of over 260,000. It's free to post a job and you only pay when you hire.",
        meta: [
          {
            name: 'description',
            content:
              "Twine matches companies to the best digital and creative freelancers from a network of over 390,000. It's free to post a job and you only pay when you hire.",
          },
          { charset: 'utf-8' },
          { property: 'og:site_name', content: 'Twine' },
          {
            property: 'og:image',
            content:
              'https://www.twine.net/assets/img/logos/twine-social-icon-min.png',
          },
          { property: 'og:locale', content: 'en_US' },
          {
            property: 'og:title',
            content: 'Twine: Hire quality freelancers for your job',
          },
          { property: 'og:url', content: 'https://www.twine.net/' },
          {
            property: 'og:description',
            content:
              "Twine matches companies to the best digital and creative freelancers from a network of over 390,000. It's free to post a job and you only pay when you hire.",
          },
          { property: 'og:type', content: 'website' },
          { name: 'twitter:site', content: '@jointwine' },
          { name: 'twitter:card', content: 'summary' },
          {
            name: 'twitter:title',
            content: 'Twine: Hire quality freelancers for your job',
          },
          {
            name: 'twitter:description',
            content:
              "Twine matches companies to the best digital and creative freelancers from a network of over 390,000. It's free to post a job and you only pay when you hire.",
          },
          {
            name: 'twitter:image',
            content:
              'https://www.twine.net/assets/img/logos/twine-social-icon-min.png',
          },
        ],
      },
    },
    defaultUserRating: 4,
    defaultNumberOfFreelancersOnLandingPages: 8,
    minimumBudget: 3000,
    stripeKey: 'pk_live_WyBQin3driOenb2RnSvoQSzZ',
    stripeDevKey: 'pk_test_CbXcrPcRicmzINkb3c0B5PIh',
    WSSEndpoint: 'wss://gyj5y94he1.execute-api.us-east-1.amazonaws.com/Prod',
    WSSEndpointDev: 'wss://fcd9qig3o6.execute-api.eu-west-2.amazonaws.com/Prod',
    GTMEndpoint:
      environment.isProduction &&
      process.env.ENDPOINT_FRONTEND === 'www.twine.net'
        ? 'GTM-TKR9N6Z'
        : 'GTM-WZR75XC',
    testingGTMLocally: process.env.TESTING_GTM_LOCALLY == 'true',
    partnerImages:
      environment.isProduction &&
      process.env.ENDPOINT_FRONTEND === 'www.twine.net'
        ? 'https://twine-partners.s3.amazonaws.com'
        : 'https://twine-partners-staging.s3.amazonaws.com',
  },
  environment
);

export default config;
