import { fromJS } from 'immutable';
import { merger } from 'helpers/HelperFunctions';

const RATE_SUCCESS = 'twineApp/briefDetails/RATE_SUCCESS';
export const SET_ENTITIES = 'twineApp/entities/SET_ENTITIES';

const initialState = {
  briefs: {},
  invites: {},
  credits: {},
  projects: {},
  projectComments: {},
  roles: {},
  testimonials: {},
  users: {},
  vouchers: {},
  bills: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENTITIES: {
      if (action.entities) {
        const imState = fromJS(state);

        // const imResult = imState.mergeDeep(fromJS(action.entities));

        // return imResult.toJS();

        return merger(imState, fromJS(action.entities)).toJS();
      }
      break;
    }
    case RATE_SUCCESS:
      return {
        ...state,
        briefs: {
          ...state.briefs,
          [action.result.for_id]: {
            ...state.briefs[action.result.for_id],
            rating: action.result,
          },
        },
      };

    default:
      return state;
  }

  return state;
}

export function get(globalState, type, id) {
  if (!globalState || !type || !id) {
    return null;
  }

  return globalState.entities[type][id] || null;
}

// export function update(type, id, update = {}) {

// }
