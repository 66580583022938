const GETUNDOCKAUTHURL = 'twineApp/undock/GETUNDOCKAUTHURL';
const GETUNDOCKAUTHURL_SUCCESS = 'twineApp/undock/GETUNDOCKAUTHURL_SUCCESS';
const GETUNDOCKAUTHURL_FAIL = 'twineApp/undock/GETUNDOCKAUTHURL_FAIL';
const PASSAUTHCODE = 'twineApp/undock/PASSAUTHCODE';
const PASSAUTHCODE_SUCCESS = 'twineApp/undock/PASSAUTHCODE_SUCCESS';
const PASSAUTHCODE_FAIL = 'twineApp/undock/PASSAUTHCODE_FAIL';
const AVAILABILITY = 'twineApp/undock/AVAILABILITY';
const AVAILABILITY_SUCCESS = 'twineApp/undock/AVAILABILITY_SUCCESS';
const AVAILABILITY_FAIL = 'twineApp/undock/AVAILABILITY_FAIL';
const CREATEMEETING = 'twineApp/undock/CREATEMEETING';
const CREATEMEETING_SUCCESS = 'twineApp/undock/CREATEMEETING_SUCCESS';
const CREATEMEETING_FAIL = 'twineApp/undock/CREATEMEETING_FAIL';
const CREATEMEETING_RESET = 'twineApp/undock/CREATEMEETING_RESET';
const AVAILABILITY_RESET = 'twineApp/undock/AVAILABILITY_RESET';

const initialState = {
  undockAuthUrl: '',
  loadingAuthUrl: false,
  loadedAuthUrl: false,
  connected: false,
  active: false,
  loadedStatus: false,
  loadingStatus: false,
  loadingAvailability: false,
  loadedAvailability: false,
  availability: [],
  creatingMeeting: false,
  createdMeeting: false,
  meetingLocation: '',
  createdMeetingResponseMessage: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Get Undock OAuth url
    case GETUNDOCKAUTHURL:
      return {
        ...state,
        loadingAuthURL: true,
      };

    case GETUNDOCKAUTHURL_SUCCESS:
      return {
        ...state,
        undockAuthUrl:
          action.result && action.result.authorizationUri
            ? action.result.authorizationUri
            : '',

        loadingAuthUrl: false,
        loadedAuthUrl: true,
      };

    case GETUNDOCKAUTHURL_FAIL:
      return {
        ...state,

        loadingAuthUrl: false,
        loadedAuthUrl: true,
      };

    // Pass auth code
    case PASSAUTHCODE:
      return {
        ...state,

        loadingStatus: true,
      };

    case PASSAUTHCODE_SUCCESS:
      return {
        ...state,
        connected: true,
        active: true,

        loadedStatus: true,
        loadingStatus: false,
      };

    case PASSAUTHCODE_FAIL:
      return {
        ...state,
        connected: false,
        active: false,

        loadedStatus: true,
        loadingStatus: false,
      };

    // Get availability
    case AVAILABILITY:
      return {
        ...state,
        loadingAvailability: true,
      };

    case AVAILABILITY_SUCCESS:
      const mergedAvailability = mergeAvailability(
        action.result,
        state.availability,
        'day'
      );
      return {
        ...state,
        availability: mergedAvailability,
        loadingAvailability: false,
        loadedAvailability: true,
      };

    case AVAILABILITY_FAIL:
      return {
        ...state,
        loadingAvailability: false,
        loadedAvailability: true,
      };

    case AVAILABILITY_RESET:
      return {
        loadingAvailability: false,
        loadedAvailability: false,
        availability: [],
      };

    case CREATEMEETING:
      return {
        ...state,
        creatingMeeting: true,
      };

    case CREATEMEETING_SUCCESS:
      return {
        ...state,
        creatingMeeting: false,
        createdMeeting: action.result.data.location ? true : false,
        createdMeetingResponseMessage: action.result.data.message,
        meetingLocation: action.result.data.location
          ? action.result.data.location
          : '',
      };

    case CREATEMEETING_SUCCESS:
      return {
        ...state,
        creatingMeeting: false,
        createdMeeting: false,
      };

    case CREATEMEETING_RESET:
      return {
        ...state,
        creatingMeeting: false,
        createdMeeting: false,
        createdMeetingResponseMessage: '',
        meetingLocation: '',
      };

    default:
      return state;
  }
}

export function generateAuthorizationURL(redirectState = '/messages') {
  return {
    types: [GETUNDOCKAUTHURL, GETUNDOCKAUTHURL_SUCCESS, GETUNDOCKAUTHURL_FAIL],
    promise: (client) =>
      client.post('/undock/authorizationuri', {
        data: {
          redirect_state: redirectState,
        },
      }),
  };
}

export function passAuthCode(authCode = '', userId = NULL) {
  return {
    types: [PASSAUTHCODE, PASSAUTHCODE_SUCCESS, PASSAUTHCODE_FAIL],
    promise: (client) =>
      client.post('/undock/token', {
        data: {
          authorization_code: authCode,
          userId: userId,
        },
      }),
  };
}

export function getAvailability(
  startTime,
  endtime,
  userOrganiser,
  userAttendee,
  availabilityType,
  timeInterval,
  timeZone
) {
  return {
    types: [AVAILABILITY, AVAILABILITY_SUCCESS, AVAILABILITY_FAIL],
    promise: (client) =>
      client.post('/undock/availability', {
        data: {
          start_time: startTime,
          end_time: endtime,
          user_organiser: userOrganiser,
          user_attendee: userAttendee,
          availability_type: availabilityType,
          time_interval: timeInterval,
          time_zone: timeZone,
        },
      }),
  };
}

function mergeAvailability(availability, existingAvailability, filterKey) {
  const mergedAvailability = existingAvailability.map(
    (existingAvailabilityItem) => {
      const newAvailabilityItem = availability.find(
        (availabilityItem) =>
          availabilityItem[filterKey] === existingAvailabilityItem[filterKey]
      );
      if (newAvailabilityItem) {
        return newAvailabilityItem;
      }
      return existingAvailabilityItem;
    }
  );

  // add any new elements from new array that are not found in existing array
  mergedAvailability.push(
    ...availability.filter(
      (availabilityItem) =>
        !mergedAvailability.find(
          (existingAvailabilityItem) =>
            existingAvailabilityItem[filterKey] === availabilityItem[filterKey]
        )
    )
  );

  return mergedAvailability;
}

export function createUndockMeeting(
  userOrganiser,
  userAttendee,
  startTime,
  interval,
  timeZone
) {
  return {
    types: [CREATEMEETING, CREATEMEETING_SUCCESS, CREATEMEETING_FAIL],
    promise: (client) =>
      client.post('/undock/meeting', {
        data: {
          user_organiser: userOrganiser,
          user_attendee: userAttendee,
          start_time: startTime,
          interval: interval,
          time_zone: timeZone,
        },
      }),
  };
}

export function resetCreateMeeting() {
  return {
    type: CREATEMEETING_RESET,
  };
}

export function resetAvailability() {
  return {
    type: AVAILABILITY_RESET,
  };
}
