import React, { memo } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Col from 'react-bootstrap/lib/Col';

import styles from './Footer.module.scss';

const SmallFooter = ({ inverse = false }) => (
  <footer className={`${inverse ? styles.inverse : ''} ${styles.container}`}>
    <nav className="container">
      <Grid>
        <Col xs={12}>
          <ul className={styles.footerList + ' ' + styles.copyright}>
            <li>
              &copy; Clowdy Ltd. t/a Twine.{' '}
              <a href="/terms" title="Terms Of Service">
                Terms
              </a>{' '}
              -{' '}
              <a href="/privacy" title="Privacy Policy">
                Privacy
              </a>
            </li>
          </ul>
        </Col>
      </Grid>
    </nav>
  </footer>
);

export default memo(SmallFooter);
