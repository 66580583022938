import superagent from 'superagent';
import config from '../config';
import * as Sentry from '@sentry/react';

import { jsonToFormData } from 'helpers/HelperFunctions';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

function formatUrl(path) {
  const adjustedPath = path[0] !== '/' ? '/' + path : path;
  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return config.apiHost + '/api' + adjustedPath;
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return '/api' + adjustedPath;
}

export default class ApiClient {
  constructor(req, res) {
    methods.forEach(
      (method) =>
        (this[method] = (path, { params, data } = {}) =>
          new Promise((resolve, reject) => {
            let newMethod = method;
            const newData = data || {};

            if (newMethod === 'patch' || newMethod === 'put') {
              newData._method = newMethod.toUpperCase();
              newMethod = 'post';
            }

            const request = superagent[newMethod](formatUrl(path));

            if (params) {
              request.query(params);
            }

            if (__SERVER__ && req.get('cookie')) {
              request.set('cookie', req.get('cookie'));
            }

            if (Object.keys(newData).length > 0) {
              const formData = new FormData();
              const formattedData = jsonToFormData(newData);

              for (const item in formattedData) {
                if (formattedData[item] instanceof File) {
                  formData.append(
                    item,
                    formattedData[item],
                    formattedData[item].name
                  );
                } else {
                  formData.append(item, formattedData[item]);
                }
              }

              request.send(formData);
            }

            request.end((err, { body, header } = {}) => {
              try {
                if (header && typeof header['set-cookie'] !== 'undefined') {
                  res.append('Set-Cookie', header['set-cookie']);
                }
              } catch (exception) {
                Sentry.captureException(exception);
                console.error(exception);
              }

              return err ? reject(body || err) : resolve(body);
            });
          }))
    );
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
