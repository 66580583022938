import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import { LinkContainer } from 'react-router-bootstrap';
import BMenuItem from 'react-bootstrap/lib/MenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import Badge from 'react-bootstrap/lib/Badge';
import Fade from 'react-bootstrap/lib/Fade';
import Thumbnail from 'components/Thumbnails/Thumbnail';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import { useHistory } from 'react-router-dom';
// import useAuth from 'hooks/useAuth';
import { trackEvent } from 'helpers/Analytics';
import styles from './MainHeader.module.scss';
import NavBarCTA from '../Main/NavBarCTA/NavBarCTA';

const MainHeader = ({
  user,
  count,
  loadedCount,
  loadedOldCount,
  oldCount,
  unreadMessages,
  isProject,
}) => {
  // const { user } = useAuth(); // Potentially causing an issue as user auth already checked in App.js
  const {
    location: { pathname },
  } = useHistory();
  const [navExpanded, setNavExpanded] = useState(false);
  const displaySignUpButton = false;
  const [displayApplyButton, setDisplayApplyButton] = useState(false);
  const [displayPostJobButton, setDisplayPostJobButton] = useState(false);
  const [displayUpsellButton, setDisplayUpsellButton] = useState(false);

  const onToggleNav = () => setNavExpanded((prev) => !prev);

  const handleHeaderCTAToDisplay = (user, isProject) => {
    setDisplayApplyButton(false);
    setDisplayPostJobButton(false);
    setDisplayUpsellButton(false);

    if (user) {
      if (user?.type === 'seller') {
        // When signed in as a freelancer and viewing the /projects page, the CTA button in the header should be “Apply” which will scroll the user down to the box at the bottom of the page. Use an animated scroll.
        if (isProject) {
          setDisplayApplyButton(true);
        } else {
          setDisplayUpsellButton(true);
        }
      } else if (user?.type === 'buyer') {
        // When signed in as a client and on the /projects page, show the current CTA “Post a Job”.
        setDisplayPostJobButton(true);
      }
    }
  };

  const handleNav = () => {
    window.location.href = '/freelancers';
  };

  useEffect(() => {
    handleHeaderCTAToDisplay(user, isProject);
  }, [user, isProject]);

  const authMobile = user && (
    <div className="visible-xs-block">
      <Nav key="navbar-1" navbar>
        {user.type === 'seller' && (
          <NavItem href="/jobs" key="jobs-nav">
            Find Jobs
          </NavItem>
        )}
        {user.type === 'buyer' && (
          <LinkContainer to="/howitworks" key="howitworks-link">
            <NavItem key="howitworks-nav" onClick={onToggleNav}>
              How it works
            </NavItem>
          </LinkContainer>
        )}
        <NavItem key="messages-link" href="/messages">
          Messages{' '}
          {unreadMessages > 0 && (
            <Badge
              style={{
                position: 'absolute',
                right: '10px',
                backgroundColor: '#40a0f5',
              }}
            >
              {unreadMessages}
            </Badge>
          )}
        </NavItem>
        {user.type === 'seller' && (
          <LinkContainer to={user.links.main_relative} key="portfolio-link">
            <NavItem onClick={onToggleNav} key="portfolio-nav">
              Portfolio
            </NavItem>
          </LinkContainer>
        )}
        {user.type === 'seller' && (
          <>
            <NavItem key="collaborate-nav" href="/collaborate">
              Collaborate
            </NavItem>
            <NavItem key="howtwineworks-nav" href="/howitworks">
              How Twine Works
            </NavItem>
          </>
        )}
        <NavItem key="browse-nav" href="/browse">
          Browse for freelancers
        </NavItem>
      </Nav>

      {user.type === 'seller' && (
        <Nav
          key="navbar-2"
          navbar
          style={{
            borderTop: '1px solid #212121',
            boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
          }}
        >
          <NavItem key="managejobs-nav" href="/manage/briefs">
            Manage Jobs
          </NavItem>
          <NavItem key="managepitches-nav" href="/manage/pitches">
            Manage Pitches
          </NavItem>
          <NavItem key="manageprojects-nav" href="/projects/manage">
            Manage Projects
          </NavItem>
          <NavItem key="earings-nav" href="/earnings">
            Earnings
          </NavItem>
          <NavItem key="shortlist-nav" href="/shortlist">
            Favourites
          </NavItem>
        </Nav>
      )}

      <Nav
        key="navbar-3"
        navbar
        style={{
          borderTop: '1px solid #212121',
          boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
        }}
      >
        {user.type === 'buyer' && (
          <NavItem key="" href="/manage/briefs">
            Manage Jobs
          </NavItem>
        )}
        {user.type === 'buyer' && (
          <NavItem key="shortlist-navitem" href="/shortlist">
            Favourites
          </NavItem>
        )}
        <NavItem key="notifcations-navitem" href="/notifications">
          Notifications{' '}
          {count + oldCount > 0 && (
            <Badge
              style={{
                position: 'absolute',
                right: '15px',
                backgroundColor: '#40a0f5',
              }}
            >
              {count + oldCount}
            </Badge>
          )}
        </NavItem>
        <NavItem key="payments-navitem" href="/payments">
          Payments
        </NavItem>
        <NavItem href="https://help.twine.net">Help Center</NavItem>
        <NavItem key="settings-navitem" href="/settings">
          Settings
        </NavItem>
        <NavItem key="sign" href="/signout">
          Log out
        </NavItem>
        {typeof user.admin_user_id !== 'undefined' ? (
          <NavItem href="/adminSwitchBack.php">Admin Switch Back</NavItem>
        ) : null}
      </Nav>
    </div>
  );
  const noAuthMobile = !user && (
    <div key="no-user-div" className="visible-xs-block">
      <Nav key="navbar-4" navbar>
        <NavItem href="/jobs" key="jobs-nav">
          Find work
        </NavItem>
        {/* Forcing a reload to send users to the webflow page instead of a 404 in the frontend */}
        <NavItem onClick={() => handleNav()} key="howitworks-nav">
          How it works
        </NavItem>
        <NavItem key="collaborate-nav" href="/collaborate">
          Collaborate
        </NavItem>
      </Nav>

      <Nav
        key="navbar-5"
        navbar
        style={{
          borderTop: '1px solid #212121',
          boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
        }}
      >
        <NavItem href="https://help.twine.net" onClick={onToggleNav}>
          Help Center
        </NavItem>
        <NavItem key="signup-nav" href="/signup">
          Sign Up
        </NavItem>
        <NavItem key="signin-nav" href="/signin">
          Sign In
        </NavItem>
      </Nav>
    </div>
  );

  const messages = (
    <li key="messages-link">
      <a href="/messages" key="messages-nav">
        Messages{' '}
        <Fade in={unreadMessages > 0}>
          <Badge
            style={{
              position: 'absolute',
              right: 0,
              top: '14px',
              backgroundColor: '#40a0f5',
            }}
          >
            {unreadMessages}
          </Badge>
        </Fade>
      </a>
    </li>
  );

  const isCtaBrowse = useMemo(
    () => /(hire-a-freelancer|messages|howitworks|whyusetwine)/.test(pathname),
    [pathname]
  );

  const isCtaPricing = useMemo(
    () =>
      /(manage|settings|projects|notifications|shortlist)/.test(pathname) ||
      (user && pathname.match(user.links.main_relative)),
    [user, pathname]
  );

  const headerCTA = (isCtaBrowse || isCtaPricing) && (
    <Navbar.Form key="header-CTA-nav" pullLeft className="hidden-xs hidden-sm">
      <LinkContainer
        to={isCtaBrowse ? '/browse' : isCtaPricing ? '/pricing' : ''}
      >
        <NavBarCTA
          displayUpsellButton={displayUpsellButton}
          displaySignUpButton={displaySignUpButton}
          displayApplyButton={displayApplyButton}
          displayPostJobButton={displayPostJobButton}
        />
      </LinkContainer>
    </Navbar.Form>
  );

  const upgradeToProCTA = (
    <Navbar.Form
      key="header-CTA-nav"
      pullLeft
      className="hidden-xs hidden-sm lato-bold"
    >
      <LinkContainer to="/pricing/creative">
        <NavBarCTA
          displayUpsellButton={displayUpsellButton}
          displaySignUpButton={displaySignUpButton}
          displayApplyButton={displayApplyButton}
          displayPostJobButton={displayPostJobButton}
        />
      </LinkContainer>
    </Navbar.Form>
  );

  const notifications = (
    <LinkContainer to="/notifications">
      <NavItem
        key="notification-nav"
        className="hidden-sm"
        title="See your notifications"
      >
        <SvgIcon size="18" icon="bell-o" />
        <Fade in={loadedCount && loadedOldCount && count + oldCount > 0}>
          <Badge
            style={{
              position: 'absolute',
              left: '25px',
              top: '10px',
              backgroundColor: '#40a0f5',
            }}
          >
            {count + oldCount}
          </Badge>
        </Fade>
      </NavItem>
    </LinkContainer>
  );

  const sharedDropdowns = (
    <>
      <BMenuItem key="shortlist-item" href="/shortlist">
        Favourites
      </BMenuItem>
      <BMenuItem key="shortlist-divider" divider />
      <BMenuItem key="notifications-item" href="/notifications">
        Notifications{' '}
        {count + oldCount > 0 && (
          <Badge
            style={{
              position: 'absolute',
              right: '10px',
              backgroundColor: '#40a0f5',
            }}
          >
            {count + oldCount}
          </Badge>
        )}
      </BMenuItem>
      <BMenuItem key="notifications-divider" divider />
      <BMenuItem key="payments-item" href="/payments">
        Payments
      </BMenuItem>
      <BMenuItem key="payments-divider" divider />
      <BMenuItem key="settings-item" href="/settings">
        Settings
      </BMenuItem>
      <BMenuItem key="settings-divider" divider />
      <BMenuItem key="help-centre-item" href="https://help.twine.net/">
        Help Centre
      </BMenuItem>
      <BMenuItem key="help-centre-divider" divider />
      <BMenuItem key="signout-item" href="/signout">
        Log out
      </BMenuItem>
      {user && typeof user.admin_user_id !== 'undefined' && (
        <>
          <BMenuItem divider key="admin-switch-back-1" />
          <BMenuItem href="/adminSwitchBack.php" key="admin-switch-back-2">
            Admin Switch Back
          </BMenuItem>{' '}
        </>
      )}
    </>
  );

  const navSections = {
    left: {
      noAuth: (
        <>
          <LinkContainer to="/jobs">
            <NavItem key="jobs_nav_2">Find Work</NavItem>
          </LinkContainer>
          {/* Forcing a reload to send users to the webflow page instead of a 404 in the frontend */}

          <NavItem key="howitworks_nav_2" onClick={() => handleNav()}>
            How it works
          </NavItem>
          <NavItem key="toolkit_nav_5" href="/toolkits/freelancer">
            Toolkit
          </NavItem>
          <NavDropdown
            key="navdropdown-1"
            noCaret
            title={
              <span style={{ position: 'relative', top: '3px' }}>
                <SvgIcon color="#fff" icon="more" />
              </span>
            }
            style={{ fontSize: '2.3em' }}
            id="icon-dropdown"
            className="hidden-xs"
          >
            <BMenuItem key="browse-item" className="visible-sm" href="/browse">
              Browse for freelancers
            </BMenuItem>
            <BMenuItem key="collaborate-item" href="/collaborate">
              Collaborate
            </BMenuItem>
            <BMenuItem
              key="community-item"
              href="https://www.instagram.com/jointwine/"
            >
              Community
            </BMenuItem>
            <BMenuItem key="blog-item" href="/blog">
              News and blog
            </BMenuItem>
          </NavDropdown>
        </>
      ),
      client: user && (
        <>
          <LinkContainer key="postajob-link" to="/post">
            <NavItem key="postajob-nav" onClick={onToggleNav}>
              New Project
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/manage/briefs">
            <NavItem key="managejobs-nav">My Projects</NavItem>
          </LinkContainer>
          {messages}
          <LinkContainer key="shortlist-nav" to="/shortlist">
            <NavItem>Favourites</NavItem>
          </LinkContainer>
          <LinkContainer
            key="browse-nav"
            to="/browse"
            onClick={() => trackEvent('header-click-browse')}
          >
            <NavItem>Browse</NavItem>
          </LinkContainer>
        </>
      ),
      freelancer: user && (
        <>
          <LinkContainer to="/jobs">
            <NavItem key="jobs_nav_2">Find Jobs</NavItem>
          </LinkContainer>
          <LinkContainer to="/manage/pitches">
            <NavItem key="jobs_nav_2">My Pitches</NavItem>
          </LinkContainer>
          {messages}
          <LinkContainer key="portfolio-link" to={user.links.main_relative}>
            <NavItem key="portfolio_nav_4">Portfolio</NavItem>
          </LinkContainer>
          <NavItem key="toolkit_nav_5" href="/toolkits/freelancer">
            Toolkit
          </NavItem>
          <NavDropdown
            key="navdropdown-1"
            noCaret
            title={
              <span style={{ position: 'relative', top: '3px' }}>
                <SvgIcon color="#fff" icon="more" />
              </span>
            }
            style={{ fontSize: '2.3em' }}
            id="icon-dropdown"
            className="hidden-xs"
          >
            <BMenuItem key="browse-item" className="visible-sm" href="/browse">
              Browse for freelancers
            </BMenuItem>
            <BMenuItem key="collaborate-item" href="/collaborate">
              Collaborate
            </BMenuItem>
            <BMenuItem
              key="community-item"
              href="https://www.instagram.com/jointwine/"
            >
              Community
            </BMenuItem>
            <BMenuItem key="blog-item" href="/blog">
              News and blog
            </BMenuItem>
          </NavDropdown>
        </>
      ),
    },
    right: {
      noAuth: (
        <>
          {headerCTA}
          <NavItem
            key="signin-nav"
            style={{
              borderTop: '1px solid #212121',
              boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.1)',
            }}
            href="/signin"
          >
            Sign in
          </NavItem>
          <NavItem key="signup-nav" href="/signup">
            Sign up
          </NavItem>
        </>
      ),
      client: {
        items: (
          <>
            {/* Client Side CTA */}
            {headerCTA}
            {notifications}
          </>
        ),
        dropdown: user && (
          <>
            <BMenuItem key="notifications-item" href="/notifications">
              Notifications{' '}
              {count + oldCount > 0 && (
                <Badge
                  style={{
                    position: 'absolute',
                    right: '10px',
                    backgroundColor: '#40a0f5',
                  }}
                >
                  {count + oldCount}
                </Badge>
              )}
            </BMenuItem>
            <BMenuItem key="notifications-divider" divider />
            <BMenuItem key="payments-item" href="/payments">
              Payments
            </BMenuItem>
            <BMenuItem key="payments-divider" divider />
            <BMenuItem key="settings-item" href="/settings">
              Settings
            </BMenuItem>
            <BMenuItem key="settings-divider" divider />
            <BMenuItem key="help-centre-item" href="https://help.twine.net/">
              Help Centre
            </BMenuItem>
            <BMenuItem key="help-centre-divider" divider />
            <BMenuItem key="signout-item" href="/signout">
              Log out
            </BMenuItem>
          </>
        ),
      },
      freelancer: {
        items: (
          <>
            {/* Displays for a freelancer that isn't a a pro user  */}

            {!user?.pro && upgradeToProCTA}
            {notifications}
          </>
        ),
        dropdown: user && (
          <>
            {!user.pro && (
              <BMenuItem key="upgrade-1" href="/pricing/creative">
                <strong>Upgrade to Twine Pro</strong>
              </BMenuItem>
            )}
            <BMenuItem key="upgrade-2" divider />
            <BMenuItem key="portfolio-item" href={user.links.main_relative}>
              Portfolio
            </BMenuItem>
            <BMenuItem key="portfolio-divider-2" divider />
            <BMenuItem key="managejobs-item" href="/manage/briefs">
              Manage Jobs
            </BMenuItem>
            <BMenuItem key="2" divider />
            <BMenuItem key="managepitches-1" href="/manage/pitches">
              Manage Pitches
            </BMenuItem>
            <BMenuItem key="managepitches-2" divider />
            <BMenuItem key="manageprojects-1" href="/projects/manage">
              Manage Projects
            </BMenuItem>
            <BMenuItem key="manageprojects-2" divider />
            <BMenuItem key="earnings-1" href="/earnings">
              Earnings
            </BMenuItem>
            <BMenuItem key="earnings-2" divider />
            {sharedDropdowns}
          </>
        ),
      },
    },
  };

  const isPost = /post/.test(pathname);

  const desktop = (
    <div>
      <Nav key="navbar-6" navbar className="hidden-xs">
        {isPost
          ? null
          : !user
          ? navSections.left.noAuth
          : user.type === 'seller'
          ? navSections.left.freelancer
          : navSections.left.client}
      </Nav>

      <Nav key="navbar-7" navbar pullRight className="hidden-xs">
        {isPost
          ? null
          : !user
          ? navSections.right.noAuth
          : user.type === 'seller'
          ? navSections.right.freelancer.items
          : navSections.right.client.items}
        <NavItem
          className="hidden-sm"
          title="help centre"
          href="https://help.twine.net"
        >
          <SvgIcon size="18" icon="help" />
        </NavItem>
        {user && (
          <NavDropdown
            key="navdropdown-2"
            noCaret
            title={
              <div style={{ display: '-webkit-inline-box' }}>
                <Thumbnail
                  key="thumbnail"
                  thumbnails={user.avatars}
                  circle
                  style={{ width: '40px', height: '40px' }}
                />
                <span
                  className="caret"
                  style={{ marginTop: '20px', marginLeft: '5px' }}
                />
                <Fade
                  key="fade"
                  in={loadedCount && loadedOldCount && count + oldCount > 0}
                >
                  <Badge
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      backgroundColor: '#40a0f5',
                    }}
                  >
                    {count + oldCount}
                  </Badge>
                </Fade>
              </div>
            }
            style={{ backgroundColor: '#333' }}
            id="image-dropdown"
            className={`hidden-xs ${styles.navMenu}`}
          >
            {user.type === 'seller'
              ? navSections.right.freelancer.dropdown
              : navSections.right.client.dropdown}
          </NavDropdown>
        )}
      </Nav>
    </div>
  );
  return (
    <Navbar staticTop inverse onToggle={onToggleNav} expanded={navExpanded}>
      <Navbar.Header>
        <Navbar.Brand>
          <a href="/">
            <div className={styles.brand}>
              <SvgIcon icon="logo" alt="Twine" />
            </div>
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
        {(!user || user.type === 'buyer' || !user.pro) && (
          <div
            className="visible-xs-block right"
            style={{ marginTop: '20px', marginRight: '10px' }}
          >
            <NavBarCTA
              displayUpsellButton={displayUpsellButton}
              displaySignUpButton={displaySignUpButton}
              displayApplyButton={displayApplyButton}
              displayPostJobButton={displayPostJobButton}
            />
          </div>
        )}
      </Navbar.Header>

      <Navbar.Collapse>
        {authMobile}

        {noAuthMobile}

        {desktop}
      </Navbar.Collapse>
    </Navbar>
  );
};

MainHeader.propTypes = {
  user: PropTypes.object,
  count: PropTypes.number,
  loadedCount: PropTypes.bool,
  loadedOldCount: PropTypes.bool,
  oldCount: PropTypes.number,
  unreadMessages: PropTypes.number,
  isProject: PropTypes.bool,
};

export default memo(MainHeader);
