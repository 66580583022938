import { useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { load, update } from 'redux/modules/auth';

const useAuth = () => {
  const dispatch = useDispatch();

  const { loaded, loading, updating, user, ...rest } = useSelector(
    ({ auth }) => auth,
    shallowEqual
  );

  const loadUser = useCallback(() => dispatch(load()), [dispatch]);

  const updateUser = useCallback(
    (...args) => dispatch(update(...args)),
    [dispatch]
  );

  useEffect(() => {
    if (!user && !loading && !updating && !loaded) {
      loadUser();
    }
  }, [user, loadUser]);

  return {
    loaded,
    loading,
    user,
    updateUser,
    updating,
    ...rest,
  };
};

export { useAuth };
export default useAuth;
