const SENDUSERREPORT = 'twineApp/reports/SENDUSERREPORT';
const SENDUSERREPORT_SUCCESS = 'twineApp/reports/SENDUSERREPORT_SUCCESS';
const SENDUSERREPORT_FAIL = 'twineApp/reports/SENDUSERREPORT_FAIL';
const REPORTMESSAGE = 'twineApp/reports/REPORTMESSAGE';
const REPORTMESSAGE_SUCCESS = 'twineApp/reports/REPORTMESSAGE_SUCCESS';
const REPORTMESSAGE_FAIL = 'twineApp/reports/REPORTMESSAGE_FAIL';

const initialState = {
  sendingUserReport: false,
  sentUserReports: [],
  sendingMessageReport: false,
  sentMessageReport: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SENDUSERREPORT:
      return {
        ...state,
        sendingUserReport: true,
      };

    case SENDUSERREPORT_SUCCESS:
      return {
        ...state,
        sendingUserReport: false,
        sentUserReports: state.sentUserReports.concat(action.user.id),
      };

    case SENDUSERREPORT_FAIL:
      return {
        ...state,
        sendingUserReport: false,
      };

    case REPORTMESSAGE:
      return {
        ...state,
        sendingMessageReport: true,
      };

    case REPORTMESSAGE_SUCCESS:
      return {
        ...state,
        sendingMessageReport: false,
        sentMessageReport: true,
      };

    case REPORTMESSAGE_FAIL:
      return {
        ...state,
        sendingMessageReport: false,
      };

    default:
      return state;
  }
}

export function sendUserReport(user, reason, comments) {
  return {
    types: [SENDUSERREPORT, SENDUSERREPORT_SUCCESS, SENDUSERREPORT_FAIL],
    promise: (client) =>
      client.put(
        '/users/' +
          user.id +
          '/report?reason=' +
          reason +
          '&comment=' +
          comments
      ),
    user,
  };
}

export function reportMessage(messageId) {
  return {
    types: [REPORTMESSAGE, REPORTMESSAGE_SUCCESS, REPORTMESSAGE_FAIL],
    promise: (client) => client.put('/messages/' + messageId + '/report'),
  };
}
