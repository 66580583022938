import { asyncConnect } from 'redux-connect';

import { loadStats } from 'redux/modules/stats';

import loadable from '@loadable/component';

const EnterpriseBase = loadable(() => import('./EnterpriseBase'));

const Enterprise = asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        const promises = [];

        if (!getState().stats.loadedStats) {
          promises.push(dispatch(loadStats()));
        }

        return Promise.all(promises);
      },
    },
  ],
  ({ stats }) => ({
    stats,
  }),
  {
    loadStats,
  }
)(EnterpriseBase);

export default Enterprise;
