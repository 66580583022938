import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from 'react-bootstrap/lib/Tooltip';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import { computeUserRank, getOverallProgress, ranks } from 'helpers/UserRank';

import styles from '../Onboarding.module.scss';

// Constants
const RANKS = [
  {
    id: ranks.INVALID_RANK,
    label: null,
    text: null,
    progressColour: null,
  },
  {
    id: ranks.LOW_RANK,
    label: 'Low',
    text: 'You’re not discoverable and need to fill in more details to win work',
    progressColour: 'danger',
  },
  {
    id: ranks.MEDIUM_RANK,
    label: 'Medium',
    text: 'You’re only a few steps away from a high score to get more work',
    progressColour: 'warning',
  },
  {
    id: ranks.HIGH_RANK,
    label: 'High',
    text: 'Great job! Your can now apply and start earning money',
    progressColour: 'success',
  },
];

const RankProgressBar = ({ user }) => {
  const currentRank = RANKS[computeUserRank(user)];
  const currentProgress = getOverallProgress(user);

  const rankTooltip = <Tooltip id="tooltip">{currentRank.text}</Tooltip>;

  return (
    <div className={styles.rankProgressBarContainer}>
      <p className={styles.rankBannerTitle}>Your profile score</p>

      <OverlayTrigger placement="bottom" overlay={rankTooltip}>
        <ProgressBar
          now={currentProgress.now}
          max={currentProgress.max}
          label={currentRank.label}
          className={styles.rankProgressBar}
          bsStyle={currentRank.progressColour}
        />
      </OverlayTrigger>
    </div>
  );
};

RankProgressBar.propTypes = {
  user: PropTypes.object,
};

export default RankProgressBar;
