import PropTypes from 'prop-types';
import React from 'react';

const SvgIcon = ({
  icon = '',
  size = '',
  color = 'currentColor',
  className = '',
  style = {},
  width = '',
  height = '',
  viewBox = '',
}) => {
  const iconObject = require(`./${icon}.svg`);

  return (
    <svg
      className={className}
      style={{ ...style }}
      viewBox={viewBox || iconObject.attributes.viewBox}
      width={size || width || iconObject.attributes.width}
      height={size || height || iconObject.attributes.height}
      fill={color}
      dangerouslySetInnerHTML={{ __html: iconObject.content }}
    />
  );
};

SvgIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
};

export default SvgIcon;
