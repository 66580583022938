import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import {
  askPermission as askDesktopNotificationPermission,
  subscribeUser as subscribeUserToDesktopNotifications,
} from 'helpers/Notifications';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { addPushSubscription } from 'redux/modules/auth';

import styles from './PromotePushNotifications.module.scss';

const PromotePushNotifications = ({
  banner,
  removeBanner,
  addPushSubscription,
}) => {
  const [desktopNotifications, setDesktopNotifications] = useState(false);

  const onClose = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);

    cookie.save('seen-promote-push-notifications', 1, {
      path: '/',
      expires: date,
    });

    removeBanner(banner.tag);
  };

  const handleToggleDesktopNotifications = () => {
    setDesktopNotifications(!desktopNotifications);

    askDesktopNotificationPermission()
      .then(subscribeUserToDesktopNotifications)
      .then((pushSubscription) => {
        const subObject = JSON.parse(JSON.stringify(pushSubscription));

        addPushSubscription(
          subObject.endpoint,
          subObject.keys.p256dh,
          subObject.keys.auth
        );

        onClose();
      })
      .catch((err) => {
        console.error(err);

        setDesktopNotifications(false);

        onClose();
      });
  };

  return (
    <div>
      <div className={styles.text}>
        {banner.message
          ? banner.message
          : 'Enable push notifications on this device'}
      </div>
      <div className={styles.toggle}>
        <ToggleSwitch
          onClick={handleToggleDesktopNotifications}
          on={desktopNotifications}
        />
      </div>
    </div>
  );
};

PromotePushNotifications.propTypes = {
  banner: PropTypes.object,
  removeBanner: PropTypes.func,
  addPushSubscription: PropTypes.func,
};

export default connect(null, { addPushSubscription })(PromotePushNotifications);
