import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Grid from 'react-bootstrap/lib/Grid';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import SvgIcon from 'components/SvgIcon/SvgIcon';

import styles from './Footer.module.scss';

const Footer = ({ inverse = false }) => (
  <footer
    className={`${inverse ? styles.inverse : ''} ${styles.container} ${
      styles.largeFooter
    }`}
  >
    <nav className={`${styles.container} ${styles.largeFooterNav}`}>
      <Grid>
        <Row>
          <Col xs={12} sm={4}>
            <ul className={styles.footerList}>
              <li className={styles.subTitle}>
                <a href="/hire-a-freelancer">
                  <strong>Hire Experts</strong>
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/howitworks">How it Works</a>
              </li>
              <li>
                <a href="/post-a-job">Post a Project</a>
              </li>
              <li>
                <a href="/hire-a-freelancer/programming">
                  App &amp; Web Developers
                </a>
              </li>
              <li>
                <a href="/hire-a-freelancer/graphic-design">
                  Graphic &amp;&nbsp;Design Experts
                </a>
              </li>
              <li>
                <a href="/hire-a-freelancer/digital-marketing">
                  Marketing Experts
                </a>
              </li>
              <li>
                <a href="/hire-a-freelancer/video-and-animation">
                  Video &amp; Animation Experts
                </a>
              </li>
              <li>
                <a href="/hire-a-freelancer/music">Music &amp; Audio Experts</a>
              </li>
              <li>
                <a href="/hire-a-freelancer">See More Freelancer Skills</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4}>
            <ul className={styles.footerList}>
              <li className={styles.subTitle}>
                <a href="https://www.twine.net/jobs">
                  <strong>Find Work</strong>
                </a>
              </li>
              <li>
                <a href="/freelancers">How to Find Work</a>
              </li>
              <li>
                <a href="/freelance/creatives">Find Creative Jobs</a>
              </li>
              <li>
                <a href="/freelance/developers">Find Developers Jobs</a>
              </li>
              <li>
                <a href="/freelance/marketing">Find Marketing Jobs</a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs">Find Freelance Jobs</a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs">See All Freelance Jobs</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4}>
            <ul className={styles.footerList}>
              <li className={styles.subTitle}>
                <a href="https://www.twine.net/hire-a-freelancer/programming">
                  <strong>Resources</strong>
                </a>
              </li>
              <li>
                <a href="https://help.twine.net/">Help &amp; FAQs</a>
              </li>
              <li>
                <a href="/enterprise">For Business &amp; Enterprise</a>
              </li>
              <li>
                <a href="/ai">For AI and Data Scientists</a>
              </li>
              <li>
                <a href="https://www.twine.net/blog/100-open-audio-and-video-datasets/">
                  Datasets for AI / ML
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/blog/">News and blog</a>
              </li>
              <li>
                <a href="https://www.twine.net/toolkits/freelancer">
                  Freelancer Toolkit
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/toolkits/startup">
                  Startup Toolkit
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt++">
          <Col xs={12} sm={4}>
            <ul className={styles.footerList}>
              <li className={styles.subTitle}>
                <a href="/hire-a-freelancer">
                  <strong>Hire Freelancers</strong>
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/find/graphic-designers/us">
                  Hire Designers in the USA
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/find/developers/us">
                  Hire Developers in the USA
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/find/animators/us">
                  Hire Animators in the USA
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/find/videographers/us">
                  Hire Videographers in USA
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/find/digital-marketers/us">
                  Hire Marketers in the USA
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4}>
            <ul className={styles.footerList}>
              <li className={styles.subTitle}>
                <a href="https://www.twine.net/jobs">
                  <strong>Find Freelance Jobs</strong>
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs/in/united-states">
                  Find Jobs in the USA
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs/in/united-kingdom">
                  Find Jobs in the UK
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs/in/canada">
                  Find Jobs in the Canada
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs/in/australia">
                  Find Jobs in the Australia
                </a>
              </li>
              <li>
                <a href="https://www.twine.net/jobs/in/germany">
                  Find Jobs in the Germany
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4}>
            <ul className={styles.footerList}>
              <li className={styles.subTitle}>
                <a href="https://www.twine.net/">
                  <strong>Twine Network</strong>
                </a>
              </li>
              <li>
                <a href="/about">About Twine</a>
              </li>
              <li>
                <a href="/ai">Twine AI</a>
              </li>
              <li>
                <a href="https://www.twine.net/signin">Log in</a>
              </li>
              <li>
                <a href="https://www.twine.net/signup">Sign up</a>
              </li>
              <li>
                <a href="https://www.twine.net/contact">Contact</a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt++">
          <Col xs={12} className={styles.footerBottom}>
            <ul className={styles.footerList + ' ' + styles.copyright}>
              <li>
                &copy; Clowdy Ltd. t/a Twine.{' '}
                <a href="/terms" title="Terms Of Service">
                  Terms
                </a>{' '}
                -{' '}
                <a href="/privacy" title="Privacy Policy">
                  Privacy
                </a>
              </li>

              <li>
                <ul className={`${styles.socialList}`}>
                  <li>
                    <a
                      rel="nofollow noopener noreferrer"
                      href="https://www.facebook.com/jointwine"
                      target="_blank"
                      title="Twine on Facebook"
                    >
                      <SvgIcon size="20" icon="facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow noopener noreferrer"
                      href="https://twitter.com/jointwine"
                      target="_blank"
                      title="Twine on Twitter"
                    >
                      <SvgIcon size="20" icon="twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow noopener noreferrer"
                      href="https://www.instagram.com/jointwine"
                      target="_blank"
                      title="Twine on Instagram"
                    >
                      <SvgIcon size="20" icon="instagram" />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
    </nav>
  </footer>
);

Footer.propTypes = { inverse: PropTypes.bool };

export default memo(Footer);
