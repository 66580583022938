import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import {
  askPermission as askDesktopNotificationPermission,
  subscribeUser as subscribeUserToDesktopNotifications,
} from 'helpers/Notifications';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { addPushSubscription, update as updateUser } from 'redux/modules/auth';

const BriefInvitePush = ({
  banner,
  removeBanner,
  addPushSubscription,
  updateUser,
  user,
}) => {
  const [desktopNotifications, setDesktopNotifications] = useState(false);

  const onClose = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);

    cookie.save('seen-brief-invite-push', 1, { path: '/', expires: date });

    removeBanner(banner.tag);
  };

  const handleToggleDesktopNotifications = () => {
    setDesktopNotifications(!desktopNotifications);

    askDesktopNotificationPermission()
      .then(() => subscribeUserToDesktopNotifications())
      .then((pushSubscription) => {
        const subObject = JSON.parse(JSON.stringify(pushSubscription));

        addPushSubscription(
          subObject.endpoint,
          subObject.keys.p256dh,
          subObject.keys.auth
        );

        updateUser(user, {
          'preferences[push][brief_invite]': desktopNotifications,
        });

        onClose();
      })
      .catch((err) => {
        console.error(err);

        setDesktopNotifications(false);
      });
  };

  return (
    <div>
      <span
        style={{ display: 'inline-block', verticalAlign: 'middle' }}
        className="mr++"
      >
        {banner.message
          ? banner.message
          : 'Never miss an opportunity. Get a browser notification when a relevant job is posted.'}
      </span>
      <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <ToggleSwitch
          onClick={handleToggleDesktopNotifications}
          on={desktopNotifications}
        />
      </span>
    </div>
  );
};

BriefInvitePush.propTypes = {
  banner: PropTypes.object,
  removeBanner: PropTypes.func,
  addPushSubscription: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps, { addPushSubscription, updateUser })(
  BriefInvitePush
);
