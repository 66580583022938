const LOADPARTNERS = 'twineApp/partners/LOADPARTNERS';
const LOADPARTNERS_SUCCESS = 'twineApp/partners/LOADPARTNERS_SUCCESS';
const LOADPARTNERS_FAIL = 'twineApp/partners/LOADPARTNERS_FAIL';

const initialState = {
  partners: [],
  loadingPartners: false,
  loadedPartners: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADPARTNERS:
      return {
        ...state,
        loadingPartners: true,
        loadedPartners: false,
        partners: [],
      };

    case LOADPARTNERS_SUCCESS:
      return {
        ...state,
        loadingPartners: false,
        loadedPartners: true,
        partners: action.result.partners
          ? action.result.partners.flatMap((partner) =>
              Array(partner.total_entries).fill(partner)
            )
          : [],
      };

    case LOADPARTNERS_FAIL:
      return {
        ...state,
        loadingPartners: false,
        loadedPartners: true,
        errors: action.error.errors,
      };
    default:
      return state;
  }
}

export function loadPartners(type, testing = false) {
  let query = `?type=${type}`;

  if (testing) query += `&testing=1`;

  return {
    types: [LOADPARTNERS, LOADPARTNERS_SUCCESS, LOADPARTNERS_FAIL],
    promise: (client) => client.get(`/partners${query}`),
  };
}
