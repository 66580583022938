const LOADPENDING = 'twineApp/pitches/LOADPENDING';
const LOADPENDING_SUCCESS = 'twineApp/pitches/LOADPENDING_SUCCESS';
const LOADPENDING_FAIL = 'twineApp/pitches/LOADPENDING_FAIL';
const LOADACCEPTED = 'twineApp/pitches/LOADACCEPTED';
const LOADACCEPTED_SUCCESS = 'twineApp/pitches/LOADACCEPTED_SUCCESS';
const LOADACCEPTED_FAIL = 'twineApp/pitches/LOADACCEPTED_FAIL';
const LOADDECLINED = 'twineApp/pitches/LOADDECLINED';
const LOADDECLINED_SUCCESS = 'twineApp/pitches/LOADDECLINED_SUCCESS';
const LOADDECLINED_FAIL = 'twineApp/pitches/LOADDECLINED_FAIL';
const LOADCOMPLETED = 'twineApp/pitches/LOADCOMPLETED';
const LOADCOMPLETED_SUCCESS = 'twineApp/pitches/LOADCOMPLETED_SUCCESS';
const LOADCOMPLETED_FAIL = 'twineApp/pitches/LOADCOMPLETED_FAIL';
const LOADMYPITCHES = 'twineApp/pitches/LOADMYPITCHES';
const LOADMYPITCHES_SUCCESS = 'twineApp/pitches/LOADMYPITCHES_SUCCESS';
const LOADMYPITCHES_FAIL = 'twineApp/pitches/LOADMYPITCHES_FAIL';
const SET_ACCEPTED_PITCH = 'twineApp/pitches/SET_ACCEPTED_PITCH';

const initialState = {
  loadedPending: false,
  loadingPending: false,
  pendingPitches: [],
  totalPending: 0,
  morePending: false,
  nextPendingPage: 1,
  loadedAccepted: false,
  loadingAccepted: false,
  acceptedPitches: [],
  totalAccepted: 0,
  nextAcceptedPage: 1,
  moreAccepted: false,
  loadedDeclined: false,
  loadingDeclined: false,
  declinedPitches: [],
  totalDeclined: 0,
  moreDeclined: false,
  nextDeclinedPage: 1,
  loadedCompleted: false,
  loadingCompleted: false,
  completedPitches: [],
  totalCompleted: 0,
  moreCompleted: false,
  nextCompletedPage: 1,
  loadedBrief: 0,
  loadingMyPitches: false,
  myPitches: {},
  myPitchesLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADPENDING:
      return {
        ...state,
        loadingPending: true,
      };

    case LOADPENDING_SUCCESS:
      return {
        ...state,
        loadingPending: false,
        loadedPending: true,
        pendingPitches:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.messages.length > 0
            ? state.pendingPitches.concat(action.result.messages)
            : [],
        totalPending:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.meta.pagination.total,
        morePending:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        nextPendingPage:
          action.result.constructor === Array && action.result.length === 0
            ? 1
            : action.result.meta.pagination.next_page,
        loadedBrief:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.messages.length > 0
            ? action.result.messages[0].notice_id
            : 0,
      };

    case LOADPENDING_FAIL:
      return {
        ...state,
        loadingPending: false,
      };

    case LOADACCEPTED:
      return {
        ...state,
        loadingAccepted: true,
      };

    case LOADACCEPTED_SUCCESS:
      return {
        ...state,
        loadingAccepted: false,
        loadedAccepted: true,
        acceptedPitches:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.messages.length > 0
            ? state.acceptedPitches.concat(action.result.messages)
            : [],
        totalAccepted:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.meta.pagination.total,
        moreAccepted:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        nextAcceptedPage:
          action.result.constructor === Array && action.result.length === 0
            ? 1
            : action.result.meta.pagination.next_page,
        loadedBrief:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.messages.length > 0
            ? action.result.messages[0].notice_id
            : 0,
      };

    case LOADACCEPTED_FAIL:
      return {
        ...state,
        loadingAccepted: false,
      };

    case LOADDECLINED:
      return {
        ...state,
        loadingDeclined: true,
      };

    case LOADDECLINED_SUCCESS:
      return {
        ...state,
        loadingDeclined: false,
        loadedDeclined: true,
        declinedPitches:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.messages.length > 0
            ? state.declinedPitches.concat(action.result.messages)
            : [],
        totalDeclined:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.meta.pagination.total,
        moreDeclined:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        nextDeclinedPage:
          action.result.constructor === Array && action.result.length === 0
            ? 1
            : action.result.meta.pagination.next_page,
        loadedBrief:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.messages.length > 0
            ? action.result.messages[0].notice_id
            : 0,
      };

    case LOADDECLINED_FAIL:
      return {
        ...state,
        loadingDeclined: false,
      };

    case LOADCOMPLETED:
      return {
        ...state,
        loadingCompleted: true,
      };

    case LOADCOMPLETED_SUCCESS:
      return {
        ...state,
        loadingCompleted: false,
        loadedCompleted: true,
        completedPitches:
          action.result.constructor === Array && action.result.length === 0
            ? []
            : action.result.messages.length > 0
            ? state.completedPitches.concat(action.result.messages)
            : [],
        totalCompleted:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.meta.pagination.total,
        moreCompleted:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        nextCompletedPage:
          action.result.constructor === Array && action.result.length === 0
            ? 1
            : action.result.meta.pagination.next_page,
        loadedBrief:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.messages.length > 0
            ? action.result.messages[0].notice_id
            : 0,
      };

    case LOADCOMPLETED_FAIL:
      return {
        ...state,
        loadingCompleted: false,
      };

    case LOADMYPITCHES:
      return {
        ...state,
        loadingMyPitches: true,
      };

    case LOADMYPITCHES_SUCCESS:
      const newMyPitches = state.myPitches;
      if (
        typeof action.result.threads !== 'undefined' &&
        action.result.threads.length > 0
      ) {
        newMyPitches[action.result.threads[0].message.notice_id] =
          action.result.threads[0].message;
      }

      return {
        ...state,
        myPitches: newMyPitches,
        loadingMyPitches: false,
        myPitchesLoaded: true,
      };

    case LOADMYPITCHES_FAIL:
      return {
        ...state,
        loadingMyPitches: false,
      };

    case SET_ACCEPTED_PITCH:
      return {
        ...state,
        acceptedPitches: [action.pitch],
      };

    default:
      return state;
  }
}

export function isPendingLoaded(globalState, briefId) {
  return (
    globalState.pitches &&
    globalState.pitches.loadedPending &&
    globalState.pitches.loadedBrief === briefId
  );
}

export function isAcceptedLoaded(globalState, briefId) {
  return (
    globalState.pitches &&
    globalState.pitches.loadedAccepted &&
    globalState.pitches.loadedBrief === briefId
  );
}

export function isDeclinedLoaded(globalState, briefId) {
  return (
    globalState.pitches &&
    globalState.pitches.loadedDeclined &&
    globalState.pitches.loadedBrief === briefId
  );
}

export function isCompletedLoaded(globalState, briefId) {
  return (
    globalState.pitches &&
    globalState.pitches.loadedCompleted &&
    globalState.pitches.loadedBrief === briefId
  );
}

export function loadPending(id, page) {
  let params =
    '?include=sender,sender.avatars,sender.covers&accepted=0&completed=0&declined=0&operator=and';

  if (page) {
    params += '&page=' + page;
  }

  return {
    types: [LOADPENDING, LOADPENDING_SUCCESS, LOADPENDING_FAIL],
    promise: (client) =>
      client.get('/notices/collaboration/' + id + '/pitches' + params),
  };
}

export function loadAccepted(id, page) {
  let params =
    '?include=sender,sender.avatars,sender.covers&accepted=1&completed=0&declined=0&operator=and';

  if (page) {
    params += '&page=' + page;
  }

  return {
    types: [LOADACCEPTED, LOADACCEPTED_SUCCESS, LOADACCEPTED_FAIL],
    promise: (client) =>
      client.get('/notices/collaboration/' + id + '/pitches' + params),
  };
}

export function loadDeclined(id, page) {
  let params =
    '?include=sender,sender.avatars,sender.covers&accepted=0&completed=0&declined=1&operator=and';

  if (page) {
    params += '&page=' + page;
  }

  return {
    types: [LOADDECLINED, LOADDECLINED_SUCCESS, LOADDECLINED_FAIL],
    promise: (client) =>
      client.get('/notices/collaboration/' + id + '/pitches' + params),
  };
}

export function loadCompleted(id, page) {
  let params =
    '?include=sender,sender.avatars,sender.covers&accepted=1&completed=1&declined=0&operator=and';

  if (page) {
    params += '&page=' + page;
  }

  return {
    types: [LOADCOMPLETED, LOADCOMPLETED_SUCCESS, LOADCOMPLETED_FAIL],
    promise: (client) =>
      client.get('/notices/collaboration/' + id + '/pitches' + params),
  };
}

export function loadMyPitches(userId, briefId = null, limit = null) {
  return {
    types: [LOADMYPITCHES, LOADMYPITCHES_SUCCESS, LOADMYPITCHES_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches/' +
          (briefId ? briefId : '') +
          '?' +
          (limit ? `limit=${limit}` : '')
      ),
  };
}

export function setAcceptedPitch(pitch) {
  return {
    type: SET_ACCEPTED_PITCH,
    pitch: pitch,
  };
}
