import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { getRedirRoute } from 'helpers/Redirections';

import {
  BillContainer,
  ManageBriefs,
  ManagePitches,
  SimplePayment,
  BriefDetailsClient,
} from 'containers/Universals';

import Find from 'containers/Find/Find';

import { asyncConnect } from 'redux-connect';

import { loadBrief } from 'redux/modules/briefs2';
import {
  isBriefLoaded as isBriefLoadedOld,
  loadBrief as loadBriefOld,
} from 'redux/modules/briefDetails';
import { get } from 'helpers/HelperFunctions';

export const ManageBriefsWrapper = (props) => {
  const match = useRouteMatch({
    path: '/manage/briefs/:briefId/pay/:pitchId',
    strict: true,
  });

  return match ? (
    <SimplePayment customProps="final" {...props} />
  ) : (
    <ManageBriefs {...props} />
  );
};

export const ManagePitchesWrapper = (props) => {
  const match = useRouteMatch({
    path: '/manage/pitches/:pitchId/new-bill',
    strict: true,
  });

  return match ? <BillContainer {...props} /> : <ManagePitches {...props} />;
};

const BriefDetailsWrapperBase = (props) => {
  const match = useRouteMatch({
    path: '/projects/:id',
  });

  return match ? <BriefDetailsClient {...props} /> : <Redirect to="/jobs" />;
};

export const BriefDetailsWrapper = asyncConnect([
  {
    promise: ({
      store: { dispatch, getState },
      match: {
        params: { id },
      },
    }) => {
      const promises = [];

      if (!get(getState(), ['briefs2', id])) {
        promises.push(dispatch(loadBrief(id)));
      }

      // TODO: Remove after BriefDetails (v1) is done with
      if (!isBriefLoadedOld(getState())) {
        promises.push(dispatch(loadBriefOld(id)));
      }

      return Promise.all(promises);
    },
  },
])(BriefDetailsWrapperBase);

export const FindRedirectWrapper = (props) => {
  const { location } = props;
  const path = location.pathname;

  const countriesToRedirect = {
    gb: 'uk',
  };

  for (const [key, value] of Object.entries(countriesToRedirect)) {
    if (path.includes(`/${key}`)) {
      return <Redirect to={path.replace(`/${key}`, `/${value}`)} />;
    }
  }

  return <Find {...props} />;
};
