import { schema } from 'normalizr';
import userSchema from './userSchema';
import briefSchema from './briefSchema';

const messageSchema = new schema.Entity('messages');

messageSchema.define({
  sender: userSchema,
  receiver: userSchema,
  notice: briefSchema,
});

export default messageSchema;
