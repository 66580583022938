const getRedirs = [
  {
    from: '/app-design',
    to: '/app-designers',
  },
  {
    from: '/game-design',
    to: '/game-designers',
  },
  {
    from: '/ui',
    to: '/ui-designers',
  },
  {
    from: '/web-design',
    to: '/web-designers',
  },
  {
    from: '/banner',
    to: '/designers/with/banner',
  },
  {
    from: '/branding',
    to: '/designers/with/branding',
  },
  {
    from: '/copywriting',
    to: '/copywriters',
  },
  {
    from: '/email-design',
    to: '/content-producers/with/email-design',
  },
  {
    from: '/flyer',
    to: '/designers/with/flyer',
  },
  {
    from: '/icon',
    to: '/graphic-designers/with/icon',
  },
  {
    from: '/infographic',
    to: '/infographics-designers',
  },
  {
    from: '/pitch-deck',
    to: '/graphic-designers/with/pitch-deck',
  },
  {
    from: '/presentation-design',
    to: '/presentation-designers',
  },
  {
    from: '/product-design',
    to: '/product-designers',
  },
  {
    from: '/social-media-design',
    to: '/social-media-freelancers',
  },
  {
    from: '/3d-design',
    to: '/3d-designers',
  },
  {
    from: '/acrylic-illustration',
    to: '/artists/with/acrylic-illustration',
  },
  {
    from: '/advert-design',
    to: '/artists/with/advert-design',
  },
  {
    from: '/advertising-illustration',
    to: '/artists/with/advertising-illustration',
  },
  {
    from: '/artwork',
    to: '/artworkers',
  },
  {
    from: '/book-cover',
    to: '/book-cover-designers',
  },
  {
    from: '/book-illustration',
    to: '/designers/with/book-illustration',
  },
  {
    from: '/brochure',
    to: '/designers/with/brochure',
  },
  {
    from: '/business-card',
    to: '/designers/with/business-card',
  },
  {
    from: '/card-deck',
    to: '/designers/with/card-deck',
  },
  {
    from: '/catalogue',
    to: '/designers/with/catalogue',
  },
  {
    from: '/charcoal-illustration',
    to: '/artists/with/charcoal-illustration',
  },
  {
    from: '/childrens-book-illustration',
    to: '/childrens-book-illustrators',
  },
  {
    from: '/collage-illustration',
    to: '/artists/with/collage-illustration',
  },
  {
    from: '/comic-strip',
    to: '/comic-illustrators',
  },
  {
    from: '/editorial-illustration',
    to: '/artists/with/editorial-illustration',
  },
  {
    from: '/fantasy-illustration',
    to: '/artists/with/fantasy-illustration',
  },
  {
    from: '/fashion-design',
    to: '/illustrators/with/fashion-design',
  },
  {
    from: '/fashion-illustration',
    to: '/artists/with/fashion-illustration',
  },
  {
    from: '/freehand-digital-illustration',
    to: '/artists/with/freehand-digital-illustration',
  },
  {
    from: '/gif',
    to: '/animators/with/gif',
  },
  {
    from: '/gouache-illustration',
    to: '/artists/with/gouache-illustration',
  },
  {
    from: '/greetings-card',
    to: '/designers/with/greetings-card',
  },
  {
    from: '/illustration',
    to: '/illustrators',
  },
  {
    from: '/keyline',
    to: '/designers/with/keyline',
  },
  {
    from: '/letterhead',
    to: '/designers/with/letterhead',
  },
  {
    from: '/lettering',
    to: '/designers/with/lettering',
  },
  {
    from: '/logo',
    to: '/logo-designers',
  },
  {
    from: '/magazine',
    to: '/designers/with/magazine',
  },
  {
    from: '/medical-illustration',
    to: '/illustrators/with/medical',
  },
  {
    from: '/narrative-illustration',
    to: '/content-producers/with/narrative-illustration',
  },
  {
    from: '/pen-and-ink-illustration',
    to: '/content-producers/with/pen-and-ink-illustration',
  },
  {
    from: '/pencil-illustration',
    to: '/content-producers/with/narrative-illustration',
  },
  {
    from: '/portrait',
    to: '/illustrators/with/portrait',
  },
  {
    from: '/postcard',
    to: '/designers/with/postcard',
  },
  {
    from: '/poster',
    to: '/designers/with/poster',
  },
  {
    from: '/signage',
    to: '/designers/with/signage',
  },
  {
    from: '/spot-illustration',
    to: '/designers/with/spot-illustration',
  },
  {
    from: '/sticker',
    to: '/designers/with/sticker',
  },
  {
    from: '/stock-image',
    to: '/photographers/with/stock-image',
  },
  {
    from: '/surface-pattern-design',
    to: '/content-producers/with/surface-pattern-design',
  },
  {
    from: '/t-shirt-design',
    to: '/illustrators/with/t-shirt-design',
  },
  {
    from: '/tattoo-design',
    to: '/illustrators/with/tattoo-design',
  },
  {
    from: '/technical-illustration',
    to: '/content-producers/with/technical-illustration',
  },
  {
    from: '/watercolour-illustration',
    to: '/content-producers/with/watercolour-illustration',
  },
  {
    from: '/tattoo-illustration',
    to: '/artists/with/tattoo',
  },
  {
    from: '/acapella',
    to: '/vocalists/with/acapella',
  },
  {
    from: '/backing-vocal',
    to: '/singers/with/backing-vocal',
  },
  {
    from: '/hook',
    to: '/music-producers/with/hook',
  },
  {
    from: '/instrumental',
    to: '/music-composers/with/instrumental',
  },
  {
    from: '/jingle',
    to: '/music-composers/with/jingle',
  },
  {
    from: '/lead-vocal',
    to: '/singers/with/lead-vocal',
  },
  {
    from: '/master',
    to: '/mastering-engineers',
  },
  {
    from: '/mix',
    to: '/mastering-engineers/with/mix',
  },
  {
    from: '/music-video',
    to: '/film-directors/with/music-video',
  },
  {
    from: '/soundtrack',
    to: '/music-composers/with/soundtrack',
  },
  {
    from: '/stem',
    to: '/music-producers/with/stem',
  },
  {
    from: '/track',
    to: '/music-producers/track',
  },
  {
    from: '/vocal',
    to: '/singers/with/vocal',
  },
  {
    from: '/dot-net',
    to: '/developers/with/.net',
  },
  {
    from: '/ableton-live',
    to: '/music-producers/with/ableton-live',
  },
  {
    from: '/acid-pro',
    to: '/music-producers/with/acid-pro',
  },
  {
    from: '/active-directory',
    to: '/developers/active-directory',
  },
  {
    from: '/adk',
    to: '/developers/with/adk',
  },
  {
    from: '/adobe-acrobat-pro',
    to: '/designers/with/adobe-acrobat',
  },
  {
    from: '/adobe-after-effects',
    to: '/designers/with/adobe-after-effects',
  },
  {
    from: '/adobe-audition',
    to: '/designers/with/adobe-audition',
  },
  {
    from: '/adobe-captivate',
    to: '/developers/with/adobe-capitivate',
  },
  {
    from: '/adobe-character-animator',
    to: '/designers/with/adobe-character-animator',
  },
  {
    from: '/adobe-coldfusion',
    to: '/developers/with/adobe-coldfusion',
  },
  {
    from: '/adobe-dimenson',
    to: '/designers/with/adobe-dimension',
  },
  {
    from: '/adobe-dreamweaver',
    to: '/developers/with/adobe-dreamweaver',
  },
  {
    from: '/adobe-freehand',
    to: '/illustrators/with/adobe-freehand',
  },
  {
    from: '/adobe-illustrator',
    to: '/designers/with/adobe-illustrator',
  },
  {
    from: '/adobe-indesign',
    to: '/designers/with/adobe-indesign',
  },
  {
    from: '/adobe-lightroom',
    to: '/image-editors/with/adobe-lightroom',
  },
  {
    from: '/adobe-photoshop',
    to: '/designers/with/adobe-photoshop',
  },
  {
    from: '/adobe-premiere-elements',
    to: '/video-editors/with/adobe-premiere-elements',
  },
  {
    from: '/adobe-premiere-pro',
    to: '/video-editors/with/adobe-premiere-pro',
  },
  {
    from: '/adobe-presenter-video-express',
    to: '/video-editors/with/adobe-video-express',
  },
  {
    from: '/adobe-spark',
    to: '/designers/with/adobe-spark',
  },
  {
    from: '/adobe-xd',
    to: '/designers/with/adobe-xd',
  },
  {
    from: '/affinity-designer',
    to: '/illustrators/with/affinity-designer',
  },
  {
    from: '/affinity-designer-for-ipad',
    to: '/illustrators/with/affinity-designer-for-ipad',
  },
  {
    from: '/affinity-photo',
    to: '/image-editors/with/affinity-photo',
  },
  {
    from: '/affinity-photo-for-ipad',
    to: '/image-editors/with/affinity-photo-for-ipad',
  },
  {
    from: '/affinity-publisher',
    to: '/designers/with/affinity-publisher',
  },
  {
    from: '/akeneo',
    to: '/developers/with/akeneo',
  },
  {
    from: '/amazon-web-services',
    to: '/developers/with/AWS',
  },
  {
    from: '/android',
    to: '/developers/with/android',
  },
  {
    from: '/angularjs',
    to: '/developers/with/angularjs',
  },
  {
    from: '/api',
    to: '/developers/with/API',
  },
  {
    from: '/arcgis',
    to: '/developers/with/arcgis',
  },
  {
    from: '/articulate-storyline',
    to: '/content-producers/with/articulate-storyline',
  },
  {
    from: '/artrage-5',
    to: '/artists/with/artrage-5',
  },
  {
    from: '/aspnet',
    to: '/developers/with/asp.net',
  },
  {
    from: '/astropad',
    to: '/illustrators/with/astropad',
  },
  {
    from: '/audacity',
    to: '/sound-editors/with/audacity',
  },
  {
    from: '/aurelia',
    to: '/developers/with/aurelia',
  },
  {
    from: '/authorizenet',
    to: '/developers/with/authorize.net',
  },
  {
    from: '/autodesk-flame',
    to: '/visual-effects/with/autodesk-flame',
  },
  {
    from: '/autodesk-maya',
    to: '/3d-designers/with/autodesk-maya',
  },
  {
    from: '/autodesk-sketchbook',
    to: '/illustrators/with/autodesk-sketchbook',
  },
  {
    from: '/autodesk-smoke',
    to: '/visual-effects/with/autodesk-smoke',
  },
  {
    from: '/avid-media-composer',
    to: '/video-editors/avid-media-composer',
  },
  {
    from: '/avs-video-editor',
    to: '/content-producers/with/video-editors',
  },
  {
    from: '/axure',
    to: '/developers/with/axure',
  },
  {
    from: '/azure',
    to: '/developers/with/azure',
  },
  {
    from: '/bash',
    to: '/developers/with/bash',
  },
  {
    from: '/bitcoin',
    to: '/developers/with/bitcoin',
  },
  {
    from: '/blender',
    to: '/animators/with/blender',
  },
  {
    from: '/boonex',
    to: '/developers/with/boonex',
  },
  {
    from: '/bootstrap',
    to: '/developers/with/bootstrap',
  },
  {
    from: '/c',
    to: '/developers/with/c',
  },
  {
    from: '/c-plus-plus',
    to: '/developers/with/c++',
  },
  {
    from: '/cakephp',
    to: '/developers/with/cakephp',
  },
  {
    from: '/cakewalk-sonar',
    to: '/music-producers/with/cakewalk-sonar',
  },
  {
    from: '/camtasia',
    to: '/video-editors/with/camtasia',
  },
  {
    from: '/celaction-animatic',
    to: '/animators/with/celaction-animatic',
  },
  {
    from: '/celaction2d',
    to: '/animators/with/celaction2d',
  },
  {
    from: '/chrome-extension',
    to: '/developers/with/chrome-extension',
  },
  {
    from: '/citrix',
    to: '/developers/with/citrix',
  },
  {
    from: '/clip-studio-paint',
    to: '/artists/with/clip-studio/paint',
  },
  {
    from: '/codeigniter',
    to: '/developers/with/codeigniter',
  },
  {
    from: '/corel-painter',
    to: '/artists/with/corel-painter',
  },
  {
    from: '/corel-videostudio',
    to: '/video-editors/with/corel-videostudio',
  },
  {
    from: '/coreldraw',
    to: '/designers/with/corel-draw',
  },
  {
    from: '/crm',
    to: '/digital-marketers/with/crm',
  },
  {
    from: '/css',
    to: '/developers/with/css',
  },
  {
    from: '/cyberlink-powerdirector',
    to: '/video-editors/with/cyberlink-powerdirector',
  },
  {
    from: '/data-science',
    to: '/designers/data-science',
  },
  {
    from: '/data-visualization',
    to: '/designers/data-visualization',
  },
  {
    from: '/davinci-resolve-studio',
    to: '/video-editors/with/davinci-resolve-studio',
  },
  {
    from: '/devexpress',
    to: '/developers/with/devexpress',
  },
  {
    from: '/digital-performer',
    to: '/sound-designers/digital-performers',
  },
  {
    from: '/django',
    to: '/web-developers/with/django',
  },
  {
    from: '/dojo',
    to: '/developers/with/dojo',
  },
  {
    from: '/dotgrid',
    to: '/designers/with/dotgrid',
  },
  {
    from: '/dropbox',
    to: '/content-producers/with/dropbox',
  },
  {
    from: '/drupal',
    to: '/developers/with/drupal',
  },
  {
    from: '/eclipse',
    to: '/developers/with/eclipse',
  },
  {
    from: '/edius',
    to: '/developers/with/edius',
  },
  {
    from: '/email-marketing',
    to: '/designers/with/email-marketing',
  },
  {
    from: '/emberjs',
    to: '/developers/with/ember.js',
  },
  {
    from: '/erlang',
    to: '/developers/with/erlang',
  },
  {
    from: '/etl',
    to: '/developers/with/etl',
  },
  {
    from: '/facebook-ads',
    to: '/ppc-managers/with/facebook-ads',
  },
  {
    from: '/facebook-api',
    to: '/developers/with/facebook-api',
  },
  {
    from: '/final-cut-pro-x',
    to: '/video-editors/with/final-cut-pro-x',
  },
  {
    from: '/explainer-videos',
    to: '/animators',
  },
  {
    from: '/finale',
    to: '/music-producers/with/finale',
  },
  {
    from: '/fl-studio',
    to: '/music-producers/with/fl-studio',
  },
  {
    from: '/forscene',
    to: '/video-editors/with/forscene',
  },
  {
    from: '/garageband',
    to: '/music-producers/with/garageband',
  },
  {
    from: '/gimp',
    to: '/designers/with/gimp',
  },
  {
    from: '/github',
    to: '/developers/with/github',
  },
  {
    from: '/go',
    to: '/developers/with/go',
  },
  {
    from: '/google-ads',
    to: '/ppc-managers/with/google-ads',
  },
  {
    from: '/google-api',
    to: '/developers/with/google-api',
  },
  {
    from: '/google-checkout',
    to: '/developers/with/google-checkout',
  },
  {
    from: '/google-drawings',
    to: '/illustrators/with/google-drawings',
  },
  {
    from: '/google-drive',
    to: '/content-producers/with/google-drive',
  },
  {
    from: '/google-latitude',
    to: '/developers/with/google-latitude',
  },
  {
    from: '/google-maps',
    to: '/developers/with/google-maps',
  },
  {
    from: '/grails',
    to: '/developers/with/grails',
  },
  {
    from: '/guitar-pro',
    to: '/music-editors/with/guitar-pro',
  },
  {
    from: '/gulp-experts',
    to: '/developers/with/gulp-experts',
  },
  {
    from: '/hadoop',
    to: '/developers/with/hadoop',
  },
  {
    from: '/harris-broadcast-velocity',
    to: '/developers/with/harris-broadcast-velocity',
  },
  {
    from: '/html5',
    to: '/developers/with/html5',
  },
  {
    from: '/hubspot',
    to: '/digital-marketers/with/hubspot',
  },
  {
    from: '/hybrid-app',
    to: '/developers/with/hybrid-app',
  },
  {
    from: '/hybris',
    to: '/developers/with/hybris',
  },
  {
    from: '/ibeacon',
    to: '/developers/with/ibeacon',
  },
  {
    from: '/imovie',
    to: '/video-editors/with/imovie',
  },
  {
    from: '/inkscape',
    to: '/illustrators/with/inkscape',
  },
  {
    from: '/invision',
    to: '/designers/with/invision',
  },
  {
    from: '/ionic',
    to: '/developers/with/ionic',
  },
  {
    from: '/ios',
    to: '/developers/with/ios',
  },
  {
    from: '/java',
    to: '/developers/with/java',
  },
  {
    from: '/javascript',
    to: '/web-developers/with/javascript',
  },
  {
    from: '/jimdo',
    to: '/developers/with/jimdo',
  },
  {
    from: '/jira',
    to: '/developers/with/jira',
  },
  {
    from: '/jms',
    to: '/developers/with/jms',
  },
  {
    from: '/jquery',
    to: '/developers/with/jquery',
  },
  {
    from: '/kaltura',
    to: '/developers/with/kaltura',
  },
  {
    from: '/kohana',
    to: '/developers/with/kohana',
  },
  {
    from: '/kotlin',
    to: '/developers/with/kotlin',
  },
  {
    from: '/krita',
    to: '/developers/with/krita',
  },
  {
    from: '/laravel',
    to: '/developers/with/laravel',
  },
  {
    from: '/layar',
    to: '/developers/with/layar',
  },
  {
    from: '/lead-nurturing',
    to: '/digital-marketers/with/lead-nurturing',
  },
  {
    from: '/lightwave',
    to: '/developers/with/lightwave',
  },
  {
    from: '/lms',
    to: '/developers/with/lms',
  },
  {
    from: '/logic-pro',
    to: '/music-producers/with/logic-pro',
  },
  {
    from: '/magento',
    to: '/developers/with/magento',
  },
  {
    from: '/magix-movie-edit-pro',
    to: '/video-editors/with/magix-movie-edit-pro',
  },
  {
    from: '/magix-vegas-pro',
    to: '/video-editors/with/magix-vegas-pro',
  },
  {
    from: '/mailchimp',
    to: '/digital-marketers/with/mailchimp',
  },
  {
    from: '/maxon-cinema-4d',
    to: '/designers/with/cinema-4d',
  },
  {
    from: '/mean-stack',
    to: '/developers/with/mean-stack',
  },
  {
    from: '/media-100-suite',
    to: '/video-editors/with/media-100-suite',
  },
  {
    from: '/meteor',
    to: '/developers/with/meteor',
  },
  {
    from: '/microsoft-certified-professional',
    to: '/developers/with/microsoft-certified-professional',
  },
  {
    from: '/microsoft-certified-solutions-associate',
    to: '/developers/with/microsoft-certified-solutions-associate',
  },
  {
    from: '/microsoft-excel',
    to: '/designers/with/microsoft-word',
  },
  {
    from: '/microsoft-office',
    to: '/content-producers/with/microsoft-office',
  },
  {
    from: '/microsoft-powerpoint',
    to: '/designers/with/microsoft-powerpoint',
  },
  {
    from: '/microsoft-word',
    to: '/designers/with/microsoft-word',
  },
  {
    from: '/mongodb',
    to: '/developers/with/mongodb',
  },
  {
    from: '/moodle',
    to: '/developers/with/moodle',
  },
  {
    from: '/mpeg-video-wizard-dvd',
    to: '/video-editors/with/mpeg-video-wizard-dvd',
  },
  {
    from: '/muvee-reveal',
    to: '/video-editors/with/muvee-reveal',
  },
  {
    from: '/mysql',
    to: '/developers/with/mysql',
  },
  {
    from: '/nexus',
    to: '/developers/nexus',
  },
  {
    from: '/nodejs',
    to: '/developers/with/node.js',
  },
  {
    from: '/ocaml',
    to: '/developers/with/ocaml',
  },
  {
    from: '/offshore',
    to: '/developers/with/offshore',
  },
  {
    from: '/opencart',
    to: '/developers/with/opencart',
  },
  {
    from: '/openstack',
    to: '/developers/with/openstack',
  },
  {
    from: '/oscommerce',
    to: '/developers/with/oscommerce',
  },
  {
    from: '/paypal',
    to: '/developers/with/paypal',
  },
  {
    from: '/perl',
    to: '/developers/with/perl',
  },
  {
    from: '/phalcon',
    to: '/developers/with/phalcon',
  },
  {
    from: '/phoenix',
    to: '/developers/with/phoenix',
  },
  {
    from: '/php',
    to: '/web-developers/with/php',
  },
  {
    from: '/phpbb',
    to: '/developers/with/phpbb',
  },
  {
    from: '/phpfox',
    to: '/developers/with/phpfox',
  },
  {
    from: '/pinnacle-studio',
    to: '/music-producers/with/pinnacle-studio',
  },
  {
    from: '/pitch-writing',
    to: '/content-producers/with/pitch-writing',
  },
  {
    from: '/pl-sql',
    to: '/developers/with/pl-sql',
  },
  {
    from: '/play',
    to: '/developers/with/play',
  },
  {
    from: '/podium',
    to: '/developers/with/podium',
  },
  {
    from: '/pos',
    to: '/developers/with/pos',
  },
  {
    from: '/postgresql',
    to: '/developers/with/postgresql',
  },
  {
    from: '/presonus-studio-one',
    to: '/music-producers/with/presonus-studio-one',
  },
  {
    from: '/pro-tools',
    to: '/music-producers/pro-tools',
  },
  {
    from: '/procreate',
    to: '/designers/procreate',
  },
  {
    from: '/propellerhead-reason',
    to: '/music-producers/with/propellerhead-reason',
  },
  {
    from: '/prototype',
    to: '/developers/prototype',
  },
  {
    from: '/python',
    to: '/developers/with/python',
  },
  {
    from: '/qt',
    to: '/developers/with/qt',
  },
  {
    from: '/raspberry-pi',
    to: '/developers/with/raspberry-pi',
  },
  {
    from: '/reactjs',
    to: '/developers/with/react-js',
  },
  {
    from: '/react-native',
    to: '/developers/with/react-native',
  },
  {
    from: '/reaktor',
    to: '/music-producers/with/reaktor',
  },
  {
    from: '/reaper',
    to: '/music-producers/with/reaper',
  },
  {
    from: '/renoise',
    to: '/music-producers/with/renoise',
  },
  {
    from: '/robotics-engineers',
    to: '/developers/with/robotics-engineers',
  },
  {
    from: '/roxio-creator',
    to: '/video-editors/with/roxio-creator',
  },
  {
    from: '/ruby',
    to: '/developers/with/ruby',
  },
  {
    from: '/ruby-on-rails',
    to: '/developers/with/ruby-on-rails',
  },
  {
    from: '/saas',
    to: '/developers/with/saas',
  },
  {
    from: '/sailsjs',
    to: '/developers/with/react-js',
  },
  {
    from: '/salesforce',
    to: '/developers/with/salesforce',
  },
  {
    from: '/scala',
    to: '/developers/with/scala',
  },
  {
    from: '/sgo-mistika',
    to: '/visual-effects/with/sgo-mistika',
  },
  {
    from: '/shopify',
    to: '/developers/with/shopify',
  },
  {
    from: '/sibelius',
    to: '/music-composers/with/sibelius',
  },
  {
    from: '/sitecore',
    to: '/developers/with/sitecore',
  },
  {
    from: '/sketch',
    to: '/designers/with/sketch',
  },
  {
    from: '/smarty',
    to: '/developers/with/smarty',
  },
  {
    from: '/socketio',
    to: '/developers/with/socket.io',
  },
  {
    from: '/sony-acid-pro',
    to: '/sound-designers/with/sony-acid-pro',
  },
  {
    from: '/sony-sound-forge',
    to: '/sound-designers/with/sony-sound-forge',
  },
  {
    from: '/sony-vegas',
    to: '/sound-designers/with/sony-vegas',
  },
  {
    from: '/spark',
    to: '/designers/with/spark',
  },
  {
    from: '/spring',
    to: '/developers/with/spring',
  },
  {
    from: '/squarespace',
    to: '/developers/with/squarespace',
  },
  {
    from: '/steinberg-cubase',
    to: '/music-producers/with/steinberg-cubase',
  },
  {
    from: '/steinberg-nuendo',
    to: '/music-producers/with/steinberg-nuendo',
  },
  {
    from: '/steinberg-wavelab',
    to: '/music-producers/with/steinberg-wavelab',
  },
  {
    from: '/stripe',
    to: '/developers/with/stripe',
  },
  {
    from: '/substance-painter',
    to: '/artists/with/substance-painter',
  },
  {
    from: '/symfony',
    to: '/developers/with/symfony',
  },
  {
    from: '/telerik',
    to: '/developers/with/telerik',
  },
  {
    from: '/titanium',
    to: '/developers/with/titanium',
  },
  {
    from: '/toon-boom-harmony',
    to: '/animators/with/toon-boom-harmony',
  },
  {
    from: '/toon-boom-producer',
    to: '/animators/with/toon-boom-producer',
  },
  {
    from: '/toon-boom-storyboard-pro',
    to: '/animators/with/toon-boom-storyboard-pro',
  },
  {
    from: '/traktor',
    to: '/djs/with/traktor',
  },
  {
    from: '/twilio',
    to: '/developers/with/twilio',
  },
  {
    from: '/twitter-ads',
    to: '/ppc-managers/twitter-ads',
  },
  {
    from: '/typo3',
    to: '/developers/with/typo3',
  },
  {
    from: '/unity',
    to: '/developers/with/unity',
  },
  {
    from: '/unity-3d',
    to: '/developers/with/unity-3d',
  },
  {
    from: '/unreal-engine-3',
    to: '/developers/with/unreal-engine-3',
  },
  {
    from: '/unreal-engine-4',
    to: '/developers/with/unreal-engine-4',
  },
  {
    from: '/video-toaster',
    to: '/video-editors/with/video-toaster',
  },
  {
    from: '/videopad-masters-edition',
    to: '/developers/with/videopad-masters-edition',
  },
  {
    from: '/vmware',
    to: '/developers/with/vmware',
  },
  {
    from: '/vocaloid',
    to: '/music-producers/with/vocaloid',
  },
  {
    from: '/web2py',
    to: '/developers/with/web2py',
  },
  {
    from: '/webkit',
    to: '/developers/with/webkit',
  },
  {
    from: '/weebly',
    to: '/developers/with/weebly',
  },
  {
    from: '/wix',
    to: '/developers/with/wix',
  },
  {
    from: '/wordpress',
    to: '/web-developers/with/wordpress',
  },
  {
    from: '/xamarin',
    to: '/developers/with/xamarin',
  },
  {
    from: '/xedio',
    to: '/content-producers/with/xedio',
  },
  {
    from: '/xml',
    to: '/developers/with/xml',
  },
  {
    from: '/yelp-api',
    to: '/developers/with/yelp-api',
  },
  {
    from: '/youtube-api',
    to: '/developers/with/youtube-api',
  },
  {
    from: '/zbrush',
    to: '/designers/zbrush',
  },
  {
    from: '/zend',
    to: '/developers/with/zend',
  },
  {
    from: '/2d-animation',
    to: '/designers/with/2d-animation',
  },
  {
    from: '/3d-animation',
    to: '/designers/with/3d-animation',
  },
  {
    from: '/3d-model',
    to: '/designers/with/3d-model',
  },
  {
    from: '/actor-showreel',
    to: '/photographers/with/actor-showreel',
  },
  {
    from: '/adobe-animate',
    to: '/animators/with/anime',
  },
  {
    from: '/animation',
    to: '/designers/with/animation',
  },
  {
    from: '/anime',
    to: '/artists/with/anime',
  },
  {
    from: '/cartoon',
    to: '/illustrators/with/cartoon',
  },
  {
    from: '/character-design',
    to: '/character-designers',
  },
  {
    from: '/claymation',
    to: '/animators/with/claymation',
  },
  {
    from: '/corporate-video',
    to: '/film-producers/with/corporate-video',
  },
  {
    from: '/costume-design',
    to: '/illustrators/with/costume-design',
  },
  {
    from: '/feature',
    to: '/film-directors/with/feature',
  },
  {
    from: '/mikumikudance',
    to: '/3d-animators/with/mikumikudance',
  },
  {
    from: '/mixamo',
    to: '/3d-animators/with/mixamo',
  },
  {
    from: '/motion-graphic',
    to: '/motion-graphic-designers',
  },
  {
    from: '/pencil2d',
    to: '/animators/with/pencil2d',
  },
  {
    from: '/photo',
    to: '/image-editors/with/photo',
  },
  {
    from: '/reallusion-cartoon',
    to: '/2d-animators/with/reallusion-cartoon',
  },
  {
    from: '/reallusion-character-creator',
    to: '/illustrators/with/reallusion-cartoon-character-creator',
  },
  {
    from: '/reallusion-iclone',
    to: '/2d-animators/with/reallusion-iclone',
  },
  {
    from: '/rotoscope',
    to: '/animators/with/rotoscope',
  },
  {
    from: '/script',
    to: '/writers/with/script',
  },
  {
    from: '/set-design',
    to: '/set-designers',
  },
  {
    from: '/short-film',
    to: '/content-producers/with/short-film',
  },
  {
    from: '/stop-action',
    to: '/photographers/with/stop-action',
  },
  {
    from: '/stop-motion',
    to: '/animators/with/stop-motion',
  },
  {
    from: '/stop-motion-animation',
    to: '/animators/with/stop-motion',
  },
  {
    from: '/theme-tune',
    to: '/music-composers/with/theme-tune',
  },
  {
    from: '/video-advert',
    to: '/film-directors/with/video-advert',
  },
  {
    from: '/vocal-feature',
    to: '/artists/with/vocal-features',
  },
  {
    from: '/voiceover',
    to: '/voiceover-artists',
  },
];

export { getRedirs };
