import { schema } from 'normalizr';
import projectSchema from './projectSchema';
import roleSchema from './roleSchema';
import userSchema from './userSchema';

const creditSchema = new schema.Entity('credits');

creditSchema.define({
  user: userSchema,
  project: projectSchema,
  roles: [roleSchema],
});

export default creditSchema;
