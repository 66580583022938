const ELEMENT = 'twineApp/highlightElement/ELEMENT';
const RESET_ELEMENT = 'twineApp/highlightElement/RESET_ELEMENT';

export default function reducer(state = null, action = {}) {
  if (action.type === ELEMENT) {
    return action.element;
  }

  if (action.type === RESET_ELEMENT) {
    return '';
  }

  return state;
}

export function setHighlightElement(element) {
  return {
    type: ELEMENT,
    element,
  };
}

export function resetHighlightElement() {
  return {
    type: RESET_ELEMENT,
  };
}
