const OWN_SKILLS = 'twineApp/skills/OWN_SKILLS';
const OWN_SKILLS_SUCCESS = 'twineApp/skills/OWN_SKILLS_SUCCESS';
const OWN_SKILLS_FAIL = 'twineApp/skills/OWN_SKILLS_FAIL';
const ADD_SKILL = 'twineApp/skills/ADD_SKILL';
const ADD_SKILL_SUCCESS = 'twineApp/skills/ADD_SKILL_SUCCESS';
const ADD_SKILL_FAIL = 'twineApp/skills/ADD_SKILL_FAIL';

const initialState = {
  loadingOwnSkills: false,
  loadedOwnSkills: false,
  ownSkills: [],
  addingSkill: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OWN_SKILLS:
      return {
        ...state,
        loadingOwnSkills: true,
      };

    case OWN_SKILLS_SUCCESS:
      return {
        ...state,
        loadingOwnSkills: false,
        loadedOwnSkills: true,
        ownSkills:
          typeof action.result === 'undefined' ||
          action.result.constructor === Array
            ? state.ownSkills
            : state.ownSkills.concat(action.result.skills),
      };

    case OWN_SKILLS_FAIL:
      return {
        ...state,
        loadingOwnSkills: false,
      };

    case ADD_SKILL:
      return {
        ...state,
        addingSkill: true,
      };

    case ADD_SKILL_SUCCESS:
      return {
        ...state,
        addingSkill: false,
      };

    case ADD_SKILL_FAIL:
      return {
        ...state,
        addingSkill: false,
      };

    default:
      return state;
  }
}

export function loadOwnSkills(user) {
  return {
    types: [OWN_SKILLS, OWN_SKILLS_SUCCESS, OWN_SKILLS_FAIL],
    promise: (client) => client.get('/users/' + user.id + '/skills'),
  };
}

export function addSkill(userId, name) {
  return {
    types: [ADD_SKILL, ADD_SKILL_SUCCESS, ADD_SKILL_FAIL],
    promise: (client) =>
      client.post('/users/' + userId + '/skills', { data: { name: name } }),
  };
}
