import { fromJS, List } from 'immutable';
import creditSchema from '../schemas/creditSchema';
import projectSchema from '../schemas/projectSchema';
import projectCommentsSchema from '../schemas/projectCommentsSchema';
import { jsonToFormData } from '../../helpers/HelperFunctions';

const DOWNLOAD = 'twineApp/project/DOWNLOAD';
const DOWNLOAD_SUCCESS = 'twineApp/project/DOWNLOAD_SUCCESS';
const DOWNLOAD_FAIL = 'twineApp/project/DOWNLOAD_FAIL';
const LOADPROJECT = 'twineApp/project/LOADPROJECT';
const LOADPROJECT_SUCCESS = 'twineApp/project/LOADPROJECT_SUCCESS';
const LOADPROJECT_FAIL = 'twineApp/project/LOADPROJECT_FAIL';
const LOADCOLLECTION = 'twineApp/project/LOADCOLLECTION';
const LOADCOLLECTION_SUCCESS = 'twineApp/project/LOADCOLLECTION_SUCCESS';
const LOADCOLLECTION_FAIL = 'twineApp/project/LOADCOLLECTION_FAIL';
const LOADRELATEDPROJECTS = 'twineApp/project/LOADRELATEDPROJECTS';
const LOADRELATEDPROJECTS_SUCCESS =
  'twineApp/project/LOADRELATEDPROJECTS_SUCCESS';
const LOADRELATEDPROJECTS_FAIL = 'twineApp/project/LOADRELATEDPROJECTS_FAIL';
const LOADPROJECTCREDITS = 'twineApp/project/LOADPROJECTCREDITS';
const LOADPROJECTCREDITS_SUCCESS =
  'twineApp/project/LOADPROJECTCREDITS_SUCCESS';
const LOADPROJECTCREDITS_FAIL = 'twineApp/project/LOADPROJECTCREDITS_FAIL';
const LIKECOMMENT = 'twineApp/project/LIKECOMMENT';
const LIKECOMMENT_SUCCESS = 'twineApp/project/LIKECOMMENT_SUCCESS';
const LIKECOMMENT_FAIL = 'twineApp/project/LIKECOMMENT_FAIL';
const UNLIKECOMMENT = 'twineApp/project/UNLIKECOMMENT';
const UNLIKECOMMENT_SUCCESS = 'twineApp/project/UNLIKECOMMENT_SUCCESS';
const UNLIKECOMMENT_FAIL = 'twineApp/project/UNLIKECOMMENT_FAIL';
const LOADPROJECTCOMMENTS = 'twineApp/project/LOADPROJECTCOMMENTS';
const LOADPROJECTCOMMENTS_SUCCESS =
  'twineApp/project/LOADPROJECTCOMMENTS_SUCCESS';
const LOADPROJECTCOMMENTS_FAIL = 'twineApp/project/LOADPROJECTCOMMENTS_FAIL';
const LOADPROJECTCOMMENTREPLIES = 'twineApp/project/LOADPROJECTCOMMENTREPLIES';
const LOADPROJECTCOMMENTREPLIES_SUCCESS =
  'twineApp/project/LOADPROJECTCOMMENTREPLIES_SUCCESS';
const LOADPROJECTCOMMENTREPLIES_FAIL =
  'twineApp/project/LOADPROJECTCOMMENTREPLIES_FAIL';
const POSTCOMMENT = 'twineApp/project/POSTCOMMENT';
const POSTCOMMENT_SUCCESS = 'twineApp/project/POSTCOMMENT_SUCCESS';
const POSTCOMMENT_FAIL = 'twineApp/project/POSTCOMMENT_FAIL';
const REPORTCOMMENT = 'twineApp/project/REPORTCOMMENT';
const REPORTCOMMENT_SUCCESS = 'twineApp/project/REPORTCOMMENT_SUCCESS';
const REPORTCOMMENT_FAIL = 'twineApp/project/REPORTCOMMENT_FAIL';
const HASLIKED = 'twineApp/project/HASLIKED';
const HASLIKED_SUCCESS = 'twineApp/project/HASLIKED_SUCCESS';
const HASLIKED_FAIL = 'twineApp/project/HASLIKED_FAIL';
const LIKE = 'twineApp/project/LIKE';
const LIKE_SUCCESS = 'twineApp/project/LIKE_SUCCESS';
const LIKE_FAIL = 'twineApp/project/LIKE_FAIL';
const UNLIKE = 'twineApp/project/UNLIKE';
const UNLIKE_SUCCESS = 'twineApp/project/UNLIKE_SUCCESS';
const UNLIKE_FAIL = 'twineApp/project/UNLIKE_FAIL';
const REPORTPROJECT = 'twineApp/project/REPORTPROJECT';
const REPORTPROJECT_SUCCESS = 'twineApp/project/REPORTPROJECT_SUCCESS';
const REPORTPROJECT_FAIL = 'twineApp/project/REPORTPROJECT_FAIL';
const ADDCREDIT = 'twineApp/project/ADDCREDIT';
const ADDCREDIT_SUCCESS = 'twineApp/project/ADDCREDIT_SUCCESS';
const ADDCREDIT_FAIL = 'twineApp/project/ADDCREDIT_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADPROJECT:
      return imState.setIn([action.projectHash, 'loadingProject'], true).toJS();

    case LOADPROJECT_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            id: action.result,
            loadedProject: true,
            loadingProject: false,
            commentsTotal:
              action.entities.projects[action.result].stats.comments,
            likesTotal: action.entities.projects[action.result].stats.likes,
          },
        })
        .toJS();

    case LOADPROJECT_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedProject: true,
            loadingProject: false,
          },
        })
        .toJS();

    case LOADCOLLECTION:
      return imState
        .setIn([action.projectHash, 'loadingCollection'], true)
        .toJS();

    case LOADCOLLECTION_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedCollection: true,
            loadingCollection: false,
            moreCollection:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.current_page <
                  action.result.meta.pagination.total_pages
                : false,
            collectionTotal:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.total
                : imState.getIn([action.projectHash, 'collectionTotal'], 0),
            collectionPagesLoaded:
              imState.getIn([action.projectHash, 'collectionPagesLoaded'], 0) +
              1,
            collection: imState
              .getIn([action.projectHash, 'collection'], List())
              .concat(
                typeof action.result.projects !== 'undefined'
                  ? action.result.projects
                  : []
              ),
          },
        })
        .toJS();

    case LOADCOLLECTION_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedCollection: true,
            loadingCollection: false,
          },
        })
        .toJS();

    case LOADRELATEDPROJECTS:
      return imState
        .setIn([action.projectHash, 'loadingRelatedProjects'], true)
        .toJS();

    case LOADRELATEDPROJECTS_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            related: imState
              .getIn([action.projectHash, 'related'], List())
              .concat(
                typeof action.result.relateds !== 'undefined'
                  ? action.result.relateds.map((related) => related.id)
                  : []
              ),
            loadedRelatedProjects: true,
            loadingRelatedProjects: false,
          },
        })
        .toJS();

    case LOADRELATEDPROJECTS_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedRelatedProjects: true,
            loadingRelatedProjects: false,
          },
        })
        .toJS();

    case LOADPROJECTCREDITS:
      return imState.setIn([action.projectHash, 'loadingCredits'], true).toJS();

    case LOADPROJECTCREDITS_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedCredits: true,
            loadingCredits: false,
            moreCredits:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.current_page <
                  action.result.meta.pagination.total_pages
                : false,
            creditsTotal:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.total
                : imState.getIn([action.projectHash, 'creditsTotal'], 0),
            creditsPagesLoaded:
              imState.getIn([action.projectHash, 'creditsPagesLoaded'], 0) + 1,
            credits: imState
              .getIn([action.projectHash, 'credits'], List())
              .concat(
                typeof action.result.credits !== 'undefined'
                  ? action.result.credits
                  : []
              ),
          },
        })
        .toJS();

    case LOADPROJECTCREDITS_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedCredits: true,
            loadingCredits: false,
          },
        })
        .toJS();

    case LIKECOMMENT:
      if (action.comment.parent_id) {
        return imState
          .setIn(
            [
              action.projectHash,
              'commentReplies',
              action.comment.parent_id.toString(),
              'commentStats',
              action.comment.id.toString(),
              'hasLiked',
            ],
            true
          )
          .toJS();
      }

      return imState
        .setIn(
          [
            action.projectHash,
            'commentStats',
            action.comment.id.toString(),
            'hasLiked',
          ],
          true
        )
        .toJS();

    case LIKECOMMENT_SUCCESS:
      return imState.toJS();

    case LIKECOMMENT_FAIL:
      if (action.comment.parent_id) {
        return imState
          .setIn(
            [
              action.projectHash,
              'commentReplies',
              action.comment.parent_id.toString(),
              'commentStats',
              action.comment.id.toString(),
              'hasLiked',
            ],
            false
          )
          .toJS();
      }

      return imState
        .setIn(
          [
            action.projectHash,
            'commentStats',
            action.comment.id.toString(),
            'hasLiked',
          ],
          false
        )
        .toJS();

    case UNLIKECOMMENT:
      if (action.comment.parent_id) {
        return imState
          .setIn(
            [
              action.projectHash,
              'commentReplies',
              action.comment.parent_id.toString(),
              'commentStats',
              action.comment.id.toString(),
              'hasLiked',
            ],
            false
          )
          .toJS();
      }

      return imState
        .setIn(
          [
            action.projectHash,
            'commentStats',
            action.comment.id.toString(),
            'hasLiked',
          ],
          false
        )
        .toJS();

    case UNLIKECOMMENT_SUCCESS:
      return imState.toJS();

    case UNLIKECOMMENT_FAIL:
      if (action.comment.parent_id) {
        return imState
          .setIn(
            [
              action.projectHash,
              'commentReplies',
              action.comment.parent_id.toString(),
              'commentStats',
              action.comment.id.toString(),
              'hasLiked',
            ],
            true
          )
          .toJS();
      }

      return imState
        .setIn(
          [
            action.projectHash,
            'commentStats',
            action.comment.id.toString(),
            'hasLiked',
          ],
          true
        )
        .toJS();

    case LOADPROJECTCOMMENTS:
      return imState
        .setIn([action.projectHash, 'loadingComments'], true)
        .toJS();

    case LOADPROJECTCOMMENTS_SUCCESS:
      const commentReplies = {};

      if (typeof action.result.comments !== 'undefined') {
        action.result.comments.forEach(function (comment) {
          const commentEntity = action.entities.projectComments[comment];

          const commentReplyStats = {};

          if (typeof commentEntity.replies !== 'undefined') {
            commentEntity.replies.forEach(function (reply) {
              const commentReplyEntity = action.entities.projectComments[reply];

              commentReplyStats[commentReplyEntity.id] = {
                hasLiked: commentReplyEntity.hasLiked,
                hasReported: commentReplyEntity.hasReported,
              };
            });
          }

          commentReplies[commentEntity.id] = {
            loadedComments: true,
            loadingComments: false,
            moreComments:
              commentEntity.num_of_replies > commentEntity.replies.length,
            commentsTotal: commentEntity.num_of_replies,
            commentsPagesLoaded: 1,
            comments: commentEntity.replies,
            commentStats: commentReplyStats,
          };
        });
      }

      const commentStats = {};

      if (typeof action.result.comments !== 'undefined') {
        action.result.comments.forEach(function (comment) {
          const commentEntity = action.entities.projectComments[comment];

          commentStats[commentEntity.id] = {
            hasLiked: commentEntity.hasLiked,
            hasReported: commentEntity.hasReported,
          };
        });
      }

      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedComments: true,
            loadingComments: false,
            moreComments:
              typeof action.result.meta !== 'undefined'
                ? action.result.meta.pagination.current_page <
                  action.result.meta.pagination.total_pages
                : false,
            commentsPagesLoaded:
              imState.getIn([action.projectHash, 'commentsPagesLoaded'], 0) + 1,
            comments: imState
              .getIn([action.projectHash, 'comments'], List())
              .concat(
                typeof action.result.comments !== 'undefined'
                  ? action.result.comments
                  : []
              ),
            commentReplies: fromJS(
              imState.getIn([action.projectHash, 'commentReplies'], {})
            )
              .mergeDeep(fromJS(commentReplies))
              .toJS(),
            commentStats: fromJS(
              imState.getIn([action.projectHash, 'commentStats'], {})
            )
              .mergeDeep(fromJS(commentStats))
              .toJS(),
          },
        })
        .toJS();

    case LOADPROJECTCOMMENTS_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            loadedComments: true,
            loadingComments: false,
          },
        })
        .toJS();

    case LOADPROJECTCOMMENTREPLIES:
      return imState
        .setIn(
          [
            action.projectHash,
            'commentReplies',
            action.parentId.toString(),
            'loadingComments',
          ],
          true
        )
        .toJS();

    case LOADPROJECTCOMMENTREPLIES_SUCCESS:
      const commentLoadReplyStats = {};

      if (typeof action.result.comments !== 'undefined') {
        action.result.comments.forEach(function (comment) {
          const commentEntity = action.entities.projectComments[comment];

          commentLoadReplyStats[commentEntity.id] = {
            hasLiked: commentEntity.hasLiked,
            hasReported: commentEntity.hasReported,
          };
        });
      }

      return imState
        .mergeDeep({
          [action.projectHash]: {
            commentReplies: {
              [action.parentId]: {
                loadedComments: true,
                loadingComments: false,
                moreComments:
                  typeof action.result.meta !== 'undefined'
                    ? action.result.meta.pagination.current_page <
                      action.result.meta.pagination.total_pages
                    : false,
                commentsPagesLoaded:
                  imState.getIn(
                    [
                      action.projectHash,
                      'commentReplies',
                      action.parentId.toString(),
                      'commentsPagesLoaded',
                    ],
                    0
                  ) + 1,
                comments: imState
                  .getIn(
                    [
                      action.projectHash,
                      'commentReplies',
                      action.parentId.toString(),
                      'comments',
                    ],
                    List()
                  )
                  .concat(
                    typeof action.result.comments !== 'undefined'
                      ? action.result.comments
                      : []
                  ),
                commentStats: fromJS(
                  imState.getIn(
                    [
                      action.projectHash,
                      'commentReplies',
                      action.parentId.toString(),
                      'commentStats',
                    ],
                    {}
                  )
                )
                  .mergeDeep(fromJS(commentLoadReplyStats))
                  .toJS(),
              },
            },
          },
        })
        .toJS();

    case LOADPROJECTCOMMENTREPLIES_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            commentReplies: {
              [action.parentId]: {
                loadedComments: true,
                loadingComments: false,
              },
            },
          },
        })
        .toJS();

    case POSTCOMMENT:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            postedComment: false,
            postingComment: true,
          },
        })
        .toJS();

    case POSTCOMMENT_SUCCESS:
      const commentPostStats = {
        [action.result]: {
          hasLiked: action.entities.projectComments[action.result].hasLiked,
          hasReported:
            action.entities.projectComments[action.result].hasReported,
        },
      };

      if (action.replyTo !== null) {
        return imState
          .mergeDeep({
            [action.projectHash]: {
              postedComment: true,
              postingComment: false,
              commentsTotal:
                imState.getIn([action.projectHash, 'commentsTotal'], 0) + 1,
              commentReplies: {
                [action.replyTo]: {
                  comments: imState
                    .getIn(
                      [
                        action.projectHash,
                        'commentReplies',
                        action.replyTo.toString(),
                        'comments',
                      ],
                      List()
                    )
                    .unshift(action.result),
                  commentStats: fromJS(
                    imState.getIn(
                      [
                        action.projectHash,
                        'commentReplies',
                        action.replyTo.toString(),
                        'commentStats',
                      ],
                      {}
                    )
                  )
                    .mergeDeep(fromJS(commentPostStats))
                    .toJS(),
                },
              },
            },
          })
          .toJS();
      }

      return imState
        .mergeDeep({
          [action.projectHash]: {
            postedComment: true,
            postingComment: false,
            commentsTotal:
              imState.getIn([action.projectHash, 'commentsTotal'], 0) + 1,
            comments: imState
              .getIn([action.projectHash, 'comments'], List())
              .unshift(action.result),
            commentStats: fromJS(
              imState.getIn([action.projectHash, 'commentStats'], {})
            )
              .mergeDeep(fromJS(commentPostStats))
              .toJS(),
          },
        })
        .toJS();

    case POSTCOMMENT_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            postedComment: false,
            postingComment: false,
          },
        })
        .toJS();

    case REPORTCOMMENT:
      if (action.comment.parent_id) {
        return imState
          .setIn(
            [
              action.projectHash,
              'commentReplies',
              action.comment.parent_id.toString(),
              'commentStats',
              action.comment.id.toString(),
              'hasReported',
            ],
            true
          )
          .toJS();
      }

      return imState
        .setIn(
          [
            action.projectHash,
            'commentStats',
            action.comment.id.toString(),
            'hasReported',
          ],
          true
        )
        .toJS();

    case REPORTCOMMENT_SUCCESS:
      return imState.toJS();

    case REPORTCOMMENT_FAIL:
      if (action.comment.parent_id) {
        return imState
          .setIn(
            [
              action.projectHash,
              'commentReplies',
              action.comment.parent_id.toString(),
              'commentStats',
              action.comment.id.toString(),
              'hasReported',
            ],
            false
          )
          .toJS();
      }

      return imState
        .setIn(
          [
            action.projectHash,
            'commentStats',
            action.comment.id.toString(),
            'hasReported',
          ],
          false
        )
        .toJS();

    case HASLIKED:
      return imState
        .setIn([action.projectHash, 'loadingLikeState'], true)
        .toJS();

    case HASLIKED_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            hasLiked: action.result.like,
            loadingLikeState: false,
          },
        })
        .toJS();

    case HASLIKED_FAIL:
      return imState
        .setIn([action.projectHash, 'loadingLikeState'], false)
        .toJS();

    // Optimistic load
    case LIKE:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            hasLiked: true,
          },
        })
        .toJS();

    case LIKE_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            likesTotal:
              imState.getIn([action.projectHash, 'likesTotal'], 0) + 1,
          },
        })
        .toJS();

    case LIKE_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            hasLiked: false,
          },
        })
        .toJS();

    // Optimistic load
    case UNLIKE:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            hasLiked: false,
          },
        })
        .toJS();

    case UNLIKE_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            likesTotal:
              imState.getIn([action.projectHash, 'likesTotal'], 1) - 1,
          },
        })
        .toJS();

    case UNLIKE_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            hasLiked: true,
          },
        })
        .toJS();

    case REPORTPROJECT:
      return imState.setIn([action.projectHash, 'hasReported'], true).toJS();

    case REPORTPROJECT_SUCCESS:
      return imState.toJS();

    case REPORTPROJECT_FAIL:
      return imState.setIn([action.projectHash, 'hasReported'], false).toJS();

    case DOWNLOAD:
      return imState
        .setIn([action.projectHash, 'loadingDownloadState'], true)
        .toJS();

    case DOWNLOAD_SUCCESS:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            downloadUrl: action.result.url ? action.result.url : false,
            requested: action.result.requested ? true : false,
            loadingDownloadState: false,
          },
        })
        .toJS();

    case DOWNLOAD_FAIL:
      return imState
        .setIn([action.projectHash, 'loadingDownloadState'], false)
        .toJS();

    case ADDCREDIT:
      return imState.setIn([action.projectHash, 'postingCredit'], true).toJS();

    case ADDCREDIT_SUCCESS:
      if (
        typeof action.result.credits === 'undefined' ||
        !action.entities.credits[action.result.credits[0]].active ||
        imState
          .getIn([action.projectHash, 'credits'], List())
          .filter((creditId) => creditId === action.result.credits[0]).size > 0
      ) {
        return imState
          .mergeDeep({
            [action.projectHash]: {
              postedCredit: true,
              postingCredit: false,
            },
          })
          .toJS();
      }

      return imState
        .mergeDeep({
          [action.projectHash]: {
            postedCredit: true,
            postingCredit: false,
            creditsTotal:
              imState.getIn([action.projectHash, 'creditsTotal'], 0) + 1,
            credits: imState
              .getIn([action.projectHash, 'credits'], List())
              .concat(
                typeof action.result.credits !== 'undefined'
                  ? action.result.credits
                  : []
              ),
          },
        })
        .toJS();

    case ADDCREDIT_FAIL:
      return imState
        .mergeDeep({
          [action.projectHash]: {
            postedCredit: false,
            postingCredit: false,
          },
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export function isProjectLoaded(globalState, projectHash) {
  return (
    globalState.project &&
    typeof globalState.project[projectHash] !== 'undefined' &&
    typeof globalState.project[projectHash].loadedProject !== 'undefined' &&
    globalState.project[projectHash].loadedProject
  );
}

export function loadProject(projectHash) {
  return {
    types: [LOADPROJECT, LOADPROJECT_SUCCESS, LOADPROJECT_FAIL],
    promise: (client) =>
      client.get(
        '/projects/' +
          projectHash +
          '?include=preferences,stats,stream,thumbnails,user,user.avatars,user.covers,children&include_inactive=1'
      ),
    projectHash,
    schema: projectSchema,
  };
}

export function isCollectionLoaded(globalState, projectHash) {
  return (
    globalState.project &&
    typeof globalState.project[projectHash] !== 'undefined' &&
    typeof globalState.project[projectHash].loadedCollection !== 'undefined' &&
    globalState.project[projectHash].loadedCollection
  );
}

export function loadCollection(projectHash, page = 1) {
  return {
    types: [LOADCOLLECTION, LOADCOLLECTION_SUCCESS, LOADCOLLECTION_FAIL],
    promise: (client) =>
      client.get(
        '/projects/' +
          projectHash +
          '/collection?include=thumbnails,stream&page=' +
          page
      ),
    projectHash,
    schema: { projects: [projectSchema] },
  };
}

export function isRelatedProjectsLoaded(globalState, projectHash) {
  return (
    globalState.project &&
    typeof globalState.project[projectHash] !== 'undefined' &&
    typeof globalState.project[projectHash].loadedRelatedProjects !==
      'undefined' &&
    globalState.project[projectHash].loadedRelatedProjects
  );
}

export function loadRelatedProjects(projectHash, limit = 10) {
  return {
    types: [
      LOADRELATEDPROJECTS,
      LOADRELATEDPROJECTS_SUCCESS,
      LOADRELATEDPROJECTS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/projects/' +
          projectHash +
          '/related?limit=' +
          limit +
          '&include=related.thumbnails,related.credits,related.credits.user,related.credits.user.avatars'
      ),
    projectHash,
    schema: { relateds: [{ related: projectSchema }] },
  };
}

export function isCreditsLoaded(globalState, projectHash) {
  return (
    globalState.project &&
    typeof globalState.project[projectHash] !== 'undefined' &&
    typeof globalState.project[projectHash].loadedCredits !== 'undefined' &&
    globalState.project[projectHash].loadedCredits
  );
}

export function loadCredits(projectHash, page = 1) {
  return {
    types: [
      LOADPROJECTCREDITS,
      LOADPROJECTCREDITS_SUCCESS,
      LOADPROJECTCREDITS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/projects/' +
          projectHash +
          '/credits?include=user,user.avatars,user.covers&active=1&page=' +
          page
      ),
    projectHash,
    schema: { credits: [creditSchema] },
  };
}

export function likeComment(projectHash, comment) {
  return {
    types: [LIKECOMMENT, LIKECOMMENT_SUCCESS, LIKECOMMENT_FAIL],
    promise: (client) =>
      client.put(`/projects/${projectHash}/comments/${comment.id}/like`),
    projectHash,
    comment,
  };
}

export function unLikeComment(projectHash, comment) {
  return {
    types: [UNLIKECOMMENT, UNLIKECOMMENT_SUCCESS, UNLIKECOMMENT_FAIL],
    promise: (client) =>
      client.delete(`/projects/${projectHash}/comments/${comment.id}/like`),
    projectHash,
    comment,
  };
}

export function isCommentsLoaded(globalState, projectHash) {
  return (
    globalState.project &&
    typeof globalState.project[projectHash] !== 'undefined' &&
    typeof globalState.project[projectHash].loadedComments !== 'undefined' &&
    globalState.project[projectHash].loadedComments
  );
}

export function loadComments(projectHash, page = 1) {
  return {
    types: [
      LOADPROJECTCOMMENTS,
      LOADPROJECTCOMMENTS_SUCCESS,
      LOADPROJECTCOMMENTS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/projects/' +
          projectHash +
          '/comments?page=' +
          page +
          '&include=replies,replies.user,replies.user.avatars,user,user.avatars'
      ),
    projectHash,
    schema: { comments: [projectCommentsSchema] },
  };
}

export function loadCommentReplies(projectHash, parentId, page = 1) {
  return {
    types: [
      LOADPROJECTCOMMENTREPLIES,
      LOADPROJECTCOMMENTREPLIES_SUCCESS,
      LOADPROJECTCOMMENTREPLIES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/projects/' +
          projectHash +
          '/comments/' +
          parentId +
          '/replies?page=' +
          page +
          '&include=user,user.avatars'
      ),
    projectHash,
    parentId,
    schema: { comments: [projectCommentsSchema] },
  };
}

export function postComment(projectHash, text, replyTo = null) {
  return {
    types: [POSTCOMMENT, POSTCOMMENT_SUCCESS, POSTCOMMENT_FAIL],
    promise: (client) =>
      client.post(
        '/projects/' +
          projectHash +
          '/comments' +
          (replyTo !== null ? '/' + replyTo + '/replies' : ''),
        {
          data: jsonToFormData({
            text: text,
          }),
        }
      ),
    projectHash,
    replyTo,
    schema: projectCommentsSchema,
  };
}

export function reportComment(projectHash, comment) {
  return {
    types: [REPORTCOMMENT, REPORTCOMMENT_SUCCESS, REPORTCOMMENT_FAIL],
    promise: (client) =>
      client.put(`/projects/${projectHash}/comments/${comment.id}/report`),
    projectHash,
    comment,
  };
}

export function hasLiked(projectHash) {
  return {
    types: [HASLIKED, HASLIKED_SUCCESS, HASLIKED_FAIL],
    promise: (client) => client.get(`/projects/${projectHash}/like`),
    projectHash,
  };
}

export function like(projectHash) {
  return {
    types: [LIKE, LIKE_SUCCESS, LIKE_FAIL],
    promise: (client) => client.put(`/projects/${projectHash}/like`),
    projectHash,
  };
}

export function unLike(projectHash) {
  return {
    types: [UNLIKE, UNLIKE_SUCCESS, UNLIKE_FAIL],
    promise: (client) => client.delete(`/projects/${projectHash}/like`),
    projectHash,
  };
}

export function reportProject(projectHash, reason, comment) {
  return {
    types: [REPORTPROJECT, REPORTPROJECT_SUCCESS, REPORTPROJECT_FAIL],
    promise: (client) =>
      client.put(
        `/projects/${projectHash}/report?reason=${reason}&comment=${comment}`
      ),
    projectHash,
  };
}

export function download(projectHash) {
  return {
    types: [DOWNLOAD, DOWNLOAD_SUCCESS, DOWNLOAD_FAIL],
    promise: (client) => client.get(`/projects/${projectHash}/download`),
    projectHash,
  };
}

export function addCredit(projectHash, projectId, user, roles) {
  return {
    types: [ADDCREDIT, ADDCREDIT_SUCCESS, ADDCREDIT_FAIL],
    promise: (client) =>
      client.post('/credits?include=user,user.avatars,user.covers', {
        data: jsonToFormData({
          project_id: projectId,
          users: [user],
          roles: roles,
          _method: 'PATCH',
        }),
      }),
    projectHash,
    schema: { credits: [creditSchema] },
  };
}
