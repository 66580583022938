import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import Lightbox from 'react-image-lightbox';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import { isEqual } from 'lodash';
import 'react-image-lightbox/style.css';

class Thumbnail extends Component {
  static propTypes = {
    aspectRatio: PropTypes.string,
    circle: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.object,
    square: PropTypes.bool,
    original: PropTypes.bool,
    style: PropTypes.object,
    thumbnails: PropTypes.object.isRequired,
    temp: PropTypes.string,
    type: PropTypes.string,
    zoom: PropTypes.bool,
    onClick: PropTypes.func,
    onZoom: PropTypes.func,
    onLoad: PropTypes.func,
    boxShadow: PropTypes.string,
  };

  static defaultProps = {
    aspectRatio: null,
    circle: undefined, // should be false, temp hack to suppress console warnings
    className: '',
    square: undefined, // should be false, temp hack to suppress console warnings
    style: {},
    zoom: undefined, // should be false, temp hack to suppress console warnings
    temp: '',
    boxShadow: '',
  };

  state = {
    imgLoaded: false,
    zoomed: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      Math.floor(nextProps.size.width) !== Math.floor(this.props.size.width) ||
      !isEqual(this.state, nextState) ||
      !isEqual(nextProps.thumbnails, this.props.thumbnails)
    );
  }

  getClosestImage(size) {
    const images = this.props.thumbnails.images;
    const keys = Object.keys(images);

    const filteredKeys = keys.filter((key) => images[key].width > size);

    let max = 0;
    if (filteredKeys.length <= 0) {
      max = keys.slice(-1).pop();
    } else {
      max = filteredKeys.shift();
    }

    return images[max];
  }

  render() {
    const color = this.props.color || this.props.thumbnails.color;
    let image = this.getClosestImage(this.props.size.width);

    if (typeof image === 'undefined') {
      return <noscript />;
    }

    if (this.props.square || typeof image.wide === 'undefined') {
      image = image.square;
    } else {
      image = image.wide;
    }

    if (this.props.type) {
      image = image[this.props.type];
    } else {
      image = image.main;
    }

    const ratios = {
      '16:9': 56.25,
      '4:3': 75,
      '3:2': 66.66,
      '8:5': 62.5,
    };
    let ratioSize = ratios[this.props.aspectRatio] || null;

    const original =
      this.props.thumbnails.original_optimised !== ''
        ? this.props.thumbnails.original_optimised
        : this.props.thumbnails.original;

    if (this.props.original && original) {
      image = original;
      ratioSize = 100;
    }

    const styles = {
      borderRadius: this.props.circle ? '50%' : '0',
      overflow: 'hidden',
      position: 'relative',
      ...this.props.style,
      ...(ratioSize ? { width: '100%', paddingBottom: `${ratioSize}%` } : {}),
      ...(this.props.zoom && original ? { cursor: 'zoom-in' } : {}),
    };

    const { aspectRatio, circle, boxShadow, square, ...rest } = this.props;

    return (
      <div
        {...rest}
        className={this.props.className ? this.props.className : ''}
        style={styles}
        id={this.props.id}
      >
        <div
          onClick={() => {
            if (this.props.onClick) {
              return this.props.onClick();
            }

            this.setState({
              zoomed:
                this.props.zoom &&
                original &&
                (this.props.onZoom ? this.props.onZoom() : true)
                  ? true
                  : false,
            });
          }}
        >
          <div
            style={{
              backgroundColor: color,
              borderRadius: this.props.circle ? '50%' : '0',
              bottom: 0,
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
          <LazyLoad>
            <div
              style={{
                backgroundImage:
                  'url(' +
                  (this.props.thumbnails.temp
                    ? this.props.thumbnails.temp
                    : image) +
                  ')',
                backgroundSize: this.props.original ? 'contain' : 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: this.props.circle ? '50%' : '0',
                bottom: 0,
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0,
                ...(this.props.boxShadow
                  ? { boxShadow: this.props.boxShadow }
                  : {}),
              }}
            />
          </LazyLoad>
          <LazyLoad>
            <img
              alt="Twine"
              src={image}
              style={{ display: 'none' }}
              onLoad={() => {
                this.setState({ imgLoaded: true });
                return this.props.onLoad ? this.props.onLoad() : true;
              }}
            />
          </LazyLoad>
          {this.props.zoom && original ? (
            <SvgIcon
              icon="zoom"
              color="white"
              size="20"
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                marginRight: '10px',
                marginBottom: '10px',
                transition: 'opacity ease 0.5s',
              }}
            />
          ) : null}
        </div>
        {this.state.zoomed ? (
          <Lightbox
            mainSrc={original}
            discourageDownloads
            onCloseRequest={() => this.setState({ zoomed: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default withSize()(Thumbnail);
