import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkOrAnchor = ({ isLink, children, to, ...rest }) =>
  isLink ? (
    <Link to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <a href={to} {...rest}>
      {children}
    </a>
  );

LinkOrAnchor.propTypes = {
  children: PropTypes.node,
  isLink: PropTypes.bool,
  to: PropTypes.string,
};

export default LinkOrAnchor;
