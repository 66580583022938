const LOADPITCHES = 'twineApp/managePitches/LOADPITCHES';
const LOADPITCHES_SUCCESS = 'twineApp/managePitches/LOADPITCHES_SUCCESS';
const RELOADPITCHES_SUCCESS = 'twineApp/managePitches/RELOADPITCHES_SUCCESS';
const LOADPITCHES_FAIL = 'twineApp/managePitches/LOADPITCHES_FAIL';
const LOADPENDINGPITCHES = 'twineApp/managePitches/LOADPENDINGPITCHES';
const LOADPENDINGPITCHES_SUCCESS =
  'twineApp/managePitches/LOADPENDINGPITCHES_SUCCESS';
const LOADPENDINGPITCHES_FAIL =
  'twineApp/managePitches/LOADPENDINGPITCHES_FAIL';
const LOADACCEPTEDPITCHES = 'twineApp/managePitches/LOADACCEPTEDPITCHES';
const LOADACCEPTEDPITCHES_SUCCESS =
  'twineApp/managePitches/LOADACCEPTEDPITCHES_SUCCESS';
const RELOADACCEPTEDPITCHES_SUCCESS =
  'twineApp/managePitches/RELOADACCEPTEDPITCHES_SUCCESS';
const LOADACCEPTEDPITCHES_FAIL =
  'twineApp/managePitches/LOADACCEPTEDPITCHES_FAIL';
const LOADDECLINEDPITCHES = 'twineApp/managePitches/LOADDECLINEDPITCHES';
const LOADDECLINEDPITCHES_SUCCESS =
  'twineApp/managePitches/LOADDECLINEDPITCHES_SUCCESS';
const LOADDECLINEDPITCHES_FAIL =
  'twineApp/managePitches/LOADDECLINEDPITCHES_FAIL';
const LOADCOMPLETEDPITCHES = 'twineApp/managePitches/LOADCOMPLETEDPITCHES';
const LOADCOMPLETEDPITCHES_SUCCESS =
  'twineApp/managePitches/LOADCOMPLETEDPITCHES_SUCCESS';
const LOADCOMPLETEDPITCHES_FAIL =
  'twineApp/managePitches/LOADCOMPLETEDPITCHES_FAIL';

const initialState = {
  pitches: [],
  pitchesTotal: 0,
  pitchesLoadMore: false,
  pitchesPagesLoaded: 0,
  loadingPitches: false,
  loadedPitches: false,

  pendingPitches: [],
  pendingPitchesTotal: 0,
  pendingPitchesLoadMore: false,
  pendingPitchesPagesLoaded: 0,
  loadingPendingPitches: false,
  loadedPendingPitches: false,

  acceptedPitches: [],
  acceptedPitchesTotal: 0,
  acceptedPitchesLoadMore: false,
  acceptedPitchesPagesLoaded: 0,
  loadingAcceptedPitches: false,
  loadedAcceptedPitches: false,

  declinedPitches: [],
  declinedPitchesTotal: 0,
  declinedPitchesLoadMore: false,
  declinedPitchesPagesLoaded: 0,
  loadingDeclinedPitches: false,
  loadedDeclinedPitches: false,

  completedPitches: [],
  completedPitchesTotal: 0,
  completedPitchesLoadMore: false,
  completedPitchesPagesLoaded: 0,
  loadingCompletedPitches: false,
  loadedCompletedPitches: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADPITCHES:
      return {
        ...state,
        loadingPitches: true,
      };

    case LOADPITCHES_SUCCESS:
      return {
        ...state,
        loadingPitches: false,
        loadedPitches: true,
        pitches:
          typeof action.result.threads === 'undefined'
            ? state.pitches
            : state.pitches.concat(action.result.threads),
        pitchesTotal:
          typeof action.result.threads === 'undefined'
            ? state.pitchesTotal
            : action.result.meta.pagination.total,
        pitchesLoadMore:
          typeof action.result.threads === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        pitchesPagesLoaded: state.pitchesPagesLoaded + 1,
      };

    case RELOADPITCHES_SUCCESS:
      return {
        ...state,
        loadingPitches: false,
        loadedPitches: true,
        pitches: action.result.threads ? action.result.threads : [],
        pitchesTotal: action.result.threads
          ? action.result.meta.pagination.total
          : 0,
        pitchesLoadMore: action.result.threads
          ? action.result.meta.pagination.current_page <
            action.result.meta.pagination.total_pages
          : false,
        pitchesPagesLoaded: 1,
      };

    case LOADPITCHES_FAIL:
      return {
        ...state,
        loadingPitches: false,
      };

    case LOADPENDINGPITCHES:
      return {
        ...state,
        loadingPendingPitches: true,
      };

    case LOADPENDINGPITCHES_SUCCESS:
      return {
        ...state,
        loadingPendingPitches: false,
        loadedPendingPitches: true,
        pendingPitches:
          typeof action.result.threads === 'undefined'
            ? state.pendingPitches
            : state.pendingPitches.concat(action.result.threads),
        pendingPitchesTotal:
          typeof action.result.threads === 'undefined'
            ? state.pendingPitchesTotal
            : action.result.meta.pagination.total,
        pendingPitchesLoadMore:
          typeof action.result.threads === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        pendingPitchesPagesLoaded: state.pendingPitchesPagesLoaded + 1,
      };

    case LOADPENDINGPITCHES_FAIL:
      return {
        ...state,
        loadingPendingPitches: false,
      };

    case LOADACCEPTEDPITCHES:
      return {
        ...state,
        loadingAcceptedPitches: true,
      };

    case LOADACCEPTEDPITCHES_SUCCESS:
      return {
        ...state,
        loadingAcceptedPitches: false,
        loadedAcceptedPitches: true,
        acceptedPitches:
          typeof action.result.threads === 'undefined'
            ? state.acceptedPitches
            : state.acceptedPitches.concat(action.result.threads),
        acceptedPitchesTotal:
          typeof action.result.threads === 'undefined'
            ? state.acceptedPitchesTotal
            : action.result.meta.pagination.total,
        acceptedPitchesLoadMore:
          typeof action.result.threads === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        acceptedPitchesPagesLoaded: state.acceptedPitchesPagesLoaded + 1,
      };

    case RELOADACCEPTEDPITCHES_SUCCESS:
      return {
        ...state,
        loadingAcceptedPitches: false,
        loadedAcceptedPitches: true,
        acceptedPitches: action.result.threads ? action.result.threads : [],
        acceptedPitchesTotal: action.result.threads
          ? action.result.meta.pagination.total
          : 0,
        acceptedPitchesLoadMore: action.result.threads
          ? action.result.meta.pagination.current_page <
            action.result.meta.pagination.total_pages
          : false,
        acceptedPitchesPagesLoaded: 1,
      };

    case LOADACCEPTEDPITCHES_FAIL:
      return {
        ...state,
        loadingAcceptedPitches: false,
      };

    case LOADDECLINEDPITCHES:
      return {
        ...state,
        loadingDeclinedPitches: true,
      };

    case LOADDECLINEDPITCHES_SUCCESS:
      return {
        ...state,
        loadingDeclinedPitches: false,
        loadedDeclinedPitches: true,
        declinedPitches:
          typeof action.result.threads === 'undefined'
            ? state.declinedPitches
            : state.declinedPitches.concat(action.result.threads),
        declinedPitchesTotal:
          typeof action.result.threads === 'undefined'
            ? state.declinedPitchesTotal
            : action.result.meta.pagination.total,
        declinedPitchesLoadMore:
          typeof action.result.threads === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        declinedPitchesPagesLoaded: state.declinedPitchesPagesLoaded + 1,
      };

    case LOADDECLINEDPITCHES_FAIL:
      return {
        ...state,
        loadingDeclinedPitches: false,
      };

    case LOADCOMPLETEDPITCHES:
      return {
        ...state,
        loadingCompletedPitches: true,
      };

    case LOADCOMPLETEDPITCHES_SUCCESS:
      return {
        ...state,
        loadingCompletedPitches: false,
        loadedCompletedPitches: true,
        completedPitches:
          typeof action.result.threads === 'undefined'
            ? state.completedPitches
            : state.completedPitches.concat(action.result.threads),
        completedPitchesTotal:
          typeof action.result.threads === 'undefined'
            ? state.completedPitchesTotal
            : action.result.meta.pagination.total,
        completedPitchesLoadMore:
          typeof action.result.threads === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        completedPitchesPagesLoaded: state.completedPitchesPagesLoaded + 1,
      };

    case LOADCOMPLETEDPITCHES_FAIL:
      return {
        ...state,
        loadingCompletedPitches: false,
      };

    default:
      return state;
  }
}

export function loadPitches(userId, page) {
  return {
    types: [LOADPITCHES, LOADPITCHES_SUCCESS, LOADPITCHES_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&page=' +
          page
      ),
  };
}

export function reloadPitches(userId, page) {
  return {
    types: [LOADPITCHES, RELOADPITCHES_SUCCESS, LOADPITCHES_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&page=' +
          page
      ),
  };
}

export function loadPendingPitches(userId, page) {
  return {
    types: [
      LOADPENDINGPITCHES,
      LOADPENDINGPITCHES_SUCCESS,
      LOADPENDINGPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&state=pending&page=' +
          page
      ),
  };
}

export function loadAcceptedPitches(userId, page) {
  return {
    types: [
      LOADACCEPTEDPITCHES,
      LOADACCEPTEDPITCHES_SUCCESS,
      LOADACCEPTEDPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&state=accepted&page=' +
          page
      ),
  };
}

export function reloadAcceptedPitches(userId, page) {
  return {
    types: [
      LOADACCEPTEDPITCHES,
      RELOADACCEPTEDPITCHES_SUCCESS,
      LOADACCEPTEDPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&state=accepted&page=' +
          page
      ),
  };
}

export function loadDeclinedPitches(userId, page) {
  return {
    types: [
      LOADDECLINEDPITCHES,
      LOADDECLINEDPITCHES_SUCCESS,
      LOADDECLINEDPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&state=declined&page=' +
          page
      ),
  };
}

export function loadCompletedPitches(userId, page) {
  return {
    types: [
      LOADCOMPLETEDPITCHES,
      LOADCOMPLETEDPITCHES_SUCCESS,
      LOADCOMPLETEDPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/pitches?include=message.notice,message.notice.user,message.notice.user.avatars&state=completed&page=' +
          page
      ),
  };
}
