import { jsonToFormData } from 'helpers/HelperFunctions';

const PAYMENTTOKEN = 'twineApp/payments/PAYMENTTOKEN';
const PAYMENTTOKEN_SUCCESS = 'twineApp/payments/PAYMENTTOKEN_SUCCESS';
const PAYMENTTOKEN_FAIL = 'twineApp/payments/PAYMENTTOKEN_FAIL';
const SUBSCRIPTIONTOKEN = 'twineApp/payments/SUBSCRIPTIONTOKEN';
const SUBSCRIPTIONTOKEN_SUCCESS = 'twineApp/payments/SUBSCRIPTIONTOKEN_SUCCESS';
const SUBSCRIPTIONTOKEN_FAIL = 'twineApp/payments/SUBSCRIPTIONTOKEN_FAIL';

const GETPAYMENTSLIST = 'twineApp/payments/GETPAYMENT';
const GETPAYMENTSLIST_SUCCESS = 'twineApp/payments/GETPAYMENT_SUCCESS';
const GETPAYMENTSLIST_FAIL = 'twineApp/payments/GETPAYMENT_FAIL';
const GETPAYMENTSLISTPAGINATED = 'twineApp/payments/GETPAYMENTSLISTPAGINATED';
const GETPAYMENTSLISTPAGINATED_SUCCESS =
  'twineApp/payments/GETPAYMENTSLISTPAGINATED_SUCCESS';
const GETPAYMENTSLISTPAGINATED_FAIL =
  'twineApp/payments/GETPAYMENTSLISTPAGINATED_FAIL';

const GETEARNINGSLIST = 'twineApp/payments/GETEARNINGSLIST';
const GETEARNINGSLIST_SUCCESS = 'twineApp/payments/GETEARNINGSLIST_SUCCESS';
const GETEARNINGSLIST_FAIL = 'twineApp/payments/GETEARNINGSLIST_FAIL';

const GETEARNINGSLISTPAGINATED = 'twineApp/payments/GETEARNINGSLISTPAGINATED';
const GETEARNINGSLISTPAGINATED_SUCCESS =
  'twineApp/payments/GETEARNINGSLISTPAGINATED_SUCCESS';
const GETEARNINGSLISTPAGINATED_FAIL =
  'twineApp/payments/GETEARNINGSLISTPAGINATED_FAIL';

const RELEASEVAULTEDMONEY = 'twineApp/payments/RELEASEVAULTEDMONEY';
const RELEASEVAULTEDMONEY_SUCCESS =
  'twineApp/payments/RELEASEVAULTEDMONEY_SUCCESS';
const RELEASEVAULTEDMONEY_FAIL = 'twineApp/payments/RELEASEVAULTEDMONEY_FAIL';

const initialState = {
  loadingPaymentToken: false,
  loadedPaymentToken: false,
  paymentToken: '',

  loadingSubscriptionToken: false,
  loadedSubscriptionToken: false,
  subscriptionToken: '',

  loadingGetPaymentsList: false,
  loadedGetPaymentsList: false,
  paymentsList: [],

  releaseAttempted: false,
  releaseSuccessful: false,
  releasedPayments: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAYMENTTOKEN:
      return {
        ...state,
        loadingPaymentToken: true,
      };

    case PAYMENTTOKEN_SUCCESS:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: true,
        paymentToken: action.result.token,
      };

    case PAYMENTTOKEN_FAIL:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: true,
      };

    case SUBSCRIPTIONTOKEN:
      return {
        ...state,
        loadingSubscriptionToken: true,
      };

    case SUBSCRIPTIONTOKEN_SUCCESS:
      return {
        ...state,
        loadingSubscriptionToken: false,
        loadedSubscriptionToken: true,
        subscriptionToken: action.result.token,
      };

    case SUBSCRIPTIONTOKEN_FAIL:
      return {
        ...state,
        loadingSubscriptionToken: false,
        loadedSubscriptionToken: true,
      };

    case GETPAYMENTSLIST:
      return {
        ...state,
        loadingGetPaymentsList: true,
      };

    case GETPAYMENTSLIST_SUCCESS:
      return {
        ...state,
        loadingGetPaymentsList: false,
        loadedGetPaymentsList: true,
        paymentsList: action.result.payments,
      };

    case GETPAYMENTSLIST_FAIL:
      return {
        ...state,
        loadingGetPaymentsList: false,
        loadedGetPaymentsList: true,
      };

    case GETPAYMENTSLISTPAGINATED:
      return {
        ...state,
        loadingGetPaymentsListPaginated: true,
      };

    case GETPAYMENTSLISTPAGINATED_SUCCESS:
      return {
        ...state,
        loadingGetPaymentsListPaginated: false,
        loadedGetPaymentsListPaginated: true,
        paymentsListPaginated: action.result.payments,
      };

    case GETPAYMENTSLISTPAGINATED_FAIL:
      return {
        ...state,
        loadingGetPaymentsListPaginated: false,
        loadedGetPaymentsListPaginated: true,
      };

    case GETEARNINGSLIST:
      return {
        ...state,
        loadingGetEarningsList: true,
      };

    case GETEARNINGSLIST_SUCCESS:
      return {
        ...state,
        loadingGetEarningsList: false,
        loadedGetEarningsList: true,
        earningsList: action.result.payments,
      };

    case GETEARNINGSLIST_FAIL:
      return {
        ...state,
        loadingGetEarningsList: false,
        loadedGetEarningsList: true,
      };

    case GETEARNINGSLISTPAGINATED:
      return {
        ...state,
        loadingGetEarningsListPaginated: true,
      };

    case GETEARNINGSLISTPAGINATED_SUCCESS:
      return {
        ...state,
        loadingGetEarningsListPaginated: false,
        loadedGetEarningsListPaginated: true,
        earningsList: action.result.payments,
      };

    case GETEARNINGSLISTPAGINATED_FAIL:
      return {
        ...state,
        loadingGetEarningsListPaginated: false,
        loadedGetEarningsListPaginated: true,
      };

    case RELEASEVAULTEDMONEY:
      return {
        ...state,
        releaseAttempted: true,
      };

    case RELEASEVAULTEDMONEY_SUCCESS:
      return {
        ...state,
        releaseAttempted: true,
        releaseSuccessful: true,
        releasedPayments: action.result,
      };

    case RELEASEVAULTEDMONEY_FAIL:
      return {
        ...state,
        releaseAttempted: true,
        releaseSuccessful: false,
        updatedPayments: action.result,
      };

    default:
      return state;
  }
}

export function getPaymentToken(data) {
  return {
    types: [PAYMENTTOKEN, PAYMENTTOKEN_SUCCESS, PAYMENTTOKEN_FAIL],
    promise: (client) => client.get('/payments/token', jsonToFormData(data)),
  };
}

export function getSubscriptionToken(data) {
  return {
    types: [
      SUBSCRIPTIONTOKEN,
      SUBSCRIPTIONTOKEN_SUCCESS,
      SUBSCRIPTIONTOKEN_FAIL,
    ],
    promise: (client) =>
      client.post('/subscriptions/token', { data: jsonToFormData(data) }),
  };
}

export function getPaymentsList(userId) {
  return {
    types: [GETPAYMENTSLIST, GETPAYMENTSLIST_SUCCESS, GETPAYMENTSLIST_FAIL],
    promise: (client) => client.get('/payments?from=' + userId),
  };
}

export function getPaymentsListPaginated(userId, pageNumber = 1) {
  return {
    types: [
      GETPAYMENTSLISTPAGINATED,
      GETPAYMENTSLISTPAGINATED_SUCCESS,
      GETPAYMENTSLISTPAGINATED_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/payments?from=' +
          userId +
          '&page=' +
          pageNumber +
          '&include=to,collaboration'
      ),
  };
}

export function getEarningsList(userId) {
  return {
    types: [GETEARNINGSLIST, GETEARNINGSLIST_SUCCESS, GETEARNINGSLIST_FAIL],
    promise: (client) => client.get('/payments?to=' + userId),
  };
}

export function getEarningsListPaginated(userId, pageNumber = 1) {
  return {
    types: [
      GETEARNINGSLISTPAGINATED,
      GETEARNINGSLISTPAGINATED_SUCCESS,
      GETEARNINGSLISTPAGINATED_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/payments?to=' +
          userId +
          '&page=' +
          pageNumber +
          '&include=from,collaboration'
      ),
  };
}

export function releaseVaultedMoney(pitchId) {
  return {
    types: [
      RELEASEVAULTEDMONEY,
      RELEASEVAULTEDMONEY_SUCCESS,
      RELEASEVAULTEDMONEY_FAIL,
    ],
    promise: (client) => client.patch('/payments/' + pitchId + '/release'),
  };
}
