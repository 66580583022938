import briefSchema from 'redux/schemas/briefSchema';
import messageSchema from 'redux/schemas/messageSchema';
import publicQuestionSchema from 'redux/schemas/publicQuestionSchema';
import userSchema from 'redux/schemas/userSchema';
import inviteSchema from 'redux/schemas/inviteSchema';
import { get } from 'helpers/HelperFunctions';
import merge from 'deepmerge';
import { union } from 'lodash';
import { DEFAULT_PENDING_PITCHES_TO_LOAD } from 'constants/constants';

const LOADBRIEF = 'twineApp/briefs2/LOADBRIEF';
const LOADBRIEF_SUCCESS = 'twineApp/briefs2/LOADBRIEF_SUCCESS';
const LOADBRIEF_FAIL = 'twineApp/briefs2/LOADBRIEF_FAIL';
const LOADSUGGESTEDCREATIVES = 'twineApp/briefs2/LOADSUGGESTEDCREATIVES';
const LOADSUGGESTEDCREATIVES_SUCCESS =
  'twineApp/briefs2/LOADSUGGESTEDCREATIVES_SUCCESS';
const LOADSUGGESTEDCREATIVES_FAIL =
  'twineApp/briefs2/LOADSUGGESTEDCREATIVES_FAIL';
const LOADPENDINGPITCHES = 'twineApp/briefs2/LOADPENDINGPITCHES';
const LOADPENDINGPITCHES_SUCCESS =
  'twineApp/briefs2/LOADPENDINGPITCHES_SUCCESS';
const LOADPENDINGPITCHES_FAIL = 'twineApp/briefs2/LOADPENDINGPITCHES_FAIL';
const RESET_LOADED_PENDING_PITCHES =
  'twineApp/briefs2/RESET_LOADED_PENDING_PITCHES';
const REMOVESUGGESTEDCREATIVE = 'twineApp/briefs2/REMOVESUGGESTEDCREATIVE';
const INVITE = 'twineApp/briefs2/INVITE';
const INVITE_SUCCESS = 'twineApp/briefs2/INVITE_SUCCESS';
const INVITE_FAIL = 'twineApp/briefs2/INVITE_FAIL';
const LOADPENDINGPUBLICQUESTIONS =
  'twineApp/briefs2/LOADPENDINGPUBLICQUESTIONS';
const LOADPENDINGPUBLICQUESTIONS_SUCCESS =
  'twineApp/briefs2/LOADPENDINGPUBLICQUESTIONS_SUCCESS';
const LOADPENDINGPUBLICQUESTIONS_FAIL =
  'twineApp/briefs2/LOADPENDINGPUBLICQUESTIONS_FAIL';
const LOADPUBLICQUESTIONS = 'twineApp/briefs2/LOADPUBLICQUESTIONS';
const LOADPUBLICQUESTIONS_SUCCESS =
  'twineApp/briefs2/LOADPUBLICQUESTIONS_SUCCESS';
const LOADPUBLICQUESTIONS_FAIL = 'twineApp/briefs2/LOADPUBLICQUESTIONS_FAIL';
const ADDVOUCHER = 'twineApp/briefs2/ADDVOUCHER';
const ADDVOUCHER_SUCCESS = 'twineApp/briefs2/ADDVOUCHER_SUCCESS';
const ADDVOUCHER_FAIL = 'twineApp/briefs2/ADDVOUCHER_FAIL';
const UPDATEBRIEF = 'twineApp/briefs2/UPDATEBRIEF';
const UPDATEBRIEF_SUCCESS = 'twineApp/briefs2/UPDATEBRIEF_SUCCESS';
const UPDATEBRIEF_FAIL = 'twineApp/briefs2/UPDATEBRIEF_FAIL';
const ACCEPTPITCH = 'twineApp/briefs2/ACCEPTPITCH';
const ACCEPTPITCH_SUCCESS = 'twineApp/briefs2/ACCEPTPITCH_SUCCESS';
const ACCEPTPITCH_FAIL = 'twineApp/briefs2/ACCEPTPITCH_FAIL';
const DECLINEPITCH = 'twineApp/briefs2/DECLINEPITCH';
const DECLINEPITCH_SUCCESS = 'twineApp/briefs2/DECLINEPITCH_SUCCESS';
const DECLINEPITCH_FAIL = 'twineApp/briefs2/DECLINEPITCH_FAIL';
const DECREMENTTOTALPENDINGQUESTIONS =
  'twineApp/briefs2/DECREMENTTOTALPENDINGQUESTIONS';
const LOADINVITES = 'twineApp/briefs2/LOADINVITES';
const LOADINVITES_SUCCESS = 'twineApp/briefs2/LOADINVITES_SUCCESS';
const LOADINVITES_FAIL = 'twineApp/briefs2/LOADINVITES_FAIL';
const LOAD_SHORTLISTED_PITCHES = 'twineApp/briefs2/LOAD_SHORTLISTED_PITCHES';
const LOAD_SHORTLISTED_PITCHES_SUCCESS =
  'twineApp/briefs2/LOAD_SHORTLISTED_PITCHES_SUCCESS';
const LOAD_SHORTLISTED_PITCHES_FAIL =
  'twineApp/briefs2/LOAD_SHORTLISTED_PITCHES_FAIL';
const ADD_TO_SHORTLISTED_PITCHES =
  'twineApp/briefs2/ADD_TO_SHORTLISTED_PITCHES';
const RESET_LOADED_SHORTLISTED_PITCHES =
  'twineApp/briefs2/RESET_LOADED_SHORTLISTED_PITCHES';
const LIKE_PITCH = 'twineApp/briefs2/LIKE_PITCH';
const LIKE_PITCH_SUCCESS = 'twineApp/briefs2/LIKE_PITCH_SUCCESS';
const LIKE_PITCH_FAIL = 'twineApp/briefs2/LIKE_PITCH_FAIL';
const LOAD_LIKED_PITCHES = 'twineApp/briefs2/LOAD_LIKED_PITCHES';
const LOAD_LIKED_PITCHES_SUCCESS =
  'twineApp/briefs2/LOAD_LIKED_PITCHES_SUCCESS';
const LOAD_LIKED_PITCHES_FAIL = 'twineApp/briefs2/LOAD_LIKED_PITCHES_FAIL';
const ADD_TO_LIKED_PITCHES = 'twineApp/briefs2/ADD_TO_LIKED_PITCHES';
const RESET_LOADED_LIKED_PITCHES =
  'twineApp/briefs2/RESET_LOADED_LIKED_PITCHES';
const SET_PINDER_TAB = 'twineApp/briefs2/SET_PINDER_TAB';
const LOADMOREPENDINGPITCHES = 'twineApp/briefs2/LOADMOREPENDINGPITCHES';
const LOADMOREPENDINGPITCHES_SUCCESS =
  'twineApp/briefs2/LOADMOREPENDINGPITCHES_SUCCESS';
const LOADMOREPENDINGPITCHES_FAIL =
  'twineApp/briefs2/LOADMOREPENDINGPITCHES_FAIL';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case LOADBRIEF:
      return merge(state, {
        [action.encodedId]: {
          loading: true,
        },
      });

    case LOADBRIEF_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loaded: true,
          loading: false,
          id: action.result,
        },
      });

    case LOADBRIEF_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loaded: true,
          loading: false,
        },
      });

    case LOADSUGGESTEDCREATIVES:
      return merge(state, {
        [action.encodedId]: {
          loadingSuggested: true,
        },
      });

    case LOADSUGGESTEDCREATIVES_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadedSuggested: true,
          loadingSuggested: false,
          suggested: get(state, [action.encodedId, 'suggested'], []).concat(
            get(action, ['result', 'users'], [])
          ),
          moreSuggested:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
        },
      });

    case LOADSUGGESTEDCREATIVES_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadedSuggested: true,
          loadingSuggested: false,
        },
      });

    case LOADPENDINGPITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadingPendingPitches: true,
        },
      });

    case LOADPENDINGPITCHES_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingPendingPitches: false,
          loadedPendingPitches: true,
          pendingPitches: union(
            get(state, [action.encodedId, 'pendingPitches'], []),
            get(action, ['result', 'messages'], [])
          ),
          morePendingPitches:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          pendingPitchesPagesLoaded:
            get(state, [action.encodedId, 'pendingPitchesPagesLoaded'], 0) + 1,
          totalPendingPitches: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
          nextPageToLoad: get(
            action,
            ['result', 'meta', 'pagination', 'next_page'],
            0
          ),
        },
      });

    case LOADPENDINGPITCHES_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingPendingPitches: false,
          loadedPendingPitches: true,
        },
      });

    case RESET_LOADED_PENDING_PITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadedPendingPitches: false,
        },
      });

    case REMOVESUGGESTEDCREATIVE:
      return merge(
        state,
        {
          [action.encodedId]: {
            suggested: get(state, [action.encodedId, 'suggested'], []).filter(
              (element) => element !== action.userId
            ),
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case INVITE:
      return merge(
        state,
        {
          [action.encodedId]: {
            // suggested: get(state, [action.encodedId, 'suggested'], []).filter(element => element !== action.user.id),
            inviting: get(state, [action.encodedId, 'inviting'], []).concat(
              action.user.id
            ),
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case INVITE_SUCCESS:
      return merge(
        state,
        {
          [action.encodedId]: {
            invites: [action.result].concat(
              get(state, [action.encodedId, 'invites'], [])
            ),
            suggested: get(state, [action.encodedId, 'suggested'], []).filter(
              (element) => element !== action.user.id
            ),
            totalInvites: get(state, [action.encodedId, 'totalInvites'], 0) + 1,
            inviting: get(state, [action.encodedId, 'inviting'], []).filter(
              (element) => element !== action.user.id
            ),
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case INVITE_FAIL:
      return merge(
        state,
        {
          [action.encodedId]: {
            inviting: get(state, [action.encodedId, 'inviting'], []).filter(
              (element) => element !== action.user.id
            ),
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case LOADPENDINGPUBLICQUESTIONS:
      return merge(state, {
        [action.encodedId]: {
          loadingPendingPublicQuestions: true,
        },
      });

    case LOADPENDINGPUBLICQUESTIONS_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingPendingPublicQuestions: false,
          loadedPendingPublicQuestions: true,
          pendingPublicQuestions: get(
            state,
            [action.encodedId, 'pendingPublicQuestions'],
            []
          ).concat(get(action, ['result', 'public_questions'], [])),
          morePendingPublicQuestions:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          pendingPublicQuestionsPagesLoaded:
            get(
              state,
              [action.encodedId, 'pendingPublicQuestionsPagesLoaded'],
              0
            ) + 1,
          totalPendingPublicQuestions: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
        },
      });

    case LOADPENDINGPUBLICQUESTIONS_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingPendingPublicQuestions: false,
          loadedPendingPublicQuestions: true,
        },
      });

    case LOADPUBLICQUESTIONS:
      return merge(state, {
        [action.encodedId]: {
          loadingPublicQuestions: true,
        },
      });

    case LOADPUBLICQUESTIONS_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingPublicQuestions: false,
          loadedPublicQuestions: true,
          publicQuestions: get(
            state,
            [action.encodedId, 'publicQuestions'],
            []
          ).concat(get(action, ['result', 'public_questions'], [])),
          morePublicQuestions:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          publicQuestionsPagesLoaded:
            get(state, [action.encodedId, 'publicQuestionsPagesLoaded'], 0) + 1,
          totalPublicQuestions: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
        },
      });

    case LOADPUBLICQUESTIONS_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingPublicQuestions: false,
          loadedPublicQuestions: true,
        },
      });

    case ADDVOUCHER:
      return merge(state, {
        [action.encodedId]: {
          addingVoucher: true,
        },
      });

    case ADDVOUCHER_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          addingVoucher: false,
        },
      });

    case ADDVOUCHER_FAIL:
      return merge(state, {
        [action.encodedId]: {
          addingVoucher: false,
        },
      });

    case UPDATEBRIEF:
      return merge(state, {
        [action.encodedId]: {
          updating: true,
        },
      });

    case UPDATEBRIEF_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          updating: false,
        },
      });

    case UPDATEBRIEF_FAIL:
      return merge(state, {
        [action.encodedId]: {
          updating: false,
        },
      });

    case ACCEPTPITCH:
      return merge(state, {
        [action.encodedId]: {
          acceptingPitch: action.pitchId,
        },
      });

    case ACCEPTPITCH_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          acceptingPitch: null,
          acceptedPitch: action.pitchId,
        },
      });

    case ACCEPTPITCH_FAIL:
      return merge(state, {
        [action.encodedId]: {
          acceptingPitch: null,
        },
      });

    case DECLINEPITCH:
      const existingDeclineList = get(
        state,
        [action.encodedId, 'decliningPitch'],
        []
      ).slice();
      existingDeclineList.push(action.pitchId);

      return merge(
        state,
        {
          [action.encodedId]: {
            decliningPitch: existingDeclineList,
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case DECLINEPITCH_SUCCESS:
      // remove from declining
      const newSuccessArray = get(
        state,
        [action.encodedId, 'decliningPitch'],
        []
      ).slice();
      newSuccessArray.splice(newSuccessArray.indexOf(action.pitchId), 1);

      // remove from pending
      // const newPendingArray = get(state, [action.encodedId, 'pendingPitches'], []).slice();
      // newPendingArray.splice(newPendingArray.indexOf(action.pitchId), 1);

      return merge(
        state,
        {
          [action.encodedId]: {
            decliningPitch: newSuccessArray,
            // pendingPitches: newPendingArray,
            pendingPitches: get(
              state,
              [action.encodedId, 'pendingPitches'],
              []
            ).filter((id) => id !== action.pitchId),
            likedPitches: get(
              state,
              [action.encodedId, 'likedPitches'],
              []
            ).filter((id) => id !== action.pitchId),
            shortlistedPitches: get(
              state,
              [action.encodedId, 'shortlistedPitches'],
              []
            ).filter((id) => id !== action.pitchId),
            totalPendingPitches:
              get(state, [action.encodedId, 'totalPendingPitches'], 0) === 0
                ? get(state, [action.encodedId, 'totalPendingPitches'], 0)
                : get(state, [action.encodedId, 'totalPendingPitches'], 0) - 1,
            nextPageToLoad:
              get(state, [action.encodedId, 'totalPendingPitches'], 0) === 0
                ? 1
                : Math.ceil(
                    (get(state, [action.encodedId, 'totalPendingPitches'], 0) -
                      1) /
                      DEFAULT_PENDING_PITCHES_TO_LOAD
                  ) -
                  get(
                    state,
                    [action.encodedId, 'pendingPitchesPagesLoaded'],
                    0
                  ),
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case DECLINEPITCH_FAIL:
      // remove from declining
      const newFailArray = get(
        state,
        [action.encodedId, 'decliningPitch'],
        []
      ).slice();
      newFailArray.splice(newFailArray.indexOf(action.pitchId), 1);

      return merge(
        state,
        {
          [action.encodedId]: {
            decliningPitch: newFailArray,
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case DECREMENTTOTALPENDINGQUESTIONS:
      return merge(state, {
        [action.encodedId]: {
          totalPendingPublicQuestions:
            get(state, [action.encodedId, 'totalPendingPublicQuestions'], 0) -
            1,
        },
      });

    case LOADINVITES:
      return merge(state, {
        [action.encodedId]: {
          loadingInvites: true,
        },
      });

    case LOADINVITES_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingInvites: false,
          loadedInvites: true,
          invites: get(state, [action.encodedId, 'invites'], []).concat(
            get(action, ['result', 'invites'], [])
          ),
          moreInvites:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          invitesPagesLoaded:
            get(state, [action.encodedId, 'invitesPagesLoaded'], 0) + 1,
          totalInvites: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
        },
      });

    case LOADINVITES_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingInvites: false,
          loadedInvites: true,
        },
      });
    case LOAD_SHORTLISTED_PITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadingShortlistedPitches: true,
        },
      });

    case LOAD_SHORTLISTED_PITCHES_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingShortlistedPitches: false,
          loadedShortlistedPitches: true,
          shortlistedPitches: union(
            get(state, [action.encodedId, 'shortlistedPitches'], []),
            get(action, ['result', 'messages'], [])
          ),
          moreShortlistedPitches:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          shortlistedPitchesPagesLoaded:
            get(state, [action.encodedId, 'shortlistedPitchesPagesLoaded'], 0) +
            1,
          totalShortlistedPitches: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
        },
      });

    case LOAD_SHORTLISTED_PITCHES_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingShortlistedPitches: false,
          loadedShortlistedPitches: true,
        },
      });
    case ADD_TO_SHORTLISTED_PITCHES:
      return merge(state, {
        [action.encodedId]: {
          shortlistedPitches: union(
            get(state, [action.encodedId, 'shortlistedPitches'], []),
            [action.pitchId]
          ),
        },
      });
    case RESET_LOADED_SHORTLISTED_PITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadedShortlistedPitches: false,
        },
      });
    case LOAD_LIKED_PITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadingLikedPitches: true,
        },
      });

    case LOAD_LIKED_PITCHES_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingLikedPitches: false,
          loadedLikedPitches: true,
          likedPitches: union(
            get(state, [action.encodedId, 'likedPitches'], []),
            get(action, ['result', 'messages'], [])
          ),
          moreLikedPitches:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          likedPitchesPagesLoaded:
            get(state, [action.encodedId, 'likedPitchesPagesLoaded'], 0) + 1,
          totalLikedPitches: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
        },
      });

    case LOAD_LIKED_PITCHES_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingLikedPitches: false,
          loadedLikedPitches: true,
        },
      });

    case ADD_TO_LIKED_PITCHES:
      return merge(
        state,
        {
          [action.encodedId]: {
            likedPitches: union(
              get(state, [action.encodedId, 'likedPitches'], []),
              [action.pitchId]
            ),
            pendingPitches: get(
              state,
              [action.encodedId, 'pendingPitches'],
              []
            ).filter((pitchId) => pitchId !== action.pitchId),
            totalPendingPitches:
              get(state, [action.encodedId, 'totalPendingPitches'], 0) === 0
                ? get(state, [action.encodedId, 'totalPendingPitches'], 0)
                : get(state, [action.encodedId, 'totalPendingPitches'], 0) - 1,
            nextPageToLoad:
              get(state, [action.encodedId, 'totalPendingPitches'], 0) === 0
                ? 1
                : Math.ceil(
                    (get(state, [action.encodedId, 'totalPendingPitches'], 0) -
                      1) /
                      DEFAULT_PENDING_PITCHES_TO_LOAD
                  ) -
                  get(
                    state,
                    [action.encodedId, 'pendingPitchesPagesLoaded'],
                    0
                  ),
          },
        },
        { arrayMerge: (_, source) => source }
      );

    case RESET_LOADED_LIKED_PITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadedLikedPitches: false,
        },
      });

    case SET_PINDER_TAB:
      return merge(state, {
        [action.encodedId]: {
          PinderTabIsNew: action.bool,
        },
      });

    case LOADMOREPENDINGPITCHES:
      return merge(state, {
        [action.encodedId]: {
          loadingMorePendingPitches: true,
        },
      });

    case LOADMOREPENDINGPITCHES_SUCCESS:
      return merge(state, {
        [action.encodedId]: {
          loadingMorePendingPitches: false,
          loadedMorePendingPitches: true,
          pendingPitches: union(
            get(state, [action.encodedId, 'pendingPitches'], []),
            get(action, ['result', 'messages'], [])
          ),
          morePendingPitches:
            get(action, ['result', 'meta', 'pagination', 'current_page'], 0) <
            get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          pendingPitchesPagesLoaded:
            get(state, [action.encodedId, 'pendingPitchesPagesLoaded'], 0) + 1,
          totalPendingPitches: get(
            action,
            ['result', 'meta', 'pagination', 'total'],
            0
          ),
          nextPageToLoad: get(
            action,
            ['result', 'meta', 'pagination', 'next_page'],
            0
          ),
        },
      });

    case LOADMOREPENDINGPITCHES_FAIL:
      return merge(state, {
        [action.encodedId]: {
          loadingMorePendingPitches: false,
          loadedMorePendingPitches: true,
        },
      });

    // case LIKE_PITCH_SUCCESS:
    //   return merge(state, {
    //     [action.encodedId]: {
    //       likedPitches: union(get(state, [action.encodedId, 'likedPitches'], []), [action.result]),
    //     },
    //   });

    default:
      return state;
  }
}

export function loadBrief(encodedId) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${encodedId}?include=user,user.avatars,invites.user.avatars`
      ),
    schema: briefSchema,
    types: [LOADBRIEF, LOADBRIEF_SUCCESS, LOADBRIEF_FAIL],
  };
}

export function loadSuggestedCreatives(encodedId, brief, limit = 20) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${encodedId}/suggested_users?include=avatars,covers&randomize=1&limit=${limit}`
      ),
    schema: { users: [userSchema] },
    types: [
      LOADSUGGESTEDCREATIVES,
      LOADSUGGESTEDCREATIVES_SUCCESS,
      LOADSUGGESTEDCREATIVES_FAIL,
    ],
  };
}

export function loadPendingPitches(
  encodedId,
  brief,
  page = 1,
  paginator = 100
) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${brief.id}/pitches?include=sender,sender.avatars,sender.skills,sender.tools,commission&accepted=0&completed=0&declined=0&liked=0&operator=and&order=client_order&page=${page}&paginator=${paginator}`
      ),
    schema: { messages: [messageSchema] },
    types: [
      LOADPENDINGPITCHES,
      LOADPENDINGPITCHES_SUCCESS,
      LOADPENDINGPITCHES_FAIL,
    ],
  };
}

export function loadMorePendingPitches(
  encodedId,
  brief,
  page = 1,
  paginator = 100
) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${brief.id}/pitches?include=sender,sender.avatars,sender.skills,sender.tools,commission&accepted=0&completed=0&declined=0&liked=0&operator=and&order=client_order&page=${page}&paginator=${paginator}`
      ),
    schema: { messages: [messageSchema] },
    types: [
      LOADMOREPENDINGPITCHES,
      LOADMOREPENDINGPITCHES_SUCCESS,
      LOADMOREPENDINGPITCHES_FAIL,
    ],
  };
}

export function removeSuggestedCreative(encodedId, userId) {
  return {
    encodedId,
    type: REMOVESUGGESTEDCREATIVE,
    userId,
  };
}

export function invite(encodedId, brief, user) {
  return {
    encodedId,
    promise: (client) =>
      client.post(`/notices/collaboration/${brief.id}/invite`, {
        data: { user_id: user.id },
      }),
    types: [INVITE, INVITE_SUCCESS, INVITE_FAIL],
    schema: inviteSchema,
    user,
  };
}

export function loadPendingPublicQuestions(encodedId, page) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${encodedId}/questions/public?page=${page}&pending=1`
      ),
    schema: { public_questions: [publicQuestionSchema] },
    types: [
      LOADPENDINGPUBLICQUESTIONS,
      LOADPENDINGPUBLICQUESTIONS_SUCCESS,
      LOADPENDINGPUBLICQUESTIONS_FAIL,
    ],
  };
}

export function loadPublicQuestions(encodedId, page) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${encodedId}/questions/public?page=${page}`
      ),
    schema: { public_questions: [publicQuestionSchema] },
    types: [
      LOADPUBLICQUESTIONS,
      LOADPUBLICQUESTIONS_SUCCESS,
      LOADPUBLICQUESTIONS_FAIL,
    ],
  };
}

export function addVoucher(encodedId, brief, voucherCode) {
  return {
    encodedId,
    promise: (client) =>
      client.patch(`/notices/collaboration/${brief.id}?include=voucher`, {
        data: { voucher: voucherCode },
      }),
    schema: briefSchema,
    types: [ADDVOUCHER, ADDVOUCHER_SUCCESS, ADDVOUCHER_FAIL],
  };
}

export function updateBrief(encodedId, brief, fields) {
  return {
    encodedId,
    promise: (client) =>
      client.patch(`/notices/collaboration/${brief.id}`, { data: fields }),
    schema: briefSchema,
    types: [UPDATEBRIEF, UPDATEBRIEF_SUCCESS, UPDATEBRIEF_FAIL],
  };
}

export function acceptPitch(encodedId, brief, pitch) {
  return {
    encodedId,
    pitchId: pitch.id,
    promise: (client) =>
      client.put(
        `/notices/collaboration/${brief.id}/pitches/${pitch.id}/accept`
      ),
    schema: messageSchema,
    types: [ACCEPTPITCH, ACCEPTPITCH_SUCCESS, ACCEPTPITCH_FAIL],
  };
}

export function declinePitch(encodedId, brief, pitch) {
  return {
    encodedId,
    pitchId: pitch.id,
    promise: (client) =>
      client.put(
        `/notices/collaboration/${brief.id}/pitches/${pitch.id}/decline`
      ),
    schema: messageSchema,
    types: [DECLINEPITCH, DECLINEPITCH_SUCCESS, DECLINEPITCH_FAIL],
  };
}

export function decrementTotalPendingQuestions(encodedId) {
  return {
    encodedId,
    type: DECREMENTTOTALPENDINGQUESTIONS,
  };
}

export function loadInvites(encodedId, brief, page = 1) {
  return {
    encodedId,
    promise: (client) =>
      client.get(
        `/notices/collaboration/${brief.id}/invite?page=${page}&include=user`
      ),
    schema: { invites: [inviteSchema] },
    types: [LOADINVITES, LOADINVITES_SUCCESS, LOADINVITES_FAIL],
  };
}

export const loadShortlistedPitches = (encodedId, brief, page = 1) => ({
  encodedId,
  promise: (client) =>
    client.get(
      `/notices/collaboration/${brief.id}/pitches/shortlisted?shortlisted?accepted=0&completed=0&declined=0&operator=and&page=${page}&include=sender,sender.avatars,sender.skills,sender.tools,commission`
    ),
  schema: { messages: [messageSchema] },
  types: [
    LOAD_SHORTLISTED_PITCHES,
    LOAD_SHORTLISTED_PITCHES_SUCCESS,
    LOAD_SHORTLISTED_PITCHES_FAIL,
  ],
});

export const addToShortlistedPitches = (encodedId, pitchId) => ({
  type: ADD_TO_SHORTLISTED_PITCHES,
  encodedId,
  pitchId,
});

export const resetLoadedShortlistedPitches = (encodedId) => ({
  type: RESET_LOADED_SHORTLISTED_PITCHES,
  encodedId,
});

export const loadLikedPitches = (encodedId, brief, page = 1) => ({
  encodedId,
  promise: (client) =>
    client.get(
      `/notices/collaboration/${brief.id}/pitches?accepted=0&completed=0&declined=0&liked=1&operator=and&page=${page}&include=sender,sender.avatars,sender.skills,sender.tools,commission`
    ),
  schema: { messages: [messageSchema] },
  types: [
    LOAD_LIKED_PITCHES,
    LOAD_LIKED_PITCHES_SUCCESS,
    LOAD_LIKED_PITCHES_FAIL,
  ],
});

export const likePitch = (encodedId, brief, pitchId) => ({
  types: [LIKE_PITCH, LIKE_PITCH_SUCCESS, LIKE_PITCH_FAIL],
  promise: (client) =>
    client.put(`/notices/collaboration/${brief.id}/pitches/${pitchId}/like`),
  encodedId,
});

export const addToLikedPitches = (encodedId, pitchId) => ({
  type: ADD_TO_LIKED_PITCHES,
  encodedId,
  pitchId,
});

export const resetLoadedLikedPitches = (encodedId) => ({
  type: RESET_LOADED_LIKED_PITCHES,
  encodedId,
});

export const resetLoadedPendingPitches = (encodedId) => ({
  type: RESET_LOADED_PENDING_PITCHES,
  encodedId,
});

export const setPinderTab = (encodedId, bool) => ({
  type: SET_PINDER_TAB,
  encodedId,
  bool: bool,
});
