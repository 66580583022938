const LOADSOCIAL = 'twineApp/onboarding/LOADSOCIAL';
const LOADSOCIAL_SUCCESS = 'twineApp/onboarding/LOADSOCIAL_SUCCESS';
const LOADSOCIAL_FAIL = 'twineApp/onboarding/LOADSOCIAL_FAIL';

const initialState = {
  social: null,
  loadingSocial: false,
  loadedSocial: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADSOCIAL:
      return {
        ...state,
        loadingSocial: true,
      };

    case LOADSOCIAL_SUCCESS:
      return {
        ...state,
        loadingSocial: false,
        loadedSocial: true,
        social: action.result,
      };

    case LOADSOCIAL_FAIL:
      return {
        ...state,
        loadingSocial: false,
      };

    default:
      return state;
  }
}

export function loadSocial(service) {
  return {
    types: [LOADSOCIAL, LOADSOCIAL_SUCCESS, LOADSOCIAL_FAIL],
    promise: (client) => client.get('/social/' + service),
    service: service,
  };
}
