import briefSchema from '../schemas/briefSchema';
import briefQuestionSchema from '../schemas/publicQuestionSchema';
import messageSchema from '../schemas/messageSchema';
import { jsonToFormData } from '../../helpers/HelperFunctions';
import moment from 'moment';

const LOADBRIEF = 'twineApp/briefDetails/LOADBRIEF';
const LOADBRIEF_SUCCESS = 'twineApp/briefDetails/LOADBRIEF_SUCCESS';
const LOADBRIEF_FAIL = 'twineApp/briefDetails/LOADBRIEF_FAIL';
const LOADCREATIVEPITCH = 'twineApp/briefDetails/LOADCREATIVEPITCH';
const LOADCREATIVEPITCH_SUCCESS =
  'twineApp/briefDetails/LOADCREATIVEPITCH_SUCCESS';
const LOADCREATIVEPITCH_FAIL = 'twineApp/briefDetails/LOADCREATIVEPITCH_FAIL';
const LOADPENDINGPITCHES = 'twineApp/briefDetails/LOADPENDINGPITCHES';
const LOADPENDINGPITCHES_SUCCESS =
  'twineApp/briefDetails/LOADPENDINGPITCHES_SUCCESS';
const LOADPENDINGPITCHES_FAIL = 'twineApp/briefDetails/LOADPENDINGPITCHES_FAIL';
const LOADACCEPTEDPITCH = 'twineApp/briefDetails/LOADACCEPTEDPITCH';
const LOADACCEPTEDPITCH_SUCCESS =
  'twineApp/briefDetails/LOADACCEPTEDPITCH_SUCCESS';
const LOADACCEPTEDPITCH_FAIL = 'twineApp/briefDetails/LOADACCEPTEDPITCH_FAIL';
const SENDPITCH = 'twineApp/briefDetails/SENDPITCH';
const SENDPITCH_SUCCESS = 'twineApp/briefDetails/SENDPITCH_SUCCESS';
const SENDPITCH_FAIL = 'twineApp/briefDetails/SENDPITCH_FAIL';
const UPDATEPITCH = 'twineApp/briefDetails/UPDATEPITCH';
const UPDATEPITCH_SUCCESS = 'twineApp/briefDetails/UPDATEPITCH_SUCCESS';
const UPDATEPITCH_FAIL = 'twineApp/briefDetails/UPDATEPITCH_FAIL';
const DELETEBRIEF = 'twineApp/briefDetails/DELETEBRIEF';
const DELETEBRIEF_SUCCESS = 'twineApp/briefDetails/DELETEBRIEF_SUCCESS';
const DELETEBRIEF_FAIL = 'twineApp/briefDetails/DELETEBRIEF_FAIL';
const ACCEPTPITCH = 'twineApp/briefDetails/ACCEPTPITCH';
const ACCEPTPITCH_SUCCESS = 'twineApp/briefDetails/ACCEPTPITCH_SUCCESS';
const ACCEPTPITCH_FAIL = 'twineApp/briefDetails/ACCEPTPITCH_FAIL';
const DECLINEPITCH = 'twineApp/briefDetails/DECLINEPITCH';
const DECLINEPITCH_SUCCESS = 'twineApp/briefDetails/DECLINEPITCH_SUCCESS';
const DECLINEPITCH_FAIL = 'twineApp/briefDetails/DECLINEPITCH_FAIL';
const UPDATEBRIEF = 'twineApp/briefDetails/UPDATEBRIEF';
const UPDATEBRIEF_SUCCESS = 'twineApp/briefDetails/UPDATEBRIEF_SUCCESS';
const UPDATEBRIEF_FAIL = 'twineApp/briefDetails/UPDATEBRIEF_FAIL';
const SENDREPORT = 'twineApp/briefDetails/SENDREPORT';
const SENDREPORT_SUCCESS = 'twineApp/briefDetails/SENDREPORT_SUCCESS';
const SENDREPORT_FAIL = 'twineApp/briefDetails/SENDREPORT_FAIL';
const SUGGESTEDCREATIVES = 'twineApp/briefDetails/SUGGESTEDCREATIVES';
const SUGGESTEDCREATIVES_SUCCESS =
  'twineApp/briefDetails/SUGGESTEDCREATIVES_SUCCESS';
const SUGGESTEDCREATIVES_FAIL = 'twineApp/briefDetails/SUGGESTEDCREATIVES_FAIL';
const INVITEUSER = 'twineApp/briefDetails/INVITEUSER';
const INVITEUSER_SUCCESS = 'twineApp/briefDetails/INVITEUSER_SUCCESS';
const INVITEUSER_FAIL = 'twineApp/briefDetails/INVITEUSER_FAIL';
const REMOVE_SUGGESTED = 'twineApp/briefDetails/REMOVE_SUGGESTED';
const LOADQUESTIONS = 'twineApp/briefDetails/LOADQUESTIONS';
const LOADQUESTIONS_SUCCESS = 'twineApp/briefDetails/LOADQUESTIONS_SUCCESS';
const LOADQUESTIONS_FAIL = 'twineApp/briefDetails/LOADQUESTIONS_FAIL';
const LOADOWNQUESTIONS = 'twineApp/briefDetails/LOADOWNQUESTIONS';
const LOADOWNQUESTIONS_SUCCESS =
  'twineApp/briefDetails/LOADOWNQUESTIONS_SUCCESS';
const LOADOWNQUESTIONS_FAIL = 'twineApp/briefDetails/LOADOWNQUESTIONS_FAIL';
const UPDATEQUESTIONTEXT = 'twineApp/briefDetails/UPDATEQUESTIONTEXT';
const POSTQUESTION = 'twineApp/briefDetails/POSTQUESTION';
const POSTQUESTION_SUCCESS = 'twineApp/briefDetails/POSTQUESTION_SUCCESS';
const POSTQUESTION_FAIL = 'twineApp/briefDetails/POSTQUESTION_FAIL';
const ANSWERQUESTION = 'twineApp/briefDetails/ANSWERQUESTION';
const ANSWERQUESTION_SUCCESS = 'twineApp/briefDetails/ANSWERQUESTION_SUCCESS';
const ANSWERQUESTION_FAIL = 'twineApp/briefDetails/ANSWERQUESTION_FAIL';
const UPDATEANSWERTEXT = 'twineApp/briefDetails/UPDATEANSWERTEXT';
const LOADQUESTION = 'twineApp/briefDetails/LOADQUESTION';
const LOADQUESTION_SUCCESS = 'twineApp/briefDetails/LOADQUESTION_SUCCESS';
const LOADQUESTION_FAIL = 'twineApp/briefDetails/LOADQUESTION_FAIL';
const LOADEXPIREDPITCHES = 'twineApp/briefDetails/LOADEXPIREDPITCHES';
const LOADEXPIREDPITCHES_SUCCESS =
  'twineApp/briefDetails/LOADEXPIREDPITCHES_SUCCESS';
const LOADEXPIREDPITCHES_FAIL = 'twineApp/briefDetails/LOADEXPIREDPITCHES_FAIL';
const PAYMENTFORPITCH = 'twineApp/briefDetails/PAYMENTFORPITCH';
const PAYMENTFORPITCH_SUCCESS = 'twineApp/briefDetails/PAYMENTFORPITCH_SUCCESS';
const PAYMENTFORPITCH_FAIL = 'twineApp/briefDetails/PAYMENTFORPITCH_FAIL';
const PAYPALCONNECT = 'twineApp/briefDetails/PAYPALCONNECT';
const PAYPALCONNECT_SUCCESS = 'twineApp/briefDetails/PAYPALCONNECT_SUCCESS';
const PAYPALCONNECT_FAIL = 'twineApp/briefDetails/PAYPALCONNECT_FAIL';
const PAYMENTTOKEN = 'twineApp/briefDetails/PAYMENTTOKEN';
const PAYMENTTOKEN_SUCCESS = 'twineApp/briefDetails/PAYMENTTOKEN_SUCCESS';
const PAYMENTTOKEN_FAIL = 'twineApp/briefDetails/PAYMENTTOKEN_FAIL';
const GETPAYMENTS = 'twineApp/briefDetails/GETPAYMENT';
const GETPAYMENTS_SUCCESS = 'twineApp/briefDetails/GETPAYMENT_SUCCESS';
const GETPAYMENTS_FAIL = 'twineApp/briefDetails/GETPAYMENT_FAIL';
const ADDVOUCHER = 'twineApp/briefDetails/ADDVOUCHER';
const ADDVOUCHER_SUCCESS = 'twineApp/briefDetails/ADDVOUCHER_SUCCESS';
const ADDVOUCHER_FAIL = 'twineApp/briefDetails/ADDVOUCHER_FAIL';
const RESET_BRIEF = 'twineApp/briefDetails/RESET_BRIEF';

const initialState = {
  brief: null,
  loadingBrief: false,
  loadedBrief: false,
  creativePitch: null,
  loadingCreativePitch: false,
  loadedCreativePitch: false,
  ownerPendingPitches: [],
  loadingOwnerPendingPitches: false,
  loadedOwnerPendingPitches: false,
  loadMoreOwnerPendingPitches: false,
  pagesLoadedOwnerPendingPitches: 0,
  totalPendingPitches: -1,
  ownerAcceptedPitch: null,
  loadingOwnerAcceptedPitch: false,
  loadedOwnerAcceptedPitch: false,
  sendingPitch: false,
  updatingPitch: false,
  deletingBrief: false,
  acceptingPitch: false,
  decliningPitch: false,
  updatingBrief: false,
  suggestedCreatives: [],
  loadingSuggestedCreatives: true,
  loadedQuestions: false,
  loadingQuestions: false,
  questions: [],
  moreQuestions: false,
  questionPagesLoaded: 0,
  loadedOwnQuestions: false,
  loadingOwnQuestions: false,
  ownQuestions: [],
  moreOwnQuestions: false,
  ownQuestionPagesLoaded: 0,
  postingQuestion: false,
  questionText: '',
  updatedAnswerText: {},
  loadingIndividualQuestion: false,
  loadedExpiredPitches: false,
  loadingExpiredPitches: false,
  expiredPitches: [],
  loadingPayment: false,
  loadedPayment: false,
  loadingPaymentToken: false,
  loadedPaymentToken: false,
  paymentToken: '',
  loadingGetPayments: false,
  loadedGetPayments: false,
  previousVendor: '',
  addingVoucher: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADBRIEF:
      return {
        ...state,
        loadingBrief: true,
      };

    case LOADBRIEF_SUCCESS:
      return {
        ...state,
        loadingBrief: false,
        loadedBrief: true,
        brief: action.result,
      };

    case LOADBRIEF_FAIL:
      return {
        ...state,
        loadingBrief: false,
        loadedBrief: true,
      };

    case LOADCREATIVEPITCH:
      return {
        ...state,
        loadingCreativePitch: true,
      };

    case LOADCREATIVEPITCH_SUCCESS:
      return {
        ...state,
        loadingCreativePitch: false,
        loadedCreativePitch: true,
        creativePitch:
          action.result.threads && action.result.threads.length > 0
            ? action.result.threads[0].message
            : null,
      };

    case LOADCREATIVEPITCH_FAIL:
      return {
        ...state,
        loadingCreativePitch: false,
      };

    case LOADPENDINGPITCHES:
      return {
        ...state,
        loadingOwnerPendingPitches: true,
      };

    case LOADPENDINGPITCHES_SUCCESS:
      return {
        ...state,
        loadingOwnerPendingPitches: false,
        loadedOwnerPendingPitches: true,
        loadMoreOwnerPendingPitches:
          action.result.constructor === Array && action.result.length === 0
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        pagesLoadedOwnerPendingPitches:
          state.pagesLoadedOwnerPendingPitches + 1,
        ownerPendingPitches:
          action.result.constructor === Array && action.result.length === 0
            ? state.ownerPendingPitches
            : state.ownerPendingPitches.concat(action.result.messages),
        totalPendingPitches:
          action.result.constructor === Array && action.result.length === 0
            ? 0
            : action.result.meta.pagination.total,
      };

    case LOADPENDINGPITCHES_FAIL:
      return {
        ...state,
        loadingOwnerPendingPitches: false,
      };

    case LOADACCEPTEDPITCH:
      return {
        ...state,
        loadingOwnerAcceptedPitch: true,
      };

    case LOADACCEPTEDPITCH_SUCCESS:
      return {
        ...state,
        loadingOwnerAcceptedPitch: false,
        loadedOwnerAcceptedPitch: true,
        ownerAcceptedPitch:
          action.result.messages && action.result.messages.length > 0
            ? action.result.messages[0]
            : null,
      };

    case LOADACCEPTEDPITCH_FAIL:
      return {
        ...state,
        loadingOwnerAcceptedPitch: false,
      };

    case SENDPITCH:
      return {
        ...state,
        sendingPitch: true,
      };

    case SENDPITCH_SUCCESS:
      return {
        ...state,
        sendingPitch: false,
        creativePitch: action.result.message,
        loadedCreativePitch: true,
      };

    case SENDPITCH_FAIL:
      return {
        ...state,
        sendingPitch: false,
      };

    case UPDATEPITCH:
      return {
        ...state,
        updatingPitch: true,
      };

    case UPDATEPITCH_SUCCESS:
      return {
        ...state,
        updatingPitch: false,
        creativePitch: action.result,
        loadedCreativePitch: true,
      };

    case UPDATEPITCH_FAIL:
      return {
        ...state,
        updatingPitch: false,
      };

    case DELETEBRIEF:
      return {
        ...state,
        deletingBrief: true,
      };

    case DELETEBRIEF_SUCCESS:
      return {
        ...state,
        deletingBrief: false,
      };

    case DELETEBRIEF_FAIL:
      return {
        ...state,
        deletingBrief: false,
      };

    case ACCEPTPITCH:
      const newlyAcceptedPitch = action.pitch;
      newlyAcceptedPitch.accepted_at = moment().format('YYYY-MM-DDTHH:mm:ssZZ');

      // if paid and has a voucher attached and less than 0, mark as paid
      if (action.brief.budget && action.brief.budget > 0 && action.voucher) {
        // if less than 0 after voucher
        if (
          (action.pitch.amount > 0 &&
            action.pitch.amount - action.voucher.amount < 0) ||
          (action.pitch.amount === 0 &&
            action.brief.budget - action.voucher.amount < 0)
        ) {
          newlyAcceptedPitch.paid_at = moment().format('YYYY-MM-DDTHH:mm:ssZZ');
        }
      }

      return {
        ...state,
        acceptingPitch: true,
        ownerAcceptedPitch: newlyAcceptedPitch,
        ownerPendingPitches: [],
      };

    case ACCEPTPITCH_SUCCESS:
      return {
        ...state,
        acceptingPitch: false,
      };

    case ACCEPTPITCH_FAIL:
      return {
        ...state,
        acceptingPitch: false,
      };

    case DECLINEPITCH:
      return {
        ...state,
        decliningPitch: true,
        ownerPendingPitches: state.ownerPendingPitches.filter(
          (pitch) => pitch.id !== action.pitch.id
        ),
      };

    case DECLINEPITCH_SUCCESS:
      return {
        ...state,
        decliningPitch: false,
      };

    case DECLINEPITCH_FAIL:
      return {
        ...state,
        decliningPitch: false,
      };

    case UPDATEBRIEF:
      return {
        ...state,
        updatingBrief: true,
      };

    case UPDATEBRIEF_SUCCESS:
      return {
        ...state,
        updatingBrief: false,
      };

    case UPDATEBRIEF_FAIL:
      return {
        ...state,
        updatingBrief: false,
      };

    case SUGGESTEDCREATIVES:
      return {
        ...state,
        loadingSuggestedCreatives: true,
      };

    case SUGGESTEDCREATIVES_SUCCESS:
      return {
        ...state,
        loadingSuggestedCreatives: false,
        suggestedCreatives:
          typeof action.result.users !== 'undefined'
            ? action.result.users.filter((user) => user.id !== action.userId)
            : [],
      };

    case SUGGESTEDCREATIVES_FAIL:
      return {
        ...state,
        loadingSuggestedCreatives: false,
      };

    case REMOVE_SUGGESTED:
      return {
        ...state,
        suggestedCreatives: state.suggestedCreatives.filter(
          (creative) => creative.id !== action.id
        ),
      };

    case INVITEUSER:
      return {
        ...state,
      };

    case INVITEUSER_SUCCESS:
      return {
        ...state,
      };

    case INVITEUSER_FAIL:
      return {
        ...state,
      };

    case LOADQUESTIONS:
      return {
        ...state,
        loadingQuestions: true,
      };

    case LOADQUESTIONS_SUCCESS:
      return {
        ...state,
        loadingQuestions: false,
        loadedQuestions: true,
        questionPagesLoaded: state.questionPagesLoaded + 1,
        questions:
          typeof action.result.public_questions !== 'undefined'
            ? state.questions.concat(action.result.public_questions)
            : state.questions,
        moreQuestions:
          typeof action.result.meta !== 'undefined'
            ? action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages
            : false,
      };

    case LOADQUESTIONS_FAIL:
      return {
        ...state,
        loadingQuestions: false,
      };

    case LOADOWNQUESTIONS:
      return {
        ...state,
        loadingOwnQuestions: true,
      };

    case LOADOWNQUESTIONS_SUCCESS:
      return {
        ...state,
        loadingOwnQuestions: false,
        loadedOwnQuestions: true,
        ownQuestionPagesLoaded: state.ownQuestionPagesLoaded + 1,
        ownQuestions:
          typeof action.result.public_questions !== 'undefined'
            ? state.ownQuestions.concat(action.result.public_questions)
            : state.ownQuestions,
        moreOwnQuestions:
          typeof action.result.meta !== 'undefined'
            ? action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages
            : false,
      };

    case LOADOWNQUESTIONS_FAIL:
      return {
        ...state,
        loadingOwnQuestions: false,
      };

    case UPDATEQUESTIONTEXT:
      return {
        ...state,
        questionText: action.text,
      };

    case POSTQUESTION:
      return {
        ...state,
        postingQuestion: true,
      };

    case POSTQUESTION_SUCCESS:
      return {
        ...state,
        postingQuestion: false,
        questionText: '',
        questions: state.questions.concat(action.result),
      };

    case POSTQUESTION_FAIL:
      return {
        ...state,
        postingQuestion: false,
      };

    case UPDATEANSWERTEXT:
      return {
        ...state,
        updatedAnswerText: {
          [action.questionId]: action.answer,
        },
      };

    case LOADQUESTION:
      return {
        ...state,
        loadingIndividualQuestion: true,
      };

    case LOADQUESTION_SUCCESS:
      return {
        ...state,
        loadingIndividualQuestion: false,
      };

    case LOADQUESTION_FAIL:
      return {
        ...state,
        loadingIndividualQuestion: false,
      };

    case LOADEXPIREDPITCHES:
      return {
        ...state,
        loadingExpiredPitches: true,
      };

    case LOADEXPIREDPITCHES_SUCCESS:
      return {
        ...state,
        loadingExpiredPitches: false,
        loadedExpiredPitches: true,
        expiredPitches:
          action.result.constructor === Array && action.result.length === 0
            ? state.expiredPitches
            : state.expiredPitches.concat(action.result.messages),
      };

    case LOADEXPIREDPITCHES_FAIL:
      return {
        ...state,
        loadingExpiredPitches: false,
      };

    case PAYMENTFORPITCH:
      return {
        ...state,
        loadingPayment: true,
        loadedPayment: false,
      };

    case PAYMENTFORPITCH_SUCCESS:
      return {
        ...state,
        loadingPayment: false,
        loadedPayment: true,
        ownerPendingPitches: [],
      };

    case PAYMENTFORPITCH_FAIL:
      return {
        ...state,
        loadingPayment: false,
        loadedPayment: false,
      };

    case PAYPALCONNECT:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: false,
        paymentToken: '',
      };

    case PAYPALCONNECT_SUCCESS:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: false,
        paymentToken: '',
      };

    case PAYPALCONNECT_FAIL:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: false,
        paymentToken: '',
      };

    case PAYMENTTOKEN:
      return {
        ...state,
        loadingPaymentToken: true,
        loadedPaymentToken: false,
        paymentToken: '',
      };

    case PAYMENTTOKEN_SUCCESS:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: true,
        paymentToken: action.result.token,
      };

    case PAYMENTTOKEN_FAIL:
      return {
        ...state,
        loadingPaymentToken: false,
        loadedPaymentToken: true,
      };

    case GETPAYMENTS:
      return {
        ...state,
        loadingGetPayments: true,
      };

    case GETPAYMENTS_SUCCESS:
      return {
        ...state,
        loadingGetPayments: false,
        loadedGetPayments: true,
        previousVendor:
          action.result.payments[0].from_vendor === 'paypal' ||
          action.result.payments[0].from_vendor === 'paypal_via_braintree' ||
          action.result.payments[0].from_vendor === 'braintree'
            ? 'braintree'
            : 'stripe',
      };

    case GETPAYMENTS_FAIL:
      return {
        ...state,
        loadingGetPayments: false,
        loadedGetPayments: true,
      };

    case ADDVOUCHER:
      return {
        ...state,
        addingVoucher: true,
      };

    case ADDVOUCHER_SUCCESS:
      return {
        ...state,
        addingVoucher: false,
      };

    case ADDVOUCHER_FAIL:
      return {
        ...state,
        addingVoucher: false,
      };
    case RESET_BRIEF:
      return initialState;

    default:
      return state;
  }
}

export function isBriefLoaded(globalState) {
  return globalState.briefDetails && globalState.briefDetails.loadedBrief;
}

export function loadBrief(briefId) {
  return {
    types: [LOADBRIEF, LOADBRIEF_SUCCESS, LOADBRIEF_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' +
          briefId +
          '?include=user,user.avatars,user.covers,rating,voucher,invites'
      ),
    schema: briefSchema,
  };
}

export function loadCreativePitch(userId, briefId) {
  return {
    types: [
      LOADCREATIVEPITCH,
      LOADCREATIVEPITCH_SUCCESS,
      LOADCREATIVEPITCH_FAIL,
    ],
    promise: (client) => client.get('/users/' + userId + '/pitches/' + briefId),
  };
}

export function loadPendingPitches(briefId, page = 1) {
  return {
    types: [
      LOADPENDINGPITCHES,
      LOADPENDINGPITCHES_SUCCESS,
      LOADPENDINGPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' +
          briefId +
          '/pitches?include=sender,sender.avatars&accepted=0&completed=0&declined=0&expired=0&operator=and&page=' +
          page
      ),
  };
}

export function loadAcceptedPitch(briefId) {
  return {
    types: [
      LOADACCEPTEDPITCH,
      LOADACCEPTEDPITCH_SUCCESS,
      LOADACCEPTEDPITCH_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' +
          briefId +
          '/pitches?accepted=1&declined=0&operator=and&include=sender,sender.avatars'
      ),
  };
}

export function loadExpiredPitches(briefId, page = 1) {
  return {
    types: [
      LOADEXPIREDPITCHES,
      LOADEXPIREDPITCHES_SUCCESS,
      LOADEXPIREDPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' +
          briefId +
          '/pitches?include=sender,sender.avatars&accepted=0&completed=0&declined=0&expired=1&operator=and&page=' +
          page
      ),
  };
}

export function sendPitch(
  briefId,
  text,
  relevantProjects,
  amount,
  deposit,
  days = 0,
  availability = null,
  dayRateCurreny,
  dayRate
) {
  const data = {
    message: text,
    notice_id: briefId,
    amount: amount * 100,
    related_projects: relevantProjects,
    available_at: availability ? availability : '',
    day_rate: dayRate,
    day_rate_currency: dayRateCurreny,
  };

  if (days > 0) {
    data.days_to_complete = days;
  }

  return {
    types: [SENDPITCH, SENDPITCH_SUCCESS, SENDPITCH_FAIL],
    promise: (client) =>
      client.post('/messages?include=message.notice', { data }),
  };
}

export function updatePitch(
  pitchId,
  amount = null,
  deposit = null,
  days = null,
  message = null
) {
  const data = {};

  data.amount = amount * 100;

  if (deposit !== null) {
    data.deposit = deposit;
  }

  if (days !== null) {
    data.days_to_complete = days;
  }

  if (message !== null) {
    data.message = message;
  }

  return {
    types: [UPDATEPITCH, UPDATEPITCH_SUCCESS, UPDATEPITCH_FAIL],
    promise: (client) =>
      client.patch('/messages/' + pitchId + '?include=notice', { data }),
  };
}

export function deleteBrief(briefId, reason = null) {
  return {
    types: [DELETEBRIEF, DELETEBRIEF_SUCCESS, DELETEBRIEF_FAIL],
    promise: (client) =>
      client.post('/notices/collaboration/' + briefId, {
        data: jsonToFormData({
          _method: 'DELETE',
          reason: reason,
        }),
      }),
  };
}

export function acceptPitch(brief, pitch, voucher = null) {
  return {
    types: [ACCEPTPITCH, ACCEPTPITCH_SUCCESS, ACCEPTPITCH_FAIL],
    promise: (client) =>
      client.put(
        '/notices/collaboration/' +
          brief.id +
          '/pitches/' +
          pitch.id +
          '/accept'
      ),
    brief: brief,
    pitch: pitch,
    voucher: voucher,
    schema: briefSchema,
  };
}

export function declinePitch(briefId, pitch) {
  return {
    types: [DECLINEPITCH, DECLINEPITCH_SUCCESS, DECLINEPITCH_FAIL],
    promise: (client) =>
      client.put(
        '/notices/collaboration/' +
          briefId +
          '/pitches/' +
          pitch.id +
          '/decline'
      ),
    pitch: pitch,
  };
}

export function updateBrief(briefId, fields) {
  return {
    types: [UPDATEBRIEF, UPDATEBRIEF_SUCCESS, UPDATEBRIEF_FAIL],
    promise: (client) =>
      client.post(
        '/notices/collaboration/' +
          briefId +
          '?include=user,user.avatars,user.covers,rating',
        { data: jsonToFormData({ ...fields, _method: 'PATCH' }) }
      ),
    schema: briefSchema,
  };
}

export function sendReport(userId, reason, comment) {
  return {
    types: [SENDREPORT, SENDREPORT_SUCCESS, SENDREPORT_FAIL],
    promise: (client) =>
      client.post('/users/' + userId + '/report', {
        data: { reason, comment, _method: 'PUT' },
      }),
  };
}

export function getSuggestedCreatives(role = '', skill = '', userId = 0) {
  let url = '/users/location/';

  if (role !== '') {
    url += encodeURIComponent(role);
  }

  if (skill !== '') {
    url += encodeURIComponent(skill);
  }

  url += '?include=avatars,covers';

  return {
    types: [
      SUGGESTEDCREATIVES,
      SUGGESTEDCREATIVES_SUCCESS,
      SUGGESTEDCREATIVES_FAIL,
    ],
    promise: (client) => client.get(url),
    userId: userId,
  };
}

export function removeSuggested(id) {
  return {
    type: REMOVE_SUGGESTED,
    id,
  };
}

export function inviteUser(fromUser, toUserId, brief) {
  return {
    types: [INVITEUSER, INVITEUSER_SUCCESS, INVITEUSER_FAIL],
    promise: (client) =>
      client.post('/notices/collaboration/' + brief.id + '/invite', {
        data: jsonToFormData({ user_id: toUserId }),
      }),
  };
}

export function loadQuestions(briefId, pending = null, page = 1) {
  let url =
    '/notices/collaboration/' +
    briefId +
    '/questions/public?limit=5&page=' +
    page;
  if (pending !== null) {
    url += '&pending=' + (pending ? '1' : '0');
  }

  return {
    types: [LOADQUESTIONS, LOADQUESTIONS_SUCCESS, LOADQUESTIONS_FAIL],
    promise: (client) => client.get(url),
    schema: { public_questions: [briefQuestionSchema] },
  };
}

export function loadOwnQuestions(
  userId,
  briefId = null,
  pending = null,
  page = 1
) {
  let url = 'users/' + userId + '/notices/collaboration';

  if (briefId !== null) {
    url += '/' + briefId;
  }

  url += '/questions/public?limit=10&page=' + page;

  if (pending !== null) {
    url += '&pending=' + (pending ? '1' : '0');
  }

  return {
    types: [LOADOWNQUESTIONS, LOADOWNQUESTIONS_SUCCESS, LOADOWNQUESTIONS_FAIL],
    promise: (client) => client.get(url),
    schema: { public_questions: [briefQuestionSchema] },
  };
}

export function loadQuestion(briefId, questionId) {
  return {
    types: [LOADQUESTION, LOADQUESTION_SUCCESS, LOADQUESTION_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/' + briefId + '/questions/public/' + questionId
      ),
    schema: briefQuestionSchema,
  };
}

export function updateQuestionText(text) {
  return {
    type: UPDATEQUESTIONTEXT,
    text,
  };
}

export function postQuestion(briefId, question) {
  return {
    types: [POSTQUESTION, POSTQUESTION_SUCCESS, POSTQUESTION_FAIL],
    promise: (client) =>
      client.post('/notices/collaboration/' + briefId + '/questions/public', {
        data: jsonToFormData({
          question,
        }),
      }),
    schema: briefQuestionSchema,
  };
}

export function updateAnswerText(questionId, answer) {
  return {
    type: UPDATEANSWERTEXT,
    questionId,
    answer,
  };
}

export function answerQuestion(briefId, questionId, answer) {
  return {
    types: [ANSWERQUESTION, ANSWERQUESTION_SUCCESS, ANSWERQUESTION_FAIL],
    promise: (client) =>
      client.post(
        '/notices/collaboration/' + briefId + '/questions/public/' + questionId,
        {
          data: jsonToFormData({
            answer,
            _method: 'PATCH',
          }),
        }
      ),
    schema: briefQuestionSchema,
  };
}

export function payForPitch(user, nonce, pitch, type = 'transfer') {
  const data = {
    braintreeNonce: nonce,
    vendor: 'braintree',
    type: type,
    for: 'collaboration',
    pitch_id: pitch.id,
  };

  return {
    types: [PAYMENTFORPITCH, PAYMENTFORPITCH_SUCCESS, PAYMENTFORPITCH_FAIL],
    promise: (client) =>
      client.post('/users/' + user.id + '/payments?include=for', {
        data: jsonToFormData(data),
      }),
    schema: { for: messageSchema },
    user,
    pitch,
    type,
  };
}

export function connectPayPal(user, nonce) {
  const data = {
    braintreeNonce: nonce,
  };

  return {
    types: [PAYPALCONNECT, PAYPALCONNECT_SUCCESS, PAYPALCONNECT_FAIL],
    promise: (client) =>
      client.post('/users/' + user.id + '/paypal', {
        data: jsonToFormData(data),
      }),
  };
}

export function getPaymentToken(data) {
  return {
    types: [PAYMENTTOKEN, PAYMENTTOKEN_SUCCESS, PAYMENTTOKEN_FAIL],
    promise: (client) =>
      client.post('/payments/token', { data: jsonToFormData(data) }),
  };
}

export function getPayments(pitchId) {
  return {
    types: [GETPAYMENTS, GETPAYMENTS_SUCCESS, GETPAYMENTS_FAIL],
    promise: (client) =>
      client.get('/payments?for_type=pitch&type=deposit&for_id=' + pitchId),
  };
}

export function addVoucher(briefId, voucher) {
  return {
    types: [ADDVOUCHER, ADDVOUCHER_SUCCESS, ADDVOUCHER_FAIL],
    promise: (client) =>
      client.post('/notices/collaboration/' + briefId + '?include=voucher', {
        data: jsonToFormData({ voucher, _method: 'PATCH' }),
      }),
    schema: briefSchema,
  };
}

export const resetBriefDetails = () => ({ type: RESET_BRIEF });
