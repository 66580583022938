const GETCITIES = 'twineapp/cities/GETCITIES';
const GETCITIES_SUCCESS = 'twineapp/cities/GETCITIES_SUCCESS';
const GETCITIES_FAIL = 'twineapp/cities/GETCITIES_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  cities: [{ city: '' }],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GETCITIES:
      return {
        ...state,
        loading: true,
      };

    case GETCITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cities: action.result.cities,
      };

    case GETCITIES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function getCitiesForCountry(country) {
  return {
    types: [GETCITIES, GETCITIES_SUCCESS, GETCITIES_FAIL],
    promise: (client) => client.get(`users/location/${country}/cities`),
  };
}
