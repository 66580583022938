import { jsonToFormData } from 'helpers/HelperFunctions';

const ADDCREDITS = 'twineApp/textProjects/ADDCREDITS';
const ADDCREDITS_SUCCESS = 'twineApp/textProjects/ADDCREDITS_SUCCESS';
const ADDCREDITS_FAIL = 'twineApp/textProjects/ADDCREDITS_FAIL';
const LOADCREDITS = 'twineApp/textProjects/LOADCREDITS';
const LOADCREDITS_SUCCESS = 'twineApp/textProjects/LOADCREDITS_SUCCESS';
const LOADCREDITS_FAIL = 'twineApp/textProjects/LOADCREDITS_FAIL';
const UPDATECREDITS = 'twineApp/textProjects/UPDATECREDITS';
const UPDATECREDITS_SUCCESS = 'twineApp/textProjects/UPDATECREDITS_SUCCESS';
const UPDATECREDITS_FAIL = 'twineApp/textProjects/UPDATECREDITS_FAIL';
const CREATEPROJECT = 'twineApp/textProjects/CREATEPROJECT';
const CREATEPROJECT_SUCCESS = 'twineApp/textProjects/CREATEPROJECT_SUCCESS';
const CREATEPROJECT_FAIL = 'twineApp/textProjects/CREATEPROJECT_FAIL';
const LOADPROJECT = 'twineApp/textProjects/LOADPROJECT';
const LOADPROJECT_SUCCESS = 'twineApp/textProjects/LOADPROJECT_SUCCESS';
const LOADPROJECT_FAIL = 'twineApp/textProjects/LOADPROJECT_FAIL';
const UPDATEPROJECT = 'twineApp/textProjects/UPDATEPROJECT';
const UPDATEPROJECT_SUCCESS = 'twineApp/textProjects/UPDATEPROJECT_SUCCESS';
const UPDATEPROJECT_FAIL = 'twineApp/textProjects/UPDATEPROJECT_FAIL';
const LOADUSERCREDITS = 'twineApp/textProjects/LOADUSERCREDITS';
const LOADUSERCREDITS_SUCCESS = 'twineApp/textProjects/LOADUSERCREDITS_SUCCESS';
const LOADUSERCREDITS_FAIL = 'twineApp/textProjects/LOADUSERCREDITS_FAIL';
const DELETEPROJECT = 'twineApp/portfolio/DELETEPROJECT';
const DELETEPROJECT_SUCCESS = 'twineApp/portfolio/DELETEPROJECT';
const DELETEPROJECT_FAIL = 'twineApp/portfolio/DELETEPROJECT';

const initialState = {
  project: {},
  credits: [],
  creatingProject: false,
  createdProject: false,
  loadingProject: false,
  loadedProject: false,
  updatingProject: false,
  updatedProject: false,
  creatingCredits: false,
  createdCredits: false,
  loadingCredits: false,
  loadedCredits: false,
  updatingCredits: false,
  updatedCredits: false,
  userCredits: [],
  loadingUserCredits: false,
  loadedUserCredits: false,
  moreToLoad: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADDCREDITS:
      return {
        ...state,
        creatingCredits: true,
      };

    case ADDCREDITS_SUCCESS:
      return {
        ...state,
        credits: action.result.credits,
        creatingCredits: false,
        createdCredits: true,
      };

    case ADDCREDITS_FAIL:
      return {
        ...state,
        creatingCredits: false,
        createdCredits: false,
        error: action.error,
      };

    case LOADCREDITS:
      return {
        ...state,
        loadingCredits: true,
      };

    case LOADCREDITS_SUCCESS:
      return {
        ...state,
        credits: action.result.credits,
        loadingCredits: false,
        loadedCredits: true,
      };

    case LOADCREDITS_FAIL:
      return {
        ...state,
        loadingCredits: false,
        loadedCredits: false,
        error: action.error,
      };

    case UPDATECREDITS:
      return {
        ...state,
        updatingCredits: true,
      };

    case UPDATECREDITS_SUCCESS:
      return {
        ...state,
        credits: action.result.credits,
        updatingCredits: false,
        updatedCredits: true,
      };

    case UPDATECREDITS_FAIL:
      return {
        ...state,
        error: action.error,
        updatingCredits: false,
        updatedCredits: false,
      };

    case CREATEPROJECT:
      return {
        ...state,
        creatingProject: true,
      };

    case CREATEPROJECT_SUCCESS:
      return {
        ...state,
        project: action.result,
        creatingProject: false,
        createdProject: true,
      };

    case CREATEPROJECT_FAIL:
      return {
        ...state,
        error: action.error,
        creatingProject: false,
        createdProject: false,
      };

    case LOADPROJECT:
      return {
        ...state,
        loadingProject: true,
      };

    case LOADPROJECT_SUCCESS:
      return {
        ...state,
        project: action.result,
        loadingProject: false,
        loadedProject: true,
      };

    case LOADPROJECT_FAIL:
      return {
        ...state,
        error: action.error,
        loadingProject: false,
        loadedProject: false,
      };

    case UPDATEPROJECT:
      return {
        ...state,
        updatingProject: true,
      };

    case UPDATEPROJECT_SUCCESS:
      return {
        ...state,
        project: action.result,
        updatingProject: false,
        updatedProject: true,
      };

    case UPDATEPROJECT_FAIL:
      return {
        ...state,
        error: action.error,
        updatingProject: false,
        updatedProject: false,
      };

    case LOADUSERCREDITS:
      return {
        ...state,
        loadingUserCredits: true,
      };

    case LOADUSERCREDITS_SUCCESS:
      return {
        ...state,
        userCredits:
          action.result.length > 0 || action.result.credits
            ? [...state.userCredits, ...action.result.credits]
            : [],
        loadingUserCredits: false,
        loadedUserCredits: true,
        moreToLoad: action.result.meta
          ? action.result.meta.pagination.current_page <
            action.result.meta.pagination.total_pages
          : false,
      };

    case LOADUSERCREDITS_FAIL:
      return {
        ...state,
        loadingUserCredits: false,
        loadedUserCredits: false,
        error: action.error,
      };

    case DELETEPROJECT:
      return { ...state };

    case DELETEPROJECT_SUCCESS:
      return { ...state };

    case DELETEPROJECT_FAIL:
      return { ...state, error: action.error };

    default:
      return state;
  }
}

export function loadProject(projectHash) {
  return {
    types: [LOADPROJECT, LOADPROJECT_SUCCESS, LOADPROJECT_FAIL],
    promise: (client) => client.get('/projects/' + projectHash),
  };
}

export function loadCredits(projectHash) {
  return {
    types: [LOADCREDITS, LOADCREDITS_SUCCESS, LOADCREDITS_FAIL],
    promise: (client) => client.get('/projects/' + projectHash + '/credits'),
  };
}

export function addCredits(projectId, user, roles) {
  return {
    types: [ADDCREDITS, ADDCREDITS_SUCCESS, ADDCREDITS_FAIL],
    promise: (client) =>
      client.post('/credits', {
        data: jsonToFormData({
          project_id: projectId,
          users: [user],
          roles: roles,
        }),
      }),
  };
}

export function updateCredits(projectId, user, roles) {
  return {
    types: [UPDATECREDITS, UPDATECREDITS_SUCCESS, UPDATECREDITS_FAIL],
    promise: (client) =>
      client.patch('/credits', {
        data: jsonToFormData({
          project_id: projectId,
          users: [user],
          roles: roles,
          _method: 'PATCH',
        }),
      }),
  };
}

export function createProject(data) {
  return {
    types: [CREATEPROJECT, CREATEPROJECT_SUCCESS, CREATEPROJECT_FAIL],
    promise: (client) =>
      client.post('/projects', {
        data,
      }),
  };
}

export function updateProject(id, data) {
  return {
    types: [UPDATEPROJECT, UPDATEPROJECT_SUCCESS, UPDATEPROJECT_FAIL],
    promise: (client) =>
      client.patch(`/projects/${id}`, {
        data,
      }),
  };
}

export function loadUserCredits(username, page = 1) {
  return {
    types: [LOADUSERCREDITS, LOADUSERCREDITS_SUCCESS, LOADUSERCREDITS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          `/credits?active=1&parents_only=1&include=project,project.thumbnails&limit=12&page=${page}`
      ),
  };
}

export function deleteProject(projectId) {
  return {
    types: [DELETEPROJECT, DELETEPROJECT_SUCCESS, DELETEPROJECT_FAIL],
    promise: (client) => client.delete('/projects/' + projectId),
  };
}
