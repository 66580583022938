const USERTYPING = 'twineApp/websockets/USER_TYPING';
const NEWMESSAGE = 'twineApp/websockets/NEW_MESSAGE';

const initialState = {
  // `usertyping` action
  userIsTyping: false,
  userIdTyping: -1,

  // `newmessage` action
  newMessageAvailable: false,
  newMessageSenderId: -1,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case USERTYPING:
      return {
        ...state,
        userIsTyping: action.isTyping,
        userIdTyping: action.senderId,
      };

    case NEWMESSAGE:
      return {
        ...state,
        newMessageAvailable: action.hasNewMessage,
        newMessageSenderId: action.senderId,
      };

    default:
      return state;
  }
}

export function setUserTyping(isTyping = false, senderId = -1) {
  return {
    type: USERTYPING,
    isTyping,
    senderId,
  };
}

export function setNewMessage(hasNewMessage = false, senderId = -1) {
  return {
    type: NEWMESSAGE,
    hasNewMessage,
    senderId,
  };
}
