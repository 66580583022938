import { asyncConnect } from 'redux-connect';
import { fromJS } from 'immutable';
import { loadStats } from 'redux/modules/stats';
import {
  isBlogRssLoaded,
  isRolesLoaded,
  isCountriesLoaded,
  isUsersLoaded,
  loadBlogRss,
  loadCountries,
  loadRoles,
  loadSkills,
  loadUsers,
  setPortfolioCarousel,
} from 'redux/modules/landingPages';

import loadable from '@loadable/component';

import { inviteUser } from 'redux/modules/briefDetails';
import { setSuccessMessage } from 'redux/modules/successMessage';

const FindBase = loadable(() => import('./FindBase'));

const Find = asyncConnect(
  [
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { role, country, city, skill },
        },
      }) => {
        const promises = [];
        const store = getState();

        if (city && !isRolesLoaded(store, role, country)) {
          promises.push(dispatch(loadRoles(role, country, city)));
        }

        if (!city && !isCountriesLoaded(store, role)) {
          promises.push(dispatch(loadCountries(role)));
        }
        if (!isUsersLoaded(store, role, country, city, skill)) {
          promises.push(dispatch(loadUsers(role, country, city, skill)));
        }

        if (!store.stats.loadedStats) {
          promises.push(dispatch(loadStats()));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { role },
        },
      }) => {
        const promises = [];

        if (!isBlogRssLoaded(getState(), role)) {
          promises.push(dispatch(loadBlogRss(role)));
        }

        if (!isBlogRssLoaded(getState(), 'buyer')) {
          promises.push(dispatch(loadBlogRss('buyer')));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    user: state.auth.user,
    landingPages: fromJS(state.landingPages),
    usersEntities: state.entities.users,
    stats: state.stats,
    ownBriefs: state.auth.ownBriefs,
    briefEntities: state.entities.briefs,
  }),
  {
    // pushState: push, return if necessary from 'connected-router'
    loadBlogRss,
    loadCountries,
    loadUsers,
    loadRoles,
    loadSkills,
    loadStats,
    setPortfolioCarousel,
    inviteUser,
    setSuccessMessage,
  }
)(FindBase);

export default Find;
