import { asyncConnect } from 'redux-connect';
import { fromJS } from 'immutable';

import {
  filter as getFilterResults,
  isFilterSet,
  isUsersLoaded,
  getFilterHash,
  getFilterFromQS,
} from 'redux/modules/browse';

import loadable from '@loadable/component';

const BrowseBase = loadable(() => import('./BrowseBase'));

const Browse = asyncConnect(
  [
    {
      promise: ({ location, store: { dispatch, getState } }) => {
        const promises = [];

        if (isFilterSet(location.query)) {
          const store = getState();
          const filter = getFilterFromQS(location);
          const hash = getFilterHash(filter);

          const {
            browse: { filterResults },
          } = store;

          const hasLoaded = isUsersLoaded(store, filter);
          const areResultsLoading =
            (filterResults[hash] && filterResults[hash].loading) || false;

          // Fetch filter results
          if (!areResultsLoading && !hasLoaded) {
            promises.push(dispatch(getFilterResults(filter)));
          }
        }
        return Promise.all(promises);
      },
    },
  ],
  ({ auth: { user: authUser }, browse: { filterResults } }) => ({
    authUser,
    filterResults: fromJS(filterResults),
  })
)(BrowseBase);

export default Browse;
