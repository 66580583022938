import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Icon extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    color: 'inherit',
    size: 'inherit',
    className: '',
  };

  render() {
    return (
      <span
        id={`icon-${this.props.icon}`}
        onClick={this.props.onClick ? this.props.onClick : null}
        className={this.props.className}
        style={{
          ...this.props.style,
          fontFamily: 'TwineIcons',
          color: this.props.color,
          fontSize: this.props.size,
          cursor: this.props.onClick ? 'pointer' : 'inherit',
        }}
      >
        {this.props.icon}
      </span>
    );
  }
}
