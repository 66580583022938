import { schema } from 'normalizr';
import userSchema from './userSchema';

const shortlistSchema = new schema.Entity('testimonials');

shortlistSchema.define({
  user: userSchema,
});

export default shortlistSchema;
