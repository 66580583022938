import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { loadUserCredits } from 'redux/modules/textProjects';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import styles from './ManageProjects.module.scss';
import noActivity from './no-activity.png';
import Loader from 'components/Loader/Loader';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import ManageProjectsCard from 'components/ManageProjectsCard/ManageProjectsCard';
import Modal from 'components/Modal/Modal';

const ManageProjects = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);

  const projectsListRef = useRef(null);

  const projectsSelect = (state) => state.textProjects;
  const projectsState = useSelector(projectsSelect);
  const { loadingUserCredits, userCredits, moreToLoad } = projectsState;

  useEffect(() => {
    dispatch(loadUserCredits(user.username));
  }, []);

  useEffect(() => {
    if (!loadingMore) {
      setLoading(loadingUserCredits);
    }
  }, [loadingUserCredits, loadingMore]);

  const loadMoreProjects = () => {
    if (!loadingMore) {
      setLoadingMore(true);
      dispatch(loadUserCredits(user.username, page + 1));
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        projectsListRef.current &&
        window.innerHeight + window.scrollY >=
          projectsListRef.current.offsetHeight &&
        moreToLoad
      ) {
        loadMoreProjects();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page, moreToLoad, loadingMore]);

  useEffect(() => {
    setLoadingMore(false);
  }, [userCredits]);

  return (
    <div className="mt++ lato-regular">
      <Grid>
        <Row>
          <Col lg={12}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>Manage Projects</h1>
              <div className={styles.projectButtons}>
                <Button
                  bsStyle="primary"
                  className={styles.button}
                  onClick={() => {
                    !user.email_verified
                      ? setShowEmailVerifyModal(true)
                      : (window.location.href = '/projects/create');
                  }}
                >
                  <SvgIcon icon="plus_circle" size="16" />
                  New Project
                </Button>
                <Button
                  bsStyle="primary"
                  className={styles.button}
                  onClick={() => {
                    !user.email_verified
                      ? setShowEmailVerifyModal(true)
                      : (window.location.href = '/upload');
                  }}
                >
                  <SvgIcon icon="upload" size="16" />
                  New Project with Files
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {loading ? (
              <Loader loading />
            ) : (
              <div ref={projectsListRef}>
                {userCredits.length > 0 ? (
                  <>
                    {userCredits.map((credit) => (
                      <ManageProjectsCard
                        project={credit.project}
                        user={user}
                        key={credit.project.id}
                      />
                    ))}
                  </>
                ) : (
                  <div className="mt++ text-center">
                    <img src={noActivity} alt="Twine" />
                    <p className={styles.noProjectsTitle}>No Projects Added</p>
                    <>
                      <p>{`It appears you haven't added any projects yet`}</p>
                      <p>
                        Use one of the buttons above to create a new project or
                        upload your work
                      </p>
                    </>
                  </div>
                )}
                <Loader loading={loadingMore} />
              </div>
            )}
          </Col>
        </Row>
      </Grid>
      <Modal
        show={showEmailVerifyModal}
        onExit={() => setShowEmailVerifyModal(false)}
        closeButton
      >
        <div className="p++">
          <h3 className="lato-bold text-center mb">Verify your Email</h3>
          <p>
            Before you can add a new project, you need to activate your account.
          </p>
          <p>
            Please check your email for an email from us with an activation
            button and click it.
          </p>
          <p>
            Please check your spam/junk folder in case it has ended up in there.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ManageProjects;
