let _serviceWorker = null;

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let idx = 0; idx < rawData.length; ++idx) {
    outputArray[idx] = rawData.charCodeAt(idx);
  }
  return outputArray;
}

function isAvailable() {
  return (
    __CLIENT__ &&
    navigator &&
    'serviceWorker' in navigator &&
    window &&
    'PushManager' in window
  );
}

function hasBlocked() {
  return (
    typeof Notification !== 'undefined' && Notification.permission === 'denied'
  );
}

function hasPermission() {
  return (
    typeof Notification !== 'undefined' && Notification.permission === 'granted'
  );
}

function askPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== 'granted') {
      throw new Error('Permission not granted.');
    }
  });
}

function registerServiceWorker() {
  return navigator.serviceWorker
    .register(require('utils/service-worker.js').default)
    .then(function (registration) {
      _serviceWorker = registration;

      return _serviceWorker;
    })
    .catch(function (err) {
      console.error('Unable to register service worker.', err);
    });
}

function subscribeUser() {
  return _serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.VAPID_PUBLIC_KEY),
  });
}

function unsubscribeUser() {
  return _serviceWorker.pushManager.getSubscription().then((subscription) => {
    return subscription.unsubscribe((successful) => successful);
  });
}

export {
  askPermission,
  hasBlocked,
  hasPermission,
  isAvailable,
  registerServiceWorker,
  subscribeUser,
  unsubscribeUser,
};
