import userSchema from '../schemas/userSchema';

const LOADUSER = 'twineApp/users/LOADUSER';
const LOADUSER_SUCCESS = 'twineApp/users/LOADUSER_SUCCESS';
const LOADUSER_FAIL = 'twineApp/users/LOADUSER_FAIL';
const LOADCOUNT = 'twineApp/users/LOADCOUNT';
const LOADCOUNT_SUCCESS = 'twineApp/users/LOADCOUNT_SUCCESS';
const LOADCOUNT_FAIL = 'twineApp/users/LOADCOUNT_FAIL';

const initialState = {
  count: 0,
  loading: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADUSER:
      return {
        ...state,
        loading: state.loading.concat([action.ident]),
      };

    case LOADUSER_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((value) => value !== action.ident),
      };

    case LOADUSER_FAIL:
      return {
        ...state,
        loading: state.loading.filter((value) => value !== action.ident),
      };

    case LOADCOUNT_SUCCESS:
      return {
        ...state,
        count: action.result.count,
      };

    default:
      return state;
  }
}

// ident: username: String | userId: Integer
export function loadUser(ident, include = []) {
  const finalIncludes = [...include, 'avatars', 'covers'];
  return {
    types: [LOADUSER, LOADUSER_SUCCESS, LOADUSER_FAIL],
    promise: (client) =>
      client.get('/users/' + ident + `?include=${finalIncludes.toString()}`),
    ident,
    schema: userSchema,
  };
}

export function isCountLoaded(globalState) {
  return globalState.users.count > 0;
}

export function loadCount() {
  return {
    types: [LOADCOUNT, LOADCOUNT_SUCCESS, LOADCOUNT_FAIL],
    promise: (client) => client.get('/users/count'),
  };
}

export function getUserById(state, id) {
  return state.entities.users[id];
}

export function getUserByUsername(state, username) {
  if (typeof state.entities.users === 'undefined') {
    return null;
  }

  for (const user in state.entities.users) {
    if (state.entities.users[user].username === username) {
      return state.entities.users[user];
    }
  }

  return null;
}
