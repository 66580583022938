const SUCCESSMESSAGE = 'twineApp/successMessage/SUCCESSMESSAGE';
const RESET_SUCCESSMESSAGE = 'twineApp/successMessage/RESET_SUCCESSMESSAGE';

export default function reducer(state = null, action = {}) {
  if (action.type === SUCCESSMESSAGE) {
    return action.message;
  }

  if (action.type === RESET_SUCCESSMESSAGE) {
    return '';
  }

  return state;
}

export function setSuccessMessage(message) {
  return {
    type: SUCCESSMESSAGE,
    message,
  };
}

export function resetSuccessMessage() {
  return {
    type: RESET_SUCCESSMESSAGE,
  };
}
