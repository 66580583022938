const LOADSTATS = 'twineApp/stats/LOADSTATS';
const LOADSTATS_SUCCESS = 'twineApp/stats/LOADSTATS_SUCCESS';
const LOADSTATS_FAIL = 'twineApp/stats/LOADSTATS_FAIL';

const initialState = {
  count: 0,
  loadedStats: false,
  loadingStats: false,
  rating: {
    avg: 0,
    count: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADSTATS:
      return {
        ...state,
        loadingStats: true,
      };

    case LOADSTATS_SUCCESS:
      return {
        ...state,
        count: action.result.users.count,
        loadedStats: true,
        loadingStats: false,
        rating: action.result.users.rating,
      };

    case LOADSTATS_FAIL:
      return {
        ...state,
        loadedStats: true,
        loadingStats: false,
      };

    default:
      return state;
  }
}

export function loadStats() {
  return {
    types: [LOADSTATS, LOADSTATS_SUCCESS, LOADSTATS_FAIL],
    promise: (client) => client.get('/website/stats'),
  };
}
