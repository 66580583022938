import { fromJS, List } from 'immutable';
import queryString from 'query-string';
import { union } from 'lodash';
// Schemas
import roleSchema from '../schemas/roleSchema';
import skillSchema from '../schemas/skillSchema';
import briefSchema from '../schemas/briefSchema';
import messageSchema from '../schemas/messageSchema';

// Actions
const LOADROLES = 'twineApp/projects2/LOADROLES';
const LOADROLES_SUCCESS = 'twineApp/projects2/LOADROLES_SUCCESS';
const LOADROLES_FAIL = 'twineApp/projects2/LOADROLES_FAIL';
const LOADSKILLS = 'twineApp/projects2/LOADSKILLS';
const LOADSKILLS_SUCCESS = 'twineApp/projects2/LOADSKILLS_SUCCESS';
const LOADSKILLS_FAIL = 'twineApp/projects2/LOADSKILLS_FAIL';
const LOADROLESKILLS = 'twineApp/projects2/LOADROLESKILLS';
const LOADROLESKILLS_SUCCESS = 'twineApp/projects2/LOADROLESKILLS_SUCCESS';
const LOADROLESKILLS_FAIL = 'twineApp/projects2/LOADROLESKILLS_FAIL';
const LOADBRIEFS = 'twineApp/projects2/LOADBRIEFS';
const LOADBRIEFS_SUCCESS = 'twineApp/projects2/LOADBRIEFS_SUCCESS';
const LOADBRIEFS_FAIL = 'twineApp/projects2/LOADBRIEFS_FAIL';
const LOADOWNBRIEFS = 'twineApp/projects2/LOADOWNBRIEFS';
const LOADOWNBRIEFS_SUCCESS = 'twineApp/projects2/LOADOWNBRIEFS_SUCCESS';
const LOADOWNBRIEFS_FAIL = 'twineApp/projects2/LOADOWNBRIEFS_FAIL';
const LOADOWNPITCHES = 'twineApp/projects2/LOADOWNPITCHES';
const LOADOWNPITCHES_SUCCESS = 'twineApp/projects2/LOADOWNPITCHES_SUCCESS';
const LOADOWNPITCHES_FAIL = 'twineApp/projects2/LOADOWNPITCHES_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADROLES:
      return imState
        .setIn(['filterRoles', action.paid.toString(), 'loading'], true)
        .toJS();

    case LOADROLES_SUCCESS:
      return imState
        .mergeDeep({
          filterRoles: {
            [action.paid.toString()]: {
              roles:
                Object.keys(action.result).length > 0
                  ? action.result.suggested_roles
                  : [],
              loaded: true,
              loading: false,
            },
          },
        })
        .toJS();

    case LOADROLES_FAIL:
      return imState
        .mergeDeep({
          filterRoles: {
            [action.paid.toString()]: {
              loaded: true,
              loading: false,
            },
          },
        })
        .toJS();

    case LOADSKILLS:
      return imState
        .setIn(['filterSkills', action.paid.toString(), 'loading'], true)
        .toJS();

    case LOADSKILLS_SUCCESS:
      return imState
        .mergeDeep({
          filterSkills: {
            [action.paid.toString()]: {
              skills:
                Object.keys(action.result).length > 0
                  ? action.result.suggested_skills
                  : [],
              loaded: true,
              loading: false,
            },
          },
        })
        .toJS();

    case LOADSKILLS_FAIL:
      return imState
        .mergeDeep({
          filterSkills: {
            [action.paid.toString()]: {
              loaded: true,
              loading: false,
            },
          },
        })
        .toJS();

    case LOADROLESKILLS:
      return imState
        .setIn(['filterRoleSkills', action.paid.toString(), 'loading'], true)
        .toJS();

    case LOADROLESKILLS_SUCCESS:
      return imState
        .mergeDeep({
          filterRoleSkills: {
            [action.paid.toString()]: {
              roleSkills:
                Object.keys(action.result).length > 0
                  ? action.result.role_skills
                  : [],
              loaded: true,
              loading: false,
            },
          },
        })
        .toJS();

    case LOADROLESKILLS_FAIL:
      return imState
        .mergeDeep({
          filterRoleSkills: {
            [action.paid.toString()]: {
              loaded: true,
              loading: false,
            },
          },
        })
        .toJS();

    case LOADBRIEFS:
      return imState
        .mergeDeep({
          briefs: {
            [action.hash]: {
              loading: true,
              loaded: imState.getIn(['briefs', action.hash, 'loaded'], false),
            },
          },
        })
        .toJS();

    case LOADBRIEFS_SUCCESS: {
      const briefsResults =
        Object.keys(action.result).length > 0 ? action.result : [];

      return imState
        .mergeDeep({
          briefs: {
            [action.hash]: {
              loading: false,
              loaded: true,
              briefs: List(
                union(
                  imState
                    .getIn(['briefs', action.hash, 'briefs'], List())
                    .toArray(),
                  briefsResults.collaborations
                )
              ),
              total: imState.getIn(
                ['briefs', action.hash, 'total'],
                typeof briefsResults.meta !== 'undefined'
                  ? briefsResults.meta.pagination.total
                  : 0
              ),
              pagesLoaded:
                imState.getIn(['briefs', action.hash, 'pagesLoaded'], 0) + 1,
              moreToLoad:
                action.page <
                (typeof briefsResults.meta !== 'undefined'
                  ? briefsResults.meta.pagination.total_pages
                  : 1),
            },
          },
        })
        .toJS();
    }

    case LOADBRIEFS_FAIL:
      return imState
        .mergeDeep({
          briefs: {
            [action.hash]: {
              loading: false,
              loaded: imState.getIn(['briefs', action.hash, 'loaded'], false),
            },
          },
        })
        .toJS();

    case LOADOWNBRIEFS:
      return imState
        .mergeDeep({
          ownBriefs: {
            loading: true,
            loaded: imState.getIn(['ownBriefs', 'loaded'], false),
          },
        })
        .toJS();

    case LOADOWNBRIEFS_SUCCESS:
      const ownBriefsResults =
        Object.keys(action.result).length > 0 ? action.result : [];

      return imState
        .mergeDeep({
          ownBriefs: {
            loading: false,
            loaded: true,
            briefs: imState
              .getIn(['ownBriefs', 'briefs'], List())
              .concat(List(ownBriefsResults.collaborations)), // eslint-disable-line new-cap
            total: imState.getIn(
              ['ownBriefs', 'total'],
              typeof ownBriefsResults.meta !== 'undefined'
                ? ownBriefsResults.meta.pagination.total
                : 0
            ),
            pagesLoaded: imState.getIn(['ownBriefs', 'pagesLoaded'], 0) + 1,
            moreToLoad:
              action.page <
              (typeof ownBriefsResults.meta !== 'undefined'
                ? ownBriefsResults.meta.pagination.total_pages
                : 1),
          },
        })
        .toJS();

    case LOADOWNBRIEFS_FAIL:
      return imState
        .mergeDeep({
          ownBriefs: {
            loading: false,
            loaded: imState.getIn(['ownBriefs', 'loaded'], false),
          },
        })
        .toJS();

    case LOADOWNPITCHES:
      return imState
        .mergeDeep({
          ownPitches: {
            loading: true,
            loaded: imState.getIn(['ownPitches', 'loaded'], false),
          },
        })
        .toJS();

    case LOADOWNPITCHES_SUCCESS:
      const ownPitchesResults =
        Object.keys(action.result).length > 0 ? action.result : [];

      return imState
        .mergeDeep({
          ownPitches: {
            loading: false,
            loaded: true,
            pitches: imState
              .getIn(['ownPitches', 'pitches'], List())
              .concat(List(ownPitchesResults.threads)),
            total: imState.getIn(
              ['ownPitches', 'total'],
              typeof ownPitchesResults.meta !== 'undefined'
                ? ownPitchesResults.meta.pagination.total
                : 0
            ),
            pagesLoaded: imState.getIn(['ownPitches', 'pagesLoaded'], 0) + 1,
            moreToLoad:
              action.page <
              (typeof ownPitchesResults.meta !== 'undefined'
                ? ownPitchesResults.meta.pagination.total_pages
                : 1),
          },
        })
        .toJS();

    case LOADOWNPITCHES_FAIL:
      return imState
        .mergeDeep({
          ownPitches: {
            loading: false,
            loaded: imState.getIn(['ownPitches', 'loaded'], false),
          },
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export function isFilterRolesLoaded(globalState, paid = null) {
  return (
    globalState.projects2 &&
    typeof globalState.projects2.filterRoles !== 'undefined' &&
    typeof globalState.projects2.filterRoles[paid.toString()] !== 'undefined' &&
    typeof globalState.projects2.filterRoles[paid.toString()].loaded !==
      'undefined' &&
    globalState.projects2.filterRoles[paid.toString()].loaded
  );
}

export function loadFilterRoles(paid = null) {
  return {
    types: [LOADROLES, LOADROLES_SUCCESS, LOADROLES_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/roles?limit=1000' +
          (paid !== null ? '&paid=' + +paid : '')
      ),
    paid,
    schema: { suggested_roles: [roleSchema] },
  };
}

export function isFilterSkillsLoaded(globalState, paid = null) {
  return (
    globalState.projects2 &&
    typeof globalState.projects2.filterSkills !== 'undefined' &&
    typeof globalState.projects2.filterSkills[paid.toString()] !==
      'undefined' &&
    typeof globalState.projects2.filterSkills[paid.toString()].loaded !==
      'undefined' &&
    globalState.projects2.filterSkills[paid.toString()].loaded
  );
}

export function loadFilterSkills(paid = null) {
  return {
    types: [LOADSKILLS, LOADSKILLS_SUCCESS, LOADSKILLS_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/skills?limit=1000' +
          (paid !== null ? '&paid=' + +paid : '')
      ),
    paid,
    schema: { suggested_skills: [skillSchema] },
  };
}

export function isFilterRoleSkillsLoaded(globalState, paid = null) {
  return (
    globalState.projects2 &&
    typeof globalState.projects2.filterRoleSkills !== 'undefined' &&
    typeof globalState.projects2.filterRoleSkills[paid.toString()] !==
      'undefined' &&
    typeof globalState.projects2.filterRoleSkills[paid.toString()].loaded !==
      'undefined' &&
    globalState.projects2.filterRoleSkills[paid.toString()].loaded
  );
}

export function loadFilterRoleSkills(paid = null) {
  return {
    types: [LOADROLESKILLS, LOADROLESKILLS_SUCCESS, LOADROLESKILLS_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration/roleSkills?limit=1000&group_by=category' +
          (paid !== null ? '&paid=' + +paid : '')
      ),
    paid,
  };
}

function getBriefsHash(
  paid,
  roles,
  experienceLevels,
  hiringCapacities,
  jobStatusOpen = false,
  remote = false,
  internal = false,
  jobLocationText = null,
  searchTerm = null
) {
  let rolesString = null;

  if (typeof roles === 'object') {
    rolesString = roles.join();
  } else if (typeof roles === 'string') {
    rolesString = roles;
  }
  const selectedExperienceLevels = experienceLevels
    ? experienceLevels.filter((l) => l.isChecked)
    : [];
  const selectedExperienceLevelsString =
    selectedExperienceLevels.length > 0
      ? selectedExperienceLevels.map((l) => l.type).join()
      : null;

  const selectedHiringCapacities = hiringCapacities
    ? hiringCapacities.filter((h) => h.isChecked)
    : [];
  const selectedHiringCapacitiesString =
    selectedHiringCapacities.length > 0
      ? selectedHiringCapacities.map((h) => h.type).join()
      : null;

  return encodeURIComponent(
    `${paid}|${rolesString}|${selectedExperienceLevelsString}|${selectedHiringCapacitiesString}|${jobStatusOpen}|${remote}|${internal}|${jobLocationText}|${searchTerm}`
  );
}

export function isBriefsLoaded(
  globalState,
  paid = null,
  roles = null,
  experienceLevels = null,
  hiringCapacities = null,
  jobStatusOpen = false,
  remote = false,
  internal = false,
  jobLocationText = null,
  searchTerm = null
) {
  const hash = getBriefsHash(
    paid,
    roles,
    experienceLevels,
    hiringCapacities,
    jobStatusOpen,
    remote,
    internal,
    jobLocationText,
    searchTerm
  );

  return (
    globalState.projects2 &&
    typeof globalState.projects2.briefs !== 'undefined' &&
    typeof globalState.projects2.briefs[hash] !== 'undefined' &&
    typeof globalState.projects2.briefs[hash].loaded !== 'undefined' &&
    globalState.projects2.briefs[hash].loaded
  );
}

export function loadBriefs(
  page = 1,
  paid = null,
  roles,
  experienceLevels = null,
  hiringCapacities = null,
  jobStatusOpen = false,
  remote = false,
  internal = false,
  jobLocationText = null,
  searchTerm = null
) {
  const hash = getBriefsHash(
    paid,
    roles,
    experienceLevels,
    hiringCapacities,
    jobStatusOpen,
    remote,
    internal,
    jobLocationText,
    searchTerm
  );
  let params = '?include=user,user.avatars,user.covers&limit=10';

  // Page
  params += '&page=' + page;

  // Paid
  if (paid !== null) {
    params += '&paid=' + +paid;
  }

  if (roles && roles.length > 0) {
    params +=
      '&' + queryString.stringify({ roles: roles }, { arrayFormat: 'bracket' });
  }

  // Experience Levels
  if (experienceLevels.filter((l) => l.isChecked).length > 0) {
    params +=
      '&' +
      queryString.stringify(
        {
          experienceLevel: experienceLevels
            .filter((l) => l.isChecked)
            .map((l) => l.type),
        },
        { arrayFormat: 'bracket' }
      );
  }

  // Hiring Capacities
  if (hiringCapacities.filter((h) => h.isChecked).length > 0) {
    params +=
      '&' +
      queryString.stringify(
        {
          hiringCapacity: hiringCapacities
            .filter((h) => h.isChecked)
            .map((h) => h.type),
        },
        { arrayFormat: 'bracket' }
      );
  }

  // Open Status
  if (jobStatusOpen) {
    // Only request Briefs with `open` status
    params += '&status=open';
  } else {
    // Request all Brief statuses
    params +=
      '&state=active,in_progress,completed,expired,inactive,paid,final_files_uploaded,paid_final_files_uploaded,final_files_approved,paid_final_files_approved&include_state=1';
  }

  // Remote
  if (remote) {
    // Only request Briefs with `open` status
    params += '&remote=1';
  }

  if (internal) {
    params += '&internal=1';
  }

  // Location Text
  if (jobLocationText) {
    params += `&inLocation=${jobLocationText.replaceAll('-', ' ')}`;
  }

  if (searchTerm) {
    params += `&searchTerm=${searchTerm}`;
  }

  return {
    types: [LOADBRIEFS, LOADBRIEFS_SUCCESS, LOADBRIEFS_FAIL],
    promise: (client) => client.get('/notices/collaboration' + params),
    hash,
    page,
    schema: { collaborations: [briefSchema] },
  };
}

export function isOwnBriefsLoaded(globalState) {
  return (
    globalState.projects2 &&
    typeof globalState.projects2.ownBriefs !== 'undefined' &&
    typeof globalState.projects2.ownBriefs.loaded !== 'undefined' &&
    globalState.projects2.ownBriefs.loaded
  );
}

export function loadOwnBriefs(user) {
  return {
    types: [LOADOWNBRIEFS, LOADOWNBRIEFS_SUCCESS, LOADOWNBRIEFS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' + user.id + '/notices/collaboration?include=pitches,rating'
      ),
    schema: { collaborations: [briefSchema] },
  };
}

export function isOwnPitchesLoaded(globalState) {
  return (
    globalState.projects2 &&
    typeof globalState.projects2.ownPitches !== 'undefined' &&
    typeof globalState.projects2.ownPitches.loaded !== 'undefined' &&
    globalState.projects2.ownPitches.loaded
  );
}

export function loadOwnPitches(user) {
  return {
    types: [LOADOWNPITCHES, LOADOWNPITCHES_SUCCESS, LOADOWNPITCHES_FAIL],
    promise: (client) =>
      client.get(
        '/users/' + user.id + '/pitches?include=message.notice&state=accepted'
      ),
    schema: { pitches: [messageSchema] },
  };
}
