import publicQuestionSchema from 'redux/schemas/publicQuestionSchema';
import merge from 'deepmerge';

const ANSWERQUESTION = 'twineApp/publicQuestions2/ANSWERQUESTION';
const ANSWERQUESTION_SUCCESS =
  'twineApp/publicQuestions2/ANSWERQUESTION_SUCCESS';
const ANSWERQUESTION_FAIL = 'twineApp/publicQuestions2/ANSWERQUESTION_FAIL';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case ANSWERQUESTION:
      return merge(state, {
        [action.question.id]: {
          answering: true,
        },
      });

    case ANSWERQUESTION_SUCCESS:
      return merge(state, {
        [action.question.id]: {
          answering: false,
        },
      });

    case ANSWERQUESTION_FAIL:
      return merge(state, {
        [action.question.id]: {
          answering: false,
        },
      });

    default:
      return state;
  }
}

export function answerQuestion(brief, question, answer) {
  return {
    types: [ANSWERQUESTION, ANSWERQUESTION_SUCCESS, ANSWERQUESTION_FAIL],
    promise: (client) =>
      client.patch(
        `/notices/collaboration/${brief.id}/questions/public/${question.id}`,
        { data: { answer } }
      ),
    schema: publicQuestionSchema,
    question,
  };
}
