const LOADBRIEFS = 'twineApp/manageBriefs/LOADBRIEFS';
const LOADBRIEFS_SUCCESS = 'twineApp/manageBriefs/LOADBRIEFS_SUCCESS';
const LOADBRIEFS_FAIL = 'twineApp/manageBriefs/LOADBRIEFS_FAIL';
const LOADPENDINGAPPROVALBRIEFS =
  'twineApp/manageBriefs/LOADPENDINGAPPROVALBRIEFS';
const LOADPENDINGAPPROVALBRIEFS_SUCCESS =
  'twineApp/manageBriefs/LOADPENDINGAPPROVALBRIEFS_SUCCESS';
const LOADPENDINGAPPROVALBRIEFS_FAIL =
  'twineApp/manageBriefs/LOADPENDINGAPPROVALBRIEFS_FAIL';
const LOADLIVEBRIEFS = 'twineApp/manageBriefs/LOADLIVEBRIEFS';
const LOADLIVEBRIEFS_SUCCESS = 'twineApp/manageBriefs/LOADLIVEBRIEFS_SUCCESS';
const LOADLIVEBRIEFS_FAIL = 'twineApp/manageBriefs/LOADLIVEBRIEFS_FAIL';
const LOADINPROGRESSBRIEFS = 'twineApp/manageBriefs/LOADINPROGRESSBRIEFS';
const LOADINPROGRESSBRIEFS_SUCCESS =
  'twineApp/manageBriefs/LOADINPROGRESSBRIEFS_SUCCESS';
const LOADINPROGRESSBRIEFS_FAIL =
  'twineApp/manageBriefs/LOADINPROGRESSBRIEFS_FAIL';
const LOADEXPIREDBRIEFS = 'twineApp/manageBriefs/LOADEXPIREDBRIEFS';
const LOADEXPIREDBRIEFS_SUCCESS =
  'twineApp/manageBriefs/LOADEXPIREDBRIEFS_SUCCESS';
const LOADEXPIREDBRIEFS_FAIL = 'twineApp/manageBriefs/LOADEXPIREDBRIEFS_FAIL';
const LOADCOMPLETEDBRIEFS = 'twineApp/manageBriefs/LOADCOMPLETEDBRIEFS';
const LOADCOMPLETEDBRIEFS_SUCCESS =
  'twineApp/manageBriefs/LOADCOMPLETEDBRIEFS_SUCCESS';
const LOADCOMPLETEDBRIEFS_FAIL =
  'twineApp/manageBriefs/LOADCOMPLETEDBRIEFS_FAIL';
const LOADALLBRIEFS = 'twineApp/manageBriefs/LOADALLBRIEFS';
const LOADALLBRIEFS_SUCCESS = 'twineApp/manageBriefs/LOADALLBRIEFS_SUCCESS';
const LOADALLBRIEFS_FAIL = 'twineApp/manageBriefs/LOADALLBRIEFS_FAIL';

const initialState = {
  briefs: [],
  loadingBriefs: false,
  loadedBriefs: false,
  pendingApprovalBriefs: [],
  pendingApprovalBriefsTotal: 0,
  pendingApprovalBriefsLoadMore: false,
  pendingApprovalBriefsPagesLoaded: 0,
  loadingPendingApprovalBriefs: false,
  loadedPendingApprovalBriefs: false,
  liveBriefs: [],
  liveBriefsTotal: 0,
  liveBriefsLoadMore: false,
  liveBriefsPagesLoaded: 0,
  loadingLiveBriefs: false,
  loadedLiveBriefs: false,
  inProgressBriefs: [],
  inProgressBriefsTotal: 0,
  inProgressBriefsLoadMore: false,
  inProgressBriefsPagesLoaded: 0,
  loadingInProgressBriefs: false,
  loadedInProgressBriefs: false,
  expiredBriefs: [],
  expiredBriefsTotal: 0,
  expiredBriefsLoadMore: false,
  expiredBriefsPagesLoaded: 0,
  loadingExpiredBriefs: false,
  loadedExpiredBriefs: false,
  completedBriefs: [],
  completedBriefsTotal: 0,
  completedBriefsLoadMore: false,
  completedBriefsPagesLoaded: 0,
  loadingCompletedBriefs: false,
  loadedCompletedBriefs: false,
  loadingAllBriefs: false,
  loadedAllBriefs: false,
  allBriefs: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADBRIEFS:
      return {
        ...state,
        loadingBriefs: true,
      };

    case LOADBRIEFS_SUCCESS:
      return {
        ...state,
        loadingBriefs: false,
        loadedBriefs: true,
        briefs:
          typeof action.result.collaborations === 'undefined'
            ? state.briefs
            : state.briefs.concat(action.result.collaborations),
      };

    case LOADBRIEFS_FAIL:
      return {
        ...state,
        loadingBriefs: false,
      };

    case LOADPENDINGAPPROVALBRIEFS:
      return {
        ...state,
        loadingPendingApprovalBriefs: true,
      };

    case LOADPENDINGAPPROVALBRIEFS_SUCCESS:
      return {
        ...state,
        loadingPendingApprovalBriefs: false,
        loadedPendingApprovalBriefs: true,
        pendingApprovalBriefs:
          typeof action.result.collaborations === 'undefined'
            ? state.pendingApprovalBriefs
            : state.pendingApprovalBriefs.concat(action.result.collaborations),
        pendingApprovalBriefsTotal:
          typeof action.result.collaborations === 'undefined'
            ? state.pendingApprovalBriefsTotal
            : action.result.meta.pagination.total,
        pendingApprovalBriefsLoadMore:
          typeof action.result.collaborations === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        pendingApprovalBriefsPagesLoaded:
          state.pendingApprovalBriefsPagesLoaded + 1,
      };

    case LOADPENDINGAPPROVALBRIEFS_FAIL:
      return {
        ...state,
        loadingPendingApprovalBriefs: false,
      };

    case LOADLIVEBRIEFS:
      return {
        ...state,
        loadingLiveBriefs: true,
      };

    case LOADLIVEBRIEFS_SUCCESS:
      return {
        ...state,
        loadingLiveBriefs: false,
        loadedLiveBriefs: true,
        liveBriefs:
          typeof action.result.collaborations === 'undefined'
            ? state.liveBriefs
            : state.liveBriefs.concat(action.result.collaborations),
        liveBriefsTotal:
          typeof action.result.collaborations === 'undefined'
            ? state.liveBriefsTotal
            : action.result.meta.pagination.total,
        liveBriefsLoadMore:
          typeof action.result.collaborations === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        liveBriefsPagesLoaded: state.liveBriefsPagesLoaded + 1,
      };

    case LOADLIVEBRIEFS_FAIL:
      return {
        ...state,
        loadingLiveBriefs: false,
      };

    case LOADINPROGRESSBRIEFS:
      return {
        ...state,
        loadingInProgressBriefs: true,
      };

    case LOADINPROGRESSBRIEFS_SUCCESS:
      return {
        ...state,
        loadingInProgressBriefs: false,
        loadedInProgressBriefs: true,
        inProgressBriefs:
          typeof action.result.collaborations === 'undefined'
            ? state.inProgressBriefs
            : state.inProgressBriefs.concat(action.result.collaborations),
        inProgressBriefsTotal:
          typeof action.result.collaborations === 'undefined'
            ? state.inProgressBriefsTotal
            : action.result.meta.pagination.total,
        inProgressBriefsLoadMore:
          typeof action.result.collaborations === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        inProgressBriefsPagesLoaded: state.inProgressBriefsPagesLoaded + 1,
      };

    case LOADINPROGRESSBRIEFS_FAIL:
      return {
        ...state,
        loadingInProgressBriefs: false,
      };

    case LOADEXPIREDBRIEFS:
      return {
        ...state,
        loadingExpiredBriefs: true,
      };

    case LOADEXPIREDBRIEFS_SUCCESS:
      return {
        ...state,
        loadingExpiredBriefs: false,
        loadedExpiredBriefs: true,
        expiredBriefs:
          typeof action.result.collaborations === 'undefined'
            ? state.expiredBriefs
            : state.expiredBriefs.concat(action.result.collaborations),
        expiredBriefsTotal:
          typeof action.result.collaborations === 'undefined'
            ? state.expiredBriefsTotal
            : action.result.meta.pagination.total,
        expiredBriefsLoadMore:
          typeof action.result.collaborations === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        expiredBriefsPagesLoaded: state.expiredBriefsPagesLoaded + 1,
      };

    case LOADEXPIREDBRIEFS_FAIL:
      return {
        ...state,
        loadingExpiredBriefs: false,
      };

    case LOADCOMPLETEDBRIEFS:
      return {
        ...state,
        loadingCompletedBriefs: true,
      };

    case LOADCOMPLETEDBRIEFS_SUCCESS:
      return {
        ...state,
        loadingCompletedBriefs: false,
        loadedCompletedBriefs: true,
        completedBriefs:
          typeof action.result.collaborations === 'undefined'
            ? state.completedBriefs
            : state.completedBriefs.concat(action.result.collaborations),
        completedBriefsTotal:
          typeof action.result.collaborations === 'undefined'
            ? state.completedBriefsTotal
            : action.result.meta.pagination.total,
        completedBriefsLoadMore:
          typeof action.result.collaborations === 'undefined'
            ? false
            : action.result.meta.pagination.current_page <
              action.result.meta.pagination.total_pages,
        completedBriefsPagesLoaded: state.completedBriefsPagesLoaded + 1,
      };

    case LOADCOMPLETEDBRIEFS_FAIL:
      return {
        ...state,
        loadingCompletedBriefs: false,
      };

    case LOADALLBRIEFS:
      return {
        ...state,
        loadingAllBriefs: true,
      };

    case LOADALLBRIEFS_SUCCESS:
      return {
        ...state,
        loadingAllBriefs: false,
        loadedAllBriefs: true,
        allBriefs: action.result.collaborations,
      };

    case LOADALLBRIEFS_FAIL:
      return {
        ...state,
        loadingAllBriefs: false,
      };

    default:
      return state;
  }
}

export function loadBriefs(userId, page) {
  return {
    types: [LOADBRIEFS, LOADBRIEFS_SUCCESS, LOADBRIEFS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?include=user,user.avatars,pitches&page=' +
          page
      ),
  };
}

export function loadPendingApprovalBriefs(userId, page) {
  return {
    types: [
      LOADPENDINGAPPROVALBRIEFS,
      LOADPENDINGAPPROVALBRIEFS_SUCCESS,
      LOADPENDINGAPPROVALBRIEFS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?include=user,user.avatars&state=pending_approval&page=' +
          page
      ),
  };
}

export function loadLiveBriefs(userId, page) {
  return {
    types: [LOADLIVEBRIEFS, LOADLIVEBRIEFS_SUCCESS, LOADLIVEBRIEFS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?include=user,user.avatars&state=active&page=' +
          page
      ),
  };
}

export function loadInProgressBriefs(userId, page) {
  return {
    types: [
      LOADINPROGRESSBRIEFS,
      LOADINPROGRESSBRIEFS_SUCCESS,
      LOADINPROGRESSBRIEFS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?include=user,user.avatars,pitches:accepted(true),pitches.sender,pitches.sender.avatars&state=in_progress,paid,final_files_uploaded,paid_final_files_uploaded,final_files_approved,paid_final_files_approved&include_state=1&page=' +
          page
      ),
  };
}

export function loadExpiredBriefs(userId, page) {
  return {
    types: [
      LOADEXPIREDBRIEFS,
      LOADEXPIREDBRIEFS_SUCCESS,
      LOADEXPIREDBRIEFS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?include=user,user.avatars&state=expired&page=' +
          page
      ),
  };
}

export function loadCompletedBriefs(userId, page) {
  return {
    types: [
      LOADCOMPLETEDBRIEFS,
      LOADCOMPLETEDBRIEFS_SUCCESS,
      LOADCOMPLETEDBRIEFS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?include=user,user.avatars,pitches:accepted(true),pitches.sender,pitches.sender.avatars&state=completed&page=' +
          page
      ),
  };
}

export function loadAllBriefs(userId) {
  return {
    types: [LOADALLBRIEFS, LOADALLBRIEFS_SUCCESS, LOADALLBRIEFS_FAIL],
    promise: (client) =>
      client.get('/users/' + userId + '/notices/collaboration/?state=any'),
  };
}
