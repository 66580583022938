import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import qhistory from 'qhistory';
import { parse, stringify } from 'query-string';
import { persistState } from 'redux-devtools';
import clientMiddleware from './middleware/clientMiddleware';
import createRootReducer from './modules/reducer';
import DevTools from '../containers/DevTools/DevTools';

export default function createStore(url = '/', client, data) {
  const isServer = () =>
    !(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    );

  const history = qhistory(
    isServer()
      ? createMemoryHistory({
          initialEntries: [url],
        })
      : createBrowserHistory({ basename: url }),
    stringify,
    parse
  );

  const reduxRouterMiddleware = routerMiddleware(history);

  const middleware = [clientMiddleware(client), reduxRouterMiddleware];

  let finalCreateStore = applyMiddleware(...middleware)(_createStore);

  if (
    (__DEVELOPMENT__ || /(local|development)/.test(__CLOUD_ENV__)) &&
    __CLIENT__ &&
    __DEVTOOLS__
  ) {
    finalCreateStore = compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
        : DevTools.instrument(),
      persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/))
    )(_createStore);
  }

  const reducer = createRootReducer(history);
  const store = finalCreateStore(reducer, data);

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(createRootReducer);
    });
  }

  return { history, store };
}
