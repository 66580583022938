import React from 'react';
import LazyLoad from 'react-lazy-load';
import styles from './LazyLoad.module.scss';

const LazyLoadWrapper = (props) => (
  <LazyLoad
    offsetBottom={350}
    offsetHorizontal={3500}
    className={styles.fade}
    {...props}
  />
);

export default LazyLoadWrapper;
