import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { load as loadSuggestedRoles } from 'redux/modules/suggestedRoles2';

import SelectSelectize from 'components/Shared/Selectors/SelectSelectize';

@connect(
  (state) => ({
    loadedSuggestedRoles: state.suggestedRoles2.loaded,
    loadingSuggestedRoles: state.suggestedRoles2.loading,
    suggestedRoles: state.suggestedRoles2.suggestedRoles,
    roleEntities: state.entities.roles,
  }),
  {
    loadSuggestedRoles,
  }
)
export default class RolesSelector extends Component {
  static propTypes = {
    existing: PropTypes.array,
    onSelect: PropTypes.func,
    loadingSuggestedRoles: PropTypes.bool,
    loadedSuggestedRoles: PropTypes.bool,
    suggestedRoles: PropTypes.array,
    roleEntities: PropTypes.object,
    // functions
    loadSuggestedRoles: PropTypes.func,
  };

  static defaultProps = {
    existing: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRoles:
        props.existing.map((role) => ({
          label: role,
          value: role,
        })) || [],
      suggestedRoles: this.sortSuggestedRoles(props),
    };
  }

  // componentWillMount() {
  //   const existingRoles = this.props.existing.map((role) => ({
  //     label: role,
  //     value: role,
  //   }));

  //   if (Object.keys(existingRoles).length > 0) {
  //     this.setState({ selectedRoles: existingRoles });

  //     if (this.props.onSelect) {
  //       this.props.onSelect(existingRoles);
  //     }
  //   }
  // }

  componentDidMount() {
    if (!!this.state.selectedRoles.length && this.props.onSelect) {
      this.props.onSelect(this.state.selectedRoles);
    }

    if (!this.props.loadingSuggestedRoles && !this.props.loadedSuggestedRoles) {
      this.props.loadSuggestedRoles();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedRoles !== this.state.selectedRoles &&
      this.props.onSelect
    ) {
      this.props.onSelect(this.state.selectedRoles);
    }

    if (prevProps.suggestedRoles.length !== this.props.suggestedRoles.length) {
      this.setSuggestedRoles();
    }
  }

  sortSuggestedRoles = (props = this.props) =>
    props.suggestedRoles
      .map((roleId) => props.roleEntities[roleId])
      .sort((a, b) => a.name.toUpperCase() - b.name.toUpperCase());

  setSuggestedRoles = () =>
    this.setState({ suggestedRoles: this.sortSuggestedRoles() });

  handleChange = (selectedRoles) =>
    this.props.maxValue && selectedRoles.length >= this.props.maxValue
      ? null
      : this.setState({
          selectedRoles,
        });

  render() {
    const { ...rest } = this.props;

    const { selectedRoles, suggestedRoles } = this.state;

    return (
      <SelectSelectize
        isMulti
        hideResetButton
        options={suggestedRoles.map(({ name }) => ({
          label: name,
          value: name,
        }))}
        values={selectedRoles}
        onValuesChange={this.handleChange}
        placeholder="Start typing your role"
        renderNoResultsFound={() => (
          <div className="no-results-found">
            {this.props.loadingSuggestedRoles
              ? 'Loading...'
              : 'No results found'}
          </div>
        )}
        {...rest}
        id={'selectors-RolesSelector-rolesSearch'}
      />
    );
  }
}
