const ADD_BANNER = 'twineApp/briefDetails/ADD_BANNER';
const REMOVE_BANNER = 'twineApp/briefDetails/REMOVE_BANNER';

const initialState = {
  banners: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BANNER:
      const addBanners = state.banners.filter(
        (banner) => banner.tag !== action.tag
      );
      addBanners.push(action);

      return {
        ...state,
        banners: addBanners,
      };

    case REMOVE_BANNER:
      return {
        ...state,
        banners: state.banners.filter((banner) => banner.tag !== action.tag),
      };

    default:
      return state;
  }
}

export function add(
  tag,
  message = '',
  color = 'primary',
  closeable = true,
  priority = 20
) {
  return {
    closeable,
    color,
    message,
    priority,
    tag,
    type: ADD_BANNER,
  };
}

export function remove(tag) {
  return {
    tag,
    type: REMOVE_BANNER,
  };
}
