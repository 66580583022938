const LOAD = 'twineApp/suggestedSkills/LOAD';
const LOAD_SUCCESS = 'twineApp/suggestedSkills/LOAD_SUCCESS';
const LOAD_FAIL = 'twineApp/suggestedSkills/LOAD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  suggestedSkills: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        suggestedSkills: action.result.suggested_skills,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.suggestedSkills && globalState.suggestedSkills.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.get('/suggestedSkills?include=questions&limit=1000'),
  };
}
