import { schema } from 'normalizr';
import inviteSchema from './inviteSchema';
import userSchema from './userSchema';
import voucherSchema from './voucherSchema';

const briefSchema = new schema.Entity('briefs');

briefSchema.define({
  invites: { invites: [inviteSchema] },
  user: userSchema,
  voucher: voucherSchema,
});

export default briefSchema;
