import { MEDIUM_STEPS, HIGH_STEPS } from 'constants/Steps';

/*
 * Functions that calculate the rank of a user:
 * - low rank (default)
 * - medium rank
 * - high rank
 */

// Ranks
const INVALID_RANK = 0;
const LOW_RANK = 1;
const MEDIUM_RANK = 2;
const HIGH_RANK = 3;
const MAX_RANK = HIGH_RANK;

const calculateProgress = (steps, user) => ({
  done: steps.reduce(
    (done, step) => (step.isComplete(user) ? done + 1 : done),
    0
  ),
  total: steps.length,
});

const calculateBasicProgress = (user = null) =>
  calculateProgress(MEDIUM_STEPS, user);

const calculateAdvancedProgress = (user = null) =>
  calculateProgress(HIGH_STEPS, user);

const calculateExtraProgress = (user = null) => calculateProgress([], user);

const computeUserRank = (user) => {
  if (!user) return INVALID_RANK;
  const basicProgress = calculateBasicProgress(user);
  const advancedProgress = calculateAdvancedProgress(user);
  const extraProgress = calculateExtraProgress(user);

  return extraProgress.done === extraProgress.total
    ? basicProgress.done === basicProgress.total
      ? advancedProgress.done === advancedProgress.total
        ? HIGH_RANK
        : MEDIUM_RANK
      : LOW_RANK
    : INVALID_RANK;
};

const getOverallProgress = (user) => {
  const { done: doneLow, total: totalLow } = calculateBasicProgress(user);
  const { done: doneMid, total: totalMid } = calculateAdvancedProgress(user);

  // If Medium rank is not yet met
  if (doneLow !== totalLow) {
    // doneLow / (totalLow + totalMid)
    return { now: doneLow, max: totalLow + totalMid };
  }

  // If High rank is not yet met - but Medium rank is
  if (doneMid !== totalMid) {
    // (doneLow + doneMid) / (totalLow + totalMid)
    return { now: doneLow + doneMid, max: totalLow + totalMid };
  }

  // 100% - High rank achieved
  return { now: 1, max: 1 };
};

const ranks = {
  INVALID_RANK: INVALID_RANK,
  LOW_RANK: LOW_RANK,
  MEDIUM_RANK: MEDIUM_RANK,
  HIGH_RANK: HIGH_RANK,
  MAX_RANK: MAX_RANK,
};

export {
  calculateBasicProgress,
  calculateAdvancedProgress,
  calculateExtraProgress,
  getOverallProgress,
  computeUserRank,
  ranks,
};
