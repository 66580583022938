import { fromJS, List } from 'immutable';
import { jsonToFormData } from '../../helpers/HelperFunctions';
import threadSchema from '../schemas/threadSchema';
import messageSchema from '../schemas/messageSchema';

const LOADINBOX = 'twineApp/messages/LOADINBOX';
const LOADINBOX_SUCCESS = 'twineApp/messages/LOADINBOX_SUCCESS';
const LOADINBOX_FAIL = 'twineApp/messages/LOADINBOX_FAIL';
const LOADMESSAGES = 'twineApp/messages/LOADMESSAGES';
const LOADMESSAGES_SUCCESS = 'twineApp/messages/LOADMESSAGES_SUCCESS';
const LOADMESSAGES_FAIL = 'twineApp/messages/LOADMESSAGES_FAIL';
const LOADMESSAGE = 'twineApp/messages/LOADMESSAGE';
const LOADMESSAGE_SUCCESS = 'twineApp/messages/LOADMESSAGE_SUCCESS';
const LOADMESSAGE_FAIL = 'twineApp/messages/LOADMESSAGE_FAIL';
const UPDATENEWMESSAGE = 'twineApp/messages/UPDATENEWMESSAGE';
const SENDMESSAGE = 'twineApp/messages/SENDMESSAGE';
const SENDMESSAGE_SUCCESS = 'twineApp/messages/SENDMESSAGE_SUCCESS';
const SENDMESSAGE_FAIL = 'twineApp/messages/SENDMESSAGE_FAIL';
const SENDFILE = 'twineApp/messages/SENDFILE';
const SENDFILE_SUCCESS = 'twineApp/messages/SENDFILE_SUCCESS';
const SENDFILE_FAIL = 'twineApp/messages/SENDFILE_FAIL';
const ISINTRO = 'twineApp/messages/ISINTRO';
const ISINTRO_SUCCESS = 'twineApp/messages/ISINTRO_SUCCESS';
const ISINTRO_FAIL = 'twineApp/messages/ISINTRO_FAIL';
const LOADPENDINGPITCHES = 'twineApp/messages/LOADPENDINGPITCHES';
const LOADPENDINGPITCHES_SUCCESS =
  'twineApp/messages/LOADPENDINGPITCHES_SUCCESS';
const LOADPENDINGPITCHES_FAIL = 'twineApp/messages/LOADPENDINGPITCHES_FAIL';
const LOADACCEPTEDPITCHES = 'twineApp/messages/LOADACCEPTEDPITCHES';
const LOADACCEPTEDPITCHES_SUCCESS =
  'twineApp/messages/LOADACCEPTEDPITCHES_SUCCESS';
const LOADACCEPTEDPITCHES_FAIL = 'twineApp/messages/LOADACCEPTEDPITCHES_FAIL';
const LOADACCEPTEDBRIEFS = 'twineApp/messages/LOADACCEPTEDBRIEFS';
const LOADACCEPTEDBRIEFS_SUCCESS =
  'twineApp/messages/LOADACCEPTEDBRIEFS_SUCCESS';
const LOADACCEPTEDBRIEFS_FAIL = 'twineApp/messages/LOADACCEPTEDBRIEFS_FAIL';
const LOADINTRO = 'twineApp/messages/LOADINTRO';
const LOADINTRO_SUCCESS = 'twineApp/messages/LOADINTRO_SUCCESS';
const LOADINTRO_FAIL = 'twineApp/messages/LOADINTRO_FAIL';
const CHANGEMESSAGESMODALUSER = 'twineApp/messages/CHANGEMESSAGESMODALUSER';
const LOADUNREAD = 'twineApp/messages/LOADUNREAD';
const LOADUNREAD_SUCCESS = 'twineApp/messages/LOADUNREAD_SUCCESS';
const LOADUNREAD_FAIL = 'twineApp/messages/LOADUNREAD_FAIL';
const DECREMENTMESSAGECOUNT = 'twineApp/messages/DECREMENTMESSAGECOUNT';
const DELETEMESSAGE = 'twineApp/messages/DELETEMESSAGE';
const DELETEMESSAGE_SUCCESS = 'twineApp/messages/DELETEMESSAGE_SUCCESS';
const DELETEMESSAGE_FAIL = 'twineApp/messages/DELETEMESSAGE_FAIL';
const ARCHIVETHREAD = 'twineApp/messages/ARCHIVETHREAD';
const ARCHIVETHREAD_SUCCESS = 'twineApp/messages/ARCHIVETHREAD_SUCCESS';
const ARCHIVETHREAD_FAIL = 'twineApp/messages/ARCHIVETHREAD_FAIL';
const CLEARMESSAGES = 'twineApp/messages/CLEARMESSAGES';
const CLEARINBOX = 'twineApp/messages/CLEARINBOX';

const ACCEPTPITCH_SUCCESS = 'twineApp/briefDetails/ACCEPTPITCH_SUCCESS';
const DECLINEPITCH_SUCCESS = 'twineApp/briefDetails/DECLINEPITCH_SUCCESS';

// For Websocket on nudge
const LOADNEWMESSAGE = 'twineApp/messages/LOADNEWMESSAGE';
const LOADNEWMESSAGE_SUCCESS = 'twineApp/messages/LOADNEWMESSAGE_SUCCESS';
const LOADNEWMESSAGE_FAIL = 'twineApp/messages/LOADNEWMESSAGE_FAIL';

const MARKASREAD = 'twineApp/messages/VIEWEDPITCHES';
const MARKASREAD_SUCCESS = 'twineApp/messages/VIEWEDPITCHES_SUCCESS';
const MARKASREAD_FAIL = 'twineApp/messages/VIEWEDPITCHES_FAIL';

const initialState = {
  inbox: [],
  inboxLoaded: false,
  inboxLoading: false,
  inboxPagesLoaded: 0,
  inboxMore: false,
  introsLeft: 0,
  introsLoading: false,
  messages: {},
  modalUser: null,
  unreadMessages: 0,
  loadingUnreadMessages: false,
  loadingMessage: false,
  loadedMessage: false,
  message: [],
  acceptedPitches: {},
  acceptedBriefs: {},
  isMarkingAsRead: false,
  isMarkedAsRead: false,
};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case LOADINBOX:
      return imState.set('inboxLoading', true).toJS();

    case LOADINBOX_SUCCESS:
      const inboxResult = action.result;

      return imState
        .merge({
          inboxLoading: false,
          inboxLoaded: true,
          inboxMore:
            typeof inboxResult.meta !== 'undefined'
              ? inboxResult.meta.pagination.current_page <
                inboxResult.meta.pagination.total_pages
              : false,
          inboxPagesLoaded: imState.get('inboxPagesLoaded') + 1,
          inbox:
            typeof inboxResult.threads !== 'undefined'
              ? imState.get('inbox').concat(inboxResult.threads)
              : List(), // eslint-disable-line new-cap
        })
        .toJS();

    case LOADINBOX_FAIL:
      return imState.set('inboxLoading', false).toJS();

    case LOADMESSAGES:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              loading: true,
              loaded: imState.hasIn(['messages', action.username, 'loaded'])
                ? imState.getIn(['messages', action.username, 'loaded'])
                : false,
              pagesLoaded: imState.hasIn([
                'messages',
                action.username,
                'pagesLoaded',
              ])
                ? imState.getIn(['messages', action.username, 'pagesLoaded'])
                : 0,
              more: imState.hasIn(['messages', action.username, 'more'])
                ? imState.getIn(['messages', action.username, 'more'])
                : false,
              messages: imState.hasIn(['messages', action.username, 'messages'])
                ? imState.getIn(['messages', action.username, 'messages'])
                : List([]), // eslint-disable-line new-cap
              newMessage: imState.getIn(
                ['messages', action.username, 'newMessage'],
                ''
              ),
              sendingMessage: false,
              introed: true,
            },
          },
        })
        .toJS();

    case LOADMESSAGES_SUCCESS:
      const messageResult = action.result;

      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              loading: false,
              loaded: true,
              more:
                messageResult.meta.pagination.current_page <
                messageResult.meta.pagination.total_pages,
              pagesLoaded:
                imState.getIn(['messages', action.username, 'pagesLoaded']) + 1,
              messages: fromJS(messageResult.messages)
                .reverse()
                .concat(
                  imState.getIn(['messages', action.username, 'messages'])
                ),
            },
          },
        })
        .toJS();

    case LOADMESSAGES_FAIL:
      // if it errors with a 404, it's OK, it just means we don't have any messages yet
      return action.error.status_code === 404
        ? imState
            .mergeDeep({
              messages: {
                [action.username]: {
                  loading: false,
                  loaded: true,
                },
              },
            })
            .toJS()
        : imState.toJS();

    case LOADNEWMESSAGE:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              loadingMessage: true,
              loadedMessage: false,
            },
          },
        })
        .toJS();

    case LOADNEWMESSAGE_SUCCESS:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              loadingMessage: false,
              loadedMessage: true,
              messages: imState
                .getIn(['messages', action.username, 'messages'], List())
                .push(action.entities.threads[action.result].message),
            },
          },
        })
        .toJS();

    case LOADNEWMESSAGE_FAIL:
      // if it errors with a 404, it's OK, it just means we don't have any messages yet
      return action.error.status_code === 404
        ? imState
            .mergeDeep({
              messages: {
                [action.username]: {
                  loadingMessage: false,
                  loadedMessage: true,
                },
              },
            })
            .toJS()
        : imState.toJS();

    case LOADMESSAGE:
      return imState
        .mergeDeep({
          loadingMessage: true,
          loadedMessage: false,
          message: List([]),
        })
        .toJS();

    case LOADMESSAGE_SUCCESS:
      return imState
        .mergeDeep({
          loadingMessage: false,
          loadedMessage: true,
          message: action.result.messages,
        })
        .toJS();

    case LOADMESSAGE_FAIL:
      return imState
        .mergeDeep({
          loadingMessage: false,
          loadedMessage: true,
        })
        .toJS();

    case UPDATENEWMESSAGE:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              newMessage: action.message,
            },
          },
        })
        .toJS();

    case SENDFILE:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              sendingMessage: true,
            },
          },
        })
        .toJS();

    case SENDFILE_SUCCESS:
      return imState
        .mergeDeep({
          inbox:
            imState.get('inbox').indexOf(action.result) === -1
              ? imState.get('inbox').unshift(action.result)
              : imState
                  .get('inbox')
                  .delete(imState.get('inbox').indexOf(action.result))
                  .unshift(action.result),
          messages: {
            [action.username]: {
              sendingMessage: false,
              messages: imState
                .getIn(['messages', action.username, 'messages'], List())
                .push(action.entities.threads[action.result].message),
            },
          },
        })
        .toJS();

    case SENDFILE_FAIL:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              sendingMessage: false,
            },
          },
        })
        .toJS();

    case SENDMESSAGE:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              sendingMessage: true,
            },
          },
        })
        .toJS();

    case SENDMESSAGE_SUCCESS:
      return imState
        .mergeDeep({
          inbox:
            imState.get('inbox').indexOf(action.result) === -1
              ? imState.get('inbox').unshift(action.result)
              : imState
                  .get('inbox')
                  .delete(imState.get('inbox').indexOf(action.result))
                  .unshift(action.result),
          introsLeft: imState.get('introsLeft') - 1,
          messages: {
            [action.username]: {
              sendingMessage: false,
              newMessage: '',
              messages: imState
                .getIn(['messages', action.username, 'messages'], List())
                .push(action.entities.threads[action.result].message),
              introed: true,
            },
          },
        })
        .toJS();

    case SENDMESSAGE_FAIL:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              sendingMessage: false,
            },
          },
        })
        .toJS();

    case ISINTRO_SUCCESS:
      return imState
        .mergeDeep({
          messages: {
            [action.username]: {
              introed: action.result.introed,
            },
          },
        })
        .toJS();

    case LOADPENDINGPITCHES:
      return imState
        .mergeDeep({
          pitches: {
            [action.username]: {
              loading: true,
              loaded: imState.hasIn(['pitches', action.username, 'loaded'])
                ? imState.getIn(['pitches', action.username, 'loaded'])
                : false,
            },
          },
        })
        .toJS();

    case LOADPENDINGPITCHES_SUCCESS:
      const pitchResult = action.result;

      return imState
        .mergeDeep({
          pitches: {
            [action.username]: {
              loading: false,
              loaded: true,
              pitches:
                typeof pitchResult.threads !== 'undefined'
                  ? fromJS(pitchResult.threads)
                  : [],
            },
          },
        })
        .toJS();

    case LOADPENDINGPITCHES_FAIL:
      return imState
        .mergeDeep({
          pitches: {
            [action.username]: {
              loading: false,
              loaded: false,
            },
          },
        })
        .toJS();

    case LOADACCEPTEDPITCHES:
      return imState
        .mergeDeep({
          acceptedPitches: {
            [action.username]: {
              loading: true,
              loaded: imState.hasIn(['pitches', action.username, 'loaded'])
                ? imState.getIn(['pitches', action.username, 'loaded'])
                : false,
            },
          },
        })
        .toJS();

    case LOADACCEPTEDPITCHES_SUCCESS:
      return imState
        .mergeDeep({
          acceptedPitches: {
            [action.username]: {
              loading: false,
              loaded: true,
              pitches:
                typeof action.result.threads !== 'undefined'
                  ? fromJS(action.result.threads)
                  : [],
            },
          },
        })
        .toJS();

    case LOADACCEPTEDPITCHES_FAIL:
      return imState
        .mergeDeep({
          acceptedPitches: {
            [action.username]: {
              loading: false,
              loaded: false,
            },
          },
        })
        .toJS();

    case LOADACCEPTEDBRIEFS:
      return imState
        .mergeDeep({
          acceptedBriefs: {
            [action.username]: {
              loading: true,
              loaded: imState.hasIn(['briefs', action.username, 'loaded'])
                ? imState.getIn(['briefs', action.username, 'loaded'])
                : false,
            },
          },
        })
        .toJS();

    case LOADACCEPTEDBRIEFS_SUCCESS:
      return imState
        .mergeDeep({
          acceptedBriefs: {
            [action.username]: {
              loading: false,
              loaded: true,
              pitches:
                typeof action.result.threads !== 'undefined'
                  ? fromJS(action.result.threads)
                  : [],
            },
          },
        })
        .toJS();

    case LOADACCEPTEDBRIEFS_FAIL:
      return imState
        .mergeDeep({
          acceptedBriefs: {
            [action.username]: {
              loading: false,
              loaded: false,
            },
          },
        })
        .toJS();

    case LOADINTRO:
      return imState
        .merge({
          introsLoading: true,
        })
        .toJS();

    case LOADINTRO_SUCCESS:
      return imState
        .merge({
          introsLoading: false,
          introsLeft: action.result.left,
        })
        .toJS();

    case LOADINTRO_FAIL:
      return imState
        .merge({
          introsLoading: false,
        })
        .toJS();

    case LOADUNREAD:
      return imState
        .merge({
          loadingUnreadMessages: true,
        })
        .toJS();

    case LOADUNREAD_SUCCESS:
      return imState
        .merge({
          loadingUnreadMessages: false,
          unreadMessages: action.result.count,
        })
        .toJS();

    case LOADUNREAD_FAIL:
      return imState
        .merge({
          loadingUnreadMessages: false,
        })
        .toJS();

    case CHANGEMESSAGESMODALUSER:
      return imState
        .merge({
          modalUser: action.user,
        })
        .toJS();

    case DECREMENTMESSAGECOUNT:
      return imState.merge({
        unreadMessages: imState.get('unreadMessages') - 1,
      });

    case DELETEMESSAGE:
      return imState
        .setIn(
          ['messages', action.username, 'messages'],
          imState
            .getIn(['messages', action.username, 'messages'])
            .filter((messageId) => messageId !== action.messageId)
        )
        .toJS();

    case ACCEPTPITCH_SUCCESS:
      return imState
        .setIn(
          ['pitches', action.pitch.sender.username, 'pitches'],
          imState
            .getIn(['pitches', action.pitch.sender.username, 'pitches'], List())
            .delete(
              imState
                .getIn(
                  ['pitches', action.pitch.sender.username, 'pitches'],
                  List()
                )
                .findIndex(
                  (idx) => idx.getIn(['message', 'id'], -1) === action.pitch.id
                )
            )
        )
        .toJS();

    case DECLINEPITCH_SUCCESS:
      return imState
        .setIn(
          ['pitches', action.pitch.sender.username, 'pitches'],
          imState
            .getIn(['pitches', action.pitch.sender.username, 'pitches'], List())
            .delete(
              imState
                .getIn(
                  ['pitches', action.pitch.sender.username, 'pitches'],
                  List()
                )
                .findIndex(
                  (idx) => idx.getIn(['message', 'id'], -1) === action.pitch.id
                )
            )
        )
        .toJS();

    case CLEARMESSAGES:
      return imState.deleteIn(['messages', action.username]).toJS();

    case ARCHIVETHREAD:
      return imState
        .merge({
          inbox: imState
            .get('inbox')
            .delete(imState.get('inbox').indexOf(action.id)),
        })
        .toJS();

    case CLEARINBOX:
      return imState
        .merge({
          inbox: [],
        })
        .toJS();

    case MARKASREAD:
      return imState
        .merge({
          isMarkingAsRead: true,
        })
        .toJS();

    case MARKASREAD_SUCCESS:
      return imState
        .merge({
          isMarkingAsRead: false,
          isMarkedAsRead: true,
        })
        .toJS();

    case MARKASREAD_FAIL:
      return imState
        .merge({
          isMarkingAsRead: false,
          isMarkedAsRead: false,
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export function loadInbox(page = 1, archived = false) {
  return {
    types: [LOADINBOX, LOADINBOX_SUCCESS, LOADINBOX_FAIL],
    promise: (client) =>
      client.get(
        '/threads?limit=20&page=' +
          page +
          '&include=message,user,user.avatars&archived=' +
          archived
      ),
    schema: { threads: [threadSchema] },
  };
}

export function loadMessages(username, page = 1) {
  return {
    types: [LOADMESSAGES, LOADMESSAGES_SUCCESS, LOADMESSAGES_FAIL],
    promise: (client) =>
      client.get(
        '/threads/' +
          username +
          '?limit=20&page=' +
          page +
          '&include=message,commission,sender,sender.avatars,receiver,receiver.avatars,notice,notice.voucher'
      ),
    schema: { messages: [messageSchema] },
    username,
  };
}

export function loadMessage(messageId) {
  return {
    types: [LOADMESSAGE, LOADMESSAGE_SUCCESS, LOADMESSAGE_FAIL],
    promise: (client) =>
      client.get('/messages/' + messageId + '?include=sender'),
    schema: messageSchema,
    messageId,
  };
}

export function loadNewMessage(username, otherUserId) {
  return {
    types: [LOADNEWMESSAGE, LOADNEWMESSAGE_SUCCESS, LOADNEWMESSAGE_FAIL],
    promise: (client) =>
      client.get('/messages/new/' + otherUserId + '?include=user,message'),
    schema: threadSchema,
    username: username,
  };
}

export function getInboxThreads(state) {
  const threadIds = state.messages.inbox;
  return threadIds.map((id) => state.entities.threads[id]);
}

export function getMessage(state, id) {
  return state.entities.messages[id];
}

export function getMessages(state, ids) {
  return ids.map((id) => getMessage(state, id));
}

export function updateNewMessage(username, message) {
  return {
    type: UPDATENEWMESSAGE,
    username,
    message,
  };
}

export function sendMessage(user, message, type = 'private') {
  let typeString = type;

  if (!(typeof typeString === 'string' || typeString instanceof String)) {
    typeString = 'private';
  }

  return {
    types: [SENDMESSAGE, SENDMESSAGE_SUCCESS, SENDMESSAGE_FAIL],
    promise: (client) =>
      client.post('/messages?include=user,message', {
        data: jsonToFormData({
          message: message,
          receiver_id: user.id,
          type: typeString,
        }),
      }),
    username: user.username,
    schema: threadSchema,
  };
}

export function sendFile(user, message) {
  return {
    types: [SENDFILE, SENDFILE_SUCCESS, SENDFILE_FAIL],
    promise: (client) =>
      client.post('/messages?include=user,message', {
        data: jsonToFormData({
          message: message,
          receiver_id: user.id,
          type: 'automated',
        }),
      }),
    username: user.username,
    schema: threadSchema,
  };
}

export function loadPendingPitches(username) {
  return {
    types: [
      LOADPENDINGPITCHES,
      LOADPENDINGPITCHES_SUCCESS,
      LOADPENDINGPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          '/pitches?state=pending&include=message.sender,message.notice,message.notice.voucher'
      ),
    username,
  };
}

export function loadAcceptedPitches(username) {
  return {
    types: [
      LOADACCEPTEDPITCHES,
      LOADACCEPTEDPITCHES_SUCCESS,
      LOADACCEPTEDPITCHES_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          username +
          '/pitches?state=accepted&include=message.sender,message.notice,message.notice.voucher'
      ),
    username,
  };
}

export function loadAcceptedBriefs(username, loggedInUserId) {
  return {
    types: [
      LOADACCEPTEDBRIEFS,
      LOADACCEPTEDBRIEFS_SUCCESS,
      LOADACCEPTEDBRIEFS_FAIL,
    ],
    promise: (client) =>
      client.get(
        '/users/' +
          loggedInUserId +
          '/pitches?state=accepted&include=message.notice&with=' +
          username
      ),
    username,
  };
}

export function loadIntros() {
  return {
    types: [LOADINTRO, LOADINTRO_SUCCESS, LOADINTRO_FAIL],
    promise: (client) => client.get('/messages/limit'),
  };
}

export function isIntro(username) {
  return {
    types: [ISINTRO, ISINTRO_SUCCESS, ISINTRO_FAIL],
    promise: (client) => client.get('/users/' + username + '/introed'),
    username,
  };
}

export function loadUnreadMessages() {
  return {
    types: [LOADUNREAD, LOADUNREAD_SUCCESS, LOADUNREAD_FAIL],
    promise: (client) => client.get('/notifications/messages/count'),
  };
}

export function decrementMessageCount() {
  return {
    type: DECREMENTMESSAGECOUNT,
  };
}

export function deleteMessage(username, messageId) {
  return {
    types: [DELETEMESSAGE, DELETEMESSAGE_SUCCESS, DELETEMESSAGE_FAIL],
    promise: (client) => client.delete('/messages/' + messageId),
    messageId,
    username,
  };
}

export function clearMessages(username) {
  return {
    type: CLEARMESSAGES,
    username,
  };
}

export function clearInbox() {
  return {
    type: CLEARINBOX,
  };
}

export function archiveThread(id, archive = true) {
  const data = {
    archive: archive.toString(),
  };

  return {
    types: [ARCHIVETHREAD, ARCHIVETHREAD_SUCCESS, ARCHIVETHREAD_FAIL],
    promise: (client) => client.patch('/threads/' + id + '/archive', { data }),
    id,
  };
}

export function markAsRead(messageID) {
  return {
    types: [MARKASREAD, MARKASREAD_SUCCESS, MARKASREAD_FAIL],
    promise: (client) => client.patch('/messages/viewed/' + messageID),
  };
}
