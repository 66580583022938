import _ from 'lodash';
import queryString from 'query-string';
import { fromJS, List } from 'immutable';

import browseSchema from '../schemas/browseSchema';

const FILTER = 'twineApp/browse/FILTER';
const FILTER_SUCCESS = 'twineApp/browse/FILTER_SUCCESS';
const FILTER_FAIL = 'twineApp/browse/FILTER_FAIL';

const initialState = {
  filterResults: {},
};

export default function reducer(state = initialState, action = {}) {
  const imState = fromJS(state);

  switch (action.type) {
    case FILTER:
      return imState
        .mergeDeep({
          filterResults: {
            [action.hash]: {
              loading: true,
              loaded: imState.getIn(
                ['filterResults', action.hash, 'loaded'],
                false
              ),
            },
          },
        })
        .toJS();

    case FILTER_SUCCESS:
      const users = action.result.users ? action.result.users : [];
      const meta = action.result.meta ? action.result.meta : null;

      return imState
        .mergeDeep({
          filterResults: {
            [action.hash]: {
              loading: false,
              loaded: true,
              results: imState
                .getIn(['filterResults', action.hash, 'results'], List())
                .concat(List(users)),
              total: imState.getIn(
                ['filterResults', action.hash, 'total'],
                meta ? meta.pagination.total : 0
              ),
              totalPages: imState.getIn(
                ['filterResults', action.hash, 'totalPages'],
                meta ? meta.pagination.total_pages : 0
              ),
            },
          },
        })
        .toJS();

    case FILTER_FAIL:
      return imState
        .mergeDeep({
          filterResults: {
            [action.hash]: {
              loading: false,
            },
          },
        })
        .toJS();

    default:
      return imState.toJS();
  }
}

export function isFilterSet(filter) {
  // Checks if filter is set
  // based on the query string params
  const keys = ['role', 'skill', 'location', 'minDayRate', 'maxDayRate'];

  // Check each key if defined, != null, != '', etc
  return keys.reduce((prev, next) => {
    return prev || Boolean(_.get(filter, next, false));
  }, false);
}

export function getFilterFromQS(location) {
  const { search } = location;
  const queryStringParams = queryString.parse(search);

  const filter = {
    role: queryStringParams.role || '',
    skill: queryStringParams.skill || '',
    location: queryStringParams.location || '',
    minDayRate: queryStringParams.minDayRate || '',
    maxDayRate: queryStringParams.maxDayRate || '',
    page: queryStringParams.page || 1,
  };

  return filter;
}

export function getFilterHash(filter) {
  return encodeURIComponent(
    `${filter.role || ''}|${filter.skill || ''}|${filter.location || ''}|${
      filter.minDayRate || ''
    }|${filter.maxDayRate || ''}|${filter.page || ''}`
  );
}

export function isUsersLoaded(globalState, filter) {
  const hash = getFilterHash(filter);

  return (
    globalState.browse &&
    typeof globalState.browse.filterResults !== 'undefined' &&
    typeof globalState.browse.filterResults[hash] !== 'undefined' &&
    typeof globalState.browse.filterResults[hash].loaded !== 'undefined' &&
    globalState.browse.filterResults[hash].loaded
  );
}

export function filter(filter, limit = 20) {
  const hash = getFilterHash(filter);
  let queryString = `?include=avatars,skills,covers&limit=${limit}`;

  // Pagination
  const page = _.get(filter, 'page', 1);
  queryString += `&page=${page}`;

  // Role filter
  if (_.get(filter, 'role', false)) {
    queryString += `&role=${filter.role}`;
  }

  // Skill filter
  if (_.get(filter, 'skill', false)) {
    queryString += `&skill=${filter.skill}`;
  }

  // Location filter
  if (_.get(filter, 'location', false)) {
    queryString += `&locationText=${filter.location}`;
  }

  // Day Rate filter
  if (_.get(filter, 'minDayRate', false)) {
    queryString += `&minDayRate=${filter.minDayRate}`;
  }
  if (_.get(filter, 'maxDayRate', false)) {
    queryString += `&maxDayRate=${filter.maxDayRate}`;
  }

  return {
    types: [FILTER, FILTER_SUCCESS, FILTER_FAIL],
    promise: (client) => client.get(`/browse${queryString}`),
    hash: hash,
    page: page,
    limit: limit,
    schema: {
      users: [browseSchema],
    },
  };
}
