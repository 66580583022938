import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal/Modal';
import Button from 'react-bootstrap/lib/Button';

import styles from './AlertBox.module.scss';

const AlertBox = ({
  cancelButtonText = 'Cancel',
  confirmButtonText = 'OK',
  disableCancelButton = false,
  disableConfirmButton = false,
  icon = '',
  onCancel = null,
  onConfirm = null,
  onEnter = null,
  show = false,
  text = '',
  title = '',
  theme = 'primary',
}) => (
  <Modal
    animation="scale"
    className={styles.container}
    onEnter={() => (onEnter ? onEnter() : null)}
    show={show}
  >
    {show && icon === 'error' && (
      <div className={`${styles.icon} ${styles.alertBoxIconError}`}>
        <span className={`${styles.alertBoxIconXMark}`}>
          <span
            className={`${styles.alertBoxIconLine} ${styles.alertBoxIconLeft}`}
          ></span>
          <span
            className={`${styles.alertBoxIconLine} ${styles.alertBoxIconRight}`}
          ></span>
        </span>
      </div>
    )}
    {show && icon === 'warning' && (
      <div className={`${styles.icon} ${styles.alertBoxIconWarning}`}>
        <span className={`${styles.alertBoxIconBody}`}></span>
        <span className={`${styles.alertBoxIconDot}`}></span>
      </div>
    )}
    {show && icon === 'info' && (
      <div className={`${styles.icon} ${styles.alertBoxIconInfo}`}></div>
    )}
    {show && icon === 'success' && (
      <div className={`${styles.icon} ${styles.alertBoxIconSuccess}`}>
        <span
          className={`${styles.alertBoxIconLine} ${styles.alertBoxIconTip}`}
        ></span>
        <span
          className={`${styles.alertBoxIconLine} ${styles.alertBoxIconLong}`}
        ></span>
        <div className={`${styles.alertBoxIconPlaceholder}`}></div>
        <div className={`${styles.alertBoxIconFix}`}></div>
      </div>
    )}
    {title !== '' && <div className={styles.title}>{title}</div>}
    {text !== '' && <div className={styles.text}>{text}</div>}
    <div className={styles.buttons}>
      <Button
        bsStyle={theme}
        disabled={disableConfirmButton}
        onClick={() => (onConfirm ? onConfirm() : null)}
      >
        {confirmButtonText}
      </Button>
      {onCancel && (
        <Button
          bsStyle="info"
          disabled={disableCancelButton}
          onClick={() => onCancel()}
        >
          {cancelButtonText}
        </Button>
      )}
    </div>
  </Modal>
);

AlertBox.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  disableCancelButton: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  icon: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onEnter: PropTypes.func,
  show: PropTypes.bool,
  text: PropTypes.node,
  title: PropTypes.string,
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'info',
  ]),
};

export default AlertBox;
