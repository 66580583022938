const POSTBRIEF = 'twineApp/briefs/POSTBRIEF';
const POSTBRIEF_SUCCESS = 'twineApp/briefs/POSTBRIEF_SUCCESS';
const POSTBRIEF_FAIL = 'twineApp/briefs/POSTBRIEF_FAIL';
const LOADBRIEFSWITHSTATE = 'twineApp/briefs/LOADBRIEFSWITHSTATE';
const LOADBRIEFSWITHSTATE_SUCCESS =
  'twineApp/briefs/LOADBRIEFSWITHSTATE_SUCCESS';
const LOADBRIEFSWITHSTATE_FAIL = 'twineApp/briefs/LOADBRIEFSWITHSTATE_FAIL';

const initialState = {
  postingBrief: false,
  postedBrief: null,
  loadingBriefsWithState: false,
  briefsWithState: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case POSTBRIEF:
      return {
        ...state,
        postingBrief: true,
      };

    case POSTBRIEF_SUCCESS:
      return {
        ...state,
        postingBrief: false,
        postedBrief: action.result,
      };

    case POSTBRIEF_FAIL:
      return {
        ...state,
        postingBrief: false,
      };

    case LOADBRIEFSWITHSTATE:
      return {
        ...state,
        loadingBriefsWithState: true,
      };

    case LOADBRIEFSWITHSTATE_SUCCESS:
      return {
        ...state,
        loadingBriefsWithState: false,
        briefsWithState:
          typeof action.result.collaborations !== 'undefined'
            ? action.result.collaborations
            : [],
      };

    case LOADBRIEFSWITHSTATE_FAIL:
      return {
        ...state,
        loadingBriefsWithState: false,
      };

    default:
      return state;
  }
}

export function postBrief(
  title,
  description,
  role,
  skill,
  budget,
  currency,
  answers,
  isPrivate,
  isRemote,
  location,
  externalUrl
) {
  const data = {
    text: title,
    spec: description,
    amount: budget,
    currency,
    questions: answers,
    private: isPrivate ? 1 : 0,
    remote: isRemote ? 1 : 0,
    location,
    externalUrl,
  };

  if (role) {
    data.role = role;
  } else if (skill) {
    data.skill = skill;
  }

  return {
    types: [POSTBRIEF, POSTBRIEF_SUCCESS, POSTBRIEF_FAIL],
    promise: (client) => client.post('/notices/collaboration', { data }),
  };
}

export function loadBriefsWithState(userId, page = 1, status = []) {
  let url =
    '/users/' +
    userId +
    '/notices/collaboration?page=' +
    page +
    '&include=pitches:accepted(true)';

  if (status.length > 0) {
    url = url + '&state=' + status.join(',');
  }

  if (status.length > 1) {
    url = url + '&include_state=1';
  }

  return {
    types: [
      LOADBRIEFSWITHSTATE,
      LOADBRIEFSWITHSTATE_SUCCESS,
      LOADBRIEFSWITHSTATE_FAIL,
    ],
    promise: (client) => client.get(url),
    userId,
  };
}
