import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row,
} from 'react-bootstrap';
import Panel from 'components/Panel/Panel';
import styles from './EditProject.module.scss';
import RolesSelector from 'components/Shared/Selectors/RolesSelector';
import {
  updateProject,
  loadCredits,
  loadProject,
  updateCredits,
} from 'redux/modules/textProjects';
import useAuth from 'hooks/useAuth';
import Loader from 'components/Loader/Loader';
import MarkdownSection from 'components/TextProjects/MarkdownSection';
import { setSuccessMessage } from 'redux/modules/successMessage';

const EditProject = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { hash } = useParams();

  useEffect(() => {
    dispatch(loadProject('c' + hash));
    dispatch(loadCredits('c' + hash));
  }, []);

  const projectSelect = (state) => state.textProjects;
  const projectState = useSelector(projectSelect);

  const {
    loadingProject,
    loadingCredits,
    updatingProject,
    updatingCredits,
    project,
    credits,
  } = projectState;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loadingProject && !loadingCredits) {
      setLoading(false);
      setTitle(project?.title);
      setDescription(project?.description);
      const creditedRoles = credits[0]?.roles.map((role) => role.name);
      setSelectedRoles(creditedRoles);
    } else {
      setLoading(true);
    }
  }, [project, credits, loadingProject, loadingCredits]);

  const handleRolesChange = (roles) => {
    setSelectedRoles(roles);
  };

  const handleUpdateProject = () => {
    setErrors({});
    dispatch(updateProject(project.id, { title, description }))
      .then(() => {
        dispatch(
          updateCredits(
            project.id,
            user.id,
            selectedRoles.map((role) => role.value)
          )
        )
          .then(() => {
            window.location.href = project.links.main_relative;
            dispatch(setSuccessMessage('Project successfully updated'));
          })
          .catch((error) => {
            console.error('Error updating credits:', error);
            setErrors(error.errors);
          });
      })
      .catch((error) => {
        console.error('Error updating project:', error);
        setErrors(error.errors);
      });
  };

  return (
    <div className="mt++ lato-regular">
      <Grid>
        <Row>
          <Col lg={8}>
            <Panel className={styles.container}>
              {loading ? (
                <Loader loading />
              ) : (
                <>
                  <p className={styles.title}>Editing {project?.title}</p>
                  <FormGroup controlId="project-title">
                    <ControlLabel className="fs-15">Project Title</ControlLabel>
                    <FormControl
                      type="text"
                      value={title || ''}
                      placeholder="Enter the title of your project here"
                      onChange={(event) => setTitle(event.target.value)}
                      maxLength="140"
                      style={{ fontSize: '14px' }}
                      disabled={updatingProject}
                    />
                    {errors?.title && (
                      <HelpBlock className={styles.error}>
                        {errors?.title[0]}
                      </HelpBlock>
                    )}
                  </FormGroup>
                  <FormGroup controlId="project-description">
                    <ControlLabel className="fs-15">
                      Project Description
                    </ControlLabel>
                    <FormControl
                      componentClass="textarea"
                      value={description || ''}
                      placeholder="Enter the description of your project here. Use the cheat sheet on this page to see how you can make use of the Markdown support and various services embeds"
                      onChange={(event) => setDescription(event.target.value)}
                      style={{ fontSize: '14px' }}
                      rows="10"
                      disabled={updatingProject}
                    />
                    {errors?.description && (
                      <HelpBlock className={styles.error}>
                        {errors?.description[0]}
                      </HelpBlock>
                    )}
                  </FormGroup>
                  <FormGroup controlId="project-credits">
                    <ControlLabel className="fs-15">
                      Credit your role(s)
                    </ControlLabel>
                    <RolesSelector
                      maxValues={5}
                      style={{ width: '100%' }}
                      existing={selectedRoles}
                      onSelect={handleRolesChange}
                      disabled={updatingCredits}
                    />
                    {errors.roles && (
                      <HelpBlock className={styles.error}>
                        Please fill in this field with at least 1 role selected
                      </HelpBlock>
                    )}
                  </FormGroup>
                  <div className={styles.buttonContainer}>
                    <Button
                      bsStyle="default"
                      href={project?.links?.main_relative}
                    >
                      Cancel
                    </Button>
                    <Button bsStyle="primary" onClick={handleUpdateProject}>
                      Save Changes
                    </Button>
                  </div>
                </>
              )}
            </Panel>
          </Col>
          <Col lg={4}>
            <MarkdownSection />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default EditProject;
