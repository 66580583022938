const CREATIVE_DESIGN_JOBS = [
  '3D Designer',
  'Advert Design',
  'Album Art Designer',
  'App Design',
  'Artwork',
  'Artworker',
  'Banner',
  'Banner Ad Designer',
  'Brand Designer',
  'Branding',
  'Brochure',
  'Business Card',
  'Card Deck',
  'Catalogue',
  'Concept Visualizer',
  'Creative Director',
  'Data Visualizer',
  'Designer',
  'Flyer',
  'Flyer Designer',
  'GIF',
  'Graphic Designer',
  'Greetings Card',
  'Icon',
  'Image Editor',
  'Infographic',
  'Infographic Designer',
  'Interior Designer',
  'Letterhead',
  'Lettering',
  'Logo',
  'Logo Designer',
  'Magazine',
  'Monogram Designer',
  'Packaging Designer',
  'Pitch Deck',
  'Pitch Deck Designer',
  'Postcard',
  'Poster',
  'Poster Designer',
  'Presentation Design',
  'Presentation Designer',
  'Product Design',
  'Product Designer',
  'Signage',
  'Sticker',
  'Surface Pattern Design',
  'T-Shirt Designer',
  'T-shirt Design',
  'UI',
  'UI Designer',
  'Web Designer',
];

const CREATIVE_ILLUSTRATION_JOBS = [
  '3D Visualizer',
  'Acrylic Illustration',
  'Advertising Illustration',
  'Architectural Illustrator',
  'Book Cover',
  'Book Cover Designer',
  'Book Illustration',
  'Book Illustrator',
  'Caricature Artist',
  'Cartoon',
  'Character Design',
  'Charcoal Illustration',
  'Childrens Book Illustration',
  'Children’s Book Illustrator',
  'Collage Illustration',
  'Colourist',
  'Comic Illustrator',
  'Comic Strip',
  'Concept Artist',
  'Editorial Illustration',
  'Event Visualizer',
  'Fantasy Illustration',
  'Fashion Design',
  'Fashion Illustration',
  'Freehand Digital Illustration',
  'Freelance Digital Illustrator',
  'Gouache Illustration',
  'Illustration',
  'Illustrator',
  'Lithographer',
  'Narrative Illustration',
  'Pen and Ink Illustration',
  'Pencil Illustration',
  'Scamp Artist',
  'Spot Illustration',
  'Storyboard Artist',
  'Tattoo Design',
  'Tattoo Illustration',
  'Tattoo Illustrator',
  'Technical Illustration',
  'Textile Designer',
  'Vector Illustrator',
  'Watercolour Illustration',
];

const CREATIVE_MUSIC_JOBS = [
  'Acapella',
  'Arranger',
  'Audio Engineer',
  'Audio Mixer',
  'Backing Vocal',
  'Banjoist',
  'Bass Drum Player',
  'Bassist',
  'Beatboxer',
  'Beats',
  'Cellist',
  'Chamber Orchestra',
  'Composer',
  'Conductor',
  'DJ',
  'Double Bassist',
  'Drummer',
  'Film Composer',
  'Flutist',
  'Guitarist',
  'Harmonica Player',
  'Hook',
  'Instrumental',
  'Jingle',
  'Keyboards',
  'Lead Vocal',
  'Master',
  'Mastering Engineer',
  'Mix',
  'Mixing Engineer',
  'Music Composer',
  'Music Editor',
  'Music Producer',
  'Oboist',
  'Orchestra',
  'Orchestrator',
  'Organist',
  'Percussion',
  'Pianist',
  'Piano Trio',
  'Podcast Producer',
  'Rap Artist',
  'Saxophonist',
  'Session Artist',
  'Singer',
  'Sitar',
  'Snare Drum Player',
  'Songwriter',
  'Sound Designer',
  'Sound Editor',
  'Soundtrack',
  'Soundtrack Musician',
  'Stem',
  'String Quartet',
  'Strings',
  'Theme Tune',
  'Track',
  'Trombonist',
  'Trumpeter',
  'Vibraphone',
  'Violinist',
  'Vocal',
  'Vocal Feature',
  'Vocalist',
];

const CREATIVE_PHOTO_JOBS = [
  'Photo',
  'Photographer',
  'Photoshop Editor',
  'Portrait',
  'Stock Image',
];

const CREATIVE_VIDEO_JOBS = [
  '2D Animation',
  '2D Animator',
  '3D Animation',
  '3D Animator',
  '3D Design',
  '3D Model',
  'Actor',
  'Actor Showreel',
  'Actress',
  'Animation',
  'Animator',
  'Camera Operator',
  'Cinematographer',
  'Corporate Video',
  'Costume Design',
  'Director of Photography',
  'Explainer Video',
  'Film Director',
  'Film Producer',
  'Filmmaker',
  'Hair and Makeup',
  'Motion Graphic',
  'Motion Graphic Designer',
  'Music Video',
  'Presenter',
  'Promo Video',
  'Set Design',
  'Short Film',
  'Stop Motion Animation',
  'Stylist',
  'Video Advert',
  'Video Editor',
  'Videographer',
  'Visual Effects',
  'Voiceover',
  'Voiceover Artist',
  'Wardrobe Supervisor',
];

export {
  CREATIVE_DESIGN_JOBS,
  CREATIVE_ILLUSTRATION_JOBS,
  CREATIVE_MUSIC_JOBS,
  CREATIVE_PHOTO_JOBS,
  CREATIVE_VIDEO_JOBS,
};
